import ImmutableTimeEntry from '../immutables/ImmutableTimeEntry';
import ImmutableTimer from '../immutables/ImmutableTimer';
import ImmutableTemplate from '../immutables/ImmutableTemplate';
import { number } from 'prop-types';

export enum MatterTypeText {
    BILLABLE = 'BILLABLE',
    NON_BILLABLE = 'NON-BILLABLE'
}
export interface TimerChunk {
    id?: number;
    startTime: string;
    endTime: string;
    description: string;
    timerId: number;
    timeEntryId?: number | null;
    deleted: boolean;
    used: boolean;
    submitted: boolean;
    timeEntry?: ImmutableTimeEntry;
}
export interface NarrativeWarning {
    bannedWord: string;
    responseMessage: string;
}
export interface Matter {
    id: number;
    number: string;
    name: string;
    description: string;
    clientId: number;
    matterGroup?: string;
    isPhaseCode: boolean;
    isActCode: boolean;
    isFfTaskCode: boolean;

    clientName: string;
    clientNumber: string;
    status: string;
    language: string;
    languageText: string;
    timeEntryUnit: string;
    entryType: string;
    type: string;
    typeText: MatterTypeText;
    statusDescription: string;
    lastModified: string;
    startDate: string;
    endDate: string;
    bannedWords: string[];
    blockBillingWords: string[];
    tracked?: boolean;
    maxLength?: number;
    minLength?: number;
    billingPartnerName?: string;
    billingManagerName?: string;
    billingOffice?: string;
    narrativeWarnings?: NarrativeWarning[];
}
export interface Client {
    id: number;
    name: string;
    number: string;
    clientGroup?: string;
    maxLength?: number;
    minLength?: number;
    parent?: boolean;
    searchTerm?: string;
}
export enum CodeType {
    TASK = 'TASK',
    FFTASK = 'FFTASK',
    FFACT = 'FFACT',
    PHASE = 'PHASE',
    ACT = 'ACT'
}
export interface Code {
    id: number;
    name: string;
    description: string;
    type: CodeType;
    codeSet?: string;
    deleted?: boolean;
    startDate?: string;
    endDate?: string;
    lastModified?: string;
}
export interface WorkLocale {
    createdOn: string;
    id: number;
    lastModified: string;
    localeSearch: string;
    sapCode: string;
    workCity: string;
    workCountry: string;
    workRegion: string;
}

export interface LocalStorageWorkLocale {
    workLocaleId: number;
    tkId: number;
}
export interface TimeKeeperAssignment {
    id: number;
    timeKeeperId: number;
    startDate: string;
    endDate: string;
    office: string;
    officeName: string;
    name: string;
    networkId: string;
    delegated: boolean;
    deleted: boolean;
    writable: boolean;
}
export interface ActionCode {
    id: number;
    actionCode: string;
    actionText: string;
    actionResponse: string | null | undefined;
    stopEntry: boolean;
}
export interface User {
    displayName: string;
    name: string;
    id: number;
    valid: boolean;
}
export class Narrative {
    id?: number;
    key: string;
    replacement: string;
    deleted: boolean;
    global: boolean;
    lastModified: string;

    constructor(key?: string, replacement?: string) {
        this.key = key ? key : '';
        this.replacement = replacement ? replacement : '';
        this.global = false;
    }
    
    clone = () => {
        return Object.assign(new Narrative(),
            JSON.parse(JSON.stringify(this))
        );
    }
}
export interface CodeSetTemplate {
    id: number;
    codeSetName: string;
    codeSetRef: string;
    phaseId: number;
    phaseName: string;
    phaseDesc: string;
    taskCodeId: number;
    taskCode: string;
    taskCodeDesc: string;
    actCodeId: number;
    actCode: string;
    actCodeDesc: string;
    actionCodeId: number;
    actionCode: string;
    codeSetNarrative: string;
    inactive: boolean;
}
export enum LoginMode {
    CREDENTIALS_ONLY = 'CREDENTIALS_ONLY',
    EAGER_SSO_FALLBACK = 'EAGER_SSO_FALLBACK',
    EAGER_SSO_NOFALLBACK = 'EAGER_SSO_NOFALLBACK',
    OPTIONAL_SSO = 'OPTIONAL_SSO'
}
export enum WeekDays {
    SUN = 'SUN',
    MON = 'MON',
    TUE = 'TUE',
    WED = 'WED',
    THURS = 'THURS',
    FRI = 'FRI',
    SAT = 'SAT'
}
export interface TkGoals {
    april: number;
    august: number;
    december: number;
    february: number;
    goalYear: number;
    id: number;
    january: number;
    july: number;
    june: number;
    march: number;
    may: number;
    november: number;
    october: number;
    office: string;
    september: number;
    timekeeperId: number;
    tkName: string;
    yearTotal: number;
}
export enum Months {
    january = 1,
    february,
    march,
    april,
    may,
    june,
    july,
    august,
    september,
    october,
    november,
    december
}
export interface Features {
    EpochConfigWorkLocaleEnabled: boolean;
    EpochConfigActionCodesRequired: boolean;
    EpochConfigCodeSetTemplatesEnabled: boolean;
    EpochConfigNarrativesMinimumChars: number;
    EpochConfigNarrativesMaximumChars: number;
    EpochConfigLoginMode: LoginMode;
    EpochConfigCalendarFirstDayOfWeek: WeekDays
    EpochConfigFlatFeeCodesEnabled: boolean;
    EpochConfigTimeSegmentsSubmittedVisible: boolean;
    EpochConfigTimeEntriesMattersRequired: boolean;
    EpochConfigTimeCastEnabled: boolean;
    EpochConfigSyncPeriodInMinutes: number;
    EpochConfigTimeCastPullFromLocalOutlook: boolean;
    EpochConfigTimeCastConnectAutomaticallyByDefault: boolean;
    EpochConfigReferenceRequired: boolean;
    EpochConfigTKGoalsEnabled: boolean;
    EpochConfigKerberosEnabled: boolean;
    EpochConfigTrackedMatterClientsEnabled: boolean;
    EpochConfigTimeCastSegmentsFetchDays: number;
    EpochConfigTimeCastSegmentsRetentionDays: number;
    EpochConfigTimeEntriesPageDays: number;
    EpochConfigTimersEnabled: boolean;
    EpochConfigMattersPageEnabled: boolean;
    EpochConfigTimeCastURLWhiteListEnabled: boolean;
    EpochConfigClientGroupSearch: boolean;
    EpochConfigMatterLabel: string;
    EpochConfigValidateRestrictionsOnSave: boolean;
}
export class CustomDictionary {
    id?: number;
    userId: number;
    dictionary: string;
    deleted: boolean;
    lastModified: string;
    dirty: boolean;
    errors: {
        spaceError: boolean,
        dupError: boolean,
        blankError: boolean
    };

    constructor(dictionary?: string) {
        this.dictionary = dictionary ? dictionary : '';
        this.deleted = false;
        this.dirty = false;
        this.errors = {
            spaceError: false,
            dupError: false,
            blankError: false
        }
    }

    clone = () => {
        return Object.assign(new CustomDictionary(),
            JSON.parse(JSON.stringify(this))
        );
    }
    
    toWriteable = () => {
        return {
            id: this.id,
            userId: this.userId,
            dictionary: this.dictionary,
            deleted: this.deleted
            
        }
    }
}
export interface MatterListType {
    id: number;
    name: string;
    number: string;
    isHeader: boolean;
    clientName?: string;
    clientId: number;
    description: string;
}
export interface TkHours {
    id: number;
    timeKeeperId: number;
    startDate: string;
    endDate: string;
    dayHours: number;
    weekHours: number;
    monthHours: number;
    yearHours: number;
}
export interface TimeCastProgram {
    id?: number;
    code: string;
    programName: string;
    operatingSystem: string;
    architecture: string;
    knownExecutable: string;
    knownPath: string;
    submittedBy: number;
    createdOn: string;
    lastModified: string;
    color: string;
    
    deleted?: true
}
interface TimeCastSegmentData {}
export interface PhoneCallData extends TimeCastSegmentData {
    fromNumber: string
    toNumber: string
    initiator: boolean
    otherParty?: {
        email: string
        fullName: string
    }
}
export interface CalendarEventData extends TimeCastSegmentData {
    subject: string
    myMeetingResponse: 'Unknown'
                     | 'Organizer'
                     | 'Tentative'
                     | 'Accept'
                     | 'Decline'
                     | 'NoResponseReceived'
}
export interface SentMailData extends TimeCastSegmentData {
    subject: string,
    toRecipients: string[]
}
export interface ConversationHistoryData extends TimeCastSegmentData {
    subject: string
}
export interface SegmentOptions {
    extractFromLocalOutlook: boolean
}
/**
 * This is no longer used, but older DESKTOP_CAPTURE segments may
 * have data in this format.
 * 
 * Should be removed sometime in the future.
 */
export interface DeprecatedDesktopCaptureData extends TimeCastSegmentData {
    windowid: number
    parentWindowId: number
    windowTitle: string
    isParent: boolean
    processId: number
    processName: string
    moduleName: string
    filePath: string
    applicationBestGuess: string
}
export interface DesktopCaptureData extends TimeCastSegmentData {
    title: string
    id: number
    app: string
    matterNumber: string;
}
export type TimeCastSegmentType = 'DESKTOP_CAPTURE'
    | 'CALENDAR_EVENT'
    | 'VIRTUAL_CALENDAR_EVENT'
    | 'SENT_EMAIL'
    | 'VIRTUAL_SENT_EMAIL'
    | 'PHONE_CALL'
    | 'VIRTUAL_PHONE_CALL'
    | 'UNKNOWN'
    | 'CONVERSATION_HISTORY'

export interface TimeCastSegment {
    id?: number
    startTime: string
    endTime: string
    data: TimeCastSegmentData // NOTE: This could potentially have undocumented properties!
    type: TimeCastSegmentType
    associatedTimeEntry: number | null | undefined
    foreignIdentifier: string
    createdBy: number
    createdOn: string
    lastModified: string
    
    deleted?: true
}
export interface Setting {
    id?: number | null;
    key: string
    value: string
    deleted: boolean
    global: boolean
    lastModified?: string
}
export enum PdfFormatType {
    DATETYPE = 'Work Date',
    MATTERTYPE = 'Matter',
    CLIENTTYPE = 'Client'
}
export class TimeEntryPdfHeader {
    title: string;
    dataKey: string;

    constructor(title: string, key: string) {
        this.title = title;
        this.dataKey = key;
    }
}
export interface TimeEntryPdfCell {
    workdate?: string;
    client?: string;
    matter?: string;
    office: string | null | undefined;
    phase: string;
    task: string;
    activity: string;
    ffTask?: string;
    ffAct?: string;
    action?: string | null | undefined;
    duration: string;
    status: string;
}
export interface OfflineItems {
    TimeEntries: ImmutableTimeEntry[],
    Timers: ImmutableTimer[],
    Templates: ImmutableTemplate[],
    TimerChunks: Map<number, TimerChunk[]>
}
export interface CodeSetFlags {
    isPhaseCode: boolean;
    isActCode: boolean;
    isFfTaskCode: boolean;
    phases: Code[];
    ffTasks: Code[];
    activities: Code[];
}
export interface Progress {
    message: string;
    percent?: number;
    option?: {};
}
export interface CaptureSegmentsListObservables {
    expandedApplications: string[],
    selectedSegments: number,
    expandedTimeGroups: string[],
    isTCViewInProgress: boolean
}
export interface DayCount {
    workDate: string,
    count: number
}
export enum TimeEntryType {
    NORMAL = 'NORMAL',
    COPY = 'COPY',
    MERGE = 'MERGE',
    SPLIT = 'SPLIT',
    TRANSFER = 'TRANSFER',
    COLLABORATE = 'COLLABORATE',
    GRID = 'GRID'
}

export interface TkOffice {
    createdBy: number,
    createdDate: string,
    endDate: string,
    id: number,
    lastEditedBy: number,
    lastModified: string,
    office: string,
    officeName: string,
    startDate: string,
    timekeeperId: number,
    deleted: boolean
}

export interface TkOfficeOfficeName {
    office: string,
    officeName: string
}
export interface EULA {
    eulaText: string;
    lastModified: Date;
}
export interface CollaborateType {
    timeKeeperId: number,
    tkName: string,
    matterId?: number
}