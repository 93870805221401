import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText
} from '@material-ui/core';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import { InlineDatePicker } from 'material-ui-pickers';
import { DateTime } from 'luxon';
import * as Styled from './styled';
import MoveDateDialogStore from 'store/moveDate.dialog.store';
import TimeEntryForm from 'components/TimeEntryForm/TimeEntryForm';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { Features } from '../../api/types/types';
import { getDateFormat, isoDate } from '../../util/date';
import { withTranslation } from 'react-i18next';

interface Props {
    store?: MoveDateDialogStore;
    // tslint:disable-next-line:no-any
    t: any;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        store: rootStore.moveDateDialogStore
    };
})

@observer
class TimeEntryDialog extends React.Component<Props> {

    handleDateChange = (date: Date) => {
        const workDate: DateTime = DateTime.local(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
            0,
            0,
            0
        );
        this.props.store!.setNewDate(workDate);
    };

    cancel = () => {
        this.props.store!.clear();
        this.props.store!.cancel();
    }

    render() {
        const { t, store } = this.props;
        const { isOpen, newDate, entries, saveEntries, invDur, invWorkDate, rootStore } = store!;
        let tkOfficesToShow = rootStore.appStore.getActiveTKOfficesForDate(newDate)
        return (
            <FeaturesConsumer>
                { (features: Features) =>
                <Dialog
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    open={isOpen}
                    onClose={this.cancel}
                    scroll="paper"
                    maxWidth="md"
                    aria-labelledby="scroll-dialog-title"
                    id="timeentry-dialog"
                    fullWidth={true}
                >
                    <DialogTitle
                        id="dialog-movedate-title"
                        style={{paddingBottom: 0}}
                    >
                        {t('dialog.move.title')}
                    </DialogTitle>
                    <DialogContent>
                        <Styled.StyledContent>
                            <Styled.StyledPicker>
                                <InlineDatePicker
                                    format={newDate ? newDate.toFormat(getDateFormat()) : 'MM/dd/yyyy'}
                                    value={newDate ? isoDate(newDate) : ''}
                                    label={t('dialog.move.field.date.label')}
                                    onChange={this.handleDateChange}
                                    leftArrowIcon={<KeyboardArrowLeft/>}
                                    rightArrowIcon={<KeyboardArrowRight/>}
                                    onlyCalendar={true}
                                    error={invDur}
                                    helperText={invDur ? t('validation.duration.exceed_24') : ''}
                                />
                                {invWorkDate &&
                                <FormHelperText error={true}>
                                    {t('validation.date.invalid')}
                                </FormHelperText>
                                }
                            </Styled.StyledPicker>
                            <Styled.Entries>
                            {entries.map((te: TimeEntry) =>
                                <Styled.EntryItem key={te.id as number}>
                                    <TimeEntryForm
                                        timeEntry={te}
                                        onChange={() => {/** */}}
                                        disabled={true}
                                        showDate={true}
                                        actionCodesRequired={features.EpochConfigActionCodesRequired}
                                        minNarrativeLength={features.EpochConfigNarrativesMinimumChars}
                                        maxNarrativeLength={features.EpochConfigNarrativesMaximumChars}
                                        tkOfficesToShow={tkOfficesToShow!}
                                    />
                                </Styled.EntryItem>
                            )}
                            </Styled.Entries>
                        </Styled.StyledContent>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={saveEntries} disabled={invDur}>
                            {t('save', { ns: 'common' })}
                        </Button>
                        <Button onClick={this.cancel}>
                            {t('cancel', { ns: 'common' })}
                        </Button>
                    </DialogActions>
                </Dialog>
                }
            </FeaturesConsumer>
        );
    }
}

export default withTranslation(['timeentries', 'common'])(TimeEntryDialog);