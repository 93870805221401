import { action, computed, observable, makeObservable } from 'mobx';
import DialogRootStore from 'store/dialog.root.store';
import { TimerChunk } from '../../api/types/types';
import { getDurStringBetween } from 'components/TimerChunkItem/TimerChunkItem';
import { RootStore } from 'store/root.store';

export default class EditTimerTimeDialogStore extends DialogRootStore<TimerChunk, TimerChunk> {
    @observable startTime: string = '';
    @observable endTime: string = '';
    @observable hours: number;
    @observable minutes: number;
    @observable seconds: number;
    @observable stHours: number;
    @observable stMinutes: number;
    @observable stSeconds: number;
    @observable meridiem: string;
    @observable timerChunk: TimerChunk = new class implements TimerChunk {
        deleted: boolean;
        description: string;
        endTime: string;
        id: number;
        startTime: string;
        submitted: boolean;
        timeEntryId: number | null;
        timerId: number;
        used: boolean;
    };
    @observable invalidEndTime: boolean = false;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @action.bound
    async onOpen(chunk: TimerChunk) {
        this.timerChunk = chunk;
        const startDateTimeStamp = new Date(chunk.startTime).setMilliseconds(0);
        const endDateTimeStamp = new Date(chunk.endTime).setMilliseconds(0);
        this.setStartTime(new Date(startDateTimeStamp).toISOString());
        this.setEndTime(new Date(endDateTimeStamp).toISOString());
    }
    @action
    setEndTime = (time: string) => {
        this.endTime = time;
        this.setAllMeasurements(time, false);
    }
    @action
    setStartTime = (time: string) => {
        this.startTime = time;
        this.setAllMeasurements(time, true);
    }
    setAllMeasurements(time: string, isStartTime: boolean) {
        this.invalidEndTime = false;
        const twFourHours = new Date(time).getHours();
        // this.hours = (twFourHours + 11) % 12 + 1;
        if (isStartTime) {
            this.stHours = twFourHours;
            this.stMinutes = new Date(time).getMinutes();
            this.stSeconds = new Date(time).getSeconds();
        } else {
            this.hours = twFourHours;
            this.minutes = new Date(time).getMinutes();
            this.seconds = new Date(time).getSeconds();
        }
        if (twFourHours < 12) {
            this.meridiem = 'AM'
        } else {
            this.meridiem = 'PM'
        }
    }
    @action
    saveChunkTime = async () => {
        if (new Date(this.endTime) <= new Date(this.startTime)) {
            this.invalidEndTime = true;
            return ;
        }
        this.timerChunk.startTime = this.startTime;
        this.timerChunk.endTime = this.endTime;
        await this.rootStore.timerStore.debouncedChunkSave(this.timerChunk, true);
        this.isOpen = false;
    }
    @action
    setHours = (hour: number, isStartTime: boolean) => {
        this.invalidEndTime = false;
        if (isStartTime) {
            this.stHours = hour;
            const dateTimeStamp = new Date(this.startTime).setHours(hour);
            this.startTime = new Date(dateTimeStamp).toISOString();
        } else {
            this.hours = hour;
            const dateTimeStamp = new Date(this.endTime).setHours(hour);
            this.endTime = new Date(dateTimeStamp).toISOString();
        }
    }
    @action
    setMinutes = (minute: number, isStartTime: boolean) => {
        this.invalidEndTime = false;
        if (isStartTime) {
            this.stMinutes = minute;
            const dateTimeStamp = new Date(this.startTime).setMinutes(minute);
            this.startTime = new Date(dateTimeStamp).toISOString();
        } else {
            this.minutes = minute;
            const dateTimeStamp = new Date(this.endTime).setMinutes(minute);
            this.endTime = new Date(dateTimeStamp).toISOString();
        }
    }
    @action
    setSeconds = (sec: number, isStartTime: boolean) => {
        this.invalidEndTime = false;
        if (isStartTime) {
            this.stSeconds = sec;
            const dateTimeStamp = new Date(this.startTime).setSeconds(sec);
            this.startTime = new Date(dateTimeStamp).toISOString();
        } else {
            this.seconds = sec;
            const dateTimeStamp = new Date(this.endTime).setSeconds(sec);
            this.endTime = new Date(dateTimeStamp).toISOString();
        }
    }
    @action
    setMeridiem = (val: string) => {
        this.invalidEndTime = false;
        this.meridiem = val;
        if (val === 'PM') {
            let pmHours = new Date(this.endTime).getHours() + 12;
            this.endTime = new Date(new Date(this.endTime).setHours(pmHours)).toISOString();
        } else {
            const amHours = new Date(this.endTime).getHours() - 12;
            this.endTime = new Date(new Date(this.endTime).setHours(amHours)).toISOString();
        }
    }
    @computed get timeDuration(): string {
        if (new Date(this.endTime) <= new Date(this.startTime)) {
            return 'dialog.edit_chunk_time.validation.duration';
        }
        return getDurStringBetween(new Date(this.startTime), new Date(this.endTime))
    }
    @computed get invalidHours(): boolean {
        return this.hours > 23 || this.hours < 0;
    }
    @computed get invalidMinutes(): boolean {
        return this.minutes > 59 || this.minutes < 0;
    }
    @computed get invalidSeconds(): boolean {
        return this.seconds > 59 || this.minutes < 0;
    }
}