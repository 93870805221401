import ImmutableTimer from 'api/immutables/ImmutableTimer';

export const getTimerDurationTxt = (t: ImmutableTimer, includeSubmitted?: boolean, isChunk: boolean = false) => { 
  const timer = t;
  let secs = (!isChunk && timer.totalDuration) || 0;
  if ((includeSubmitted !== undefined) && !includeSubmitted) {
      secs = secs - (timer.convertedDuration || 0);
  }
  if (timer.active) {
      let da = Math.floor((new Date().getTime() - new Date(timer.startedOn!).getTime()) / 1000);
      secs += da;
  }
  // secs = Math.floor(secs / 1000);
  let hours   = Math.floor(secs / 3600);
  let minutes = Math.floor((secs - (hours * 3600)) / 60);
  let seconds = secs - (hours * 3600) - (minutes * 60);
  let hstring = `${hours}`;
  let sstring = `${seconds}`;
  let mstring = `${minutes}`;
  if (hours   < 10) {hstring   = '0' + hstring; }
  if (minutes < 10) {mstring = '0' + mstring; }
  if (seconds < 10) {sstring = '0' + sstring; }
  return `${hstring}:${mstring}:${sstring}`
};