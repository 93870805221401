'use strict';

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();

  window.Promise = require('promise/lib/es6-extensions.js');
} // fetch() polyfill for making API calls.


require('whatwg-fetch');

Number.isNaN = Number.isNaN || function (value) {
  return value !== value;
}; // Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.


Object.assign = require('object-assign'); // https://tc39.github.io/ecma262/#sec-array.prototype.includes

if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value: function value(searchElement, fromIndex) {
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      } // 1. Let O be ? ToObject(this value).


      var o = Object(this); // 2. Let len be ? ToLength(? Get(O, "length")).

      var len = o.length >>> 0; // 3. If len is 0, return false.

      if (len === 0) {
        return false;
      } // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)


      var n = fromIndex | 0; // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.

      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return x === y || typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y);
      } // 7. Repeat, while k < len


      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(searchElement, elementK) is true, return true.
        if (sameValueZero(o[k], searchElement)) {
          return true;
        } // c. Increase k by 1. 


        k++;
      } // 8. Return false


      return false;
    }
  });
} // https://tc39.github.io/ecma262/#sec-array.prototype.findIndex


if (!Array.prototype.findIndex) {
  Object.defineProperty(Array.prototype, 'findIndex', {
    writable: true,
    value: function value(predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this); // 2. Let len be ? ToLength(? Get(O, "length")).

      var len = o.length >>> 0; // 3. If IsCallable(predicate) is false, throw a TypeError exception.

      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      } // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.


      var thisArg = arguments[1]; // 5. Let k be 0.

      var k = 0; // 6. Repeat, while k < len

      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return k.
        var kValue = o[k];

        if (predicate.call(thisArg, kValue, k, o)) {
          return k;
        } // e. Increase k by 1.


        k++;
      } // 7. Return -1.


      return -1;
    }
  });
}

if (!Math.trunc) {
  Math.trunc = function (v) {
    v = +v;
    return v - v % 1 || (!isFinite(v) || v === 0 ? v : v < 0 ? -0 : 0);
  };
}

if (!global.requestAnimationFrame) {
  global.requestAnimationFrame = function (callback) {
    setTimeout(callback, 0);
  };
}

if (!Object.entries) Object.entries = function (obj) {
  var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array

  while (i--) {
    resArray[i] = [ownProps[i], obj[ownProps[i]]];
  }

  return resArray;
};

(function (global) {
  var channels = [];

  function BroadcastChannel(channel) {
    var $this = this;
    channel = String(channel);
    var id = '$BroadcastChannel$' + channel + '$';
    channels[id] = channels[id] || [];
    channels[id].push(this);
    this._name = channel;
    this._id = id;
    this._closed = false;
    this._mc = new MessageChannel();

    this._mc.port1.start();

    this._mc.port2.start();

    global.addEventListener('storage', function (e) {
      if (e.storageArea !== global.localStorage) return;
      if (e.newValue === null) return;
      if (e.key.substring(0, id.length) !== id) return;
      var data = JSON.parse(e.newValue);

      $this._mc.port2.postMessage(data);
    });
  }

  BroadcastChannel.prototype = {
    // BroadcastChannel API
    get name() {
      return this._name;
    },

    postMessage: function postMessage(message) {
      var $this = this;

      if (this._closed) {
        var e = new Error();
        e.name = 'InvalidStateError';
        throw e;
      }

      var value = JSON.stringify(message); // Broadcast to other contexts via storage events...

      var key = this._id + String(Date.now()) + '$' + String(Math.random());
      global.localStorage.setItem(key, value);
      setTimeout(function () {
        global.localStorage.removeItem(key);
      }, 500); // Broadcast to current context via ports

      channels[this._id].forEach(function (bc) {
        if (bc === $this) return;

        bc._mc.port2.postMessage(JSON.parse(value));
      });
    },
    close: function close() {
      if (this._closed) return;
      this._closed = true;

      this._mc.port1.close();

      this._mc.port2.close();

      var index = channels[this._id].indexOf(this);

      channels[this._id].splice(index, 1);
    },

    // EventTarget API
    get onmessage() {
      return this._mc.port1.onmessage;
    },

    set onmessage(value) {
      this._mc.port1.onmessage = value;
    },

    addEventListener: function addEventListener(type, listener
    /*, useCapture*/
    ) {
      return this._mc.port1.addEventListener.apply(this._mc.port1, arguments);
    },
    removeEventListener: function removeEventListener(type, listener
    /*, useCapture*/
    ) {
      return this._mc.port1.removeEventListener.apply(this._mc.port1, arguments);
    },
    dispatchEvent: function dispatchEvent(event) {
      return this._mc.port1.dispatchEvent.apply(this._mc.port1, arguments);
    }
  };
  global.BroadcastChannel = global.BroadcastChannel || BroadcastChannel;
})(self);