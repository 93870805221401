import BaseElectronImplementation from './Base.impl';
import TimeCastAPI from '../../interfaces/TimeCastAPI';
import { SegmentOptions, TimeCastProgram, TimeCastSegment } from '../../types/types';
import { TimeCastProgramI, TimeCastSegmentI, TimeEntry } from './Dexie';
import { ApiResult } from 'api/util';
import { TimeCastSettings } from '../../../containers/TimeCastSettings/TimeCastSettings';
import { TimeCast } from '../../../util/TimeCast';
import { DateTime } from 'luxon';
import logger from '../../../logging/logging';
import RootImpl from './Root.impl';

const constants = require('../../../constants.json');

interface IPCArgs {
    action: string
    // tslint:disable-next-line:no-any
    context: any
}

export default class TimecastImpl extends BaseElectronImplementation implements TimeCastAPI {
    /*=======================================================================
       State
     ======================================================================*/
    registeredIPCListener = false;
    programHandlers: (((programs: TimeCastProgram[]) => void) | null)[] = [];
    segmentHandlers: (((segments: TimeCastSegment[]) => void) | null)[] = [];

    constructor(props: RootImpl) {
        super(props);
        const { ipcRenderer } = require('electron');
        ipcRenderer.on('tc-cron-scheduler', this.cronScheduler);
    }

    /*=======================================================================
        IPC Communication
     ======================================================================*/
    listenForIPCEvents = () => {
        if (this.registeredIPCListener) {
            return;
        }

        const { ipcRenderer } = require('electron');

        ipcRenderer.on(constants.timecast.ipcChannel,
            async (event: { returnValue: unknown }, args: string) => {
                const json = JSON.parse(args) as IPCArgs;

                const action = json.action;
                const context = json.context;

                switch (action) {
                    case 'add-program':
                        const program = await this.saveProgram(context as TimeCastProgramI);
                        ipcRenderer.send('>add-program', JSON.stringify(program));
                        break;
                    case 'add-segment':
                        const segment = await this.saveSegmentsFromTimeCast([context] as TimeCastSegment[]);
                        ipcRenderer.send('>add-segment', JSON.stringify(segment));
                        break;
                    case 'get-settings':
                        const settings = await this.root.Settings.all();
                        ipcRenderer.send('>get-settings', JSON.stringify(settings));
                        break;
                    case 'get-setting':
                        const setting = await this.root.Settings.getByKey(context);
                        ipcRenderer.send('>get-setting', JSON.stringify(setting));
                        break;
                    case 'get-programs':
                        const programs = await this.allPrograms();
                        ipcRenderer.send('>get-programs', JSON.stringify(programs));
                        break;
                    default:
                        break;
                }
            }
        );
        this.registeredIPCListener = true;
    }

    /*=======================================================================
       TimeCast Programs
     ======================================================================*/

    registerReceiverForPrograms = (handler: (s: TimeCastProgram[]) => void) => {
        this.programHandlers.push(handler);
        const theIndex = this.programHandlers.length - 1;
        return () => {
            this.programHandlers[theIndex] = null;
        }
    }

    receivePrograms = async (programs: TimeCastProgram[]): Promise<void> => {
        const promises = programs.map((setting) => this.root.db.timecastPrograms.get({ id: setting.id! }));
        let localSettings = await Promise.all(promises);
        programs.forEach((segment, index) => {
            if (localSettings[index]) {
                // tslint:disable-next-line: no-any
                (segment as TimeCastProgramI).localId = localSettings[index]!.localId;
            }
        })
        await this.root.db.timecastPrograms.bulkPut(programs);
        let s = programs.map(setting => Object.assign({}, setting));
        this.programHandlers.filter(h => h !== null).forEach(h => h!(s));
    }

    allPrograms = async (): Promise<TimeCastProgram[]> => {
        try {
            return await this.root.db.timecastPrograms.toArray();
        } catch (e) {
            logger.error('Error fetching TimeCast Programs', e);
            return [];
        }
    }

    // getProgram = async (programId: number): Promise<TimeCastProgram> => {
    //     const query: { [p: string]: IndexableType } = {};
    //
    //     if (programId < 0) {
    //         query.localId = programId * -1;
    //     } else {
    //         query.id = programId;
    //     }
    //    
    //     return (await this.root.db.timecastPrograms.get(query))!;
    // };

    getWriteableProgramId = async (program: TimeCastProgram): Promise<number | undefined> => {
        try {
            if (!program.id) {
                return undefined;
            }
            if (program.id < 0) {
                return program.id * -1;
            }
            return (await this.root.db.timecastPrograms.get({ id: program.id }))!.localId
        } catch (e) {
            logger.error('TimeCast, Error fetching wtitable program', e);
            return undefined;
        }
    }

    trySaveOneProgram = async (program: TimeCastProgram): Promise<TimeCastProgram> => {
        try {
            let insertKey = await this.getWriteableProgramId(program);
            let writeableEntry = JSON.parse(JSON.stringify(program)) as TimeCastProgramI;
            if (insertKey) {
                writeableEntry.localId = insertKey;
            }
            writeableEntry.serverDirty = true;
            let localId = await this.root.db.timecastPrograms.put(writeableEntry);
            let safeEntry = (await this.root.db.timecastPrograms.get(localId))!;
            return Object.assign({}, safeEntry);
        } catch (e) {
            logger.error('TimeCast, Error in saving a program', e);
            return program;
        }
    }

    saveProgram = async (program: TimeCastProgram): Promise<TimeCastProgramI> => {
        let resp = await this.trySaveOneProgram(program);
        this.root.Session.write();
        return resp;
    }

    writePrograms = async () => {
        const dirtyPrograms = await this.root.db.timecastPrograms
            .filter(p => p.serverDirty || false)
            .toArray();
        if (dirtyPrograms.length === 0) {
            return;
        }
        const emitPrograms: TimeCastProgramI[] = [];
        const writingOp = dirtyPrograms
            .map(p => ({
                ...p,
                id: p.id! < 0 ? undefined : p.id
            }))
            .map(p => {
                try {
                    const json = {...p};
                    delete json.localId;
                    delete json.serverDirty;
                    delete json.deleted;
                    return this.root.webImpl.TimeCast.saveProgram(json);
                } catch (e) {
                    return Promise.resolve(null);
                }
            });
        
        const results = await Promise.all(writingOp);
        
        // tslint:disable-next-line:no-any
        const proms: Promise<any>[] = [];
        
        for (let i = 0; i < results.length; i++) {
            let local = dirtyPrograms[i]!;
            let remote = results[i];
            if (!remote) {
                // failed write, do something
            } else {
                (remote as TimeCastProgramI).localId = local.localId;
                proms.push(this.root.db.timecastPrograms.put(remote));
                if (local.id! < 0) {
                    local.deleted = true;
                    // id less than 0, only local 
                    emitPrograms.push({...local});
                }
                emitPrograms.push({...remote});
            }
        }
        await Promise.all(proms);
        this.programHandlers.filter(h => h !== null).forEach(h => h!(emitPrograms));
    }

    /*=======================================================================
       TimeCast Segments
     ======================================================================*/

    registerReceiverForSegments = (handler: (s: TimeCastSegment[]) => void) => {
        this.segmentHandlers.push(handler);
        const theIndex = this.segmentHandlers.length - 1;
        return () => {
            this.segmentHandlers[theIndex] = null;
        }
    }

    receiveSegments = async (segments: TimeCastSegment[]): Promise<void> => {
        // New virtual segments will have an id of 0 -- we need to process these ones differently then the rest.
        
        // Gather the new virtual segments (which have an id of 0)
        const virtualSegments = [] as TimeCastSegment[];
        segments = segments.filter(segment => {
            if (typeof segment.data !== 'string') {
                segment.data = JSON.stringify(segment.data);
            }
            if (segment.id === 0) {
                segment.id = undefined;
                virtualSegments.push(segment);
                return false;
            }
            
            return true;
        });
        
        // 1) Save other segments as normal
        const promises = segments.map((segment) => {
            if (segment.type === 'DESKTOP_CAPTURE') {
                return this.root.db.timecastSegments.get({ id: segment.id! });
            } else {
                return this.root.db.timecastSegments.get({ foreignIdentifier: segment.foreignIdentifier! });
            }
            
        });
        let localSegments = await Promise.all(promises);
        
        segments.forEach((segment, index) => {
            if (localSegments[index]) {
                (segment as TimeCastSegmentI).localId = localSegments[index]!.localId;
            }
        });
        // await this.root.db.timecastSegments.bulkPut(segments);
        let s = segments.map(segment => Object.assign({}, segment));
        
        // 2) Save virtual segments
        const promises2 = virtualSegments.map((segment) => this.root.db.timecastSegments.get({
            foreignIdentifier: segment.foreignIdentifier
        }));
        let localVirtualSegments = await Promise.all(promises2);
        virtualSegments.forEach((segment, index) => {
            if (localVirtualSegments[index]) {
                (segment as TimeCastSegmentI).localId = localVirtualSegments[index]!.localId;
            }
        });
        await this.root.db.timecastSegments.bulkPut([...segments, ...virtualSegments]);
        let s2 = virtualSegments.map(segment => Object.assign({}, segment));
        
        this.segmentHandlers.filter(h => typeof h === 'function').forEach(h => h!(s.concat(s2)));
    }
    preRetentionDateSegments = async (startDateTime: string, endDateTime: string) => {
        let retentionFromDate = DateTime.fromISO(localStorage.getItem('tcSegmentsFrom') || '') ||
            DateTime.local().toISO();
        let fromDate = DateTime.fromISO(startDateTime);
        let tcDatesArr = (JSON.parse(localStorage.getItem('tcDatesBfrRetentionDateArray')!)) || [];
        if (fromDate <= retentionFromDate) {
            // const newFrom = fromDate.minus({ months: 3 }).startOf('month');
            // localStorage.setItem('tcRetentionFromDate', JSON.stringify(newFrom));
            tcDatesArr.push(startDateTime);
            localStorage.setItem('tcDatesBfrRetentionDateArray', JSON.stringify(tcDatesArr));
            try {
                const entries = await this.root.webImpl.TimeCast.getSegmentsBetween(
                    startDateTime,
                    endDateTime,
                    {extractFromLocalOutlook: false}
                );
                // const promArray: Promise<TimeCastSegmentI>[] = entries.map(async (entry: TimeCastSegment) => {
                //     let insertKey = await this.getWriteableSegmentId(entry);
                //     let writeableEntry = JSON.parse(JSON.stringify(entry)) as TimeCastSegmentI
                //     if (insertKey) {
                //         writeableEntry.localId = insertKey;
                //     }
                //     return writeableEntry as TimeCastSegmentI;
                // });
                // const writable: TimeCastSegmentI[] = await Promise.all(promArray);
                // await this.root.db.timecastSegments.bulkPut(writable);
                await this.receiveSegments(entries);
            } catch (e) {
                throw e;
            }
        }
    }
    getSegmentsBetween = async (startDateTime: string, endDateTime: string, options: SegmentOptions)
        : Promise<TimeCastSegment[]> => {
        let tcDatesArr = (JSON.parse(localStorage.getItem('tcDatesBfrRetentionDateArray')!)) || [];
        try {
            if (!tcDatesArr.includes(startDateTime)) {
                await this.preRetentionDateSegments(startDateTime, endDateTime);
            }
            let data = (
                await this.root.db.timecastSegments
                    .where('startTime')
                    .between(startDateTime, endDateTime, true, true)
                    .toArray()
            ).filter((seg) => !seg.deleted)!;

            if (options.extractFromLocalOutlook) {
                const d = new Date(startDateTime);
                const year = d.getFullYear();
                const month = d.getMonth() + 1;
                const day = d.getDate();
                const localOutlookSegments = (await TimeCast.getLocalOutlookSegments(year, month, day))
                    .filter((seg) => this.checkIfSegmentAlreadyExists('CONVERSATION_HISTORY', data, seg)
                );
                data = data.concat(localOutlookSegments);
            } else if (this.root.connection.isOnline()) {
                const onlineOnlySegments = (await this.root.webImpl.TimeCast
                    .getSegmentTypesBetween(
                        startDateTime,
                        endDateTime,
                        ['VIRTUAL_CALENDAR_EVENT', 'VIRTUAL_SENT_EMAIL', 'VIRTUAL_PHONE_CALL']
                    ));
                this.receiveSegments(onlineOnlySegments);
                const captured = onlineOnlySegments.filter(cap => cap.type === 'DESKTOP_CAPTURE');
                const filtered = onlineOnlySegments.filter(segment1 =>
                    !data.find(segment2 => segment1.foreignIdentifier === segment2.foreignIdentifier)
                );
                data = [...data, ...filtered];
            }
            // deserialize the 'data' string
            return data.map(segment => ({
                ...segment,
                data: (typeof segment.data as unknown === 'string')
                ? JSON.parse(segment.data as unknown as string)
                : segment.data
            }));
        } catch (e) {
            logger.error('Error fetching TimeCast segments between selected dates', e);
            throw(e);
        }
    }
    getWriteableSegmentId = async (segment: TimeCastSegment): Promise<number | undefined> => {
        if (!segment.id) {
            return undefined;
        }
        if (segment.id < 0) {
            return segment.id * -1;
        }
        try {
            const localEntry = await this.root.db.timecastSegments.get({ id: segment.id });
            const returnVal = localEntry ? localEntry.localId : undefined;
            return returnVal;
        } catch (e) {
            logger.info('TimeCast, Error fetching writable segment by using segment Id', e);
            return undefined;
        }
    }
    getWriteableSegmentByForeignId = async (segment: TimeCastSegment): Promise<number | undefined> => {
        if (!segment.foreignIdentifier) {
            return undefined;
        }
        try {
            const localEntry = await this.root.db.timecastSegments.get({ foreignIdentifier: segment.foreignIdentifier });
            const returnVal = localEntry ? localEntry.localId : undefined;
            return returnVal;
        } catch (e) {
            logger.info('TimeCast, Error fetching writable segment by using foreign Id', e);
            return undefined;
        }
    }

    trySaveOneSegment = async (segment: TimeCastSegment): Promise<ApiResult<TimeCastSegment>> => {
        // Save virtual calendar events as concrete events
        if (segment.type === 'VIRTUAL_CALENDAR_EVENT') {
            segment.type = 'CALENDAR_EVENT';
            segment.id = undefined;
        } else if (segment.type === 'VIRTUAL_SENT_EMAIL') {
            segment.type = 'SENT_EMAIL';
            segment.id = undefined;
        } else if (segment.type === 'VIRTUAL_PHONE_CALL') {
            segment.type = 'PHONE_CALL';
            segment.id = undefined;
        }
        
        if (typeof segment.data !== 'string') {
            segment.data = JSON.stringify(segment.data);
        }
        try {
            let insertKey = undefined;
            if (segment.type === 'DESKTOP_CAPTURE') {
                insertKey = await this.getWriteableSegmentId(segment);
            } else {
                insertKey = await this.getWriteableSegmentByForeignId(segment);
            }
            
            let writeableEntry = JSON.parse(JSON.stringify(segment)) as TimeCastSegmentI;
            writeableEntry.id = segment.id;
            if (insertKey) {
                writeableEntry.localId = insertKey;
            }
            writeableEntry.serverDirty = true;
            let localId = await this.root.db.timecastSegments.put(writeableEntry);
            let safeEntry = (await this.root.db.timecastSegments.get(localId))!;
            return {
                status: {
                    failed: false,
                    message: 'Success'
                },
                object: Object.assign({}, safeEntry)
            }
        } catch (e) {
            logger.info('Error saving TimeCast segment', e);
            return {
                status: {
                    failed: true,
                    message: 'Failed save'
                },
                object: segment
            }
        }
    }

    saveSegmentsFromTimeCast = async (segments: TimeCastSegment[]): Promise<ApiResult<TimeCastSegment>[]> => {
        let filterd: TimeCastSegment[] = [];
        for (let index = 0; index < segments.length; index++) {
            const segm = segments[index];
            const existingFromTC = filterd.find(f => this.segmentsExists(f, segm, 'DESKTOP_CAPTURE'));
            const existingFromLocal = await this.root.db.timecastSegments
                .where({
                    type: 'DESKTOP_CAPTURE',
                    startTime: segm.startTime,
                    endTime: segm.endTime
                })
                .count();
            if (!existingFromTC && existingFromLocal <= 0) {
                filterd.push(segm);
            }
        };
        
        return this.saveSegments(filterd);
    }
    saveSegments =  async (segments: TimeCastSegment[]): Promise<ApiResult<TimeCastSegment>[]> => {
        const JsonSegments = await segments.map(segment => {
            const clone = { ...segment };
            if (typeof clone.data !== 'string') {
                clone.data = JSON.stringify(clone.data);
            }
            return clone;
        });

        const resp = await Promise.all(JsonSegments.map(this.trySaveOneSegment));
        this.root.Session.write();
        return resp;
    }
    segmentsExists = (segment1: TimeCastSegment, segment2: TimeCastSegment, type: string) => {
        return segment1.type.toUpperCase() === type &&
            DateTime.fromISO(segment1.startTime).equals(DateTime.fromISO(segment2.startTime)) &&
            DateTime.fromISO(segment1.endTime).equals(DateTime.fromISO(segment2.endTime)) &&
            segment1.data === segment2.data;
    }
    checkIfSegmentAlreadyExists = (type: string, segments: TimeCastSegmentI[], seg: TimeCastSegmentI) => {
        const existing = segments.find(s =>
            s.type.toUpperCase() === type &&
            DateTime.fromISO(s.startTime).equals(DateTime.fromISO(seg.startTime)) &&
            DateTime.fromISO(s.endTime).equals(DateTime.fromISO(seg.endTime)) &&
            s.data === seg.data
        );
        // Remove if the segments are already being saved in backend or local DB.
        if (existing) {
            return false;
        } else {
            return true;
        }
    }
    deleteSegments = async (segments: TimeCastSegment[]): Promise<ApiResult<TimeCastSegment>[]> => {
        segments = segments.map((segment) => {
            // deserialize the 'data' string
            const clone: TimeCastSegment = { ...segment };
            if (typeof clone.data !== 'string') {
                clone.data = JSON.stringify(clone.data);
            }
            return clone;
        });
        let deletedSegments: ApiResult<TimeCastSegment>[] = [];
        let virtualExchangeSegments: TimeCastSegment[] = segments
            .filter((s) => s.type === 'VIRTUAL_CALENDAR_EVENT' || 
                s.type === 'VIRTUAL_SENT_EMAIL' || 
                s.type === 'VIRTUAL_PHONE_CALL')
            .map((seg) => {
                seg.id = 0;
                return seg;
            });
        let captureSegments: TimeCastSegment[] = segments.filter((s) => s.type !== 'VIRTUAL_CALENDAR_EVENT'
            && s.type !== 'VIRTUAL_SENT_EMAIL' && s.type !== 'VIRTUAL_PHONE_CALL');
        // Virtual segments can be deleted online only
        if (virtualExchangeSegments.length > 0) {
            try {
                let resp = await this.root.webImpl.TimeCast.deleteSegments(virtualExchangeSegments);
                deletedSegments = [...deletedSegments, ...resp];
                const notFailed: TimeCastSegment[] = (resp.filter(result => !result.status.failed))
                    .map(tcApi => tcApi.object);
                if (notFailed.length > 0) {
                    this.receiveSegments(notFailed);
                }
            } catch (e) {
                logger.error('Error deleting TimeCast segments', e)
                throw e;
            }
        }
        // Capture segments would delete like the TimeEntries or Timers
        if (captureSegments.length > 0) {
            for (let i = 0; i < captureSegments.length; i++) {
                let segmnt = captureSegments[i];
                const resp = await this.trySaveOneSegment(segmnt);
                deletedSegments = deletedSegments.concat(resp);
            }
            this.root.Session.write();
        }
        return deletedSegments;
    }
    
    failableWrite = async () => {
        try {
            await this.root.Session.write();
        } catch (e) {
            return;
        }
    }

    dissociateSegmentsFromTimeEntry = async (id: number | null | undefined) => {
        if (!id || id === 0) {
            return;
        } else {
            const targetId = id;
            await this.root.db.timecastSegments
                .where({ associatedTimeEntry: targetId })
                .modify({ associatedTimeEntry: null, serverDirty: true });
            await this.root.db.timerChunks
                .where({ timeEntryId: targetId })
                .modify({ timeEntryId: null, serverDirty: true });
            // this.root.Session.write();
        }
    }

    writeSegments = async () => {
        const dirtySegments = await this.root.db.timecastSegments
            .filter(p => {
                if (p.serverDirty) {
                    if ((p.associatedTimeEntry || 0) < 0) {
                        // exclude unwriteables
                        return false;
                    }
                    return true;
                }
                return false;
            })
            .toArray();

        if (dirtySegments.length === 0) {
            return;
        }

        const emitSegments: TimeCastSegmentI[] = [];

        const writingOp = dirtySegments
            .map(segment => ({
                ...segment,
                id: segment.id! < 0 ? undefined : segment.id
            }))
            .map(segment => {
                const json = {...segment};
                if (json.serverDirty) { delete json.serverDirty; }
                if (json.localId) { delete json.localId; }
                // delete json.deleted;
                return json as TimeCastSegmentI;
            });

        const results = await this.root.webImpl.TimeCast.saveSegments(writingOp);

        // tslint:disable-next-line:no-any
        const proms: Promise<any>[] = [];
        for (let i = 0; i < results.length; i++) {
            let local = dirtySegments[i]!;
            let remote = results[i].object;
            if (!remote) {
                // failed write, do something
            } else {
                (remote as TimeCastSegmentI).localId = local.localId;

                proms.push(this.root.db.timecastSegments.put(remote));

                if (local.id! < 0) {
                    local.deleted = true;
                    // id less than 0, only local 
                    emitSegments.push({...local});
                }
                emitSegments.push({...remote});
            }
        }
        await Promise.all(proms);
        this.segmentHandlers.filter(h => h !== null).forEach(h => h!(emitSegments));
    }

    getTimeCastSegmentsByTimeEntryIds = async (ids: number[]): Promise<TimeCastSegment[]> => {
        try {
            return await this.root.db.timecastSegments
                .where('associatedTimeEntry')
                .anyOf(ids)
                .toArray();
        } catch (e) {
            logger.error('Error fetching TimeCast segments by TimeEntry Ids', e);
            return [];
        }
    }

    updateTimeCastSegments = async (ids: number[], timeEntryId: number):
     Promise<ApiResult<TimeCastSegment>[]> => {
        await Promise.all(ids.map(async (id) => {
            await this.root.db.timecastSegments
                .where({ id: id })
                .modify({ associatedTimeEntry: timeEntryId, serverDirty: true });
        }));
        await this.associateDirtyTimeCastSegments();
        this.root.Session.write();
        return [];
    }

    associateDirtyTimeCastSegments = async () => {
        const associatedDirtySegments = await this.root.db.timecastSegments
            .filter(p => p.associatedTimeEntry! < 0)
            .toArray();
        associatedDirtySegments.forEach(async ads => {
            let timeEntryLocalId = ads.associatedTimeEntry! * -1;
            const toAssociate = await this.root.db.timeEntries.get(timeEntryLocalId);
            if (toAssociate) {
                ads.associatedTimeEntry = toAssociate.id;
            }
            await this.trySaveOneSegment(ads);
        })
    }

    /*=======================================================================
       Shared
     ======================================================================*/
    write = async () => {
        await this.writePrograms();
        
        // only push segments if server push is enabled!
        const settings = await this.root.Settings.getByKey(constants.timecast.settingKey);
        const timeCastSetting = TimeCastSettings.fromSetting(settings);
        
        if (timeCastSetting.isServerPushEnabled()) {
            await this.writeSegments();
        }
    }
    /**
     * implementation of job 'tc-cron-scheduler', which deletes local TC segments that start 
     * before the retention period and are not associated with any time entry.
     * The retention period is defined by the feature flag EpochConfigTimeCastSegmentsRetentionDays.
     * Job is meant to be run whenever the epoch app is started.
     */
    cronScheduler = async () => {
        const features = await this.root.Session.getFeatures();
        if (!features) {
            return;
        }
        const { EpochConfigTimeCastSegmentsRetentionDays } = features;
        const retentionDate = DateTime
            .local()
            .minus({'days': EpochConfigTimeCastSegmentsRetentionDays})
            .startOf('day');
        // Delete segments from 1900 to new retention date.
        const infiniteInitialDate = DateTime.fromISO('1900-01-01T00:00:00.000Z').toISO();
        const deletable = await this.root.db.timecastSegments
            .where('startTime')
            .between(infiniteInitialDate, retentionDate.toISO())
            .and(t => !t.associatedTimeEntry)
            .primaryKeys();
        this.root.db.timecastSegments.bulkDelete(deletable);
    }
}
