import * as React from 'react';
import * as Styled from './styled.desktop';
import {
    DateRange,
    Home,
    FileCopy,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Note,
    Settings,
    Cloud,
    Timer,
    List,
    Dashboard
} from '@material-ui/icons';
import { FlexDiv } from 'common/flex';
import { NavBarStyles } from './styled.desktop'
import { Badge, Menu, MenuItem, Tooltip, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import AppStore from 'store/app.store';
import { withRouter } from 'react-router';
import NavigationStore from 'store/navigation.store';
import { Platform } from '../../util/Platform';
import TimeKeepersList from 'components/TimeKeepersList/TimeKeepersList';
import { DateTime } from 'luxon';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { Features } from '../../api/types/types';
import { withTranslation } from 'react-i18next';

interface State {
    expanded: boolean;
    userMenuOpen: boolean;
}

// tslint:disable:no-any
interface Props {
    appStore?: AppStore;
    routerStore?: NavigationStore;
    match: {
        path: string;
        params: string;
        isExact: boolean;
        url: string;
    } | any;
    location: {
        pathname: string
    } | any;
    history: any;
    classes?: any;
    t: any;
}
// tslint:enable:no-any

function getInitials(name: string): string {
    const firstAndLastName = (name.trim() || '').split(/\s/);
    const firstName = firstAndLastName.shift() || '';
    const lastName = firstAndLastName.pop() || '';
    const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;
    
    return initials.toUpperCase();
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        appStore: rootStore.appStore,
        routerStore: rootStore.routerStore
    };
})
@observer
export class NavBarDesktop extends React.Component<Props, State> {
    userMenuAnchor = React.createRef<HTMLLIElement>();
    preventClick: boolean = false;
    // tslint:disable-next-line:no-any
    timer: any;

    state = {
        expanded: false,
        userMenuOpen: false
    };

    handleExpand = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    synchronize = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        clearTimeout(this.timer);
        this.preventClick = true;
        if (e.shiftKey) {
            if (Platform.isElectron()) {
                this.props.appStore!.reSynchronize();
            } else {
                this.props.appStore!.getFeaturesApi();
            }
        } else {
            this.props.appStore!.synchronize();
        }
    }

    handleTkSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.props.appStore!.setTkSearchText(evt.target.value);
    };
    
    // openTkList = () => {
    //     this.setState({ tkMenuOpen: true });
    //     this.props.appStore!.setTkSearchText('');
    //    
    // }

    takeMeToOfflineView = () => {
        if (this.props.appStore!.offlineEntryCount === 0) {
            return;
        }
        if (Platform.isElectron()) {
            this.props.routerStore!.attemptPush(`/settings/offlineEntries`);
        }
    }

    goToOfflineView = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        this.timer = setTimeout(() => {
            if (!this.preventClick) {
                this.takeMeToOfflineView();
            }
            this.preventClick = false;
        }, 500);
    }
    
    goToView = (path: string) => {
        let pathName = this.props.location.pathname;
        if (pathName.startsWith('/settings') && path.startsWith('/settings') ||
            pathName.startsWith('/home') && path.startsWith('/home')) { return; }

        this.props.routerStore!.attemptPush(path);
    }
    logout = () => {
        this.setState({ userMenuOpen: false });
        if (Platform.isElectron()) {
            this.props.appStore!.attemptHardLogout();
        } else {
            this.props.appStore!.logOut();
        }
    }

    render() {
        const { expanded } = this.state;
        const { classes, t, appStore } = this.props;
        const {
            user,
            currentTimekeeper,
            uniqueTimeKeepers,
            online,
            offlineEntryCount,
            filteredTimeKeepers,
            disableAnimations
        } = appStore!;
        return (
            <FeaturesConsumer>
                {(features: Features) =>
                    <Styled.NavBarContainer>
                        {this.props.location.pathname.startsWith(`/timersPopOut`) ? <></> :
                            <Styled.IconList expanded={expanded} animationsEnabled={!disableAnimations}>
                                <Styled.Logo onClick={this.handleExpand}>
                                    <Styled.EPImg src="static/epoch-logo-text-left.png"/>
                                    <Styled.OImg src="static/epoch-logo-small.png"/>
                                    <Styled.CHImg src="static/epoch-logo-text-right.png"/>
                                </Styled.Logo>
                                <Tooltip title={t('app.nav_bar.home')} placement="right" disableHoverListener={expanded}>
                                    <Styled.IconItem
                                        onClick={() => this.goToView('/home')}
                                        selected={this.props.location.pathname.startsWith('/home')}
                                    >
                                        <Home fontSize="inherit"/>
                                        <Styled.IconText>{t('app.nav_bar.home')}</Styled.IconText>
                                    </Styled.IconItem>
                                </Tooltip>

                                <Tooltip title={t('app.nav_bar.timeentries')} placement="right" disableHoverListener={expanded}>
                                    <Styled.IconItem
                                        selected={this.props.location.pathname.startsWith('/timeentries')}
                                        onClick={() => this.goToView('/timeentries')}
                                    >
                                        <DateRange fontSize="inherit"/>
                                        <Styled.IconText>{t('app.nav_bar.timeentries')}</Styled.IconText>
                                    </Styled.IconItem>
                                </Tooltip>
                                
                                {features.EpochConfigMattersPageEnabled && 
                                <Tooltip
                                    title={t('app.nav_bar.matters', { matterLabel: features.EpochConfigMatterLabel })}
                                    placement="right"
                                    disableHoverListener={expanded}
                                >
                                    <Styled.IconItem
                                        selected={this.props.location.pathname.startsWith('/matters')}
                                        onClick={() => this.goToView('/matters')}
                                    >
                                        <List fontSize="inherit"/>
                                        <Styled.IconText>
                                            {t('app.nav_bar.matters', { matterLabel: features.EpochConfigMatterLabel })}
                                        </Styled.IconText>
                                    </Styled.IconItem>
                                </Tooltip>}

                                <Tooltip title={t('app.nav_bar.templates')} placement="right" disableHoverListener={expanded}>
                                    <Styled.IconItem
                                        selected={this.props.location.pathname.startsWith('/templates')}
                                        onClick={() => this.goToView('/templates')}
                                    >
                                        <FileCopy fontSize="inherit"/>
                                        <Styled.IconText>{t('app.nav_bar.templates')}</Styled.IconText>
                                    </Styled.IconItem>
                                </Tooltip>

                                {features.EpochConfigTimersEnabled &&
                                <Tooltip title={t('app.nav_bar.timers')} placement="right" disableHoverListener={expanded}>
                                    <Styled.IconItem
                                        selected={this.props.location.pathname.startsWith('/timers')}
                                        onClick={() => this.goToView('/timers')}
                                    >
                                        <Timer fontSize="inherit"/>
                                        <Styled.IconText>{t('app.nav_bar.timers')}</Styled.IconText>
                                    </Styled.IconItem>
                                </Tooltip>}
                                
                                <Tooltip title={t('app.nav_bar.narratives')} placement="right" disableHoverListener={expanded}>
                                    <Styled.IconItem
                                        selected={this.props.location.pathname.startsWith('/narratives')}
                                        onClick={() => this.goToView('/narratives')}
                                    >
                                        <Note fontSize="inherit" />
                                        <Styled.IconText>{t('app.nav_bar.narratives')}</Styled.IconText>
                                    </Styled.IconItem>
                                </Tooltip>

                                <Tooltip title={t('app.nav_bar.settings')} placement="right" disableHoverListener={expanded}>
                                    <Styled.IconItem
                                        selected={this.props.location.pathname.startsWith('/settings')}
                                        onClick={() => this.goToView('/settings/general')}
                                    >
                                        <Settings fontSize="inherit" />
                                        <Styled.IconText>{t('app.nav_bar.settings')}</Styled.IconText>
                                    </Styled.IconItem>
                                </Tooltip>

                                {JSON.parse(localStorage.getItem('permissions') || '[]').length > 0 &&
                                <Tooltip title={t('app.nav_bar.reports')} placement="right" disableHoverListener={expanded}>
                                    <Styled.IconItem
                                        selected={this.props.location.pathname.startsWith('/reports')}
                                        onClick={() => this.goToView('/reports')}
                                    >
                                        <Dashboard fontSize="inherit" />
                                        <Styled.IconText>{t('app.nav_bar.reports')}</Styled.IconText>
                                    </Styled.IconItem>
                                </Tooltip>}

                                <FlexDiv flex={1} />
                                <Tooltip
                                    title={t(`app.nav_bar.cloud.status.${online ? 'connected' : 'offline'}`)}
                                    placement="right"
                                    disableHoverListener={expanded}
                                >
                                    <Styled.IconItem>
                                        <Badge badgeContent={offlineEntryCount} color="error">
                                            <Cloud
                                                onClick={this.goToOfflineView}
                                                onDoubleClick={this.synchronize}
                                                style={{marginLeft: '3px', color: online ? '#fff' : 'rgb(230, 107, 107)'}}
                                            />
                                        </Badge>
                                        <Styled.IconText style={{marginLeft: 24}}>
                                            {t(`app.nav_bar.cloud.status.${online ? 'connected' : 'offline'}`)}
                                        </Styled.IconText>
                                    </Styled.IconItem>
                                </Tooltip>
                                <Tooltip title={t('app.nav_bar.timekeeper')} placement="right" disableHoverListener={expanded}>
                                    <TimeKeepersList
                                        render={(tkMenuAnchor, openTkList) => (
                                            <Styled.IconItem
                                                onClick={openTkList}
                                                innerRef={tkMenuAnchor}
                                                style={{ overflow: 'visible' }}
                                            >
                                                {(uniqueTimeKeepers.length > 1) ? (
                                                    <Badge
                                                        badgeContent={uniqueTimeKeepers.length}
                                                        classes={{ badge: classes.customBadge }}
                                                        color="primary"
                                                    >
                                                        <Styled.SmallAvatar primary={true}>
                                                            {getInitials(currentTimekeeper.name)}
                                                        </Styled.SmallAvatar>
                                                    </Badge>
                                                ) : (
                                                    <Styled.SmallAvatar primary={true}>
                                                        {getInitials(currentTimekeeper.name)}
                                                    </Styled.SmallAvatar>
                                                )}
                                                {this.state.expanded && <Styled.IconText>{currentTimekeeper.name}</Styled.IconText>}
                                            </Styled.IconItem>
                                        )}
                                        collaborate={false}
                                        timeKeepers={filteredTimeKeepers}
                                        menuWidth={300}
                                        workDate={DateTime.local().startOf('day').toISO()}
                                    />
                                </Tooltip>
                                <Tooltip
                                    title={this.props.appStore!.user.displayName}
                                    placement="right"
                                    disableHoverListener={expanded}
                                >
                                    <Styled.IconItem
                                        onClick={() => this.setState({ userMenuOpen: true })}
                                        innerRef={this.userMenuAnchor}
                                    >
                                        <Styled.SmallAvatar>{getInitials(user.displayName)}</Styled.SmallAvatar>
                                        <Styled.IconText>{user.displayName}</Styled.IconText>
                                    </Styled.IconItem>
                                </Tooltip>

                                <Menu
                                    anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                                    anchorEl={this.userMenuAnchor.current}
                                    open={this.state.userMenuOpen}
                                    onClose={() => this.setState({ userMenuOpen: false })}
                                >
                                    <MenuItem
                                        disabled={!online}
                                        onClick={this.logout}
                                    >
                                        {t('app.nav_bar.user.menu.logout.label')}
                                    </MenuItem>
                                </Menu>
                                <Styled.IconItem onClick={this.handleExpand}>
                                    {expanded ? (
                                        <KeyboardArrowLeft fontSize="inherit"/>
                                    ) : (
                                        <KeyboardArrowRight fontSize="inherit"/>
                                    )}
                                    <Styled.IconText>{t('collapse')}</Styled.IconText>
                                </Styled.IconItem>
                            </Styled.IconList>}
                    </Styled.NavBarContainer>
                }
            </FeaturesConsumer>
        );
    }
}

// tslint:disable-next-line:no-any
export default  withRouter(withStyles(NavBarStyles as any)(withTranslation(['common'])(NavBarDesktop)));