import { observer } from 'mobx-react';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { RootStoreContext } from '../../App';
import * as Styled from './styled';

export default observer(function () {
    let store = useContext(RootStoreContext).reportsStore;

    useEffect(() => {
        const load = async () => {
            await store.initializeSession();
            await loadDashboard();
        };
        if (store.currentDashboard) {
            load();
        } else {
            store.rootStore.routerStore.push('/reports');
        }
    }, []);

    const loadDashboard = async () => {
        await embedDashboard({
            id: store.currentDashboard,
            supersetDomain: 'https://reporting.fulcrum.gt',
            mountPoint: document.getElementById('superset-reporting')!,
            fetchGuestToken: store.fetchGuestToken,
            dashboardUiConfig: {
                hideChartControls: false,
                hideTitle: false,
                hideTab: true
            }
        });
    };

    return (
        <div style={{ height: '100%' }}>
            <Styled.SupersetContainer
                id="superset-reporting"
                style={{
                    height: '100%',
                    width: '100%'
                }}
            />
        </div>
    );
});
