import * as React from 'react';
import * as Styled from './styled.mobile';
import {
    CalendarToday,
    FileCopy,
} from '@material-ui/icons';
import { withTranslation } from 'react-i18next';

interface Props {
    // tslint:disable-next-line:no-any
    t: any;
}
class NavBarMobile extends React.Component<Props> {
    render() {
        const { t } = this.props;
        return (
            <Styled.NavBarContainer>
                <Styled.IconList>
                    <Styled.CustomNavLink to="/home">
                        <Styled.IconItem>
                            <CalendarToday fontSize="inherit" />
                            <Styled.IconText>{t('title_plural', { ns: 'timeentry' })}</Styled.IconText>
                        </Styled.IconItem>
                    </Styled.CustomNavLink>
                    <Styled.CustomNavLink to="/templates">
                        <Styled.IconItem>
                            <FileCopy fontSize="inherit" />
                            <Styled.IconText>{t('title_plural', { ns: 'template' })}</Styled.IconText>
                        </Styled.IconItem>
                    </Styled.CustomNavLink>
                </Styled.IconList>
            </Styled.NavBarContainer>
        );
    }
}

export default withTranslation(['timeentries', 'templates'])(NavBarMobile);
