import * as React from 'react';
import NavBarDesktop from './NavBar.desktop';
import NavBarMobile from './NavBar.mobile';
import { Desktop, Mobile } from 'common/responsive';

export default class NavBar extends React.Component {
    render() {
        return (
            <>
                <Desktop>
                    <NavBarDesktop />
                </Desktop>
                <Mobile>
                    <NavBarMobile />
                </Mobile>
            </>
        );
    }
}