import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const PaginationContainer = styled.div`
    background-color: white; 
    alignItems: center; 
    display: flex;
    justify-content: center;
    padding: 10px 0px;
`;

export const PageHeader = styled.div`
    margin: 0 auto;
    height: 50px;
    align-items: center;
    flex-direction: row;
    flex: no-fill;
    display: flex;
    padding-bottom: 15px;   
`;
export const PageNumberIconBtn = styled(IconButton)<{isSelected: boolean}>`
    background-color: ${props => props.isSelected === true ? '#b7b7b7 !important;' : 'none'};
`;