import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './styled';

const AboutEpochContainer: React.FC = () => {
    const { t } = useTranslation(['settings']);

    let serverURL =  `${window.location.protocol}//${window.location.host}/api/`;
    let wsUrl = `${serverURL.includes('https') ? 'wss://' : 'ws://'}${window.location.host}/ws`;

    // tslint:disable-next-line:no-any
    if ((window as any).process) {
        wsUrl = localStorage.getItem('wsUrl') || '';
        serverURL = localStorage.getItem('serverUrl') || '';
    }
    // tslint:disable-next-line:no-any
    let manifest = (window as any).MANIFEST;
    let appVersion = manifest.version;
    // let env = process.env.NODE_ENV;
    // let serverURL = rootStore.appStore.currentServerUrl;

    return (
        <div>
            <Styled.FormContainer>
                <Styled.FormLabel>{t('tab.about.build.version')}</Styled.FormLabel>
                <span>:</span>
                <Styled.FormValue>{appVersion}</Styled.FormValue>
            </Styled.FormContainer>
            <Styled.FormContainer>
                <Styled.FormLabel>{t('tab.about.build.date')}</Styled.FormLabel>
                <span>:</span>
                {/* tslint:disable-next-line:no-any */}
                <Styled.FormValue>{new Date((window as any).BUILD_DATE).toDateString()}</Styled.FormValue>
            </Styled.FormContainer>
            <Styled.FormContainer>
                <Styled.FormLabel>{t('tab.about.build.commit')}</Styled.FormLabel>
                <span>:</span>
                {/* tslint:disable-next-line:no-any */}
                <Styled.FormValue>{(window as any).HASH}</Styled.FormValue>
            </Styled.FormContainer>
            <Styled.FormContainer>
                <Styled.FormLabel>{t('tab.about.url.server')}</Styled.FormLabel>
                <span>:</span>
                <Styled.FormValue>{serverURL}</Styled.FormValue>
            </Styled.FormContainer>
            <Styled.FormContainer>
                <Styled.FormLabel>{t('tab.about.url.sync')}</Styled.FormLabel>
                <span>:</span>
                <Styled.FormValue>{wsUrl}</Styled.FormValue>
            </Styled.FormContainer>
        </div>
    );
}

export default AboutEpochContainer;