import * as React from 'react';
import * as Styled from './styled';
import {
    Assessment,
    ChevronLeft,
    ChevronRight,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Subject
} from '@material-ui/icons';
import { Divider, IconButton, Tabs, Tooltip, Typography } from '@material-ui/core';
import { ViewMode } from 'containers/Home/Home.desktop';
import { theme } from 'common/theme';
import { Doughnut } from 'react-chartjs-2';
import { Mode, Props as CalendarProps } from 'components/Calendar/Calendar';
import Calendar from 'components/Calendar';
import { DateTime } from 'luxon';
import HomeStore, { AggregateTotals } from 'store/home.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { DayCount, Features, TkHours } from '../../api/types/types';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { withTranslation, useTranslation } from 'react-i18next';
import { i18n } from 'i18next';

interface Props extends CalendarProps {
    homeStore?: HomeStore;
    view: ViewMode;
    aggregateTotals: AggregateTotals;
    onPrevMonth: () => void;
    onNextMonth: () => void;
    tkHours: number;
    toggleExpansion: boolean;
    timeEntriesCount?: DayCount[];
    // tslint:disable-next-line:no-any
    t: any;
    i18n: i18n;
}

interface State {
    expanded: boolean;
    curTab: string;
}

const Summary: React.FC<{ totals: AggregateTotals }> = ({ totals }) => {
    const { t } = useTranslation('home');
    let totalDraft = (totals.posted + totals.draft).toFixed(2);
    let totalBillable = (totals.billable + totals.nonbillable).toFixed(2);

    return (
        <Styled.SummaryInfoContainer>
            <Styled.SummarySection>
                <Styled.SummaryLine style={{ color: theme.summary.green }}>
                    <div>{t('summary_panel.summary.posted')}</div>
                    <div>{totals.posted.toFixed(2)}</div>
                </Styled.SummaryLine>
                <Styled.SummaryLine style={{ color: theme.summary.orange }}>
                    <div>{t('summary_panel.summary.draft')}</div>
                    <div>{totals.draft.toFixed(2)}</div>
                </Styled.SummaryLine>
                <Divider style={{ marginTop: '4px' }}/>
                <Styled.SummaryLine>
                    <div>{t('summary_panel.summary.total')}</div>
                    <div>{totalDraft}</div>
                </Styled.SummaryLine>
            </Styled.SummarySection>

            <Styled.SummarySection style={{ marginTop: '4px' }}>
                <Styled.SummaryLine style={{ color: theme.summary.blue }}>
                    <div>{t('summary_panel.summary.billable')}</div>
                    <div>{totals.billable.toFixed(2)}</div>
                </Styled.SummaryLine>
                <Styled.SummaryLine style={{ color: theme.summary.red }}>
                    <div>{t('summary_panel.summary.non_billable')}</div>
                    <div>{totals.nonbillable.toFixed(2)}</div>
                </Styled.SummaryLine>
                <Divider style={{ marginTop: '4px' }}/>
                <Styled.SummaryLine>
                    <div>{t('summary_panel.summary.total')}</div>
                    <div>{totalBillable}</div>
                </Styled.SummaryLine>
            </Styled.SummarySection>
        </Styled.SummaryInfoContainer>
    );
};

const MiniSummary: React.FC<{ totals: AggregateTotals }> = ({ totals }) => (
    <Styled.MiniSummaryInfoContainer>
        <Styled.MiniSummaryLine style={{ color: theme.summary.green }}>
            {totals.posted.toFixed(2)}
        </Styled.MiniSummaryLine>
        <Divider/>
        <Styled.MiniSummaryLine style={{ color: theme.summary.orange }}>
            {totals.draft.toFixed(2)}
        </Styled.MiniSummaryLine>
        <Divider/>
        <Styled.MiniSummaryLine style={{ color: theme.summary.blue }}>
            {totals.billable.toFixed(2)}
        </Styled.MiniSummaryLine>
        <Divider/>
        <Styled.MiniSummaryLine style={{ color: theme.summary.red }}>
            {totals.nonbillable.toFixed(2)}
        </Styled.MiniSummaryLine>
        <Divider/>
    </Styled.MiniSummaryInfoContainer>
);

const Chart: React.FC<{ totals: AggregateTotals, hours: number,
    disableRotation: boolean, features: Features }> = ({ totals, hours, disableRotation, features
}) => {
    const { t } = useTranslation(['home']);
    let posted = totals.posted;
    let draft = totals.draft;
    let reqHrsLabel = t('summary_panel.chart.caption.required');
    if (features.EpochConfigTKGoalsEnabled) {
        posted = totals.billablePosted || 0;
        draft = totals.billableDraft || 0;
        reqHrsLabel = t('summary_panel.chart.caption.goal');
    }
    let total = posted + draft;
    let reqHrs = hours - Number(total);
    if (reqHrs < 0) {
        reqHrs = 0;
    }
    let labels = [
        t('summary_panel.chart.doughnut.tooltip.posted'),
        t('summary_panel.chart.doughnut.tooltip.draft'),
        reqHrsLabel
    ];
    let tkhours = [
        Number(posted.toFixed(2)),
        Number(draft.toFixed(2)),
        Number(reqHrs.toFixed(2))
    ];
    const data = {
        labels: labels,
        datasets: [{
            data: tkhours,
            backgroundColor: [
                theme.chart.main.green,
                theme.chart.main.orange,
                theme.chart.main.gray
            ],
            hoverBackgroundColor: [
                theme.chart.hover.green,
                theme.chart.hover.orange,
                theme.chart.hover.gray
            ]
        }]
    };
    const options = {
        legend: {
            display: false
        },
        animation: {
            duration: disableRotation ? 0 : 1000
        },
        maintainAspectRatio: false
    };

    return (
        <div>
            <Styled.ChartText>
                <Typography variant="body2">
                    {t('summary_panel.chart.title')}
                </Typography>
            </Styled.ChartText>
            <Styled.StyledDoughnutContainer>
                <Doughnut data={data} options={options}/>
            </Styled.StyledDoughnutContainer>
            <Styled.ChartText>
                <Styled.ChartTextItem>
                    <Typography>{Number(total).toFixed(2)}</Typography>
                    <Typography variant="body2">{`${t('summary_panel.chart.caption.actual')} `}</Typography>
                </Styled.ChartTextItem>
                <Styled.ChartTextItem>
                    <Typography>{Number(hours).toFixed(2)}</Typography>
                    <Typography variant="body2">{reqHrsLabel}</Typography>
                </Styled.ChartTextItem>
            </Styled.ChartText>
        </div>
    );
};

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        homeStore: rootStore.homeStore
    };
})
@observer
class InfoBar extends React.Component<Props, State> {
    state = {
        expanded: this.props.toggleExpansion,
        curTab: 'summary'
    };
    
    componentWillReceiveProps(nextProps: Readonly<Props>): void {
        if (nextProps.toggleExpansion !== this.props.toggleExpansion) {
            this.setState({ expanded: nextProps.toggleExpansion });
        }
    }

    handleExpand = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    handleTabChange = (_: React.ChangeEvent<{}>, value: string) => {
        this.setState({ curTab: value });
    };

    miniCalendarCell = (date: DateTime) => {
        const {
            getMetaDataForDay,
            miniCalendarMainDate,
            selectedDates,
            setSelectedDates,
            isToday
        } = this.props.homeStore!;
        const { timeEntriesCount } = this.props;
        const metaData = timeEntriesCount ? timeEntriesCount.filter((t) => t.workDate === date.toISODate()) : [];

        // const isToday = (date.year === today.year && date.month === today.month && date.day === today.day)
        const isEnabled = date.month === miniCalendarMainDate.month;
        return (
            <Styled.MiniDayBox
                enabled={isEnabled}
                selected={selectedDates.findIndex((sd) => sd.startOf('day').equals(date.startOf('day'))) > -1}
                today={isToday(date)}
                onMouseDown={_ => isEnabled && setSelectedDates([date], true, true)}
            >
                <Styled.MiniDateContainer
                    enabled={isEnabled}
                    selected={selectedDates.findIndex((sd) => sd.startOf('day').equals(date.startOf('day'))) > -1}
                    today={isToday(date)}
                >
                    {date.day}
                </Styled.MiniDateContainer>
                <div style={{height: 'auto'}}>
                    <Styled.MiniTimeEntryIndicator style={{opacity: isEnabled && metaData.length > 0 ? 1 : 0}} />
                </div>
            </Styled.MiniDayBox>
        );
    };

    render() {
        const {
            view,
            currentDate,
            aggregateTotals,
            onPrevMonth,
            onNextMonth,
            tkHours,
            t
        } = this.props;
        const { expanded, curTab } = this.state;
        const startOfWeek = this.props.homeStore!.rootStore.appStore.startOfWeek;
        const disableTransition = this.props.homeStore!.rootStore.appStore.disableAnimations;

        return (
            <Styled.Container square={true} expanded={expanded}>
                {expanded
                    ? (
                        <Styled.Inner>
                            <Styled.TitleContainer>
                                <Tooltip title={t('collapse', { ns: 'common' })}>
                                    <IconButton onClick={this.handleExpand}>
                                        <ChevronRight/>
                                    </IconButton>
                                </Tooltip>
                                <Styled.Title variant="h6">
                                    {t(`summary_panel.title.${view.toLowerCase()}`)}
                                </Styled.Title>
                            </Styled.TitleContainer>
                            {view !== ViewMode.MONTH && (
                                <FeaturesConsumer>
                                    {(features: Features) =>
                                        <div style={{ marginBottom: '1px' , marginTop: '-15px'}}>
                                            <Tabs
                                                value={this.state.curTab}
                                                onChange={this.handleTabChange}
                                                variant={'fullWidth'}
                                                indicatorColor="primary"
                                                textColor="primary"
                                            >
                                                <Styled.StyledTab value="summary" icon={<Subject/>}/>
                                                {!features.EpochConfigTKGoalsEnabled &&
                                                    <Styled.StyledTab value="chart" icon={<Assessment/>}/>
                                                }
                                            </Tabs>
                                        </div>
                                    }
                                </FeaturesConsumer>
                            )}
                            {(view === ViewMode.MONTH || curTab === 'summary') && <Summary totals={aggregateTotals}/>}
                            {(view === ViewMode.MONTH || curTab === 'chart') &&
                                <FeaturesConsumer>
                                    {(features: Features) =>
                                        <Chart
                                            totals={aggregateTotals}
                                            hours={tkHours}
                                            disableRotation={disableTransition}
                                            features={features}
                                        />
                                    }
                                </FeaturesConsumer>
                            }
                            {view !== ViewMode.MONTH && (
                                <Styled.MiniCalenderContainer>
                                    <Styled.MiniCalendarDate>
                                        <IconButton onClick={onPrevMonth}>
                                            <KeyboardArrowLeft/>
                                        </IconButton>
                                        <Typography variant="h6">
                                            {currentDate.toFormat('LLLL y')}
                                        </Typography>
                                        <IconButton onClick={onNextMonth}>
                                            <KeyboardArrowRight/>
                                        </IconButton>
                                    </Styled.MiniCalendarDate>
                                    <Styled.MiniCalInner>
                                        <Calendar
                                            style={{display: 'block'}}
                                            currentDate={currentDate}
                                            cellComponent={this.miniCalendarCell}
                                            alignDays="center"
                                            startOfWeek={startOfWeek}
                                            mode={Mode.MONTH}
                                        />
                                    </Styled.MiniCalInner>
                                </Styled.MiniCalenderContainer>
                            )}
                        </Styled.Inner>
                    )

                    : (
                        <div style={{ margin: '10px' }}>
                            <Tooltip title={t('expand', { ns: 'common' })}>
                                <IconButton onClick={this.handleExpand}>
                                    <ChevronLeft/>
                                </IconButton>
                            </Tooltip>
                            <MiniSummary totals={aggregateTotals}/>
                        </div>
                    )
                }
            </Styled.Container>
        );
    }
}

export default withTranslation(['home', 'common'])(InfoBar);
