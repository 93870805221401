import styled from 'common/styled-components';

export const FlexDiv = styled<
    {fillContainer?: boolean, flex?: number, direction?: string, wrapAround?: boolean}, 'div'>('div')`
   display:flex;
   ${props => props.direction ? `flex-direction: ${props.direction};` : ''}
   ${props => props.flex ? `flex: ${props.flex};
   min-width:0;
   ` : ''}
   ${props => props.fillContainer ? `
   height:100%;
   width: 100%;
   ` : ''};
   ${props => props.wrapAround && 'flex-wrap: wrap;'}
`;