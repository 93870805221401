import TimeEntryAPI, { AssociateApiResult } from 'api/interfaces/TimeEntryAPI';
import BaseElectronImplementation from './Base.impl';
import { DateTime } from 'luxon';
import ImmutableTimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import { TimeEntry } from './Dexie';
import ImmutableBaseEntry from 'api/immutables/ImmutableBaseEntry';
import { ApiResult } from 'api/util';
import { TimeEntryType, DayCount, TimerChunk, TimeCastSegment } from '../../types/types';
import logger from '../../../logging/logging';

export default class TimeEntryImpl extends BaseElectronImplementation implements TimeEntryAPI {
    handlers: (((entries: ImmutableTimeEntry[]) => void) | null)[] = [];
    hydrateTimeEntry = async (timeEntry: TimeEntry): Promise<ImmutableTimeEntry> => {
        try {
            let entry = Object.assign(new ImmutableTimeEntry(), timeEntry);
            if (entry.sapStatus !== SapStatus.UNSUBMITTED) {
                return entry;
            }
            // todo parallelize this
            // if (entry.matterId) {
            //     let matter = await this.root.Matter.get(entry.matterId);
            //     entry = ImmutableBaseEntry.applyMatter<ImmutableTimeEntry>(entry, matter)
            // }
            // if (entry.phaseId) {
            //     let phase = await this.root.Code.get(entry.phaseId);
            //     entry = ImmutableBaseEntry.applyPhase<ImmutableTimeEntry>(entry, phase)
            // }
            // if (entry.taskCodeId) {
            //     let task = await this.root.Code.get(entry.taskCodeId);
            //     entry = ImmutableBaseEntry.applyTask<ImmutableTimeEntry>(entry, task)
            // }
            // if (entry.actCodeId) {
            //     let act = await this.root.Code.get(entry.actCodeId);
            //     entry = ImmutableBaseEntry.applyActivity<ImmutableTimeEntry>(entry, act)
            // }
            // if (entry.ffTaskCodeId) {
            //     let fftask = await this.root.Code.get(entry.ffTaskCodeId);
            //     entry = ImmutableBaseEntry.applyFFTask<ImmutableTimeEntry>(entry, fftask)
            // }
            // if (entry.ffActCodeId) {
            //     let ffact = await this.root.Code.get(entry.ffActCodeId);
            //     entry = ImmutableBaseEntry.applyFFActivity<ImmutableTimeEntry>(entry, ffact)
            // }
            // if (timeEntry.billingLang !== `EN`) {
            //     entry.billingLang = timeEntry.billingLang;
            //     entry.billingLangText = timeEntry.billingLangText;
            // }
            entry.dirty = false;
            return entry;
        } catch (e) {
            logger.error('Time Entries, Hydrating the Time Entry failed.\n', e);
            return Object.assign(new ImmutableTimeEntry(), timeEntry);
        }
    }

    /**
     * get time entries within given range and belonging to given timekeeper and are not deleted
     * @param fromDate from date to filter time entries
     * @param toDate to date to filter time entries
     * @param tkId time keeper id that time entries belong to
     * @returns list of immutable time entries within criteria
     */
    async getEntries(fromDate: DateTime, toDate: DateTime, tkId: number) {
        try {
            // this only gets called dates doesnt fall under retention dates
            this.preRetentionDateEntries(fromDate, toDate, tkId);
            let entries = await this.root.db.timeEntries
                .where('workDateTime').between(fromDate.toISO(), toDate.toISO(), true, true)
                .and(entry => !entry.deleted && entry.timeKeeperId === tkId)
                .toArray();
            return Promise.all(entries.map(this.hydrateTimeEntry));
        } catch (e) {
            logger.error('Time Entries, Get Entries api call failed.\n', e);
            return [];
        }
    }

    preRetentionDateEntries = async (fromDate: DateTime, toDate: DateTime, tkId: number) => {
        // If dates doesn't fall under the retention dates, set new retention dates and call getAll
        let retentionFromDate = DateTime.fromISO(
            JSON.parse(localStorage.getItem('retentionFromDate') || '') ||
            DateTime.local().toISO()
        );
        if (fromDate <= retentionFromDate) {
            const from = fromDate.minus({ months: 3 }).startOf('month');
            localStorage.setItem('retentionFromDate', JSON.stringify(from));
            try {
                const entries = await this.root.webImpl.TimeEntry.getEntries(from, retentionFromDate, tkId);
                const promArray = entries.map(async (entry: ImmutableTimeEntry) => {
                    let insertKey = await this.getWriteableId(entry);
                    let writeableEntry = entry.toWriteable() as TimeEntry;
                    if (insertKey) {
                        writeableEntry.localId = insertKey;
                    }
                    return writeableEntry as TimeEntry;
                });
                const writable: TimeEntry[] = (await Promise.all(promArray)).map((ent) => ent as TimeEntry);
                await this.root.db.timeEntries.bulkPut(writable);
            } catch (e) {
                logger.error('Time Entries, Preretention Date Entries failed.\n', e);
            }
        }
    }

    async getEntry(id: number) {
        try {
            if (id < 0) {
                id = id * -1;
            } else {
                return await this.hydrateTimeEntry((await this.root.db.timeEntries.get({ id }))!);
            }
            return await this.hydrateTimeEntry((await this.root.db.timeEntries.get(id))!);
        } catch (e) {
            logger.error('Time Entries, Fetching Entry failed.\n', e);
            return new ImmutableTimeEntry();
        }
    }

    async getEntryByLocalId(entry: TimeEntry) {
        try {
            if (entry.localId) {
                return await this.hydrateTimeEntry((await this.root.db.timeEntries.get({ localId: entry.localId }))!);
            }
            return await this.hydrateTimeEntry(entry);
        } catch (e) {
            logger.error('Time Entries, Get Entry By Local Id failed.\n', e);
            return new ImmutableTimeEntry();
        }
    }

    getWriteableId = async (entry: ImmutableBaseEntry): Promise<number | undefined> => {
        try {
            if (!entry.id) {
                return undefined;
            }
            if (entry.id < 0) {
                return entry.id * -1;
            }
            const localEntry = (await this.root.db.timeEntries.get({ id: entry.id }));
            const returnVal = localEntry ? localEntry.localId : undefined;
            return returnVal;
        } catch (e) {
            logger.error('Time Entries, Get Writeable Id failed.\n', e)
            return undefined;
        }
    }
    trySaveOne = async (entry: ImmutableTimeEntry): Promise<ApiResult<ImmutableTimeEntry>> => {
        try {
            let insertKey = await this.getWriteableId(entry);
            let writeableEntry = entry.toWriteable() as TimeEntry;
            // if (writeableEntry.timeEntryType === TimeEntryType.COLLABORATE) {
            //     writeableEntry.timeEntryType = TimeEntryType.NORMAL;
            // }
            writeableEntry.sapStatus = entry.sapStatus;
            if (insertKey) {
                writeableEntry.localId = insertKey;
            } else {
                // Creating createdOn value only when we dont have insertKey which means it is created first time
                writeableEntry.createdOn = (new Date()).toISOString();
            }
            writeableEntry.serverDirty = true;
            let localId = await this.root.db.timeEntries.put(writeableEntry);
            let safeEntry = (await this.root.db.timeEntries.get(localId))!;
            if (safeEntry.deleted) {
                this.root.TimeCast.dissociateSegmentsFromTimeEntry(safeEntry.id);
            }
            if (writeableEntry.matterId) {
                this.root.Matter.updateLastUsedOnTkMappings(writeableEntry.matterId);
            }
            return {
                status: {
                    failed: false,
                    message: 'Success'
                },
                object: Object.assign(new ImmutableTimeEntry(), safeEntry)
            }
        } catch (e) {
            logger.error(e.message, entry);
            return {
                status: {
                    failed: true,
                    message: 'Failed save'
                },
                object: entry
            }
        }
    }

    updateEntries = async (entries: ImmutableTimeEntry[]): Promise<ApiResult<ImmutableTimeEntry>[]> => {
        try {
            // Try saving the entries
            const ents = await Promise.all(entries.map(this.trySaveOne));
            this.root.Session.write();

            // Write if possible
            // let error = false
            // try {
            //     await this.root.Session.write();
            // } catch (e) {
            //     /* do nothing */
            //     error = true
            // }
            //
            // // Re-fetch entries after write (so `id` is updated if it was written successfully)
            // if (!error) {
            //     for (let i = 0; i < ents.length; i++) {
            //         const ent = ents[i];
            //         if (!ent.status.failed && (ent.object as TimeEntry).localId) {
            //             const localTE = await this.root.db.timeEntries.get(
            //             {localId: (ent.object as TimeEntry).localId!});
            //             ent.object = Object.assign(new ImmutableTimeEntry(), localTE);
            //         }
            //     }
            // }

            return ents;
        } catch (e) {
            logger.error('Time Entries, Update Entries failed.\n', e);
            return [];
        }
    };

    registerReciever = (handler: (entries: ImmutableTimeEntry[]) => void) => {
        this.handlers.push(handler);
        const theIndex = this.handlers.length - 1;
        return () => {
            this.handlers[theIndex] = null;
        }
    }
    getTotalForDateExclusive = async (date: string, excludeIds: number[]): Promise<number> => {
        try {
            // TODO: write this 
            let te: ImmutableTimeEntry[] = await this.getEntries(
                DateTime.fromISO(date),
                DateTime.fromISO(date),
                this.root.Session.currentTimeKeeper!);
            return te.reduce((prev, cur) => {
                if (cur.id && excludeIds.includes(cur.id)) {
                    return prev;
                }
                return prev + cur.duration;
            }, 0);
        } catch (e) {
            logger.error('Time Entries, Get Total For Date Exclusive failed.\n', e);
            return 0;
        }
    }
    recieve = async (timeEntries: TimeEntry[]): Promise<void> => {
        try {
            const promArray = timeEntries.map(async (te) => {
                if (te.id === undefined && te.localId && te.serverDirty) { // If received entries offline
                    te.id = te.localId * -1;
                    await this.root.db.timeEntries.put(te);
                }
                // check if timeentry is present in local db by timeentry id or worklocation
                return this.root.db.timeEntries
                    .filter((obj) => (obj.id === te.id || (
                        obj.workLocation !== undefined &&
                        obj.workLocation !== null &&
                        te.workLocation !== null &&
                        obj.workLocation! === te.workLocation))
                    ).first();
            });
            let localTes = await Promise.all(promArray);
            timeEntries.forEach((te, idx) => {
                if (localTes[idx]) {
                    te.localId = localTes[idx]!.localId;
                }
            });
            await this.root.db.timeEntries.bulkPut(timeEntries);
            let tes = timeEntries.map(te => Object.assign(new ImmutableTimeEntry(), te));
            this.handlers.filter(h => h !== null).forEach(h => h!(tes));
        } catch (e) {
            logger.error('Time Entries, receive failed.\n', e);
        }
    }
    write = async () => {
        try {
            let dirtyEntries = await this.root.db.timeEntries.filter(te => te.serverDirty || false).toArray();
            if (dirtyEntries.length === 0) {
                return;
            }
            const sessionId = localStorage.getItem('sessionId');
            const emitEntries: ImmutableTimeEntry[] = [];
            const toWrite = dirtyEntries
                .map(te => {
                    let newte = Object.assign(new ImmutableTimeEntry(), te);
                    if (newte.id! < 0) {
                        // work location must be unique to troubleshoot duplicate timeentries
                        // worklocation should be set when it does not exist and should not change even when session changes
                        if (newte.workLocation === undefined) {
                            newte.workLocation = `${newte.localId}_${sessionId}`;
                        }
                        newte.id = undefined;
                    }
                    return newte;
                });
            const results = await this.root.webImpl.TimeEntry.updateEntries(toWrite);
            let errorMessages: string[] = [];
            // tslint:disable-next-line:no-any
            let proms: Promise<any>[] = [];

            for (let i = 0; i < results.length; i++) {
                let curRes = results[i];
                let localEntry = dirtyEntries[i]!;
                const localEntryId = localEntry.localId! * -1;

                if (curRes.status.failed) {
                    if (curRes.status.message) {
                        errorMessages.push(curRes.status.message);
                    }
                    // failed write, do something
                } else {
                    (curRes.object as TimeEntry).localId = localEntry.localId;
                    proms.push(this.root.db.timeEntries.put(curRes.object));
                    proms.push(
                        this.root.db.timerChunks.where({ timeEntryId: localEntryId })
                            .modify({ timeEntryId: curRes.object.id })
                    );
                    // update localIds referenced by TimeCastSegments
                    proms.push(
                        this.root.db.timecastSegments.where({ associatedTimeEntry: localEntryId })
                            .modify({ associatedTimeEntry: curRes.object.id })
                    );
                    if (localEntry.id! < 0) {
                        localEntry.deleted = true;
                        // id less than 0, only local 
                        emitEntries.push(Object.assign(new ImmutableTimeEntry(), localEntry));
                    }
                    emitEntries.push(Object.assign(new ImmutableTimeEntry(), curRes.object));
                }
            }
            await Promise.all(proms);
            if (errorMessages.length > 0) {
                // TODO: Internationalization
                alert(`Server error: ${errorMessages.join()}`);
            }
            this.handlers.filter(h => h !== null).forEach(h => h!(emitEntries));
        } catch (e) {
            logger.error('Time Entries, write failed.\n', e);
        }
    }

    getTKHours = async (date: DateTime) => {
        try {
            let tkId = this.root.Session.currentTimeKeeper!;
            if (tkId) {
                const Prom = this.root.db.tkHours.where({ timeKeeperId: tkId })
                    .and((d) => DateTime.fromISO(d.endDate) >= date && DateTime.fromISO(d.startDate) <= date)
                    .first();
                return Prom;
            } else {
                return undefined;
            }
        } catch (e) {
            logger.error('Time Entries, get Time Keeper Hours (getTKHours) failed.\n', e);
            return undefined;
        }
    }
    getServerEntries = async (tkId: number) => {
        let from = DateTime.local().minus({ months: 2 }).startOf('month');
        let to = DateTime.local().endOf('month');
        const entries: ImmutableTimeEntry[] = await this.root.webImpl.TimeEntry.getEntries(from, to, tkId);
        const promArray = entries.map(async (entry: ImmutableTimeEntry) => {
            let insertKey = await this.getWriteableId(entry);
            let writeableEntry = entry.toWriteable() as TimeEntry;
            if (insertKey) {
                writeableEntry.localId = insertKey;
            }
            return writeableEntry as TimeEntry;
        });
        const writable: TimeEntry[] = (await Promise.all(promArray)).map((ent) => ent as TimeEntry);
        await this.root.db.timeEntries.bulkPut(writable);
    }

    getTimEntriesCount = async (fromDate: DateTime, toDate: DateTime) => {
        try {
            let tkId = this.root.Session.currentTimeKeeper!;
            // this only gets called dates doesnt fall under retention dates
            this.preRetentionDateEntries(fromDate, toDate, tkId);
            // Defined finalResult array of day counts
            let finalResult: DayCount[] = [];
            await this.root.db.timeEntries.orderBy('workDateTime')
                .filter(d =>
                    (DateTime.fromISO(d.workDateTime) >= fromDate && DateTime.fromISO(d.workDateTime) <= toDate) &&
                    (d.timeKeeperId === tkId) && (!d.deleted)
                ).eachKey(date => {
                    let dateInLoop = DateTime.fromISO(date.toLocaleString()).toISODate();
                    // The result with the exact date in the loop
                    if (!finalResult.find(el => el.workDate === dateInLoop)) {
                        // if already existing instance of date then add count
                        // finalResult.map(x => {
                        //     if (x.workDate === dateInLoop) {
                        //         x.count = x.count + 1
                        //     }
                        //    
                        //     return x
                        // })
                        finalResult.push({ workDate: dateInLoop, count: 1 });
                    }
                });
            return finalResult;
        } catch (e) {
            logger.error('Time Entries, get Server Entries failed.\n', e);
            return [];
        }
    }
    associateSegmentsToEntry = async (entry: ImmutableTimeEntry, chunks: TimerChunk[], tcSegs?: TimeCastSegment[]) => {
        try {
            const teApiResult = await this.trySaveOne(entry);
            const { object } = teApiResult;
            let timerChunkApis;
            let tcResult;
            if (chunks.length > 0) {
                const chunksToUpdate = chunks.map(chunk => {
                    chunk.timeEntryId = object.id;
                    if (object.sapStatus !== SapStatus.UNSUBMITTED) {
                        chunk.submitted = true;
                    }
                    return chunk;
                });
                timerChunkApis = await this.root.Timer.updateChunks(chunksToUpdate, [], true);
            }
            if (tcSegs) {
                const tcToUpdate = tcSegs.map(seg => {
                    seg.associatedTimeEntry = object.id;
                    return seg;
                });
                tcResult = await this.root.TimeCast.saveSegments(tcToUpdate);
            }
            let apiResult: AssociateApiResult = { TimeEntryApi: teApiResult };
            if (timerChunkApis) {
                apiResult.TimerChunkApis = timerChunkApis;
            }
            if (tcResult) {
                apiResult.TimeCastApis = tcResult;
            }
            this.root.Session.write();
            return apiResult;
        } catch (e) {
            logger.error('Time Entries, Associate Segments To Entry failed.\n', e);
            return Promise.reject(e);
        }
    }
    searchWorkLocales = async (searchText: string) => {
        let results = await this.root.webImpl.TimeEntry.searchWorkLocales(searchText);
        if (results.length === 0) {
            results = await this.root.db.workLocales
                .filter(w => w.localeSearch.toLowerCase().includes(searchText.toLowerCase()))
                .toArray();
        }
        return results;
    }
    getWorkLocaleById = async (id: number) => {
        try {
            return await this.root.webImpl.TimeEntry.getWorkLocaleById(id);
        } catch {
            return await this.root.db.workLocales
                .where({'id': id})
                .toArray();
        }
    }

    uploadTimeEntries = async (file: File, mimeType: string) => {
        return [];
    }

    async getAuditLog(id: number) {
        try {
            return await this.root.webImpl.TimeEntry.getAuditLog(id);
        } catch {
            return [];
        }
    }
}