import { action, computed, observable, makeObservable } from 'mobx';
import BaseStore from 'store/base.store';
import { Matter, MatterListType } from '../api/types/types';
import { RootStore } from 'store/root.store';
import Mutex from '../api/util';
import { debounce } from 'typescript-debounce-decorator';
import { sortByAlphaNumeric } from '../util/utils';

export default class MattersStore extends BaseStore {
    @observable availableMatters: Matter[] = [];
    @observable trackedMatters: Matter[] = [];
    @observable selectedMatters: number[] = [];
    @observable loadingTracked: boolean = false;
    @observable loadingAvailable: boolean = false;
    @observable searchTrackedText: string = '';
    @observable searchAvailableText: string = '';
    @observable currentEnd: number = 0;
    caughtEmAll: boolean = false;
    loadMutex = new Mutex();

    handlerDestructor: () => void;
    constructor(root: RootStore) {
        super(root);
        makeObservable(this);
        this.initialize();
    }
    initialize = async () => {
        this.handlerDestructor = this.rootStore.api.Matter.registerReciever(this.receiveMatters);
    }
    @action receiveMatters = (matters: Matter[]) => {
        if (this.rootStore.appStore.currentTimekeeper && this.rootStore.routerStore.location.pathname === '/matters') {
            // this.loadAvailableMatters(this.searchAvailableText, false, 0, 50);
            this.appendToTracked(matters);
        }
    }
    @action
    async loadTrackedMatters() {
        this.loadingTracked = true;
        this.trackedMatters = await this.searchTrackedMatters();
        this.loadingTracked = false;
    }
    @debounce(1000, {leading: false})
    @action
    async loadAvailableMatters(query?: string, showTracked?: boolean, offset?: number, limit?: number) {
        this.loadingAvailable = true;
        this.availableMatters = await this.searchAvailableMatters(query, showTracked, offset, limit);
        this.currentEnd = this.availableMatters.length;
        this.loadingAvailable = false;
        this.selectedMatters = [];
    }
    searchAvailableMatters = async (query?: string, showTracked?: boolean, offset?: number, limit?: number) => {
        try {
            return await this.rootStore.api.Matter.getAvailableMatters(
                query, 
                showTracked, 
                undefined, 
                '',  
                offset, 
                limit
            );
        } catch (e) {
            return [];
        }
    }
    searchTrackedMatters = async () => {
        try {
            return await this.rootStore.api.Matter.getTrackedMatters();
        } catch (e) {
            return [];
        }
    }
    appendToTracked = (matters: Matter[]) => {
        matters.forEach((matter) => {
            const tracked = this.trackedMatters.find(m => m.id === matter.id);
            if (!tracked) {
                this.trackedMatters.push(matter); 
            }
        });
    }
    @action setTrackedSearchText(val: string) {
        this.searchTrackedText = val;
    }
    @action setAvailableSearchText(val: string) {
        this.searchAvailableText = val;
    }
    @action setSelectedMatters = (ids: number[]) => {
        this.selectedMatters =  ids;
    }
    // Tracking or untracking matters
    @action track = async () => {
        const ns = 'matters';
        let key: string;
        if (this.rootStore.appStore.online) {
            if (this.selectedMatters.length === 0) {
                key = 'right_panel.header.icon.snackbar.no_selection';
                this.rootStore.snackbarStore.triggerSnackbar(key, { ns });
                return;
            }
            await this.rootStore.api.Matter.track(this.selectedMatters)
            let avMatters = new Set(this.availableMatters);
            this.availableMatters = Array.from(
                new Set([...avMatters].filter((m) => !new Set(this.selectedMatters).has(m.id)))
            );
            const putInTracked = Array.from(new Set([...avMatters].filter((m) => 
                new Set(this.selectedMatters).has(m.id))));
            this.trackedMatters = this.trackedMatters.concat(putInTracked);
            this.trackedMatters = this.sortMattersArray(this.trackedMatters);
            this.resetTrackedAndAvailableMatters();
            key = 'right_panel.header.icon.snackbar.confirmation';
            this.rootStore.snackbarStore.triggerSnackbar(key, { ns });
            this.rootStore.api.Session.sync();
        } else {
            this.searchAvailableMatters('');
            key = 'right_panel.header.icon.dialog.message.offline';
            const matterLabel = this.rootStore.appStore.features.EpochConfigMatterLabel;
            // eslint-disable-next-line no-restricted-globals
            this.confirm(key, { matterLabel, ns });
        }
    }

    resetTrackedAndAvailableMatters = () => {
        this.setSelectedMatters([]);
        // this.setAvailableSearchText('');
        // this.setTrackedSearchText('');
        // this.loadTrackedMatters();
        // this.loadAvailableMatters('', false);
    }
    
    @action untrack = async (ids: number[]) => {
        const ns = 'matters';
        let key: string;
        if (this.rootStore.appStore.online) {
            await this.rootStore.api.Matter.untrack(ids);
            let trMatters = new Set(this.trackedMatters);
            this.trackedMatters = Array.from(
                new Set([...trMatters].filter((m) => !new Set(ids).has(m.id)))
            );
            const putInAvailable = Array.from(new Set([...trMatters].filter((m) => new Set(ids).has(m.id))));
            this.availableMatters = this.availableMatters
                .concat(putInAvailable)
                .filter((m) => 
                    m.number.toLowerCase().includes(this.searchAvailableText.toLowerCase()) ||
                    m.clientNumber.toLowerCase().includes(this.searchAvailableText.toLowerCase()) ||
                    m.clientName.toLowerCase().includes(this.searchAvailableText.toLowerCase()) ||
                    m.name.toLowerCase().includes(this.searchAvailableText.toLowerCase()) ||
                    m.description.toLowerCase().includes(this.searchAvailableText.toLowerCase())
                )
            this.availableMatters = this.sortMattersArray(this.availableMatters);
            this.resetTrackedAndAvailableMatters();
            key = 'left_panel.list.row.icon.snackbar.confirmation';
            this.rootStore.snackbarStore.triggerSnackbar(key, { ns });
        } else {
            this.setAvailableSearchText('');
            this.setTrackedSearchText('');
            this.loadTrackedMatters();
            key = 'left_panel.list.row.icon.dialog.message.offline';
            const matterLabel = this.rootStore.appStore.features.EpochConfigMatterLabel;
            // eslint-disable-next-line no-restricted-globals
            this.confirm(key, { matterLabel, ns });
        }
    }
    fetchMoreTrackedMatters = async () => {
        // Todo: Call API if needed in the future.
        return this.trackedMatters;
    }
    fetchMoreAvailMatters = async () => {
        if (this.caughtEmAll) {
            return;
        }
        await this.loadMutex.execute(async () => {
            let startOffset = this.currentEnd;
            let newElems = await this.searchAvailableMatters(this.searchAvailableText, false, startOffset, 50);
            this.currentEnd = this.currentEnd + newElems.length;
            if (newElems.length === 0) {
                this.caughtEmAll = true;
            }
            this.availableMatters = this.availableMatters.concat(newElems);
        })
    }
    @computed get availableClientMattersTuple(): MatterListType[] {
        let results = new Map();
        let clients: Matter[] = [];
        this.availableMatters.forEach((matter: Matter) => {
            let res = results.get(matter.clientId);
            if (res === undefined) {
                res = [];
                clients.push(matter);
            }
            res.push(matter);
            results.set(matter.clientId, res);
        });
        clients.sort((m1: Matter, m2: Matter) => m1.clientName!.localeCompare(m2.clientName!));

        let mattersArr: MatterListType[] = [];
        // Because couldn't figure out any other approach for grouping + virtualization as of now. 
        // Fixme: Implement better approach for grouping + virtualization.
        clients.forEach((matter: Matter) => {
            let objToPush: MatterListType;
            objToPush = {
                id: matter.clientId,
                name: matter.clientName,
                clientId: matter.clientId,
                clientName: matter.clientName,
                number: matter.clientNumber,
                isHeader: true,
                description: ''
            }
            mattersArr.push(objToPush);
            results.get(matter.clientId).forEach((mat: Matter) => {
                objToPush = {
                    id: mat.id,
                    name: mat.name,
                    clientId: matter.clientId,
                    clientName: matter.clientName,
                    number: mat.number,
                    isHeader: false,
                    description: mat.description
                }
                mattersArr.push(objToPush);
            })
        })
        mattersArr = this.sortMattersArray(mattersArr);
        return mattersArr;
    }
    @computed get trackedClientMattersTuple(): MatterListType[] {
        let results = new Map();
        let clients: Matter[] = [];
        this.filteredTrackedMatters.forEach((matter: Matter) => {
            let res = results.get(matter.clientId);
            if (res === undefined) {
                res = [];
                clients.push(matter);
            }
            res.push(matter);
            results.set(matter.clientId, res);
        });
        clients.sort((m1: Matter, m2: Matter) => m1.clientName!.localeCompare(m2.clientName!));

        let mattersArr: MatterListType[] = [];
        clients.forEach((matter: Matter) => {
            let objToPush: MatterListType;
            objToPush = {
                id: matter.clientId,
                name: matter.clientName,
                clientId: matter.clientId,
                clientName: matter.clientName,
                number: matter.clientNumber,
                isHeader: true,
                description: ''
            }
            mattersArr.push(objToPush);
            results.get(matter.clientId).forEach((mat: Matter) => {
                objToPush = {
                    id: mat.id,
                    name: mat.name,
                    clientId: matter.clientId,
                    clientName: matter.clientName,
                    number: mat.number,
                    isHeader: false,
                    description: mat.description
                }
                mattersArr.push(objToPush);
            })
        })
        mattersArr = this.sortMattersArray(mattersArr);
        return mattersArr;
    }
    // tslint:disable-next-line:no-any
    sortMattersArray = (mattersList: any) => {
        // tslint:disable-next-line:no-any
        mattersList = mattersList.sort((a: any, b: any) => {
            if (a.clientId === b.clientId) {
                return a.isHeader && !b.isHeader ? -1 : sortByAlphaNumeric(a.number, b.number);
            } 
            let clientNameA = a.clientName.toLowerCase();
            let clientNameB = b.clientName.toLowerCase();
            return clientNameA > clientNameB ? 1 : clientNameB > clientNameA ? -1 : 1;
        })
        return mattersList;
    }
    @computed get filteredAvailableMatters(): Matter[] {
        if (!this.searchAvailableText || this.searchAvailableText.length === 0) {
            return this.availableMatters;
        }
        let filteredEntries = this.availableMatters.filter((m: Matter) => {
            return this.searchWithinObject(m, this.searchAvailableText);
        });
        return filteredEntries;
    }
    @computed get filteredTrackedMatters(): Matter[] {
        if (!this.searchTrackedText.trim() || this.searchTrackedText.trim().length === 0) {
            return this.trackedMatters;
        }
        let filteredEntries = this.trackedMatters.filter((m: Matter) => {
            return this.searchWithinObject(m, this.searchTrackedText.trim());
        });
        return filteredEntries;
    }
    searchWithinObject(m: Matter, search: string): boolean {
        let mname = m.name ? m.name.toLowerCase() : '',
            mnumber = m.number ? m.number.toLowerCase() : '',
            mdesc = m.description ? m.description.toLowerCase() : '',
            cname = m.clientName ? m.clientName.toLowerCase() : '',
            cnumber = m.clientNumber ? m.clientNumber.toLowerCase() : '',
            
            searchText = search.toLowerCase();

        return mname.includes(searchText) ||
            mnumber.includes(searchText) ||
            mdesc.includes(searchText) ||
            cname.includes(searchText) ||
            cnumber.includes(searchText);
    }
}