import DialogRootStore from './dialog.root.store';
import { action, observable, override, makeObservable } from 'mobx';
import { RootStore } from 'store/root.store';
import i18n from 'i18next';

/**
 * confirmation dialog store
 * meant to act as the confirm function in traditional javascript
 * call this.confirm('your message'), and once it is handled by the user,
 * it will resolve with a boolean whether the user confirmed or not
 */
export default class ConfirmStore extends DialogRootStore<string, boolean> {
    @observable message: string = '';

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @action.bound
    async onOpen(msg: string) {
        this.message = msg;
    }

    /**
     * wrapper method for open,
     * @param msg
     * @return Promise of open dialog
     */
    @action.bound
    confirm(msg: string, options: {} = { ns: 'common'}) {
        return this.open(i18n.t(msg, options));
    }

    // overrides cancel which rejects promise
    @override
    cancel(): void {
        this.resolveAndClose(false);
    }

    @action.bound
    verify(): void {
        this.resolveAndClose(true);
    }
}
