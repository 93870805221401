import { useContext, useRef } from 'react';
import { IconButton } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { RootStoreContext } from '../App';

export default function BulkUploadButton() {
    const inputFileRef = useRef<HTMLInputElement>( null );
    const { t } = useTranslation('timeentries');
    const rootStore = useContext(RootStoreContext);
    const onFileChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
        let files = e.currentTarget.files ? e.currentTarget.files : [];
        let file = files[0];
        
        rootStore.api.TimeEntry.uploadTimeEntries(file, file.type).then(() => {
            rootStore.snackbarStore.triggerSnackbar('upload success');
            rootStore.appStore.synchronize();
        });
    }
    const onBtnClick = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    }

    return (
    <>
        <input
            accept=".csv, .xlsx, .xls"
            style={{ display: 'none' }}
            id="upload-file"
            hidden={true}
            type="file"
            ref={inputFileRef}
            onChange={onFileChange}
        />
        <IconButton
            key={'upload'}
            title={t('header.action.upload')}
            onClick={onBtnClick}
        >
            <CloudUpload />
        </IconButton>
    </>
    )
}