import styled from 'styled-components';
import { Button, Checkbox, IconButton } from '@material-ui/core';
import { FlexDiv } from 'common/flex';
import { ViewHeadline } from '@material-ui/icons';
import { CaptureSegmentsList } from 'components/CaptureSegmentsList/CaptureSegmentsList';
// import borderTopB4B4B4 from '../../images/border-top-b4b4b4.svg';
// import borderTopFAFAFA from '../../images/border-top-fafafa.svg';
// import borderTopF3F3F3 from '../../images/border-top-f3f3f3.svg';

export const ViewButton = styled(Button)`
    width: 88px;
`;
export const NewEntryNavButton = styled.div`
    margin-left: auto;
    border-right: 1px solid #fcb52c;
`;

export const Container = styled(FlexDiv)`
    height: 100%;
`;

export const Plus = styled.span`
    font-size: 24px;
    transition: ${props => props.theme.animationsEnabled && '0.2s'};
    cursor: pointer;
    color: ${props => props.theme.primary.main};
    display: none;
    margin: 8px;
    user-select: none;
    &:hover {
        font-size:30px;
    }
`;

export const DateContainer = styled.div<{ today?: boolean }>`
    && {
        height: 25px;
    }
    width: 25px;
    margin-top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${props => props.today && props.theme.primary.main};
    &&& {
        color: ${props => props.today && props.theme.white.main};
    }
`;

export const CellBottom = styled.div`
    && {
        height: auto;
    }
    align-self: flex-end;
`;

export const DayBox = styled.div<{ enabled?: boolean, selected?: boolean }>`
    padding: 7px;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    color: ${props => !props.enabled && 'rgba(0, 0, 0, 0.5)'};
    ${DateContainer} {
        color: ${props => props.selected && props.theme.primary.main};
    }
    ${CellBottom} {
        font-weight: ${props => props.selected && '500'};
    }
    &:hover {
        background: ${props => props.enabled && 'rgba(0, 0, 0, 0.02)'};
        cursor: ${props => props.enabled ? 'pointer' : 'default'};
        ${Plus} {
            ${props => props.enabled && 'display: inline'};
        }
    }
    box-shadow: ${props => props.selected && '0px 1px 15px -4px rgba(99,99,99,1)'};
    background: ${props => props.selected && 'rgba(0, 0, 0, 0.02)'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const CellTop = styled.div`
    && {
        height: auto;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const TotalsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TotalsLineContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const MainContainer = styled.div<{ week?: boolean }>`
    display: grid;
    width: 100%;
    height: 100%;
    grid-auto-flow: row;
    ${props => props.week && `grid-template-rows: 225px 1fr;`}
`;

export const TimeEntryList = styled.div`
    overflow-y: auto;
    padding: 5px;
    flex: 1;
`;

export const CalendarContainer = styled.div`
    transition: ${props => props.theme.animationsEnabled && 'all 0.3s cubic-bezier(0.55, 0, 0.1, 1)'};
    overflow: hidden;
    width: 100%;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

export const FabContainer = styled.div<{onScreen: boolean}>`
    position: fixed;
    width: 100%;
    transition: 0.4s;
    bottom: ${props => props.onScreen ? '24px' : '-150px'};
    right: 0;
    text-align: center;
    pointer-events: none;
    button {
        margin: 5px;
        pointer-events: all;
    }
    display: flex;
    justify-content: center;
`;

export const Posted = styled.div`
    color: ${props => props.theme.summary.green};
`;

export const Draft = styled.div`
    color: ${props => props.theme.summary.orange};
`;

export const Billable = styled.div`
    color: ${props => props.theme.summary.blue};
`;

export const NonBillable = styled.div`
    color: ${props => props.theme.summary.red}
`;

// tslint:disable-next-line:no-any
export const arrowStyles = (muiTheme: any) => ({
    arrowPopper: {
        '&[x-placement*="bottom"] $arrowArrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${muiTheme.palette.grey[700]} transparent`
            }
        },
        '&[x-placement*="top"] $arrowArrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${muiTheme.palette.grey[700]} transparent transparent transparent`
            }
        },
        '&[x-placement*="right"] $arrowArrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${muiTheme.palette.grey[700]} transparent transparent`
            }
        },
        '&[x-placement*="left"] $arrowArrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${muiTheme.palette.grey[700]}`
            }
        }
    },
    arrowArrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: `''`,
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid'
        }
    },
    groupBySelect: {
        marginTop: 14,
        color: '#fff',
        fontSize: 12
    },
    groupByForm: {
        width: 120,
        marginLeft: 'auto',
        right: 3
    }
});

export const LoadingIndicator = styled.div`
    position: absolute;
    width: 100%;
    pointer-events: none;
    top: 60px;
    z-index: 1300;
`;

export const CapturedSegmentsControls = styled.div`
  background-color: #444;
  height: 60px;
  display: flex;
  align-items: center;
`;

export const CapturedSegmentsContainer = styled.div`
  flex: 1;
  border-right: 1px solid lightgray;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-flow: column;
`;

export const CapturedSegmentsListContainer = styled.div`
  height: 100%;
  overflow: hidden auto;
  display: flex;
`;

export const SegmentTime = styled.div`
  font-size: 0.7em;
  width: 115px;
`;

export const SegmentTitle = styled.div<{gap?: boolean}>`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  ${p => p.gap ? `color: #636363;` : ''}
`;

export const SegmentContent = styled.div`
  display: flex;
  flex-flow: row;
`;

export const SegmentCheckbox = styled(Checkbox)`
  width: 40px;
  height: 40px;
  float: left;
`;
export const SegmentViewHeadLine = styled.div`
  width: 40px;
  height: 40px;
  float: left;
  display: flex;
  align-items: center;
`;

export const Segment = styled.div<{ gap?: boolean }>`
  background-color: ${p => p.gap ? 'rgba(180,180,180,0.10)' : '#FFFFFF00'};
  color: black;
  display: inline-block;
  align-items: center;
  cursor: pointer;
  height: 40px;
  width: 100%;
  ${p => p.gap ? 'line-height: 40px;' : ''}
  user-select: none;
`;

export const Group = styled.div<{writable?: boolean}>`
  height: 40px;
  line-height: 40px;
  ${p => p.writable && `pointer-events: none`}
`;

export const Gap = styled.div<{writable?: boolean}>`
  height: 40px;
  line-height: 40px;
  ${p => p.writable && `pointer-events: none`}
`;

export const SegmentIconButton = styled(IconButton)`
  padding: 0 !important;
  width: 40px;
  height: 40px;
  
  & svg {
    color: ${p => p.disabled ? 'rgb(255, 255, 255, 0.26)' : 'white'};
  }
  
  & svg[data-active=true] {
    color: #ff9800;
  }
`;

export const WeekDayViewContainer = styled.div`
    display: flex;
    maxHeight: 100%;
    overflow: auto;
    
    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;
export const SegmentsPane = styled.div`
    border-left: 1px solid #666;
    flex: 1;
    height: 100%;
    overflow: auto;
`;