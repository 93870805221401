import ClientAPI from 'api/interfaces/ClientAPI';
import BaseElectronImplementation from './Base.impl';
import { Client } from 'api/types/types';
export default class ClientImpl extends BaseElectronImplementation implements ClientAPI {
    searchClients = async (searchText: string) => {
        try {
            return await this.root.webImpl.Client.searchClients(searchText);
        } catch {
            const features = await this.root.Session.getFeatures();
            let curTk = this.root.Session.currentTimeKeeper!;
            let trackedMatters = (await this.root.db.matterTkMappings
                .where({ timeKeeperId: curTk, tracked: 'true' })
                .with({ matter: 'matterId' })).filter(m => m !== undefined);
            const distinctClientIds = Array.from(new Set(trackedMatters.map(m => m.matter!.clientId!))).filter(c => c !== undefined)
            let clients = await this.root.db.clients
                .where('id')
                .anyOf(distinctClientIds).toArray()
            if (features.EpochConfigClientGroupSearch) {
                // First we are testing with search term and returning if we find something that starts with
                let exactSearchedClients = clients.filter((c) => {
                    let checkSearchTerm = `${c.searchTerm}`
                    return checkSearchTerm.toLowerCase().startsWith(searchText.toLowerCase())
                })
                // If we have any data passing the above condition we are returning the list to show in Clients Drop Down
                if (exactSearchedClients.length > 0) {
                    return exactSearchedClients.sort(
                        (a, b) => { 
                            let aClientGroup = a.clientGroup ? a.clientGroup : '';
                            let bClientGroup = b.clientGroup ? b.clientGroup : '';
                            let aParent = a.parent ? a.parent : false;
                            let bParent = b.parent ? b.parent : false;
                            return aClientGroup.localeCompare(bClientGroup) || 
                                bParent.toString().localeCompare(aParent.toString());
                        });
                }
                // Second we will create a search Term similar to backend to search our clients list
                let searchBTWClientSearchTerm = clients.filter((c) => {
                    let checkSearchTerm = `${c.number} - ${c.name} - ${c.clientGroup} - ${c.searchTerm}`
                    return checkSearchTerm.toLowerCase().includes(searchText.toLowerCase())
                })
                // If we have any data passing the above condition we are returning the list to show in Clients Drop Down
                if (searchBTWClientSearchTerm.length > 0) {
                    return searchBTWClientSearchTerm.sort((a, b) => a.clientGroup!.localeCompare(b.clientGroup!) ||
                        b.parent!.toString().localeCompare(a.parent!.toString()))
                }
            }
            return clients.filter((c) => {
                let comp = `${c.number} - ${c.name}`;
                return comp.toLowerCase().includes(searchText.toLowerCase())
            });
        }
    }
    getClients = async () => {
        return await this.root.db.clients.toArray();
    }

    get = async (id: number) => {
        const client = await this.root.db.clients.get(id);
        return (client !== undefined) ? client : null;
    }
}