import * as React from 'react';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    IconButton,
    TextField,
    Tooltip
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import MultipleTimeEntriesDialogStore from 'store/multipleTimeentries.dialog.store';
import TimeEntryForm from 'components/TimeEntryForm/TimeEntryForm';
import { FlexDiv } from 'common/flex';
import { InlineDatePicker } from 'material-ui-pickers';
import { ChevronLeft, ChevronRight, KeyboardArrowLeft, KeyboardArrowRight, Lock, LockOpen } from '@material-ui/icons';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import { ApiConsumer } from 'common/ApiProvider';
import RootAPI from '../../api/interfaces/RootAPI';
import Template from 'api/immutables/ImmutableTemplate';
import { DateTime } from 'luxon';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { Features } from '../../api/types/types';
import { isoDate, getDateFormat } from '../../util/date';
import { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import { withTranslation } from 'react-i18next';
import { getTemplateTooltipText } from 'util/template';

interface Props {
    dialogStore?: MultipleTimeEntriesDialogStore;
    allowSaveAsTemplate?: boolean;
    // tslint:disable-next-line:no-any
    t: any;
}

interface State {
    controlKeyPressed?: boolean;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        dialogStore: rootStore.multipleTimeEntriesDialogStore
    }
})
@observer
class MultipleTimeEntriesDialog extends React.Component<Props, State> {
    state: State = {}

    componentDidMount(): void {
        document.addEventListener('keyup', this.keyup);
        document.addEventListener('keydown', this.keydown);
    }

    componentWillUnmount(): void {
        document.removeEventListener('keydown', this.keydown);
        document.removeEventListener('keyup', this.keyup);
    }

    changeReference = (event: React.ChangeEvent<HTMLInputElement>) => {
        let entry = this.props.dialogStore!.entry.setReference(event.target.value);
        this.props.dialogStore!.changeEntry(entry);
    }

    setTemplateName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.dialogStore!.setTemplateName(event.target.value);
    }

    cancel = () => {
        this.props.dialogStore!.clearAndClose();
    }

    nextTimeEntry = () => {
        this.props.dialogStore!.nextTimeEntry();
    }

    previousTimeEntry = () => {
        this.props.dialogStore!.previousTimeEntry();
    }
    setWorkDate = (date: Date) => {
        const workDate: DateTime = DateTime.local(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
            0,
            0,
            0
        );
        this.props.dialogStore!.setWorkDate(workDate);
        this.props.dialogStore!.validationState = undefined;    
    }
    
    disableDate = (day: Date) => {
        const date = DateTime.local(
            day.getFullYear(),
            day.getMonth() + 1, // getMonth returns from 0 to 11
            day.getDate()
        ).toISO();
        const entries = this.props.dialogStore!.entries;
        
        return !entries.some(timeEntry => 
            timeEntry.workDateTime === date
        );
    }

    render() {
        const { t, dialogStore } = this.props;
        const {
            isOpen,
            entry,
            entries,
            templateName,
            selectedTemplate,
            changeEntry,
            setTemplate,
            saving,
            wrappedPost,
            wrappedSave,
            durationValidationState,
            validationState,
            templateValidationState,
            toggleLock,
            editAllLock,
            wrappedPostAll,
            wrappedSaveAll,
            canSelectPreviousTimeEntry,
            canSelectNextTimeEntry,
            removeEntryFromSelection,
            indexOfEntityBeingEdited,
            setFieldLoaderFn,
            rootStore
        } = dialogStore!;

        let workDateErrText: string = ''
        if (validationState && validationState.invalidWorkDate) {
            workDateErrText = t('validation.date.invalid');
        }
        const fetchTemplates = rootStore.templateStore.fetchTemplatesInAutoComplate;
        let tkOfficesToShow = rootStore.appStore.getActiveTKOfficesForDate((DateTime.fromISO(entry && entry.workDateTime)));
        return (
            <FeaturesConsumer>
                {(features: Features) =>
                    <>
                        <Dialog
                            PaperProps={{ style: { overflow: 'visible', maxWidth: '1024px' } }}
                            disableBackdropClick={true}
                            disableEscapeKeyDown={true}
                            open={isOpen}
                            onClose={this.cancel}
                            fullWidth={true}
                            maxWidth={false}
                            disableRestoreFocus={true}
                        >
                            <DialogTitle
                                id="timeentry-dialog-title"
                                style={{ paddingBottom: 0 }}
                            >
                                {t('dialog.multi_entries.title')}
                            </DialogTitle>
                            <DialogContent style={{ overflow: 'visible', paddingBottom: 0 }}>
                                <FlexDiv direction="row" wrapAround={true}>
                                    {!editAllLock ? (
                                        <>
                                            <Button
                                                disabled={!canSelectPreviousTimeEntry}
                                                onClick={this.previousTimeEntry}
                                            >
                                                <ChevronLeft/>
                                            </Button>
                                            <InlineDatePicker
                                                format={entry ? 
                                                    DateTime.fromISO(entry.workDateTime).toFormat(getDateFormat()) : 'MM/dd/yyyy'}
                                                onlyCalendar={true}
                                                value={entry ? isoDate(DateTime.fromISO(entry.workDateTime)) : ''}
                                                disabled={entry && entry.isPosted()}
                                                onChange={this.setWorkDate}
                                                leftArrowIcon={<KeyboardArrowLeft/>}
                                                rightArrowIcon={<KeyboardArrowRight/>}
                                                shouldDisableDate={this.disableDate}
                                            />
                                            <Button
                                                disabled={!canSelectNextTimeEntry}
                                                onClick={this.nextTimeEntry}
                                            >
                                                <ChevronRight/>
                                            </Button>
                                            <Tooltip title={t('dialog.multi_entries.edit.one.icon.tooltip')}>
                                                <Button 
                                                    disabled={indexOfEntityBeingEdited === entries.length - 1} 
                                                    onClick={toggleLock}
                                                >
                                                    <LockOpen/>
                                                </Button>
                                            </Tooltip>
                                            <FlexDiv flex={1}/>
                                        </>
                                    ) : (
                                        <FlexDiv
                                            flex={3}
                                            direction="row"
                                            wrapAround={true}
                                            style={{maxHeight: '110px', overflow: 'auto', marginRight: '2px'}}
                                        >
                                            {entries.map(timeEntry => (
                                                <Chip
                                                    label={
                                                            DateTime.fromISO(
                                                                timeEntry.workDateTime.substring(0, 10)
                                                            ).toFormat(getDateFormat())
                                                        }
                                                    onDelete={() => removeEntryFromSelection(timeEntry)}
                                                    style={{margin: '2px'}}
                                                />
                                            ))}
                                            <Tooltip title={t('dialog.multi_entries.edit.all.icon.tooltip')}>
                                                <IconButton onClick={toggleLock} style={{padding: '6px'}}>
                                                    <Lock/>
                                                </IconButton>
                                            </Tooltip>
                                        </FlexDiv>
                                    )}
                                    {entry && !entry.isPosted() && <ApiConsumer>
                                        {(api: RootAPI) =>
                                            <FlexDiv style={{alignItems: 'flex-end'}}>
                                                <AutoCompleteField
                                                    label={t('field.template.title')}
                                                    fetch={(search: string) => fetchTemplates(search, entry)}
                                                    currentItem={selectedTemplate}
                                                    clearable={true}
                                                    disabled={false}
                                                    onClear={() => setTemplate(undefined)}
                                                    getItemText={(m: Template) => `${m.name}`}
                                                    onSelect={setTemplate}
                                                    width={200}
                                                    tooltip={(m: Template) => getTemplateTooltipText(m, t)}
                                                />
                                            </FlexDiv>}

                                    </ApiConsumer>}
                                </FlexDiv>
                                {workDateErrText && (
                                    <FormHelperText error={true}>
                                        {workDateErrText}
                                    </FormHelperText>
                                )}
                                {entry && <TimeEntryForm
                                    validationState={validationState}
                                    durValidationState={durationValidationState}
                                    minHeight={144}
                                    timeEntry={entry}
                                    onChange={changeEntry}
                                    actionCodesRequired={features.EpochConfigActionCodesRequired}
                                    minNarrativeLength={features.EpochConfigNarrativesMinimumChars}
                                    maxNarrativeLength={features.EpochConfigNarrativesMaximumChars}
                                    onSetFieldLoader={setFieldLoaderFn}
                                    tkOfficesToShow={tkOfficesToShow!}
                                />}
                            </DialogContent>
                            <DialogActions>
                                {features.EpochConfigReferenceRequired && entry && !entry.matterId && <div style={{paddingLeft: 12}}>
                                    <TextField
                                        label={t('field.reference')}
                                        error={validationState && validationState.isReferenceEmpty}
                                        helperText={validationState && validationState.isReferenceEmpty ? t('validation.reference.invalid') : ''}
                                        value={entry && entry.reference ? entry.reference : ''}
                                        onChange={this.changeReference}
                                    /></div>}
                            {entry && !editAllLock && <div style={{paddingLeft: 12}}>
                                    <span>{`${t('dialog.multi_entries.edit.one.status.label')} :`}</span>
                                    {entry.sapStatus === SapStatus.NEW ? <span> {t('dialog.multi_entries.edit.one.status.new')} </span> :
                                        entry.sapStatus === SapStatus.UNSUBMITTED ? <span> {t('dialog.multi_entries.edit.one.status.saved')} </span> :
                                            entry.sapStatus === SapStatus.POSTED || SapStatus.QUEUED && 
                                            <span> {t('dialog.multi_entries.edit.one.status.posted')} </span>}
                                </div>}
                                {entry && !entry.isPosted() && <div style={{ paddingLeft: 12 }}>
                                    {entry && entry.matterId && this.props.allowSaveAsTemplate && <TextField
                                        label={t('field.save_as_template')}
                                        inputProps={{ maxlength: 100 }}
                                        value={templateName}
                                        error={templateValidationState && !templateValidationState.valid}
                                        helperText={
                                            (templateValidationState && !templateValidationState.valid) ?
                                                t(`validation.template.${templateValidationState.duplicateName ? 'duplicate' : 'start_with'}`)
                                            : ''
                                        }
                                        onChange={this.setTemplateName}
                                    />}
                                </div>}

                                <FlexDiv flex={1}/>

                                {entry && !entry.isPosted() && <>
                                    {(editAllLock || this.state.controlKeyPressed) ? (
                                        <>
                                            <Button disabled={saving} onClick={wrappedSaveAll}>
                                                {t('dialog.multi_entries.edit.all.action.save.label')}
                                            </Button>
                                            <Button disabled={saving} onClick={wrappedPostAll}>
                                                {t('dialog.multi_entries.edit.all.action.post.label')}
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            {/* TODO: Better tooltip text */}
                                            <Tooltip title={t('dialog.multi_entries.edit.one.action.tooltip.save')}>
                                                <Button disabled={saving} onClick={wrappedSave}>
                                                    {t('save', { ns: 'common' })}
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title={t('dialog.multi_entries.edit.one.action.tooltip.post')}>
                                                <Button disabled={saving} onClick={wrappedPost}>
                                                    {t('action.post')}
                                                </Button>
                                            </Tooltip>
                                        </>
                                    )}
                                </>}
                                <Button onClick={this.cancel}>{t('cancel', { ns: 'common' })}</Button>
                            </DialogActions>
                        </Dialog>
                    </>
                }
            </FeaturesConsumer>
        )
    }

    private keydown = (e: KeyboardEvent) => {
        if (e.ctrlKey) {
            this.setState({ controlKeyPressed: true });
        }
    }

    private keyup = (e: KeyboardEvent) => {
        // `e.ctrlKey` for keyup events is false for some reason
        // that's why we skip the check

        if (e.keyCode === 17) {
            this.setState({ controlKeyPressed: false });
        }
    }
}

export default withTranslation(['timeentries', 'common'])(MultipleTimeEntriesDialog);