import { Client, Code, CodeType, Matter, MatterTypeText, ActionCode, CodeSetTemplate } from 'api/types/types';

export default class ImmutableBaseEntry {
    id?: number | null;
    timeKeeperId: number;
    matterId: number | null;
    phaseId?: number | null;
    taskCodeId?: number | null;
    actCodeId: number | null;
    ffTaskCodeId: number | null;
    ffActCodeId: number | null;
    narrative: string | null;
    createdOn: string | null;
    deleted: boolean | null;
    lastModified: string | null;
    actionCodeId: number | null;
    actionCode?: string | null;
    bannedWords?: string[] | null;
    matterNumber?: string | null;
    matterName?: string | null;
    matterDescription?: string | null;
    clientId?: number | null;
    clientNumber?: string | null;
    clientName?: string | null;
    matterTypeText?: MatterTypeText | null;
    billingLang?: string | null;
    billingLangText?: string | null;
    timeEntryUnit?: string | null;
    blockBillingWords?: string[] | null;
    entryType?: string | null;
    matterStatus?: string | null;
    matterStatusDesc?: string | null;
    matterStartDate?: string | null;
    matterEndDate?: string | null;
    phaseName?: string | null;
    phaseDesc?: string | null;
    taskCode?: string | null;
    taskCodeDesc?: string | null;
    actCode?: string | null;
    actCodeDesc?: string | null;
    ffTaskCode?: string | null;
    ffTaskCodeDesc?: string | null;
    ffActCode?: string | null;
    ffActCodeDesc?: string | null;
    actionResponse?: string | null;
    matterBillingLang?: string | null;
    workLocaleId?: number | null;
    guid?: string | null;
    dirty?: boolean;
    isPhaseCode: boolean;
    isActCode: boolean;
    isFfTaskCode: boolean;
    stopEntry?: boolean;
    billingPartnerName?: string | null;
    billingManagerName?: string | null;
    billingOffice?: string | null;

    selectedCodeSetTemplate?: CodeSetTemplate | null;

    static applyMatter<T extends ImmutableBaseEntry>(srcObj: T, matter?: Matter | null): T {
        srcObj.dirty = true;
        if (!matter) {
            srcObj.matterId = null;
            srcObj.matterDescription = null;
            srcObj.matterEndDate = null;
            srcObj.matterStartDate = null;
            srcObj.matterNumber = null;
            srcObj.matterName = null;
            srcObj.matterTypeText = null;
            srcObj.billingLang = null;
            srcObj.billingLangText = null;
            srcObj.matterBillingLang = null;
            srcObj.timeEntryUnit = null;
            srcObj.bannedWords = null;
            srcObj.blockBillingWords = null;
            srcObj.entryType = null;
            srcObj.matterStatus = null;
            srcObj.matterStatusDesc = null;
            srcObj.actionResponse = null;
            srcObj.billingManagerName = null;
            srcObj.billingPartnerName = null;
            srcObj.billingOffice = null;

            srcObj.isPhaseCode = false;
            srcObj.isActCode = false;
            srcObj.isFfTaskCode = false;
            srcObj.stopEntry = false;

        } else {
            srcObj.matterId = matter.id;
            srcObj.matterDescription = matter.description;
            srcObj.matterEndDate = matter.endDate;
            srcObj.matterStartDate = matter.startDate;
            srcObj.matterNumber = matter.number;
            srcObj.matterName = matter.name;
            srcObj.clientNumber = matter.clientNumber;
            srcObj.clientName = matter.clientName;
            srcObj.matterTypeText = matter.typeText;
            srcObj.billingLang = matter.language;
            srcObj.billingLangText = matter.languageText;
            srcObj.timeEntryUnit = matter.timeEntryUnit;
            srcObj.bannedWords = matter.bannedWords;
            srcObj.blockBillingWords = matter.blockBillingWords;
            srcObj.entryType = matter.entryType;
            srcObj.matterStatus = matter.status;
            srcObj.matterStatusDesc = matter.statusDescription;
            srcObj.clientId = matter.clientId;
            srcObj.matterBillingLang = matter.language;
            srcObj.billingManagerName = matter.billingManagerName;
            srcObj.billingPartnerName = matter.billingPartnerName;
            srcObj.billingOffice = matter.billingOffice;

            srcObj.isPhaseCode = matter.isPhaseCode;
            srcObj.isActCode = matter.isActCode;
            srcObj.isFfTaskCode = matter.isFfTaskCode;
        }
        return srcObj;
    }
    
    get client(): Client | null {
        if (!this.clientId) {
            return null;
        }
        return {
            id: this.clientId!,
            name: this.clientName!,
            number: this.clientNumber!
        };
    }
    
    get matter(): Matter | null {
        if (!this.matterId) {
            return null;
        }
        return {
            id: this.matterId!,
            description: this.matterDescription!,
            endDate: this.matterEndDate!,
            startDate: this.matterStartDate!,
            number: this.matterNumber!,
            name: this.matterName!,
            clientNumber: this.clientNumber!,
            clientName: this.clientName!,
            clientId: this.clientId!,
            typeText: this.matterTypeText!,
            language: this.billingLang!,
            languageText: this.billingLangText!,
            timeEntryUnit: this.timeEntryUnit!,
            bannedWords: this.bannedWords!,
            blockBillingWords: this.blockBillingWords!,
            entryType: this.entryType!,
            status: this.matterStatus!,
            statusDescription: this.matterStatusDesc!,
            type: '',
            lastModified: '',
            isPhaseCode: this.isPhaseCode,
            isActCode: this.isActCode,
            isFfTaskCode: this.isFfTaskCode,
            billingPartnerName: this.billingPartnerName!,
            billingManagerName: this.billingManagerName!,
            billingOffice: this.billingOffice!
        };
    }
    
    get phase(): Code | null {
        if (!this.phaseId) {
            return null;
        }
        return {
            description: this.phaseDesc!,
            id: this.phaseId!,
            name: this.phaseName!,
            type: CodeType.PHASE
        };
    }
    
    get task(): Code | null {
        if (!this.taskCodeId) {
            return null;
        }
        return {
            description: this.taskCodeDesc!,
            id: this.taskCodeId!,
            name: this.taskCode!,
            type: CodeType.TASK
        };
    }
    
    get activity(): Code | null {
        if (!this.actCodeId) {
            return null;
        }
        return {
            description: this.actCodeDesc!,
            id: this.actCodeId!,
            name: this.actCode!,
            type: CodeType.ACT
        };
    }
    
    get ffTask(): Code | null {
        if (!this.ffTaskCodeId) {
            return null;
        }
        return {
            id: this.ffTaskCodeId!,
            name: this.ffTaskCode!,
            description: this.ffTaskCodeDesc!,
            type: CodeType.FFTASK
        };
    }
    
    get ffActivity(): Code | null {
        if (!this.ffActCodeId) {
            return null;
        }
        return {
            id: this.ffActCodeId!,
            name: this.ffActCode!,
            description: this.ffActCodeDesc!,
            type: CodeType.FFACT
        };
    }

    get actionCodeObj(): ActionCode | null {
        if (!this.actionCodeId) {
            return null;
        }
        return {
            id: this.actionCodeId,
            actionCode: this.actionCode!,
            actionText: '',
            actionResponse: this.actionResponse,
            stopEntry: false
        };
    }

    static applyClient<T extends ImmutableBaseEntry>(srcObj: T, client?: Client | null): T {
        srcObj.dirty = true;
        if (!client) {
            srcObj.clientId = null;
            srcObj.clientName = null;
            srcObj.clientNumber = null;
        } else {
            srcObj.clientId = client.id;
            srcObj.clientName = client.name;
            srcObj.clientNumber = client.number;
        }
        return srcObj;
        
    }
    
    static applyPhase<T extends ImmutableBaseEntry>(srcObj: T, code?: Code | null): T {
        srcObj.dirty = true;
        if (!code) {
            srcObj.phaseDesc = null;
            srcObj.phaseName = null;
            srcObj.phaseId = null;
            return srcObj;
        }
        srcObj.phaseDesc = code.description;
        srcObj.phaseId = code.id;
        srcObj.phaseName = code.name;
        return srcObj;
    }
    static applyTask<T extends ImmutableBaseEntry>(srcObj: T, code?: Code | null ): T {
        srcObj.dirty = true;
        if (!code) {
            srcObj.taskCodeDesc = null;
            srcObj.taskCode = null;
            srcObj.taskCodeId = null;
            return srcObj;
        }
        srcObj.taskCodeDesc = code.description;
        srcObj.taskCodeId = code.id;
        srcObj.taskCode = code.name;
        return srcObj;
    }
    static applyActivity<T extends ImmutableBaseEntry>(srcObj: T, code?: Code | null): T {
        srcObj.dirty = true;
        if (!code) {
            srcObj.actCodeDesc = null;
            srcObj.actCode = null;
            srcObj.actCodeId = null;
            return srcObj;
        }
        srcObj.actCodeDesc = code.description;
        srcObj.actCodeId = code.id;
        srcObj.actCode = code.name;
        return srcObj;
    }
    static applyFFTask<T extends ImmutableBaseEntry>(srcObj: T, code?: Code | null ): T {
        srcObj.dirty = true;
        if (!code) {
            srcObj.ffTaskCodeDesc = null;
            srcObj.ffTaskCode = null;
            srcObj.ffTaskCodeId = null;
            return srcObj;
        }
        srcObj.ffTaskCodeDesc = code.description;
        srcObj.ffTaskCodeId = code.id;
        srcObj.ffTaskCode = code.name;
        return srcObj;
    }
    static applyFFActivity<T extends ImmutableBaseEntry>(srcObj: T, code?: Code | null): T {
        srcObj.dirty = true;
        if (!code) {
            srcObj.ffActCodeDesc = null;
            srcObj.ffActCode = null;
            srcObj.ffActCodeId = null;
            return srcObj;
        }
        srcObj.ffActCodeDesc = code.description;
        srcObj.ffActCodeId = code.id;
        srcObj.ffActCode = code.name;
        return srcObj;
    }
    static applyActionCode<T extends ImmutableBaseEntry>(srcObj: T, actionCode?: ActionCode | null): T {
        srcObj.dirty = true;
        if (!actionCode) {
            srcObj.actionCode = null;
            srcObj.actionCodeId = null;
            srcObj.actionResponse = null;
            srcObj.stopEntry = false;
            return srcObj;
        }
        srcObj.actionCodeId = actionCode.id;
        srcObj.actionCode = actionCode.actionCode;
        // codeSetNarrative takes HIGH preference
        if (!srcObj.selectedCodeSetTemplate) {
            srcObj.narrative = actionCode.actionText;
        }
        srcObj.actionResponse = actionCode.actionResponse;
        srcObj.stopEntry = actionCode.stopEntry;
        return srcObj;
    }
    static applyCodeSetTemplate<T extends ImmutableBaseEntry>(srcObj: T, codeSet?: CodeSetTemplate | null): T {
        srcObj.dirty = true;
        if (!codeSet) {
            srcObj.selectedCodeSetTemplate = null;
            return srcObj;
        }
        srcObj.selectedCodeSetTemplate = codeSet;
        return srcObj;
    }
    static applyWorkLocaleId<T extends ImmutableBaseEntry>(srcObj: T, workLocaleId?: number | null ): T {
        srcObj.dirty = true;
        if (!workLocaleId) {
            srcObj.workLocaleId = null;
        } else {
            srcObj.workLocaleId = workLocaleId;
        }
        return srcObj;
    }
}