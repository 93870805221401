import { Matter, Code, Client, ActionCode } from 'api/types/types';
import ImmutableBaseEntry from './ImmutableBaseEntry';

export default class ImmutableTemplate extends ImmutableBaseEntry {
    localUid?: string | null;
    name: string = '';

    clone = (): ImmutableTemplate => {
        return Object.assign(new ImmutableTemplate(),
            JSON.parse(JSON.stringify(this))
        );
    }
    setNarrative(narrative: string): ImmutableTemplate {
        let newObj = this.clone();
        let newEntry = newObj;
        newEntry.narrative = narrative;
        return newEntry;
    }
    setMatter = ( matter?: Matter | null): ImmutableTemplate => {
        let template = ImmutableBaseEntry.applyMatter<ImmutableTemplate>(this.clone(), matter);
        if (matter === null) {
            template = template
                .setPhase(null)
                .setAct(null)
                .setFFTask(null)
                .setFFAct(null);
        }
        return template;
    }
    setClient = (client?: Client | null): ImmutableTemplate => {
        return ImmutableBaseEntry.applyClient<ImmutableTemplate>(this.clone(), client)
            .setMatter(null);
    }
    setPhase = (code?: Code | null): ImmutableTemplate => {
        return ImmutableBaseEntry
            .applyPhase<ImmutableTemplate>(this.clone(), code)
            .setTask(null);
        // todo 
    }
    setTask = (code?: Code | null): ImmutableTemplate => {
        return ImmutableBaseEntry.applyTask<ImmutableTemplate>(this.clone(), code);
    }
    setAct = (code?: Code | null): ImmutableTemplate => {
        return ImmutableBaseEntry.applyActivity<ImmutableTemplate>(this.clone(), code);
    }
    setFFTask = (code?: Code | null): ImmutableTemplate => {
        return ImmutableBaseEntry
            .applyFFTask<ImmutableTemplate>(this.clone(), code)
            .setFFAct(null);
    }
    setFFAct = (code?: Code | null): ImmutableTemplate => {
        return ImmutableBaseEntry.applyFFActivity<ImmutableTemplate>(this.clone(), code);
    }
    setName = (name: string) => {
        this.name = name;
        return this.clone();
    }
    setActionCode = (actionCode?: ActionCode | null): ImmutableTemplate => {
        return ImmutableBaseEntry.applyActionCode<ImmutableTemplate>(this.clone(), actionCode);
    }
    setBillingLanguage = (key: string): ImmutableTemplate => {
        let entry = this.clone();
        entry.matterBillingLang = key;
        return entry;
    }
    toWriteable = () => {
        return {
            name: this.name,
            id: this.id,
            timeKeeperId: this.timeKeeperId,
            matterId: this.matterId,
            phaseId: this.phaseId,
            taskCodeId: this.taskCodeId,
            actCodeId: this.actCodeId,
            ffTaskCodeId: this.ffTaskCodeId,
            ffActCodeId: this.ffActCodeId,
            narrative: this.narrative,
            createdOn: this.createdOn,
            deleted: this.deleted,
            lastModified: this.lastModified,
            actionCodeId: this.actionCodeId,
            actionCode: this.actionCode,
            matterNumber: this.matterNumber,
            matterName: this.matterName,
            matterDescription: this.matterDescription,
            clientId: this.clientId,
            clientNumber: this.clientNumber,
            clientName: this.clientName,
            matterTypeText: this.matterTypeText,
            billingLang: this.billingLang,
            billingLangText: this.billingLangText,
            timeEntryUnit: this.timeEntryUnit,
            matterStatus: this.matterStatus,
            matterStatusDesc: this.matterStatusDesc,
            matterStartDate: this.matterStartDate,
            matterEndDate: this.matterEndDate,
            phaseName: this.phaseName,
            phaseDesc: this.phaseDesc,
            taskCode: this.taskCode,
            taskCodeDesc: this.taskCodeDesc,
            actCode: this.actCode,
            actCodeDesc: this.actCodeDesc,
            ffTaskCode: this.ffTaskCode,
            ffTaskCodeDesc: this.ffTaskCodeDesc,
            ffActCode: this.ffActCode,
            ffActCodeDesc: this.ffActCodeDesc,
            actionResponse: this.actionResponse
        }
    }
    get matterBillingLangDictionary(): string {
        return this.matterBillingLang ? this.matterBillingLang : this.billingLang ? this.billingLang : 'EN';
    }
}