import styled from 'common/styled-components';

export const StyledPicker = styled.div`
    margin: 20px 0 20px 0;
    height: auto;
`;

export const StyledContent = styled.div`
    // TODO overflow wrap time entries
`;

export const EntryItem = styled.div`
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px dotted ${props => props.theme.secondary.light};
`;

export const Entries = styled.div`
    height: fit-content;
    overflow-y: auto;
`;
