import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import NarrativeDialogStore from 'store/narrative.dialog.store';
import NarrativeField from 'components/NarrativeField';
import { Focusable } from '@fulcrumgt/react-fulcrum-editor/types/FulcrumEditor';
import { Features } from '../../api/types/types';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { withTranslation } from 'react-i18next';

interface Props {
    narrativeDialogStore?: NarrativeDialogStore;
    // tslint:disable-next-line:no-any
    t: any;
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        narrativeDialogStore: rootStore.narrativeDialogStore
    };
})
@observer
class NavTimerNarrativeDialog extends React.Component<Props> {
    render() {
        const { t, narrativeDialogStore } = this.props;
        let {
            isOpen,
            setJustOpened,
            justOpened,
            cancel,
            doSave,
            timerNote,
            setNote
        } = narrativeDialogStore!;
        return (
            <FeaturesConsumer>
                {(features: Features) =>
                    <>
            <Dialog
                open={isOpen}
                onClose={cancel}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle id="narrative-dialog-title">{t('active_chunk.dialog.title')}</DialogTitle>
                <DialogContent>
                    <NarrativeField
                        style={{minWidth: '312px', maxHeight: '160px', overflow: 'auto'}}
                        value={timerNote}
                        placeholder={t('active_chunk.dialog.field.narrative')}
                        onChange={setNote}
                        inputRef={(inputRef: Focusable) => {
                            if (justOpened) {
                                requestAnimationFrame(() => inputRef.focusAtEnd());
                                setJustOpened(false);
                            }
                        }}
                        onEnter={() => {
                            doSave();
                            return true;
                        }}
                        maxLength={features.EpochConfigNarrativesMaximumChars}
                    />
                </DialogContent>
                <DialogActions>
                    <Tooltip title={t('active_chunk.dialog.action.save.tooltip')}>
                        <Button onClick={doSave}>
                            {t('save', { ns: 'common' })}
                        </Button>
                    </Tooltip>
                    <Button onClick={cancel}>
                        {t('cancel', { ns: 'common' })}
                    </Button>
                </DialogActions>
            </Dialog>
                    </>
                }
            </FeaturesConsumer>
        );
    }
}

export default withTranslation(['timers', 'common'])(NavTimerNarrativeDialog);