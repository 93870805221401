import { Platform } from '../util/Platform';
const constants = require('constants.json');

const winston = require('winston');
require('winston-daily-rotate-file');

const logger = winston.createLogger({
    level: 'verbose',
    silent: true,
    exitOnError: false,
    format: winston.format.combine(
        // winston.format.colorize({
        //     all: true
        // }),
        winston.format.timestamp({
            format: 'YYYY-MM-DD HH:mm:ss,SSSZZ'
        }),
        // winston.format.errors({
        //     stack: true  
        // }),
        winston.format.metadata({ fillExcept: ['message', 'level', 'timestamp', 'label'] })
    )
});

if (Platform.isElectron()) {
    
    const { ipcRenderer } = require('electron');
    // tslint:disable-next-line:no-any
    ipcRenderer.on(constants.ipcEvents.SET_APPDATA_PATH, (evt: any, logObject: any) => {
        let silent = true;
        const { path, logKey } = logObject;
        const regLoggerKey = localStorage.getItem('enableLogging');
        // Registry key overrides user setting.
        if (logKey) {
            localStorage.setItem('enableLogging', JSON.stringify(logKey));
            silent = !logKey;
        } else {
            if (regLoggerKey) {
                silent = !JSON.parse(regLoggerKey);
            }
        }

        localStorage.setItem('appDataPath', path);
        
        if (path) {
            let transport = new (winston.transports.DailyRotateFile)({
                filename: `${path}/logs/epoch-%DATE%.log`,
                datePattern: 'YYYY-MM-DD-HH',
                zippedArchive: true,
                maxSize: '50m',
                maxFiles: '1d',
                format: winston.format.combine(winston.format.json())
            });

            logger.add(transport);
            logger.silent = silent;
        }
    });
    
}

// If we're in web, then log to the `console` with the format:
if (Platform.isWeb() || process.env.NODE_ENV !== 'production') {
    logger.add(new winston.transports.Console({
        format: winston.format.simple(),
    }));
}

export default logger;
