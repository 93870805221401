import React from 'react';
import { TimeCastSegmentGroup } from 'store/home.store';
import { Checkbox, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { TimeCastProgram, TimeKeeperAssignment } from '../../api/types/types';
import ImmutableTimeEntry from '../../api/immutables/ImmutableTimeEntry';
import { DesktopCaptureIcon } from 'components/TimeCastSegments/DesktopCaptureDescription';
import VirtualizedSegmentsList from 'components/CaptureSegmentsList/VirtualizedSegmentsList';
import { TKConsumer } from 'common/TKProvider';
import { useTranslation } from 'react-i18next';

const CustomExpansionPanel = styled(ExpansionPanel)`
  ${p => p.expanded ? `margin: 4px 0;` : ``}
`;
const CustomExpansionPanelSummary = styled(ExpansionPanelSummary)`
    padding: 0;
    margin: 0;
    
    & > div {
        margin: 0 !important;
        padding: 0;
    }
`;

interface Props {
    program?: TimeCastProgram;
    groups: TimeCastSegmentGroup[];
    toggleSelection: (group: TimeCastSegmentGroup) => void;
    isSelected: (group: TimeCastSegmentGroup) => boolean;
    handleDoubleClick: (group: TimeCastSegmentGroup) => void;
    expanded: boolean;
    toggleExpansion: () => void;
    selectedSegmentsToDrag: number;
    onDrop: (timeEntry: ImmutableTimeEntry) => Promise<void>;
    getProgramColor?: (programCode: string) => string;
    timeFrame?: string;
}

export const TimeCastGroup = (props: Props) => {
    const { t } = useTranslation(['home']);
    const disableSelection = props.groups.length === 0
        || props.groups.every(g => g.segments.every(s => !!s.associatedTimeEntry));
    const areAllSelected = props.groups.length > 0
        && props.groups.every(g => g.segments.every(s => !!s.associatedTimeEntry) || props.isSelected(g));

    const selectAll = () => {
        if (areAllSelected) {
            // deselect all
            props.groups.forEach(g => props.isSelected(g) && props.toggleSelection(g));
        } else {
            // select all
            props.groups.forEach(g => !props.isSelected(g) && props.toggleSelection(g));
        }
    };

    const appDuration = props.groups
        .map(s => DateTime.fromISO(s.endTime.toISO())
            .diff(DateTime.fromISO(s.startTime.toISO()), 'seconds')
            .get('seconds'))
        .reduce((previousValue: number, currentValue: number) => previousValue + currentValue, 0) / 3600;
    const {
        isSelected,
        toggleSelection,
        handleDoubleClick,
        onDrop,
        selectedSegmentsToDrag,
        groups,
        getProgramColor,
        timeFrame,
        program
    } = props;

    return (
        <CustomExpansionPanel expanded={props.expanded} onChange={props.toggleExpansion}>
            <CustomExpansionPanelSummary expandIcon={<ExpandMore />}>
                <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
                <Checkbox
                    onMouseDown={(e) => {e.stopPropagation() /* prevent click on expansion panel */}}
                    onClick={(e) => {e.stopPropagation() /* prevent click on expansion panel */}}
                    checked={areAllSelected}
                    onChange={selectAll}
                    disabled={disableSelection || !tk.writable}
                />}
                </TKConsumer>
                {timeFrame &&
                <Typography style={{alignSelf: 'center'}}>
                    {timeFrame} ({groups.length}) — {appDuration.toFixed(2)} {t('duration.hrs')}
                </Typography>}
                {program &&
                <Typography style={{alignSelf: 'center'}}>
                    <DesktopCaptureIcon color={program!.color} program={program!.code} />
                    {program!.programName} ({groups.length}) — {appDuration.toFixed(2)} {t('duration.hrs')}
                </Typography>
                }
            </CustomExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: groups.length * 50,
                        maxHeight: 300
                    }}
                >
                    {/*{props.groups.map(g => (*/}
                    {/*    <SegmentDragSource*/}
                    {/*        groupOrGap={g}*/}
                    {/*        toggleSelection={() => props.toggleSelection(g)}*/}
                    {/*        isSelected={props.isSelected(g)}*/}
                    {/*        handleDoubleClick={() => props.handleDoubleClick(g)}*/}
                    {/*        selectedSegmentsToDrag={props.selectedSegmentsToDrag}*/}
                    {/*        onDrop={props.onDrop}*/}
                    {/*    />*/}
                    {/*))}*/}
                    <VirtualizedSegmentsList
                        toggleSegmentGroupOrGapSelection={toggleSelection}
                        isSelected={isSelected}
                        selectSameWindows={handleDoubleClick}
                        updateTimeEntryFromDragSource={onDrop}
                        segments={groups}
                        selectedSegments={selectedSegmentsToDrag}
                        getProgramColor={getProgramColor}
                    />
                </div>
            </ExpansionPanelDetails>
        </CustomExpansionPanel>
    );
}