import DialogRootStore from 'store/dialog.root.store';
import { action, observable, makeObservable } from 'mobx';
import { RootStore } from 'store/root.store';

export default class NarrativeDialogStore extends DialogRootStore<void, string> {
    @observable timerNote: string = '';
    @observable justOpened: boolean = false;
    
    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    setJustOpened = (val: boolean) => {
        this.justOpened = val;
    }

    @action.bound setNote(timerNote: string) {
        this.timerNote = timerNote;
    }
    @action.bound 
    async onOpen() {
        this.timerNote = this.rootStore.timerStore.activeTimer.notes!;
        this.setJustOpened(true);
    }
    doSave = async () => {
        await this.rootStore.timerStore.saveTimerNote(this.timerNote);
        // @ts-ignore
        this.resolveAndClose(this.timerNote as object)
    }
}
