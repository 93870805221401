import styled from 'styled-components';

export const CheckboxContainer = styled.div`
    text-align: center;
    text-align: left;
    display: table-row;
`;

export const Paragraph = styled.p`
    font-size: 12px;
    line-height: 18px;
`;