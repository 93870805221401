import { observable, action, makeObservable } from 'mobx';
import { TimeKeeperAssignment } from 'api/types/types';
import DialogRootStore from 'store/dialog.root.store';
import { RootStore } from 'store/root.store';

export default class TimekeeperDialogStore extends DialogRootStore<TimeKeeperAssignment[], number> {
    @observable selectedTimekeeperId: number;
    @observable entries: TimeKeeperAssignment[] = [];
    @observable selectedEntry: number;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @action setTimeKeeper(id: number) {
        this.selectedTimekeeperId = id;
    }

    @action.bound 
    async onOpen(entries: TimeKeeperAssignment[]) {
        this.entries = entries;
    }

    selectEntry(timekeeperId: number) {
        this.selectedEntry = timekeeperId;
        this.resolveAndClose(this.selectedEntry);
    }
}