// tslint:disable:max-line-length
import React from 'react';
import { SentMailData } from '../../api/types/types';
import { Email } from '@material-ui/icons';

interface Props {
    data: SentMailData
}

export const SentMailDescription = (props: Props) => (
    <>
        <Email style={{fontSize: '13px'}}/>
        &nbsp;{props.data.subject}&nbsp;
        <span style={{fontSize: '10px'}}>
            {(props.data.toRecipients && props.data.toRecipients.length > 0) ? `(${props.data.toRecipients.join(', ')})` : ''}
        </span>
    </>
);