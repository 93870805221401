import styled from 'common/styled-components';

export const FormContainer = styled.div`
    overflow: hidden;
    width: 90%;
    padding: 10px;
`;
export const FormLabel = styled.span`
    float: left;
    width: 20%;
`;
export const FormValue = styled.span`
    padding-left: 15px;
    overflow: hidden;
`;