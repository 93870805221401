import XLSX from 'xlsx';
import ImmutableTimeEntry, { SapStatus } from '../api/immutables/ImmutableTimeEntry';
import { parseCode } from './utils';
import { Platform } from './Platform';
import { DateTime } from 'luxon';
import rootStore from 'store/root.store';
export const FileSaver = require('file-saver');

export class TimeEntriesExcelFormat {
    WorkDate: Date;
    Client: string;
    Matter: string;
    Office: string;
    Phase: string;
    Task: string;
    Activity: string;
    FFTask?: string;
    FFActivity?: string;
    ActionCode?: string;
    Hours: number;
    Narrative: string;
    Status: string;
    constructor(entry: ImmutableTimeEntry, ffEnabled: boolean, actionEnabled: boolean) {
        const timeZoneOffset = new Date().getTimezoneOffset(); // minutes
        const utcWorkDate = DateTime
            .fromISO(entry.workDateTime)
            .startOf('day').plus({minutes: timeZoneOffset}).toISO();
        
        this.WorkDate = new Date(utcWorkDate);
        
        this.Client = parseCode(entry.clientNumber, entry.clientName);
        this.Matter = parseCode(entry.matterNumber, entry.matterName);
        this.Office = parseCode(entry.office, entry.officeName) || '';
        this.Phase = parseCode(entry.phaseName, entry.phaseDesc);
        this.Task = parseCode(entry.taskCode, entry.taskCodeDesc);
        this.Activity = parseCode(entry.actCode, entry.actCodeDesc);
        
        if (ffEnabled) {
            this.FFTask = parseCode(entry.ffTaskCode, entry.ffTaskCodeDesc);
            this.FFActivity = parseCode(entry.ffActCode, entry.ffActCodeDesc);
        }
        if (actionEnabled) {
            this.ActionCode = entry.actionCode || '';
        }
        
        this.Hours = Number((entry.duration / 3600).toFixed(2));
        this.Narrative = entry.narrative || '';

        switch (entry.sapStatus) {
            case SapStatus.QUEUED:
                this.Status = 'Posted';
                break;
            case SapStatus.SUBMITTED:
                this.Status = 'Posted';
                break;
            case SapStatus.UNSUBMITTED:
                this.Status = 'Draft';
                break;
            default:
                this.Status = '';
        }
    }
}

export function exportToXLSX(data: TimeEntriesExcelFormat[], fileName: string) {
    /* Create Worksheet */
    let ws = XLSX.utils.json_to_sheet(data);
    
    /* Set Header for matter/job column from feature flag */
    const matterHeaderLabel = rootStore.appStore.features.EpochConfigMatterLabel;
    ws.C1 = { t: 's', v: matterHeaderLabel };

    /* Add to WorkBook */
    let wb = XLSX.utils.book_new();
    
    XLSX.utils.book_append_sheet(wb, ws, 'Time Entries');
    
    // /* write */
    let wBout = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'binary'
    });
    /* Generate download */
    function download(s: string) {
        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);

        for (let i = 0; i !== s.length; ++i) {
            // tslint:disable-next-line:no-bitwise
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }
    
    if (Platform.isElectron()) {
        const { dialog } = require('electron').remote;

        let customSaveAsDialog = dialog.showSaveDialog({
            title: 'Save file as',
            defaultPath: fileName,
            filters: [{
                name: 'xlsx',
                extensions: ['xlsx']
            }]
        });
        if (customSaveAsDialog) {
            XLSX.writeFile(wb, customSaveAsDialog);
        };
    } else {
        
        FileSaver.saveAs(new Blob(
            [download(wBout)],
            {
                type: 'application/octet-stream'
            }),
            `${fileName}.xlsx`
        );
    }
}

export function toExcelFormat(
    entries: ImmutableTimeEntry[], 
    ffEnabled: boolean, 
    actionEnabled: boolean): TimeEntriesExcelFormat[] {
    
    let excelEntries: TimeEntriesExcelFormat[] = [];
    entries.forEach((entry) => {
        const excelFormattedEntry = new TimeEntriesExcelFormat(entry, ffEnabled, actionEnabled);
        excelEntries.push(excelFormattedEntry);
    });
    return excelEntries;
}