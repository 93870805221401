import { LinearProgress } from '@material-ui/core';
import * as React from 'react';

interface Props {
    progressBar: number;
    color: 'primary' | 'secondary' | undefined;
}
export const LinearProgressBar = (props: Props) => {
    const { progressBar } = props;
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                
                return Math.min(oldProgress + progressBar, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);
    
    return(
        <LinearProgress variant="determinate" color={props.color} value={progress}/>
    );
}