import * as React from 'react';
import * as Styled from 'containers/Login/styled';
import { FlexDiv } from 'common/flex';
import { CircularProgress, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { RootStoreContext } from '../../App';
import { useTranslation } from 'react-i18next';

const svgLogo = require('images/logodark.svg');

function Spinner() {
    const { t } = useTranslation(['common']);
    const progress = useContext(RootStoreContext).appStore.initProgress;
    return (
        <Styled.LoginContainer>
            <FlexDiv flex={1}/>
            <Styled.LoginBox elevation={10} square={true}>
                <Styled.LogoContainer>
                    <img src={svgLogo} height="80px"/>
                </Styled.LogoContainer>
                <div style={{textAlign: 'center'}}>
                    <CircularProgress size={100}/>
                    {progress && (
                        <Styled.LoginProgressDiv>
                            <Typography>
                                {t(progress.message, progress.option)}
                            </Typography>
                        </Styled.LoginProgressDiv>
                    )}
                </div>
            </Styled.LoginBox>
            <div style={{height: '100px'}}/>
            <FlexDiv flex={1}/>
        </Styled.LoginContainer>
    );
}
export default observer(Spinner);