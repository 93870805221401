import DialogRootStore from 'store/dialog.root.store';
import { action, observable, makeObservable } from 'mobx';
import { RootStore } from 'store/root.store';

export default class HardLogoutStore extends DialogRootStore<string, void> {
    @observable message: string = '';

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @action.bound
    async onOpen(message: string) {
        this.message = message;
    }
}