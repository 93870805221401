import styled from 'common/styled-components';
import { FlexDiv } from 'common/flex';
import { IconButton } from '@material-ui/core';

export const TimeEntryItem = styled.div`
    /* padding-top: 8px; */
`;

export const TimeEntryListHeader = styled.div`
    display: flex;
    align-items: stretch;
    /* background: ${props => props.theme.secondary.main}; */
    /* color: ${props => props.theme.secondary.contrastText}; */
    width: 100%;
    /* height: 30px; */
    padding: 0 10   px;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 1.1125rem;
    font-weight: 300;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.16667em;
`;

export const ListDate = styled(FlexDiv)<{selected: boolean; writable: boolean; }>`
    flex: 3;
    align-self: center;
    justify-content: left;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 6px;
    cursor: pointer;
    ${props => props.selected && `font-weight: 400`}
    ${props => props.writable && `pointer-events: none`}
`;

export const StyledIconButton = styled(IconButton)`
    && {
        padding: 0;
    }
`;
export const TETotalDuration = styled<
    {justifyContent?: string}, 'div'>('div')`
    display: flex;
    flex: 1;
    align-self: center;
    font-size: 16px;
    ${props => props.justifyContent ? `justify-content: ${props.justifyContent};` : ''};
`;
export const TotalDuration = styled(TETotalDuration)`
    color: black;
`;
export const Posted = styled(TETotalDuration)`
    color: rgb(75, 179, 97);
`;
export const Draft = styled(TETotalDuration)`
    color: rgb(255, 152, 0);
`;