import { SegmentViewMode, TimeCastSegmentGap, TimeCastSegmentGroup } from 'store/home.store';
import * as React from 'react';
import { CaptureSegmentsListObservables, TimeCastProgram } from '../../api/types/types';
import ImmutableTimeEntry from '../../api/immutables/ImmutableTimeEntry';
import VirtualizedSegmentsList from 'components/CaptureSegmentsList/VirtualizedSegmentsList';
import { TimeCastGroup } from '../../containers/Home/TimeCastGroup';
import { useTranslation } from 'react-i18next';

interface Props {
    segmentViewMode: SegmentViewMode,
    filteredApplicationTypeSegments: Record<string, TimeCastSegmentGroup[]>,
    filteredChronologicalSegments: Array<TimeCastSegmentGroup | TimeCastSegmentGap>,
    filteredTimeTypeSegments: Record<string, TimeCastSegmentGroup[]>,
    getProgram: (programCode: string) => TimeCastProgram,
    toggleSegmentGroupOrGapSelection: (groupOrGap: TimeCastSegmentGroup | TimeCastSegmentGap) => void,
    isSelected: (groupOrGap: TimeCastSegmentGroup | TimeCastSegmentGap) => boolean,
    selectSameWindows: (groupOrGap: TimeCastSegmentGroup | TimeCastSegmentGap) => void,
    toggleApplicationExpansion: (programCode: string) => void,
    updateTimeEntryFromDragSource: (timeEntry: ImmutableTimeEntry) => Promise<void>,
    toggleTimeGroupExpansion: (groupHeader: string) => void,
    getProgramColor: (programCode: string) => string,
    LoadingDiv: () => void,
    observables: CaptureSegmentsListObservables
}
export const CaptureSegmentsList = (props: Props) => {
    const { t } = useTranslation(['home']);
    const { 
        segmentViewMode,
        filteredApplicationTypeSegments,
        filteredChronologicalSegments,
        filteredTimeTypeSegments,
        getProgram,
        toggleSegmentGroupOrGapSelection,
        isSelected,
        selectSameWindows,
        toggleApplicationExpansion,
        updateTimeEntryFromDragSource,
        toggleTimeGroupExpansion,
        getProgramColor,
        LoadingDiv
    } = props;
    const {
        expandedApplications,
        selectedSegments,
        expandedTimeGroups,
        isTCViewInProgress
    } = props.observables;
    const applicationSegments = segmentViewMode === SegmentViewMode.APPLICATION_TYPE ? 
        filteredApplicationTypeSegments : {};
    const chronologicalSegments = segmentViewMode === SegmentViewMode.CHRONOLOGICAL ? 
        filteredChronologicalSegments : [];
    const timeGroupSegments = segmentViewMode === SegmentViewMode.TIME_TYPE ? 
        filteredTimeTypeSegments : {};
    return (
        <div style={{height: 'inherit'}}>
            {segmentViewMode === SegmentViewMode.APPLICATION_TYPE && (
                <>
                    {Object.keys(applicationSegments).sort().map((programCode, index) =>
                        <TimeCastGroup
                            key={index}
                            program={getProgram(programCode)}
                            groups={applicationSegments[programCode]}
                            toggleSelection={
                                (group: TimeCastSegmentGroup) => toggleSegmentGroupOrGapSelection(group)
                            }
                            isSelected={(group: TimeCastSegmentGroup) => isSelected(group)}
                            handleDoubleClick={(group: TimeCastSegmentGroup) => selectSameWindows(group)}
                            expanded={expandedApplications.includes(programCode)}
                            toggleExpansion={() => toggleApplicationExpansion(programCode)}
                            selectedSegmentsToDrag={selectedSegments}
                            onDrop={updateTimeEntryFromDragSource}
                            getProgramColor={getProgramColor}
                        />
                    )}
                </>
            )}
            {segmentViewMode === SegmentViewMode.TIME_TYPE && (
                <>
                    {Object.keys(timeGroupSegments).map((timeStr, index) =>
                        <TimeCastGroup
                            key={index}
                            timeFrame={timeStr}
                            groups={timeGroupSegments[timeStr]}
                            toggleSelection={
                                (group: TimeCastSegmentGroup) => toggleSegmentGroupOrGapSelection(group)
                            }
                            isSelected={(group: TimeCastSegmentGroup) => isSelected(group)}
                            handleDoubleClick={(group: TimeCastSegmentGroup) => selectSameWindows(group)}
                            expanded={expandedTimeGroups.includes(timeStr)}
                            toggleExpansion={() => toggleTimeGroupExpansion(timeStr)}
                            selectedSegmentsToDrag={selectedSegments}
                            onDrop={updateTimeEntryFromDragSource}
                            getProgramColor={getProgramColor}
                        />
                    )}
                </>
            )}

            {segmentViewMode === SegmentViewMode.CHRONOLOGICAL && (
                <div style={{height: 'inherit'}}>
                    {/*{chronologicalSegments*/}
                    {/*    .map((groupOrGap, index) =>*/}
                    {/*        <SegmentDragSource*/}
                    {/*            key={index}*/}
                    {/*            groupOrGap={groupOrGap}*/}
                    {/*            toggleSelection={*/}
                    {/*                () => toggleSegmentGroupOrGapSelection(groupOrGap)*/}
                    {/*            }*/}
                    {/*            isSelected={isSelected(groupOrGap)}*/}
                    {/*            handleDoubleClick={() => selectSameWindows(groupOrGap)}*/}
                    {/*            selectedSegmentsToDrag={selectedSegments}*/}
                    {/*            onDrop={updateTimeEntryFromDragSource}*/}
                    {/*            getProgramColor={getProgramColor}*/}
                    {/*        />*/}
                    {/*    )}*/}
                        <VirtualizedSegmentsList 
                            toggleSegmentGroupOrGapSelection={toggleSegmentGroupOrGapSelection} 
                            isSelected={isSelected} 
                            selectSameWindows={selectSameWindows} 
                            updateTimeEntryFromDragSource={updateTimeEntryFromDragSource} 
                            getProgramColor={getProgramColor} 
                            segments={chronologicalSegments} 
                            selectedSegments={selectedSegments}
                        />
                </div>
            )}
            {isTCViewInProgress && LoadingDiv()}
        </div>
    );
}