import React from 'react';
import { inject } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import ConfirmCollaborateStore from 'store/confirmCollaborate.store';
import * as Styled from 'components/NavBar/styled.desktop';
import TimeKeepersList from 'components/TimeKeepersList/TimeKeepersList';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

interface Props {
    store?: ConfirmCollaborateStore;
    rootStore?: RootStore;
    // tslint:disable-next-line:no-any
    t: any;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        store: rootStore.confirmCollaborateDialogStore,
        rootStore: rootStore
    };
})
@observer
class ConfirmCollaborateDialog extends React.Component<Props> {
    render() {
        const { t, store, rootStore } = this.props;
        let {
            isOpen,
            cancel,
            resolveAndClose
        } = store!;
        const { workDateNonCollaborated } = rootStore!.timeEntryStore;
        const collaboratees = rootStore!.collaboratees.length!;
        const { getAllTimeKeepers, filteredAllTimekeepersList } = rootStore!.appStore;
        return (
            <Dialog
                open={isOpen}
                onClose={cancel}
                disableRestoreFocus={true}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle id="collaborate-dialog-title">
                    {t('dialog.collaborate_confirm.title')}
                </DialogTitle>
                <DialogContent>
                    {t('dialog.collaborate_confirm.message')}
                </DialogContent>
                <DialogActions>
                    <TimeKeepersList
                        render={(tkMenuAnchor, openTkList) =>
                            <Styled.CollaborateBtn innerRef={tkMenuAnchor}>
                                <Button
                                    onClick={evt =>
                                        getAllTimeKeepers(workDateNonCollaborated).then(() => openTkList(evt))
                                    }
                                    aria-label={t('dialog.collaborate_confirm.action.change')}
                                >
                                    {`${t('dialog.collaborate_confirm.action.change')} (${collaboratees})`}
                                </Button>
                            </Styled.CollaborateBtn>}
                        collaborate={true}
                        menuWidth={300}
                        timeKeepers={filteredAllTimekeepersList}
                        workDate={workDateNonCollaborated}
                    />
                    <Button onClick={cancel} color="primary">
                        {t('no', { ns: 'common' })}
                    </Button>
                    <Button onClick={() => resolveAndClose()} color="primary">
                        {t('yes', { ns: 'common' })}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation(['timeentries', 'common'])(ConfirmCollaborateDialog);