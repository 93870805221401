import RootAPI from 'api/interfaces/RootAPI';
import TimeEntryImpl from './TimeEntry.impl';
import TemplateImpl from './Template.impl';
import MatterImpl from './Matter.impl';
import CodeImpl from './Code.impl';
import SessionImpl from './Session.impl';
import { AxiosInstance } from 'axios';
import ClientImpl from './Client.impl';
import NarrativeImpl from './Narrative.impl';
import WebRootImpl from '../web/Root.impl';
import { EpochDatabase } from './Dexie';
import CodeTemplatesImpl from './CodeTemplates.impl';
import CustomDictionaryImpl from './CustomDictionary.impl';
import TimerImpl from './Timer.impl';
import TimecastImpl from './TimeCast.impl'
import SettingsImpl from './Settings.impl'
import SupersetSessionImpl from '../web/SupersetSession.impl';

interface Connection {
    isOnline(): boolean
}

export default class ElectronRootImpl implements RootAPI {
    webImpl: WebRootImpl;
    TimeEntry: TimeEntryImpl;
    Template: TemplateImpl;
    Matter: MatterImpl;
    Code: CodeImpl;
    Session: SessionImpl;
    Client: ClientImpl;
    Narrative: NarrativeImpl;
    db: EpochDatabase;
    CodeSet: CodeTemplatesImpl;
    Timer: TimerImpl;
    CustomDictionary: CustomDictionaryImpl;
    TimeCast: TimecastImpl;
    Settings: SettingsImpl;
    SupersetSession: SupersetSessionImpl;
    
    connection: Connection;
    
    constructor(connection: Connection) {
        this.webImpl = new WebRootImpl();
        this.TimeEntry = new TimeEntryImpl(this);
        this.Template = new TemplateImpl(this);
        this.Matter = new MatterImpl(this);
        this.Code = new CodeImpl(this);
        this.Session = new SessionImpl(this);
        this.Client = new ClientImpl(this);
        this.Narrative = new NarrativeImpl(this);
        this.db = new EpochDatabase();
        this.CodeSet = new CodeTemplatesImpl(this);
        this.CustomDictionary = new CustomDictionaryImpl(this);
        this.Timer = new TimerImpl(this);
        this.TimeCast = new TimecastImpl(this);
        this.Settings = new SettingsImpl(this);
        this.connection = connection;
    }
    
    init = async () => {
        // let loggedIn = await this.Session.initialize();
        return true;
    }
    
    deleteDatabase = () => {
        localStorage.removeItem('user');
        this.db.delete().then(() => this.db.open());
    }
}