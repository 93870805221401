import styled from 'common/styled-components';

export const OriginalEntry = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr .5fr;
    grid-gap: 10px;
    margin-bottom: 10px;
    div {
        color: ${props => props.theme.secondary.light};
        border: 1px dotted ${props => props.theme.secondary.main};
        padding: 7px;
        border-radius: 3px;
    }
`;

export const EntryInput = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr .5fr;
    grid-gap: 10px;
`;

export const DialogContent = styled.div`
    padding: 0 24px 24px;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const Entries = styled.div`
    overflow-y: auto;
    flex: 1;
`;