import styled from 'common/styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled(Paper)<{expanded: boolean; type: string}>`
    transition: .2s;
    padding-right: 5px;
    border-left: ${props => props.type === 'template' ? `5px solid #4393e6` : 
    props.type === 'timer' ? `5px solid #f44336` : ``};
    ${props => props.expanded ? `
    margin-top: 10px;
    margin-bottom: 10px;
    ` : ``}
`;

export const OfflineHeader = styled.div<{type: string; }>`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: ${props => props.type === 'template' ? 
    `.5fr 1fr 1fr 1.5fr .1fr auto auto;` : props.type === 'timer' ? 
        `1fr 1fr 1fr 1.5fr .1fr auto auto` : props.type === 'chunk' ? `1fr 1fr 2fr auto auto;` : ``}
    grid-column-gap: 3px;
    justify-content: space-between;
    align-items: stretch;
    padding: 6px 4px;
    cursor: pointer;
`;

export const TabContainer = styled.div`
    margin-top: 15px;
`;

export const TimerChunksContainer = styled(Paper)`
    border-left: 5px solid #9f9f9f;
    margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
`;

export const OfflineContainer = styled.div`
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
`;

export const OfflineList = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;