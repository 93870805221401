import { RootStore } from 'store/root.store';
import { action, makeObservable } from 'mobx';
import { AsyncErrorStore, error } from '@fulcrumgt/mobx-store-utils';

export default class BaseStore extends AsyncErrorStore {
    rootStore: RootStore;
    constructor(root: RootStore) {
        super();
        this.rootStore = root;
        makeObservable(this);
    }
    
    // tslint:disable-next-line:no-any
    @error() @action.bound handleError(err: any) {
        this.rootStore.onError(err.message);
    }

    @action.bound
    confirm(msg: string, options?: {}) {
        return this.rootStore.confirmStore.confirm(msg, options);
    }
}