import React, { Component } from 'react';
import { IconButton, MenuItem, TextField } from '@material-ui/core';
import { FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import * as Styled from '../Pagination/styled';
import { withTranslation } from 'react-i18next';

interface Props {
    currentPage: number;
    entriesPerPage: number;
    entriesLength: number;
    changeEntriesPerPage: (n: number) => void;
    onPageChange: (n: number) => void;
    // tslint:disable-next-line:no-any
    t: any;
}

export enum EntriesPerPage {
    RANGE_ONE = 25,
    RANGE_TWO = 50,
    RANGE_THREE = 75,
    RANGE_FOUR = 100
}

interface State {
    entriesPerPage: number;
    currentPage: number;
}

class Pagination extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            entriesPerPage: this.props.entriesPerPage,
            currentPage: this.props.currentPage
        };
    }

    componentWillReceiveProps(nextProps: Readonly<Props>) {
        if (nextProps.currentPage !== this.state.currentPage) {
            this.setState({currentPage: nextProps.currentPage});
        }
        if (nextProps.entriesPerPage !== this.state.entriesPerPage) {
            this.setState({entriesPerPage: nextProps.entriesPerPage});
        }
    }

    paginationRange = (): number[] => {
        let totalPagesArr = [];
        let visiblePages = 5;
        let noOfPages = this.getNoOfPages();
        let firstIndex;
        if (this.props.currentPage - visiblePages / 2 <= 0) {
            firstIndex = 1;
        } else if (this.props.currentPage + visiblePages / 2 > noOfPages) {
            firstIndex = (noOfPages - visiblePages + 1) >= 1 ? (noOfPages - visiblePages + 1) : 1;
        } else {
            firstIndex = Math.ceil(this.props.currentPage - visiblePages / 2);
        }
        for (let i = 0; i < visiblePages && i < noOfPages; i++) {
            totalPagesArr.push(firstIndex + i);
        }
        return totalPagesArr;
    }

    changeEntriesPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.changeEntriesPerPage!(Number(e.target.value));
    }

    changePage = (n: number) => {
        this.props.onPageChange(n);
    }

    getNoOfPages = (): number => {
        let calculatedPages = (this.props.entriesLength) / this.state.entriesPerPage;
        let totalPages = calculatedPages === 0 ? 1 : Math.ceil(calculatedPages);
        return totalPages;
    }
    render () {
        const { t } = this.props;
        let paginationRageArr = this.paginationRange();
        let totalPages = this.getNoOfPages();
        let { currentPage, entriesPerPage } = this.state;
        return (
            <Styled.PaginationContainer>
                <Styled.PageHeader>
                    <IconButton
                        disabled={currentPage === 1}
                        style={{fontSize: '1.25rem', width: '36px', height: '36px', paddingTop: '7px'}}
                        title={t('pagination.navigate.first.tooltip')}
                    >
                        <FirstPage onClick={() =>  this.changePage(1)}/>
                    </IconButton>
                    <IconButton
                        disabled={currentPage === 1}
                        style={{fontSize: '1.25rem', width: '36px', height: '36px', paddingTop: '7px'}}
                        title={t('pagination.navigate.previous.tooltip')}
                    >
                        <KeyboardArrowLeft onClick={() =>  this.changePage(currentPage - 1)}/>
                    </IconButton>
                    { paginationRageArr.map((pageNo) =>
                        <Styled.PageNumberIconBtn
                            key={pageNo}
                            isSelected={currentPage === pageNo}
                            onClick={() => this.changePage(pageNo)}
                            color="secondary"
                            style={{fontSize: '0.75rem', width: '36px', height: '36px'}}
                        >
                            {pageNo}
                        </Styled.PageNumberIconBtn>
                    )
                    }
                    <IconButton
                        disabled={currentPage === totalPages}
                        style={{fontSize: '1.25rem', width: '36px', height: '36px', paddingTop: '7px'}}
                        title={t('pagination.navigate.next.tooltip')}
                    >
                        <KeyboardArrowRight onClick={() =>  this.changePage(currentPage + 1)}/>
                    </IconButton>
                    <IconButton
                        disabled={currentPage === totalPages}
                        style={{fontSize: '1.25rem', width: '36px', height: '36px', paddingTop: '7px'}}
                        title={t('pagination.navigate.last.tooltip')}
                    >
                        <LastPage onClick={() =>  this.changePage(totalPages)}/>
                    </IconButton>
                    <TextField
                        fullWidth={true}
                        id="setEntries"
                        select={true}
                        value={entriesPerPage}
                        onChange={this.changeEntriesPerPage}
                        margin="normal"
                        style={{paddingLeft: '20px', marginTop: '10px'}}
                    >
                    {Object.keys(EntriesPerPage).map((key) => (
                        (typeof EntriesPerPage[key]) === 'number' && 
                            <MenuItem
                                key={EntriesPerPage[key]}
                                value={EntriesPerPage[key]}
                            >
                                {EntriesPerPage[key]}
                            </MenuItem>
                    ))}
                    </TextField>
                </Styled.PageHeader>
            </Styled.PaginationContainer>
        )
    }
};

export default withTranslation(['timeentries'])(Pagination);