import RootAPI from 'api/interfaces/RootAPI';
import TimeEntryImpl from './TimeEntry.impl';
import TemplateImpl from './Template.impl';
import MatterImpl from './Matter.impl';
import CodeImpl from './Code.impl';
import SessionImpl from './Session.impl';
import { AxiosInstance } from 'axios';
import ClientImpl from './Client.impl';
import NarrativeImpl from './Narrative.impl';
import CodeTemplatesImpl from './CodeTemplates.impl';
import SessionAPI from 'api/interfaces/SessionAPI';
import CustomDictionaryImpl from './CustomDictionary.impl';
import TimerImpl from './Timer.impl';
import TimeCastImpl from './TimeCast.impl'
import SettingsImpl from './Settings.impl'
import SupersetSessionImpl from './SupersetSession.impl';

export default class WebRootImpl implements RootAPI {
    TimeEntry = new TimeEntryImpl(this);
    Template = new TemplateImpl(this);
    Matter = new MatterImpl(this);
    Code = new CodeImpl(this);
    Client = new ClientImpl(this);
    Session: SessionImpl;
    Narrative = new NarrativeImpl(this);
    CodeSet = new CodeTemplatesImpl(this);
    Timer = new TimerImpl(this);
    CustomDictionary = new CustomDictionaryImpl(this);
    TimeCast = new TimeCastImpl(this);
    Settings = new SettingsImpl(this);
    SupersetSession = new SupersetSessionImpl(this);
    
    constructor(rootApi: string = './api') {
        this.Session = new SessionImpl(this, rootApi);
    }
    
    init = async () => {
        // let loggedIn = await this.Session.initialize();
        return true;
    }
    get axios(): AxiosInstance {
        return this.Session.axios;
    }
}