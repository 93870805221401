import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions, DialogContent,
    DialogTitle
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import ConfirmStore from 'store/confirm.store';
import { withTranslation } from 'react-i18next';

interface Props {
    message?: string;
    isOpen?: boolean;
    cancel?: () => void;
    verify?: () => void;
    ConfirmationDialogStore?: ConfirmStore;
    // tslint:disable-next-line:no-any
    t: any;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        ConfirmationDialogStore: rootStore.confirmStore
    };
})
@observer
class ConfirmationDialog extends React.Component<Props> {
    render() {
        const { t } = this.props;
        let {
            isOpen,
            message,
            cancel,
            verify
        } = this.props.ConfirmationDialogStore!;
        return (
            <Dialog
                open={isOpen}
                onClose={cancel}
                disableRestoreFocus={true}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle>{t('dialog.confirm.title')}</DialogTitle>
                <DialogContent id="unsaved-dialog-title">{t(message)}</DialogContent>
                <DialogActions>
                    <Button onClick={cancel} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={verify} color="primary">
                        {t('dialog.confirm.action.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation(['common'])(ConfirmationDialog);