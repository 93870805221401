import BaseWebImplementation from './Base.impl';
import CodeTemplateAPI from '../../interfaces/CodeTemplateAPI';
import { CodeSetTemplate } from '../../types/types';

export default class CodeTemplatesImpl extends BaseWebImplementation implements CodeTemplateAPI {
    getCodeSets = async (matterId?: number, search?: string, offset: number = 0, limit: number = 200, ) => {
        const { data } = await this.http.get(
            `/templates/codeSet?limit=${limit}&offset=${offset}` +
            `${search ? `&search=${encodeURIComponent(search)}` : ``}` +
            `${matterId ? `&matter=${matterId}` : ``}`);
        return data;
    }
}