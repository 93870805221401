import styled from 'styled-components';
import { Grid, ListItem, Typography } from '@material-ui/core';
import { AddToQueue } from '@material-ui/icons';

export const MainWrapper = styled(Grid)`
    height: 100%;
    background: #444;
`;
export const WrapperItem = styled(Grid)`
    padding-right: 1%;
`;
export const Container = styled.div`
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
`;
export const Header = styled(Grid)`
    height: 64px;
    background-color: ${props => props.theme.primary.main};
    align-items: center;
`;
export const HeaderChild = styled(Grid)<{flex: number}>`
    flex: ${props => props.flex};
    display: flex;
    align-items: flex-end;
    padding-left: 2%;
`;
export const MatterLabel = styled.p<{isHeader?: boolean}>`
    flex: 2;
    background-color: ${props => props.isHeader && '#868080'};
    color: ${props => props.isHeader && '#fff'};
    padding: 8px;
`;
export const MatterHeaderLabel = styled(Typography)`
    flex: 2;
`;
export const TrackBtn = styled(AddToQueue)`
    margin-right: 5%;
`;
export const Loading = styled.div`
    background-color: rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;
export const MatterListItem = styled(ListItem)`
    padding: 0 16px !important;
`;