import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import AppStore from 'store/app.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import HardLogoutStore from 'store/hardLogout.store';
import NavigationStore from 'store/navigation.store';
import { withTranslation } from 'react-i18next';

interface Props {
    appStore?: AppStore;
    hardLogoutStore?: HardLogoutStore;
    routerStore?: NavigationStore;
    // tslint:disable-next-line:no-any
    t: any;
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        hardLogoutStore: rootStore.hardLogoutStore,
        appStore: rootStore.appStore,
        routerStore: rootStore.routerStore
    };
})
@observer
class HardLogoutDialog extends React.Component<Props> {
    
    acceptHardLogout = () => {
        this.props.hardLogoutStore!.cancel();
        this.props.appStore!.doHardLogout();
    };

    stopHardLogout = () => {
        this.props.hardLogoutStore!.cancel();
        this.props.routerStore!.attemptPush(`/settings/offlineEntries`);
    };
    render() {
        const { t } = this.props;
        const { isOpen, message } = this.props.hardLogoutStore!;
        const dialog: string = `app.nav_bar.${message}.desktop.offline_entries.dialog`;
        return(
            <Dialog open={isOpen}>
                <DialogTitle>
                    <Warning/> {t(`${dialog}.title`)}
                </DialogTitle>
                <DialogContent>
                    {t(`${dialog}.message`)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.acceptHardLogout}>{t(`${dialog}.action.accept`)}</Button>
                    <Button onClick={this.stopHardLogout}>{t(`${dialog}.action.reject`)}</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withTranslation(['common'])(HardLogoutDialog);