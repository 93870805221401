import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

export const TemplateField = styled.div`
    min-width: 300px;
    width: 50%;
    padding-left: 5px;
    @media only screen and (min-width: 968px) {
        width: 33%;
    }
`;

export const NavAuditLogContainer = styled.div`
    float: right;
`;

export const AuditLabel = styled.span`
    color: ${props => props.theme.summary.blue};
    padding-right: 15px;
`;

export const StyledIconButton = styled(IconButton)<{disabled: boolean}>`
    color: ${props => props.theme.summary.blue} ${props => props.disabled ? '' : '!important'};
`;

export const CollaborateBtn = styled.div`

`;