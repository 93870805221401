import * as React from 'react';
import * as Styled from './styled.desktop';
import { Fab, IconButton, List, Popover, PropTypes, Tooltip } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import TimerStore from 'store/Timer/timer.store';
import ImmutableTimer from '../../api/immutables/ImmutableTimer';
import { InsertDriveFile, PlayArrow, Stop } from '@material-ui/icons';
import TimerLink from './TimerLink';
import { TimeKeeperAssignment } from '../../api/types/types';
import { TKConsumer } from 'common/TKProvider';
import { getTimerDurationTxt } from 'util/timer';
import { withTranslation } from 'react-i18next';
interface Props {
    timerStore?: TimerStore;
    // tslint:disable-next-line:no-any
    t: any;
};

interface State {
    MenuOpen: boolean;
};

interface NavHeaderTimerProps {
    timer: ImmutableTimer;
    onClick?: () => void;
    onStart: () => void;
    onStop: () => void;
    onAddNote?: () => void;
    includeSubmitted: boolean;
    loadingId?: number,
    actionColor?: PropTypes.Color;
    // tslint:disable-next-line:no-any
    t: any;
}

interface NavHeaderTimerState {
    durTxt: string;
}

class NavHeaderTimer extends React.Component<NavHeaderTimerProps, NavHeaderTimerState> {
    interval: NodeJS.Timeout;

    constructor(props: NavHeaderTimerProps) {
        super(props);
        this.state = { durTxt: '' };
        if (props.timer.active) {
            this.interval = setInterval(this.timerTick, 250);
        }
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    getSnapshotBeforeUpdate(prevProps: NavHeaderTimerProps) {
        if (prevProps.timer.active === this.props.timer.active) {
            return null;
        }
        clearInterval(this.interval);
        if (this.props.timer.active) {
            this.interval = setInterval(this.timerTick, 250);
        }
        return null;
    };
    timerTick = () => {
        const { timer, includeSubmitted } = this.props;
        this.setState({ durTxt: getTimerDurationTxt(timer, includeSubmitted) });
    };
    
    render() {
        const { 
            timer,
            onStart,
            onStop,
            loadingId,
            onAddNote,
            includeSubmitted,
            t
        } = this.props;
        
        const dtxt = timer.active ? this.state.durTxt : getTimerDurationTxt(timer, includeSubmitted);
        
        return (
            <Styled.CustomListItem
                button={true}
                onClick={this.props.onClick}
            >
                <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
                <Styled.ListItemContent>
                    {!timer.active ?
                        <Tooltip title={t('action.start')}>
                        <Fab
                            disabled={!!loadingId || !tk.writable}
                            onClick={e => {
                                e.stopPropagation();
                                onStart()
                            }}
                            size="medium"
                            style={{minWidth: '48px'}}
                            color={this.props.actionColor ? this.props.actionColor : 'primary'}
                            aria-label={t('action.start')}
                        >
                            <PlayArrow />
                            {(loadingId === timer.id) && <Styled.FabProgress size={55} />}
                        </Fab></Tooltip> :
                        <Tooltip title={t('action.pause')}>
                        <Fab
                            disabled={!!loadingId}
                            onClick={e => {
                                e.stopPropagation();
                                onStop()
                            }}
                            size="medium"
                            style={{backgroundColor: 'rgb(255, 87, 34)', color: 'white', minWidth: '48px'}}
                            aria-label={t('action.pause')}
                        >
                            <Stop />
                            {(loadingId === timer.id) && <Styled.FabProgress size={55} />}
                        </Fab></Tooltip>}
                    <Styled.ListText
                        primary={timer.name}
                        secondary={dtxt}
                        primaryTypographyProps={{
                            style: {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: 'calc(100% - 40px)'
                            }
                        }}
                    />
                    {!!timer.active &&
                    <Styled.CustomSecondaryAction>
                        <div title={t('active_chunk.icon.tooltip')}>
                            <IconButton
                                onClick={e => {
                                    e.stopPropagation();
                                    onAddNote!()
                                }}
                            >
                                <InsertDriveFile/>
                            </IconButton>
                        </div>
                    </Styled.CustomSecondaryAction>
                    }
                </Styled.ListItemContent>}
                </TKConsumer>
            </Styled.CustomListItem>
        );
    }
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        timerStore: rootStore.timerStore
    };
})

@observer
class NavTimer extends React.Component<Props, State> {
    navTimerRef: React.RefObject<HTMLDivElement> = React.createRef();
    constructor(props: Props) {
        super(props);
        this.state = {
            MenuOpen: false
        }
    }
    
    toggleMenu = () => {
        this.setState({ MenuOpen: !this.state.MenuOpen });
    };
    
    render() {
        const { t, timerStore } = this.props;
        const {
            loadingTimer,
            startTimer,
            stopTimer,
            timers,
            addTimerNote,
            isActiveToday,
            timerLoading,
            playAndStopLoading,
            activeTimerUpdateSteps,
            rootStore
        } = timerStore!;
        const includeSubmitted = rootStore.appStore.features.EpochConfigTimeSegmentsSubmittedVisible;
        const activeTimer = timers.filter(timer => !!timer.active)[0] ||
            timers.slice().sort((a: ImmutableTimer, b: ImmutableTimer) => 
                (a.lastModified < b.lastModified) ? 1 : -1)[0];
        const disableTimers = timerLoading || !!loadingTimer || !!playAndStopLoading || !!activeTimerUpdateSteps;

        return (
             <Styled.NavTimerContainer 
                 innerRef={this.navTimerRef}
                 disabled={disableTimers}
             >
                {!!activeTimer ? 
                    <NavHeaderTimer
                        timer={activeTimer}
                        onClick={() => this.toggleMenu()}
                        onStart={startTimer(activeTimer!)}
                        onStop={stopTimer(activeTimer ? activeTimer.id! : 0)}
                        onAddNote={addTimerNote(activeTimer!)}
                        loadingId={loadingTimer}
                        includeSubmitted={includeSubmitted}
                        actionColor="secondary"
                        t={t}
                    /> 
                    : <Styled.NavTimerContainer/>
                }
                <Popover
                    open={this.state.MenuOpen}
                    onClose={() => this.toggleMenu()}
                    anchorEl={this.navTimerRef.current}
                    PaperProps={{style: {
                        marginLeft: '16px',
                        borderTopLeftRadius: '0',
                        borderTopRightRadius: '0',
                        maxHeight: '228px',
                        }}}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                >
                    <List
                        style={{
                            width: 280,
                            paddingTop: '0',
                            maxWidth: 280,
                            minHeight: 48
                        }}
                    >
                        {timers
                            .filter(tt => tt.id !== activeTimer.id)
                            .sort((thisTimer, thatTimer) => (thisTimer.lastModified < thatTimer.lastModified) ? 1 : -1)
                            .map((timer: ImmutableTimer) => {
                            return (
                                <TimerLink
                                    key={timer.id!.toString()}
                                    timer={timer}
                                    isActiveToday={isActiveToday(timer)}
                                    onStart={startTimer(timer)}
                                    onStop={stopTimer(timer.id!)}
                                    loadingId={loadingTimer}
                                    includeSubmitted={includeSubmitted}
                                    disableDelete={true}
                                />
                            );
                        })}
                    </List>
                </Popover>  
             </Styled.NavTimerContainer>
        );
    }
}

export default withTranslation(['timers'])(NavTimer);