import {
    CalendarEventData, ConversationHistoryData,
    DeprecatedDesktopCaptureData,
    DesktopCaptureData,
    PhoneCallData,
    SentMailData,
    TimeCastSegment
} from '../api/types/types';

const constants = require('../constants.json');

export const PHONE_CALL_APPLICATION_NAME = 'Phone Call'
export const CALENDAR_EVENT_APPLICATION_NAME = 'Calendar Event'
export const SENT_MAIL_APPLICATION_NAME = 'Sent Mail'
export const CONVERSATION_HISTORY_APPLICATION_NAME = 'Conversation History'

export enum TimeCastConnectionStatus {
    DESKTOP,
    SERVER,
    NONE
}

export class TimeCast {
    /**
     * @return The id of the group that the segment belongs to.
     */
    public static getSegmentGroupId(segment: TimeCastSegment): number | string | null {
        if (segment.type === 'DESKTOP_CAPTURE') {
            return (segment.data as DeprecatedDesktopCaptureData).windowid
        } else if (segment.type === 'PHONE_CALL'
            || segment.type === 'VIRTUAL_PHONE_CALL'
            || segment.type === 'CALENDAR_EVENT'
            || segment.type === 'VIRTUAL_CALENDAR_EVENT'
            || segment.type === 'SENT_EMAIL'
            || segment.type === 'VIRTUAL_SENT_EMAIL'
            || segment.type === 'CONVERSATION_HISTORY') {
            return segment.foreignIdentifier;
        }

        return null;
    }

    /**
     * @return A title for the segment
     */
    public static getSegmentGroupTitle(segment: TimeCastSegment): string | null {
        if (segment.type === 'DESKTOP_CAPTURE') {
            return (segment.data as DesktopCaptureData).title
                || (segment.data as DeprecatedDesktopCaptureData).windowTitle
        } else if (segment.type === 'PHONE_CALL' || segment.type === 'VIRTUAL_PHONE_CALL') {
            const data = segment.data as PhoneCallData;
            const operative = data.initiator ? `to ${data.toNumber}` : `from ${data.fromNumber}`
            return `Call ${operative} ${data.otherParty ? `(${data.otherParty.fullName})` : ''}`
        } else if (segment.type === 'CALENDAR_EVENT' || segment.type === 'VIRTUAL_CALENDAR_EVENT') {
            const data = segment.data as CalendarEventData;

            return data.subject;
        } else if (segment.type === 'SENT_EMAIL' || segment.type === 'VIRTUAL_SENT_EMAIL') {
            const data = segment.data as SentMailData;
            
            return data.subject;
        } else if (segment.type === 'CONVERSATION_HISTORY') {
            const data = segment.data as ConversationHistoryData;
            
            return data.subject;
        }

        return null;
    }

    /**
     * @return A title for the segment
     */
    public static getSegmentGroupApplicationName(segment: TimeCastSegment): string {
        if (segment.type === 'DESKTOP_CAPTURE') {
            return (segment.data as DesktopCaptureData).app
                || (segment.data as DeprecatedDesktopCaptureData).applicationBestGuess
        } else if (segment.type === 'PHONE_CALL' || segment.type === 'VIRTUAL_PHONE_CALL') {
            return PHONE_CALL_APPLICATION_NAME;
        } else if (segment.type === 'CALENDAR_EVENT' || segment.type === 'VIRTUAL_CALENDAR_EVENT') {
            return CALENDAR_EVENT_APPLICATION_NAME;
        } else if (segment.type === 'SENT_EMAIL' || segment.type === 'VIRTUAL_SENT_EMAIL') {
            return SENT_MAIL_APPLICATION_NAME;
        } else if (segment.type === 'CONVERSATION_HISTORY') {
            return CONVERSATION_HISTORY_APPLICATION_NAME;
        }

        return 'Unknown';
    }

    public static async getConnectionStatus(): Promise<TimeCastConnectionStatus> {
        return new Promise<TimeCastConnectionStatus>((resolve) => {
            let resolved = false;
            
            try {
                const socket = new WebSocket(constants.timecast.windowsWebSocketUrl)
                socket.onerror = () => {
                    resolved = true;
                    resolve(TimeCastConnectionStatus.NONE);
                };
                socket.onopen = () => {
                    socket.send(JSON.stringify({
                        url: `${constants.timecast.PROTOCOL}:connection-status`
                    }));
                };
                socket.onmessage = (resp) => {
                    if (!resolved) {
                        resolved = true;
                        const response = resp.data
                        if (response === 'DESKTOP') {
                            resolve(TimeCastConnectionStatus.DESKTOP);
                        } else if (response === 'SERVER') {
                            resolve(TimeCastConnectionStatus.SERVER);
                        } else {
                            resolve(TimeCastConnectionStatus.NONE);
                        }
                    }
                    if (socket) {
                        socket.close();
                    }
                };

                // (timeout/fallback:) resolve automatically in 5 seconds
                setTimeout(() => {
                    if (!resolved) {
                        resolved = true;
                        resolve(TimeCastConnectionStatus.NONE);
                        if (socket && 
                            (socket.readyState !== WebSocket.CLOSED || socket.readyState !== WebSocket.CLOSING)) {
                            socket.close()
                        }
                    }
                }, 5000)
            } catch (e) {
                resolved = true;
                resolve(TimeCastConnectionStatus.NONE);
            }
        })
    }
    
    public static async getLocalOutlookSegments(
        year: number,
        month1to12: number,
        day: number): Promise<TimeCastSegment[]> {
        return new Promise<TimeCastSegment[]>((resolve) => {
            let resolved = false;
            
            try {
                const socket = new WebSocket(constants.timecast.windowsWebSocketUrl);
                socket.onerror = () => {
                    resolved = true;
                    resolve([]);
                };
                socket.onopen = () => {
                    socket.send(JSON.stringify({
                        url: `${constants.timecast.PROTOCOL}:ext-local-outlook?year=${year}&month=${month1to12}&day=${day}`
                    }));
                };
                socket.onmessage = (resp) => {
                    if (!resolved) {
                        resolved = true;
                        resolve(resp ? JSON.parse(resp.data || '') : []);
                    }
                    if (socket) {
                        socket.close();
                    }
                };

                // (timeout/fallback:) resolve automatically in 5 seconds
                setTimeout(() => {
                    if (!resolved) {
                        resolved = true;
                        resolve([]);
                        if (socket && 
                            (socket.readyState !== WebSocket.CLOSED || socket.readyState !== WebSocket.CLOSING)) {
                            socket.close()
                        }
                    }
                }, 5000)
            } catch (e) {
                resolved = true;
                resolve([]);
            }
        })
    }
}