enum Platforms {
    WEB = 'WEB',
    ELECTRON = 'ELECTRON',
    UNKNOWN = 'UNKNOWN'
}

export class Platform {
    public static get(): Platforms {
        // tslint:disable-next-line
        return (window as any).process ? Platforms.ELECTRON : Platforms.WEB
    }

    public static isElectron(): boolean {
        return this.get() === Platforms.ELECTRON
    }

    public static isWeb(): boolean {
        return this.get() === Platforms.WEB
    }
}