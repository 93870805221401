import styled from 'common/styled-components';

export const InnerInput = styled.div`
    display: flex !important;
    padding: 0 !important;
`;

export const SingleValue = styled.span`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(97% - 2px);
`;