import TimerAPI from 'api/interfaces/TimerAPI';
import ImmutableTimer from 'api/immutables/ImmutableTimer';
import { ApiResult } from 'api/util';
import BaseElectronImplementation from './Base.impl';
import { Client, Matter, TimerChunk } from '../../types/types';
import { Template, TimeEntry, Timer, TimerChunkI } from './Dexie';
import ImmutableTimeEntry, { SapStatus } from '../../immutables/ImmutableTimeEntry';
import { DateTime } from 'luxon';
import logger from '../../../logging/logging';

export interface RelationalTimer extends Timer {
    template: Template,
    matter: Matter,
    client: Client
} 
export interface RelationalTimerChunk extends TimerChunkI {
    timers: Timer[],
} 
export default class TimerImpl extends BaseElectronImplementation implements TimerAPI {
    handlers: (((entries: ImmutableTimer[]) => void) | null )[] = [];
    
    syncUpdateSelectedChunks = (updatedChunks: TimerChunk[]) => {
        // do nothing
    };
    
    syncUpdateHomeChunks = (updated: TimerChunk[]) => {
        // do nothing
    };
    
    calcTotals = async (timer: Timer | ImmutableTimer): Promise<ImmutableTimer> => {
        let base = Object.assign(new ImmutableTimer(), timer);
        let chunks = base.chunks || [];
        if (chunks.every(ch => !ch.timeEntry)) {
            chunks = await this.getChunks(timer.id || 0);
        }
        chunks = chunks.filter(c => !c.deleted && !c.submitted);
        base.totalDuration = 0;
        base.pendingDuration = 0;
        base.convertedDuration = 0;
        chunks.forEach((chunk) => {
            // replace fun to reset milliseconds of start and end time so sum of chunks durations equals timer totalDuration
            let chunkDur = Math.floor((
                (new Date(chunk.endTime.replace(/\.[0-9]{3}/, '.000'))).getTime() -
                (new Date(chunk.startTime.replace(/\.[0-9]{3}/, '.000'))).getTime()) / 1000
            );

            if ( chunk.timeEntryId) {
                const timeEntry = Object.assign(new ImmutableTimeEntry(), chunk.timeEntry);
                if (timeEntry.sapStatus) {
                    if (timeEntry.isDraft()) {
                        base.pendingDuration += chunkDur;
                    } else {
                        base.convertedDuration += chunkDur;
                        chunk.submitted = true;
                    }
                }
            }
            base.totalDuration += chunkDur; 
        });
        base.chunks = chunks;
        return base;
    }
    getChunks = async (timerId: number): Promise<TimerChunkI[]> => {
        try {
            let filteredChunks = (await this.root.db.timerChunks
                .where({timerId})
                .with({timeEntry: 'timeEntryId'}))
                .filter(tc => !tc.deleted);

            return filteredChunks;
        } catch (e) {
            logger.error('Timers, Get Chunks Failed.\n', e);
            return [];
        }
    }
    get = async (id: number) => {
        try {
            if (id < 0) {
                return await this.calcTotals((await this.root.db.timers.get({localId: id * -1}))!);
            }
            return await this.calcTotals((await this.root.db.timers.get({id: id}))!);
        } catch (e) {
            logger.error('Timers, Get Timer Failed.\n', e);
            return Promise.reject(e);
        }
    } 
    getAll = async () => {
        try {
            const tkid = this.root.Session.currentTimeKeeper;
            const prom = (await this.root.db.timers
                .where({timeKeeperId: tkid!})
                .toArray())
                .filter(a => !a.deleted)
                .map(t => Object.assign(new ImmutableTimer(), t));
            return prom;
        } catch (e) {
            logger.error('Timers, Get all Timers Failed.\n', e);
            return [];
        }
    }
    updateChunks = async (timerChunks: TimerChunk[], timer?: ImmutableTimer[], canWrite?: boolean): Promise<ApiResult<TimerChunk>[]> => {
        let writtenChunks = await Promise.all(timerChunks.map(this.trySaveOneChunk));
        
        // await this.associateDirtyTimerSegments();
        if (!canWrite) {
            this.root.Session.write();
        }
        return writtenChunks;
    }
    updateTimers = async (timers: ImmutableTimer[]) => {
        let writtenTimers = await Promise.all(timers.map(this.trySaveOne));
        this.root.Session.write();
        return writtenTimers;
    }
    updateTimerSync = async (timer: ImmutableTimer) => {
        try {
            const updated = await this.root.webImpl.Timer.updateTimers([timer]);
            timer = updated[0].object;
        } catch (e) {
            logger.error('Timers, Update Timer Sync Failed.\n', e);
            throw e;
        } finally {
            let insertKey = await this.getWriteableId(timer);
            let writeableEntry = {
                timeKeeperId: timer.timeKeeperId,
                templateId: timer.templateId,
                matterId: timer.matterId,
                active: timer.active,
                startedOn: timer.startedOn,
                startedTimezone: timer.startedTimezone,
                notes: timer.notes,
                name: timer.name,
                favorite: timer.favorite,
                deleted: timer.deleted,
                lastModified: DateTime.utc().toISO(),
                lastActive: timer.lastActive,
                pendingDuration: timer.pendingDuration,
                convertedDuration: timer.convertedDuration,
                totalDuration: timer.totalDuration
            } as Timer;
            if (insertKey) {
                writeableEntry.localId = insertKey;
                writeableEntry.id = timer.id;
            }
            await this.root.db.timers.put(writeableEntry);
            this.recieve([timer]);
        }
    }
    trySaveOneChunk = async (chunk: TimerChunk): Promise<ApiResult<TimerChunk>> => {
        try {
            let insertKey = await this.getWriteableChunkId(chunk);
            let writeableEntry = {
                id: chunk.id,
                startTime: chunk.startTime,
                endTime: chunk.endTime,
                description: chunk.description,
                timerId: chunk.timerId,
                timeEntryId: chunk.timeEntryId,
                deleted: chunk.deleted
            } as TimerChunkI;
            
            if (insertKey) {
                writeableEntry.localId = insertKey;
            }
            writeableEntry.serverDirty = true;
            let localId = await this.root.db.timerChunks.put(writeableEntry);
            // let safeEntry = (await this.root.db.timerChunks.get(localId))!;
            writeableEntry.localId = localId;
            return {
                status: {
                    failed: false,
                    message: 'Success'
                },
                object: Object.assign({}, writeableEntry)
            }

        } catch (e) {
            logger.error('Timers, Update Chunks Error.\n', e);
            return {
                status: {
                    failed: true,
                    message: 'Failed save'
                },
                object: chunk
            }
        }
    }
    
    trySaveOne = async (timer: ImmutableTimer): Promise<ApiResult<ImmutableTimer>> => {
        try {
            let insertKey = await this.getWriteableId(timer);
            let writeableEntry = {
                timeKeeperId: timer.timeKeeperId,
                templateId: timer.templateId,
                matterId: timer.matterId,
                active: timer.active,
                startedOn: timer.startedOn,
                startedTimezone: timer.startedTimezone,
                notes: timer.notes,
                name: timer.name,
                favorite: timer.favorite,
                deleted: timer.deleted,
                lastModified: DateTime.utc().toISO(),
                lastActive: timer.lastActive,
                pendingDuration: timer.pendingDuration,
                convertedDuration: timer.convertedDuration,
                totalDuration: timer.totalDuration
            } as Timer;
            if (insertKey) {
                writeableEntry.localId = insertKey;
                writeableEntry.id = timer.id;
            }
            writeableEntry.serverDirty = true;
            let localId = await this.root.db.timers.put(writeableEntry);
            if (localId) {
                writeableEntry.localId = localId;
            }
            // let safeEntry = (await this.root.db.timers.get(localId))!;
            return {
                status: {
                    failed: false,
                    message: 'Success'
                },
                object: Object.assign(new ImmutableTimer(), writeableEntry)
            }

        } catch (e) {
            logger.error('Timers, Update Timers Error.\n', e);
            return {
                status: {
                    failed: true,
                    message: 'Failed save'
                },
                object: timer
            }
        }
    }
    start = async (timer: ImmutableTimer, isPlaying: boolean) => {
        let toStart = timer.clone();
        let timerArray = [];
        let stoppedTimer = isPlaying ? await this.stop(false) : null;
        if (stoppedTimer) {
            timerArray.push(stoppedTimer);
        }
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        toStart.active = true;
        toStart.startedOn = (new Date()).toISOString();
        toStart.lastActive = DateTime.utc().toISO();
        toStart.startedTimezone = timeZone;
        toStart.notes = '';
       
        try {
            const savedApi = await this.trySaveOne(toStart);
            const savedTimer = savedApi.object;
            timerArray.push(savedTimer);
            let calculatedTimers = await Promise.all(timerArray.map(this.calcTotals));
            this.root.Session.write();

            return calculatedTimers;
        } catch (e) {
            logger.error('Timers, Start Timer Error.\n', e);
            return Promise.reject(e);
        }
    }
    getWriteableId = async (timer: Timer): Promise<number | undefined> => {
        try {
            if (!timer.id) {
                return undefined;
            }
            if (timer.id < 0) {
                return timer.id * -1;
            }
            return (await this.root.db.timers.get({id: timer.id}))!.localId;
        } catch (e) {
            logger.error('Timers, Get Writeable ID Failed.\n', e);
            return;
        }
    }
    getWriteableChunkId = async (chunk: TimerChunk): Promise<number | undefined> => {
        if (!chunk.id) {
            return undefined;
        }
        if (chunk.id < 0) {
            return chunk.id * -1;
        }
        try {
            const local = (await this.root.db.timerChunks.get({id: chunk.id}));
            if (local) {
                return local.localId;
            }
            return undefined;
        } catch (e) {
            logger.error('Timers, Get Writeable Chunk ID Failed.\n', e);
            return undefined;
        }
    }
    stop = async (emit = true) => {
        let curTk = this.root.Session.currentTimeKeeper!;
        try {
            let runningTimers = (
                await this.root.db.timers
                    .where({timeKeeperId: curTk})
                    .toArray())
                    .filter((tm) => tm.active);
            if (!runningTimers[0]) {
                return null;
            }
            let runningTimer = runningTimers[0];
            let chunksToCreateOnStop: TimerChunkI[] = [];

            // Timer StartedOn time string
            let timerStartTime = DateTime
                .fromISO(runningTimer.startedOn!)
                .setZone(runningTimer.startedTimezone!)
            // Timer stopped time string
            let currentStopTime = DateTime.local()
                .setZone(runningTimer.startedTimezone!)

            // Calculate the number of days in between start and stop time and push em to an array.
            const startedDateString = timerStartTime.toISODate();
            const stopDateString = currentStopTime.toISODate();
            
            let datesDiff = (DateTime.fromISO(stopDateString))
                .diff(DateTime.fromISO(startedDateString), ['days']).toObject().days!;
            if (datesDiff > 0) {
                let endTime: DateTime = timerStartTime.endOf('day');
                // Chunks in between start time and stop time
                const roundedNoOfDays = Math.floor(datesDiff);
                for ( let i = 1; i <= roundedNoOfDays; i++ ) {

                    let chunk = {
                        timerId: runningTimer.id!,
                        startTime: timerStartTime.toISO(),
                        endTime: endTime.toISO(),
                        description: runningTimer.notes!,
                        deleted: false,
                        used: false,
                        serverDirty: true,
                        submitted: false
                    }
                    chunksToCreateOnStop.push(chunk)
                    timerStartTime = (endTime.plus({ day: 1 })).startOf('day');
                    endTime = (endTime.plus( { day: 1 })).endOf('day');
                }
            }
            // Last Chunk
            let lastChunk = {
                timerId: runningTimer.id!,
                startTime: timerStartTime.toISO(),
                endTime: currentStopTime.toISO(),
                description: runningTimer.notes!,
                deleted: false,
                used: false,
                serverDirty: true,
                submitted: false
            }
            chunksToCreateOnStop.push(lastChunk);
            let insertKey = await this.getWriteableId(runningTimer);
            
            if (insertKey) {
                runningTimer.localId = insertKey;
            }
            chunksToCreateOnStop.forEach(async c => {
                const local: number = await this.root.db.timerChunks.put(c);
                c.localId = local;
                if (!c.id) {
                    c.id = local * -1;
                }
            });
            // await this.root.db.timerChunks.bulkPut(chunksToCreateOnStop);
           
            let stopped = await this.calcTotals(runningTimer);
            runningTimer = {
                ...runningTimer,
                active: false,
                startedOn: undefined,
                startedTimezone: '',
                notes: undefined,
                serverDirty: true,
                lastModified: DateTime.utc().toISO(),
                lastActive: DateTime.utc().toISO(),
                pendingDuration: stopped.pendingDuration,
                totalDuration: stopped.totalDuration,
                convertedDuration: stopped.convertedDuration
            }
            await this.root.db.timers.put(runningTimer);
            
            const stopdTimer = Object.assign(new ImmutableTimer(), runningTimer);
            if (emit) {
                try {
                    this.root.Session.write();
                } catch (e) {
                    return stopdTimer;
                }
            }
            if (!this.root.Session.online) {
                this.syncUpdateHomeChunks(chunksToCreateOnStop);
            }
            return stopdTimer;
        } catch (e) {
            logger.error('Timers, Stop Timer Error.\n', e);
            return null;
        }
    }
    registerReciever = (handler: (timers: ImmutableTimer[]) => void) => {
        this.handlers.push(handler);
        const theIndex = this.handlers.length - 1;
        return  () => {
            this.handlers[theIndex] = null;
        }
    }
    recieveChunks = async (timerChunks: TimerChunkI[]) => {
        if (timerChunks.length > 0) {
            let chunks = [];
            for (let i = 0; i < timerChunks.length; i++) {
                let chunk = timerChunks[i];
                if (chunk.id) {
                    let insertKey = await this.getWriteableChunkId(chunk);
                    if (insertKey) {
                        chunk.localId = insertKey;
                    }
                    chunks.push(chunk);
                }
            }
            try {
                await this.root.db.timerChunks.bulkPut(chunks);
            } catch (e) {
                logger.error('Timers, Recieve Chunks bulkPut Failed.\n', e);
            }
        }
    }
    
    recieve = async (timers: ImmutableTimer[]) => {
        if (timers.length > 0) {
            const promArray = timers.map(async (timer) => {
                let dexieTimer: Timer = timer as Timer;
                if (!dexieTimer.localId) { // if recieved timers on API call
                    const localTimer = await this.root.db.timers.get({id: timer.id!});
                    if (localTimer) {
                        dexieTimer.localId = localTimer.localId
                    }
                }
                if (!dexieTimer.id) {
                    dexieTimer.id = dexieTimer.localId! * -1;
                }
                return dexieTimer;
            });
            const dexieTimers = await Promise.all(promArray);
            const writableEntries: Timer[] = await dexieTimers.map((timer) => {
                let writeableEntry = {
                    timeKeeperId: timer.timeKeeperId,
                    templateId: timer.templateId,
                    matterId: timer.matterId,
                    active: timer.active,
                    startedOn: timer.startedOn,
                    startedTimezone: timer.startedTimezone,
                    notes: timer.notes,
                    name: timer.name,
                    favorite: timer.favorite,
                    deleted: timer.deleted,
                    lastModified: timer.lastModified,
                    lastActive: timer.lastActive,
                    pendingDuration: timer.pendingDuration,
                    convertedDuration: timer.convertedDuration,
                    totalDuration: timer.totalDuration
                } as Timer;

                if (timer.id) {
                    writeableEntry.id = timer.id;
                }
                if (timer.localId) {
                    writeableEntry.localId = timer.localId;
                    writeableEntry.serverDirty = timer.serverDirty;
                }
                return writeableEntry;
            });
            const allChunks: TimerChunkI[] = dexieTimers.reduce(
                (prev: TimerChunkI[], timer) => {
                    return prev.concat((timer as unknown as { chunks: TimerChunkI[] }).chunks);
                },
                [] as TimerChunkI[]
            ).filter((c) => c); // filter out undefined
            await this.recieveChunks(allChunks);
            let trs = await Promise.all(writableEntries.map(this.calcTotals));
            for (let i = 0; i < writableEntries.length; i++) {
                let entry = writableEntries[i];
                let calTimer: ImmutableTimer = await this.calcTotals(entry);

                writableEntries[i] = {
                    ...entry,
                    pendingDuration: calTimer.pendingDuration,
                    convertedDuration: calTimer.convertedDuration,
                    totalDuration: calTimer.totalDuration
                }
            }
            try {
                await this.root.db.timers.bulkPut(writableEntries);
            } catch (e) {
                logger.error('Timers, Receiver BulkPut Error.\n', e);
            }
            
            this.handlers.filter(h => h !== null).forEach(h => h!(trs))
        }
    }
    writeChunks = async () => {
        let serverDirtyChunks = await this.root.db.timerChunks.filter(c => c.serverDirty || false).toArray();
        let dirtyChunks = serverDirtyChunks.filter(
            chunk => {
                if (chunk.serverDirty) {
                    // exclude unwriteables
                    if ((chunk.timeEntryId || 0) < 0 || (chunk.timerId || 0) < 0) {
                        return false;
                    }
                    return true;
                }
                return false;
            }
        );
        
        if (dirtyChunks.length === 0) {
            this.handlers.filter(h => h !== null).forEach(h => h!([]));
            return;
        }
        const toWrite = dirtyChunks
            .map(tc => {
                    if ( tc.id! < 0) {
                        tc.id = undefined;
                    }
                    return tc;
                }
            )
        const distinctTimerIds = Array.from(new Set(toWrite.map(c => c.timerId)))
        // Improves performance by fetching all the timers in a single transaction.
        const timersToUpdate = await this.root.db.timers
            .where('id')
            .anyOf(distinctTimerIds)
            .toArray();
        let updatedTimers: ImmutableTimer[] = [];
        // Update durations on timer when segments are posted
        for (let i = 0; i < timersToUpdate.length; i++) {
            let calculated = await this.root.Timer.calcTotals(timersToUpdate[i]);
            timersToUpdate[i].totalDuration = calculated.totalDuration;
            timersToUpdate[i].pendingDuration = calculated.pendingDuration;
            timersToUpdate[i].convertedDuration = calculated.convertedDuration;
            updatedTimers.push(calculated);
        }

        const results = await this.root
            .webImpl
            .Timer
            .updateChunks(toWrite);
        // if api request is failed and response is not returned, render local segments
        if (results.length === 0) {
            this.syncUpdateSelectedChunks(serverDirtyChunks);
            this.syncUpdateHomeChunks(serverDirtyChunks);
        }
        // tslint:disable-next-line:no-any
        let proms: Promise<any>[] = [];
        let updated: TimerChunk[] = [];
        for (let i = 0; i < results.length; i++) {
            let curRes = results[i];
            let localEntry = dirtyChunks[i]!;

            if (curRes.status.failed) {
                // TODO:  failed write, do something
            } else {
                (curRes.object as TimerChunkI).localId = localEntry.localId;
                let timerChunkObj = curRes.object as TimerChunk;
                updated.push(timerChunkObj);
                proms.push(this.root.db.timerChunks.put(curRes.object ));
                if (timerChunkObj.id && timerChunkObj.id > 0) {
                    this.syncUpdateSelectedChunks(updated)
                    this.syncUpdateHomeChunks(updated);
                }
            }
        }
        proms.push(this.root.db.timers.bulkPut(timersToUpdate))
        await Promise.all(proms);
        this.handlers.filter(h => h !== null).forEach(h => h!(updatedTimers));
    }
    updatedChunksListener = (handler: (selectedChunks: TimerChunk[]) => void) => {
        this.syncUpdateSelectedChunks = handler;
    }
    updateHomeChunksListener = (handler: (chunks: TimerChunk[]) => void) => {
        this.syncUpdateHomeChunks = handler;
    }
    write = async () => {
        try {
            let dirtyTimers = await this.root.db.timers.filter(
                timer => {
                    if (timer.serverDirty) {
                        if ((timer.templateId || 0) < 0) {
                            return false;
                        }
                        return true;
                    }
                    return false;
                }
            ).toArray();
            if (dirtyTimers.length === 0) {
                return;
            }
            const emitEntries: ImmutableTimer[] = [];
            const sessionId = localStorage.getItem('sessionId');
            const toWrite = dirtyTimers
                .map(te => {
                        let newTimer = Object.assign(new ImmutableTimer(), te)
                        if ( newTimer.id! < 0) {
                            // localUid must be unique to troubleshoot duplicate timers
                            newTimer.localUid = `${newTimer.localId}_${sessionId}`
                            newTimer.id = undefined;
                        }
                        if (newTimer.templateId! < 0) {
                            newTimer.templateId = undefined;
                        }
                        return newTimer;
                    }
                )
            const results = await this.root
                .webImpl
                .Timer
                .updateTimers(
                    toWrite
                );
            // tslint:disable-next-line:no-any
            let proms: Promise<any>[] = [];
            for (let i = 0; i < results.length; i++) {
                let curRes = results[i];
                let localEntry = dirtyTimers[i]!;
                const localEntryId = localEntry.localId! * -1;

                if (curRes.status.failed) {
                    // TODO:  failed write, do something
                } else {
                    (curRes.object as Timer).localId = localEntry.localId;
                    proms.push(this.root.db.timers.put(curRes.object ));
                    proms.push(
                        this.root.db.timerChunks.where({timerId: localEntryId}).modify({timerId: curRes.object.id})
                    );
                    if (localEntry.id! < 0) {
                        localEntry.deleted = true;
                        // id less than 0, only local
                        emitEntries.push(Object.assign(new ImmutableTimer(), localEntry));
                    }
                    emitEntries.push(Object.assign(new ImmutableTimer(), curRes.object));
                }
            }
            await Promise.all(proms);
            if (emitEntries.length > 0) {
                const distinctTimerIds = Array.from(new Set(emitEntries.map(c => c.id!)));
                let emits = await Promise.all((await this.root.db.timers
                    .where('id')
                    .anyOf(distinctTimerIds)
                    .toArray())
                    .map(this.calcTotals));
                this.handlers.filter(h => h !== null).forEach(h => h!(emits))
            } else {
                this.handlers.filter(h => h !== null).forEach(h => h!([]));
            }
        } catch (e) {
            logger.error('Timers, Write Timers Error.\n', e);
            this.handlers.filter(h => h !== null).forEach(h => h!([]));
        }
    }

    getChunksByTimeEntryId = async (ids: number[]): Promise<TimerChunk[]> => {
        try {
            return await this.root.db.timerChunks
                .where('timeEntryId')
                .anyOf(ids)
                .toArray();
        } catch (e) {
            logger.error('Timers, Get Chunks by TimeEntry ID Failed.\n', e);
            return [];
        }
    }

    associateDirtyTimerSegments = async () => {
        try {
            const associatedDirtyChunks = await this.root.db.timerChunks
                .filter(p => p.timeEntryId! < 0)
                .toArray();
            // Associate new timeEntry ID on split, merge or transfer.
            associatedDirtyChunks.forEach(async ads => {
                let timeEntryLocalId = ads.timeEntryId! * -1;
                const toAssociate = await this.root.db.timeEntries.get(timeEntryLocalId);
                if (toAssociate) {
                    let insertKey = await this.getWriteableChunkId(ads);
                    let writeableEntry = {
                        id: ads.id,
                        startTime: ads.startTime,
                        endTime: ads.endTime,
                        description: ads.description,
                        timerId: ads.timerId,
                        timeEntryId: toAssociate.id,
                        deleted: ads.deleted,
                        serverDirty: true
                    } as TimerChunkI;

                    if (insertKey) {
                        writeableEntry.localId = insertKey;
                    }
                    if (toAssociate.sapStatus !== SapStatus.UNSUBMITTED) {
                        writeableEntry.submitted = true;
                    }
                    await this.root.db.timerChunks.put(
                        writeableEntry
                    );
                }
            });
        } catch (e) {
            logger.error('Timers, Associate Dirty Timer Segments Error.\n', e);
        }
    }
    /* This method should be used only when a TimeEntry is posted on already associated segments, 
        cannot be used on new associations. It causes asynchronous issues.
     */
    updateTimerDurationFromTimeEntry = async(ids: number[]) => {
        try {
            let associatedTimerChunks = await this.root.db.timerChunks
                    .where('timeEntryId')
                    .anyOf(ids)
                    .with({timers: 'timers'})
            if (associatedTimerChunks.length > 0) {
                this.updateChunks(associatedTimerChunks);
            }
        } catch (e) {
            logger.error('Timers, Update Timer Duration from Time Entry Failed.\n', e);
        }
    }
    // New filter methods for road map items
    filterTimersByClient = async (clientId: number): Promise<ImmutableTimer[]> => {
        try {
            let filtered: Timer[] = [];
            const timers = await this.root.db.timers
                .where({timeKeeperId: this.root.Session.currentTimeKeeper || 0})
                .with({template: 'templateId', matter: 'matterId', chunks: 'timerChunks'})
            if (timers) {
                filtered = timers.filter((timer: RelationalTimer) => {
                    if (timer.deleted) {
                        return false;
                    }
                    if (timer.matterId) {
                        return timer.matter.clientId === clientId;
                    } else if (timer.templateId) {
                        return timer.template.clientId === clientId;
                    }
                    return false;
                });
            }
            let filteredTimers = filtered.map((t) => Object.assign(new ImmutableTimer, t));
            // let filteredMappedTimers = await Promise.all(filteredTimers.map(this.calcTotals));

            return filteredTimers;
        } catch (e) {
            logger.error('Timers, Filter Timers by Clients Error.\n', e);
            return [];
        }
    }
    filterTimersByMatter = async (matterId: number): Promise<ImmutableTimer[]> => {
        try {
            let filtered: Timer[] = [];
            const timers = await this.root.db.timers
                .where({timeKeeperId: this.root.Session.currentTimeKeeper || 0})
                .with({template: 'templateId', matter: 'matterId', chunks: 'timerChunks'});
            if (timers) {
                filtered = timers.filter((timer: RelationalTimer) => {
                    if (timer.deleted) {
                        return false;
                    }
                    const mId = timer.matterId;
                    if (mId) {
                        return mId === matterId;
                    } else if (timer.templateId) {
                        return timer.template.matterId === matterId;
                    }
                    return false;
                });
            }
            let filteredTimers = filtered.map((t) => Object.assign(new ImmutableTimer, t));
            // let filteredMappedTimers = await Promise.all(filteredTimers.map(this.calcTotals));

            return filteredTimers;
        } catch (e) {
            logger.error('Timers, Filter Timers by Matter Error.\n', e);
            return [];
        }
    }
    getDistinctMattersFromTimers = async (search: string, clientId?: number): Promise<Matter[]> => {
        try {
            let matters: Matter[] = [];
            const timers = await this.root.db.timers
                .where({ timeKeeperId: this.root.Session.currentTimeKeeper || 0 })
                .with({ template: 'templateId' })
            const matterIds: number[] = [];
            if (timers) {
                timers.map(t => {
                    if (!t.deleted) {
                        const tr = t as RelationalTimer;
                        if (tr.templateId && tr.template.matterId) {
                            matterIds.push(tr.template.matterId)
                        }
                        if (tr.matterId) {
                            matterIds.push(tr.matterId)
                        }
                    }
                });
                matters = await this.root.db.matters
                    .where('id')
                    .anyOf(matterIds)
                    .toArray()
            }
            if (clientId) {
                matters = matters.filter((mat) => mat.clientId === clientId);
            }
            if (search) {
                return matters.filter((m) => {
                    let catText = `${m!.name}${m!.number}${m!.clientName}${m!.clientNumber}`.toUpperCase();
                    if (!catText.includes((search || '').trim().toUpperCase())) {
                        return false;
                    }
                    return true;
                });
            }
            matters = matters.sort((a, b) => {
                return parseInt(a.number, 10) - parseInt(b.number, 10);
            });
            return matters;
        } catch (e) {
            logger.error('Timers, Get Distinct Matters from Timers Error.\n', e);
            return [];
        }
    }
    getDistinctClientsFromTimers = async (search: string): Promise<Client[]> => {
        try {
            let clients: Client[] = [];
            const timers = await this.root.db.timers
                .where({timeKeeperId: this.root.Session.currentTimeKeeper || 0})
                .with({matter: 'matterId', template: 'templateId'});
            if (timers) {
                for ( let idx = 0; idx < timers.length; idx++ ) {
                    const t = timers[idx] as RelationalTimer;
                    // if Matter is selected in the Timer and t.matter is not an Array
                    // Dexie-relationship returns an empty array if it can't find the object
                    if ( t.deleted ) {
                        continue
                    }
                    if (t.matterId && t.matter.id) {
                        const matClientId = t.matter.clientId;
                        if (!clients.find(c => c.id === matClientId)) {
                            const clientFromMatter = await this.root.Client.get(matClientId);
                            if (clientFromMatter) {
                                clients.push(clientFromMatter)
                            }
                        }
                    }
                    // if Template is selected in the Timer
                    if (t.templateId && t.template.id) {
                        const tempClientId = t.template.clientId;
                        if (!clients.find(c => c.id === tempClientId)) {
                            if (tempClientId) {
                                const templateClient = await this.root.Client.get(tempClientId);
                                if (templateClient) {
                                    clients.push(templateClient);
                                }
                            }
                        }
                    }
                };
            }
            if (search) {
                return clients.filter((c) => {
                    let catText = `${c.number} - ${c.name}`.toUpperCase();
                    if (!catText.includes((search || '').trim().toUpperCase())) {
                        return false;
                    }
                    return true;
                });
            }
            clients = clients.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            return clients;
        } catch (e) {
            logger.error('Timers, Get Distinct Clients from Timers Error.\n', e);
            return [];
        }
    }
    getTimersForDay = async(date: DateTime) => {
        try {
            const timers = await this.root.db.timers
                .where({timeKeeperId: this.root.Session.currentTimeKeeper || 0})
                .with({chunks: 'timerChunks'});
            let filteredTimers: Timer[] = [];
            let mappedTimers = await Promise.all(timers.map(this.calcTotals));
            mappedTimers.forEach((timer) => {
                let chunks = timer.chunks;
                // Filter chunks for the date provided
                chunks = chunks.filter((chunk) => {
                    const d = new Date(chunk.startTime);
                    const startTime = DateTime.local(
                        d.getFullYear(),
                        d.getMonth() + 1,
                        d.getDate(),
                        d.getHours(),
                        d.getMinutes(),
                        d.getSeconds(),
                        d.getMilliseconds()
                    );
                    return (startTime >= date.startOf('day') && startTime < date.endOf('day') && !chunk.deleted);
                });
                timer = { ...timer,
                    chunks: chunks
                };
                if (chunks.length > 0 && !timer.deleted) {
                    filteredTimers.push(timer);
                }
            });
            let filteredMappedTimers = filteredTimers.map(t => Object.assign(new ImmutableTimer(), t));
            return filteredMappedTimers;
        } catch (e) {
            logger.error('Timers, Get Timers for Day Failed.\n', e);
            return [];
        }
    }
    // tslint:disable-next-line:no-any
    updateChunksOnTimeEntryUpdate = async (te: TimeEntry) => {
        if (te.id && (te.sapStatus === SapStatus.POSTED || te.sapStatus === SapStatus.QUEUED)) {
            const chunks = await this.root.db.timerChunks
                .where({timeEntryId: te.id})
                .with({timers: 'timers'});
            if (chunks.length > 0) {
                let timers = chunks.map((chnk: RelationalTimerChunk) => chnk.timers[0]);
                let calculated = await Promise.all(timers.map(timer => this.calcTotals(timer)));
                let writeable = [];
                for (let i = 0; i < calculated.length; i++) {
                    const cal = calculated[i];
                    const insertKey = await this.getWriteableId(cal);
                    let writeableEntry = {
                        timeKeeperId: cal.timeKeeperId,
                        templateId: cal.templateId,
                        matterId: cal.matterId,
                        active: cal.active,
                        startedOn: cal.startedOn,
                        startedTimezone: cal.startedTimezone,
                        notes: cal.notes,
                        name: cal.name,
                        favorite: cal.favorite,
                        deleted: cal.deleted,
                        lastModified: cal.lastModified,
                        lastActive: cal.lastActive,
                        pendingDuration: cal.pendingDuration,
                        convertedDuration: cal.convertedDuration,
                        totalDuration: cal.totalDuration
                    } as Timer;
                    if (insertKey) {
                        writeable.push({
                            ...writeableEntry,
                            localId: insertKey,
                            id: cal.id,
                        })
                    }
                }
                
                this.root.db.timers.bulkPut(writeable);
            }
        }
    }

    getTimersFromIds = async (ids: number[]) => {
        // TODO: Implement whenever needed for Desktop.
        return [];
    }
}
