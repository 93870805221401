import { Button } from '@material-ui/core';
import { CallMerge, Check, Delete, Save, Send, SwapHoriz, Undo } from '@material-ui/icons';
import * as Styled from '../../containers/Home/styled.desktop';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    dirty: boolean;
    saveDirtyEntries: () => void;
    clearSelectedEntries: () => void;
    postSelectedEntries: () => void;
    moveSelectedEntries: () => void;
    mergeEntries: () => void;
    transferEntries: () => void;
    deleteSelectedEntries: () => void;
    selectedEntryIds: number[];
    disableSavePost: boolean;
}

export const FabContainerView = (props: Props) => {
    const { t } = useTranslation(['timeentries', 'common']);
    const {
        dirty,
        saveDirtyEntries,
        clearSelectedEntries,
        postSelectedEntries,
        moveSelectedEntries,
        mergeEntries,
        transferEntries,
        deleteSelectedEntries,
        selectedEntryIds,
        disableSavePost
    } = props;

    return (
        <Styled.FabContainer onScreen={selectedEntryIds.length > 0 || dirty}>
            {dirty &&
            <Button
                onClick={saveDirtyEntries}
                variant="extendedFab"
                aria-label={t('save', { ns: 'common' })}
                disabled={disableSavePost}
            >
                <Save/>
                {t('fab_container.action.save')}
            </Button>}
            {(selectedEntryIds.length > 0) && <>
                <Button
                    onClick={clearSelectedEntries}
                    variant="extendedFab"
                    color="secondary"
                    aria-label={t('clear', { ns: 'common' })}
                >
                    <Undo/>
                    {t('clear', { ns: 'common' })} ({selectedEntryIds.length})
                </Button>
                <Button
                    onClick={postSelectedEntries}
                    variant="extendedFab"
                    aria-label={t('action.post')}
                    disabled={disableSavePost}
                >
                    <Check/>
                    {t('action.post')} ({selectedEntryIds.length})
                </Button>
                <Button
                    onClick={moveSelectedEntries}
                    variant="extendedFab"
                    aria-label={t('fab_container.action.move')}
                >
                    <Send/>
                    {t('fab_container.action.move')} ({selectedEntryIds.length})
                </Button>
                {selectedEntryIds.length > 1 &&
                <Button
                    onClick={mergeEntries}
                    variant="extendedFab"
                    aria-label={t('action.merge')}
                >
                    <CallMerge/>
                    {t('action.merge')} ({selectedEntryIds.length})
                </Button>
                }
                {selectedEntryIds.length > 1 &&
                <Button
                    onClick={transferEntries}
                    variant="extendedFab"
                    aria-label={t('action.transfer')}
                >
                    <SwapHoriz/>
                    {t('action.transfer')} ({selectedEntryIds.length})
                </Button>
                }
                <Button
                    onClick={deleteSelectedEntries}
                    variant="extendedFab"
                    aria-label={t('delete', { ns: 'common' })}
                >
                    <Delete/>
                    {t('delete', { ns: 'common' })} ({selectedEntryIds.length})
                </Button>
            </>}
        </Styled.FabContainer>
    )
}