import DialogRootStore from 'store/dialog.root.store';
import { action, observable, makeObservable } from 'mobx';
import { PopoutErrorMessage } from 'components/TimerPopOut/TimerPopoutErrorDialog';
import { RootStore } from 'store/root.store';

export default class TimerPopoutErrorMessageDialogStore extends DialogRootStore<PopoutErrorMessage[], void> {
    @observable errorMessages: PopoutErrorMessage[] = [];
    
    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @action.bound
    async onOpen(messages: PopoutErrorMessage[]) {
        this.errorMessages = messages;
    }
}