import { action, observable } from 'mobx';
import BaseStore from './base.store';

export default class ReportsStore extends BaseStore {
    session = this.rootStore.api.SupersetSession;
    @observable currentDashboard: string = '';

    dashboardsByCategory: {
        [key: string]: Array<{ id: string; name: string; description?: string }>;
    } = {
        'Time Entry Metrics': [
            {
                id: 'ffe4444b-f06f-446d-adc5-73c0a0a93f22',
                name: 'Time Entries',
            },
            {
                id: '55baf490-5d0d-4160-8de8-0d47c7c8bb7d',
                name: 'Time Lag',
            },
            {
                id: '0618a1b8-6682-4f32-a434-43135d2f2058',
                name: 'Billable vs Non-Billable',
            },
            {
                id: '260e8f96-5c94-4202-9190-ee57d5e4aea7',
                name: 'Practice Group'
            }
        ]
    };

    @action.bound
    setDashboard(id: string) {
        this.currentDashboard = id;
    }

    async initializeSession() {
        await this.session.initialize();
    }

    @action.bound
    async fetchGuestToken() {
        let response = await this.session.axios.post('/security/guest_token/', {
            resources: [
                {
                    id: this.currentDashboard,
                    type: 'dashboard'
                }
            ],
            user: {
                first_name: 'API DEMO',
                last_name: 'USER',
                username: 'api_user'
            },
            rls: []
        });

        return response.data.token;
    }
}