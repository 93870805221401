import styled from 'common/styled-components';
import { Paper, Typography } from '@material-ui/core';

const durationGridWidth = '378px';
const durationGridWidthSmallScr = '287px';

// *************** common components ***************
export const CommonGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr ${durationGridWidth} 54px;
    grid-template-rows: auto;
    @media only screen and (max-width: 900px) {
        grid-template-columns: 1fr ${durationGridWidthSmallScr} 54px;
    }
`;

export const Day = styled.div`
    padding: 1px 6px;
    text-align: 'center';
    -moz-text-align-last: center;
    text-align-last: center;
    @media only screen and (max-width: 900px) {
        padding: 1px 1px;
    }
`;

// *************** Grid View ***************
export const GridViewContainer = styled.div` 
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding: 5px;
`;

export const GridHeaderContainer = styled(CommonGrid)`
    grid-template-areas: "header-icon header-dates .";
`;

export const HeaderIcon = styled.div`
    grid-area: header-icon;
    display: flex;
    justify-content: flex-end;
`;

export const HeaderDate = styled.div`
    grid-area: header-dates;
    display: flex;
    flex-direction: row;
    font-size: 14px;
`;

export const Date = styled.div`
    flex: 1;
    text-align: 'center';
    -moz-text-align-last: center;
    text-align-last: center;
`;

export const GridFooterContainer = styled(CommonGrid)`
    grid-template-areas: "footer-label footer-duration footer-total-duration";
    font-weight: bold;
`;

export const FooterLabel = styled.div`
    grid-area: footer-label;
    text-align: end;
    padding: 14px 10px;
`;

export const FooterDuration = styled.div`
    grid-area: footer-duration;
    display: flex;
    flex-direction: column;
`;

export const FooterTotalDuration = styled.div`
    grid-area: footer-total-duration;
`;

export const Actions = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
// these two lines only to allow matter list visible if at bottom of screen
    height: 250px;
    align-content: flex-start;
`;

// *************** Grid Item ***************
export const ItemContainer = styled(Paper)<{color: string}>`
    border-left: 6px solid ${props => props.theme.summary[props.color]};
    transition: .2s;
    padding-top: 3px;
    padding-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const FormContainer = styled(CommonGrid)`
    grid-template-columns: 
        calc(100% - 46px - ${durationGridWidth})
        ${durationGridWidth}
        54px;
    grid-template-areas: "entry duration action";
    padding: 0 4px 4px 4px;
    @media only screen and (max-width: 900px) {
        grid-template-columns: 
            calc(100% - 46px - ${durationGridWidthSmallScr})
            ${durationGridWidthSmallScr}
            54px;
    }
`;

export const GridItemEntry = styled.div`
    grid-area: entry;
`;

export const GridItemDuration = styled.div`
    grid-area: duration;
    display: flex;
    flex-direction: column;
`;

export const Days = styled.div`
    display: flex;
    align-items: stretch;
    font-size: 14px;
`;

export const Error = styled(Typography)`
    padding: 2px;
    align-self: center;
    color: red !important;
`;

export const GridItemAction = styled.div<{disabled: boolean}>`
    grid-area: action;
    ${props => props.disabled ? 'pointer-events: none; opacity: 0.4;' : ''}
`;

// *************** Grid Entries ***************
export const FieldContainer = styled.div<{minHeight?: number}>`
    ${props => props.minHeight ? `min-height: ${props.minHeight}px;` : ``}
`;

export const Fields = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 6px;
    margin: 6px auto 0 auto;
`;

export const FieldsMain = styled(Fields)`
    grid-template-columns: minmax(200px, 0.5fr) minmax(250px, 1fr) 48px;
    margin: 0 auto;
`;

export const NarrativeFieldContainer = styled.div`
    padding: 0px;
    background-color: #f3f3f3;
    border-radius: 3px;
    overflow: hidden;
`;
