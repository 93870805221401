import styled from 'common/styled-components';

export const Fields = styled.div`
    max-width: 1499px;
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-column-gap: 10px;
`;

export const NarrativeCheckbox = styled.div`
    display: flex;
    flex-direction: row-reverse;
`;

export const BottomFields = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 200px 1fr;
    align-items: center;
    grid-column-gap: 10px;
    margin: 0 auto;
    max-width: 1499px;
    width: 100%;
`;

export const FieldContainer = styled.div<{minHeight?: number}>`
    ${props => props.minHeight ? `min-height: ${props.minHeight}px;` : ``}
`

export const NarrativeFieldContainer = styled.div`
    padding: 8px;
    background-color: #f3f3f3;
    border-radius: 3px;
    overflow: hidden;
`

export const DurationFieldContainer = styled.div`
    margin-top: 12px;
    align-self: flex-start;
`
