import { makeObservable } from 'mobx';
import DialogRootStore from 'store/dialog.root.store';
import { RootStore } from './root.store';

export default class ConfirmCollaborateStore extends DialogRootStore<void, void> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }
}
