import styled from 'common/styled-components';
export const AppContainer = styled<{disableAnimations?: boolean }, 'div'>('div')`
    position: fixed;
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    touch-action: manipulation;
    
    * {
        transition: ${props => props.disableAnimations ? `none !important` : ''};
    }
`;
export const AppBody = styled.div`
    display: flex;
    flex: 1;
    min-width: 0;
    flex-direction: column;
    touch-action: manipulation;
`;

export const Fullscreen = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`