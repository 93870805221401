import styled from 'common/styled-components';

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
export const MainContent = styled.div`
    overflow: auto;
`;
export const FormMainContent = styled.div`
    display: inline-flex;
    width: 100%;
`;