import styled from 'styled-components';
import {
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Typography
} from '@material-ui/core';

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    height: 64px;
    background-color: ${props => props.theme.primary.main};
    display: flex;
    align-items: center;
`;

export const SearchContainer = styled.div`
    flex: 1;
    padding: 12px 12px 12px 24px;
    display: flex;
    align-items: flex-end;
`;

export const Title = styled(Typography)`
    flex: 1;
    text-align: center;
`;

export const New = styled(IconButton)`
    float: right;
    && {
        margin-right: 24px;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 64px);
`;

export const ListContainer = styled(Paper)`
    flex: 1; 
    overflow: auto;
`;

export const FormContainer = styled.div`
    flex: 3;
    margin: 10px;
    overflow: hidden;
`;

export const ButtonsContainer = styled.div`
    display: flex; 
    padding: 20px 0 6px 10px;
`;

export const ListText = styled(ListItemText)`
    
`;

export const CustomSecondaryAction = styled(ListItemSecondaryAction)`
    display: none;
`;

export const CustomListItem = styled(ListItem)`
    && {
        padding: 0;
        margin: 0;
    }
    text-decoration: none;
`;

export const ListItemContent = styled.div`
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    &:hover ${CustomSecondaryAction}{
        display: block;
    }
`;