type Entity = {
    id?: number | null;
};

export function setListItem<T extends Entity>(arr: Array<T>, item: T): void {
    let found = false;
    arr.forEach((t: T, idx: number) => {
        if (t.id === item.id) {
            arr[idx] = item;
            found = true;
        }
    });
    if (!found) {
        arr.push(item);
    }
}

export function getListItem<T extends Entity>(arr: Array<T>, id: number): T | void {
    let result = undefined;
    arr.forEach((t) => {
        if (t.id === id) {
            result = t;
        }
    });
    return result;
}

export function removeListItem<T extends Entity>(arr: Array<T>, id: number): Array<T> {
    return arr.filter((entry) => {
        return entry.id !== id;
    });
}