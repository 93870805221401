import styled from 'common/styled-components';

export const Fields = styled.div`
    max-width: 1499px;
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-column-gap: 14px;
    grid-row-gap: 14px;
`;

export const Actions = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
`;
