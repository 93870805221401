import * as React from 'react';
import { Checkbox, Collapse, IconButton, Tooltip, TextField, Button } from '@material-ui/core';
import { Cancel, Check, CheckCircle, Delete, KeyboardArrowDown, KeyboardArrowUp, Save, GroupWorkOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import TimeEntryForm from 'components/TimeEntryForm/TimeEntryForm';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import * as Styled from './styled';
import TEContextMenu from 'components/TEContextMenu/TEContextMenu';
import { MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { ValidationState } from 'api/immutables/validators';
import * as ReactDOM from 'react-dom';
import scrollIntoView from 'scroll-into-view-if-needed';
import { CollaborateType, Features, TimeEntryType, TimeKeeperAssignment } from '../../api/types/types';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { FlexDiv } from 'common/flex';
import { TKConsumer } from 'common/TKProvider';
import TimeKeepersList from 'components/TimeKeepersList/TimeKeepersList';
import { CollaborateBtn } from 'components/NavBar/styled.desktop';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import TimeEntryDialogStore from 'store/timeentry.dialog.store';
import { DateTime } from 'luxon';
import { getDateFormat } from '../../util/date';
import { withTranslation } from 'react-i18next';

interface TimeEntryPanelProps {
    timeEntry: TimeEntry;
    validationState?: ValidationState;
    durValidationState?: boolean;
    onChange: (t: TimeEntry, vstate?: ValidationState, durVstate?: boolean) => void;
    onEdit?: (t: TimeEntry) => void;
    onSave?: (t: TimeEntry) => void;
    onPost?: (t: TimeEntry) => void;
    onCancel?: (t: TimeEntry) => void;
    onDelete?: (t: TimeEntry) => void;
    onCopy?: (t: TimeEntry) => void;
    onSplit?: (t: TimeEntry) => void;
    onMerge?: (t: TimeEntry) => void;
    isMergeable?: boolean;
    onTransfer?: (t: TimeEntry) => void;
    isTransferable?: boolean;
    selected?: boolean;
    onSelect?: (selected: boolean) => void;
    expanded?: boolean;
    onExpand?: (expanded: boolean) => void;
    posted?: boolean; /** if true, time entry is posted and cannot be edited */
    error?: boolean; /** any error messages */
    disabled?: boolean;
    noCheckBox?: boolean;
    buildCodeSets?: (id: number) => void;
    timeCastEnabledInDayView?: boolean;
    dialogStore?: TimeEntryDialogStore;
    onSetFieldLoader?: (value: boolean) => void;
    offlineWorkDate?: boolean;
    // roles: Role[];
    // tslint:disable-next-line:no-any
    t: any;
}

const styles = {
    tooltip : {
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical' as 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '400px',
        maxHeight: '46px'
    }
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        dialogStore: rootStore.timeEntryDialogStore
    };
})
@observer
class TimeEntryPanel extends React.Component<TimeEntryPanelProps> {
    expand = async () => {
        if (!this.props.expanded) {
            const entry = this.props.timeEntry;
            if (this.props.buildCodeSets) {
                await this.props.buildCodeSets(entry.id!);
            }
            setTimeout(() => {
                let elem: HTMLElement = ReactDOM.findDOMNode(this) as HTMLElement;
                scrollIntoView(elem, { behavior: 'smooth', scrollMode: 'if-needed' });
            }, 0);
        }
        this.props.onExpand!(!this.props.expanded);
    }
    
    select = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        this.props.onSelect!(checked);
    }

    changeReference = (event: React.ChangeEvent<HTMLInputElement>) => {
        let entry = this.props.timeEntry.setReference(event.target.value);
        this.props.onChange(entry);
    }

    disableCollaborateBtn = (writable: boolean) => {
        const { online } = this.props.dialogStore!.rootStore.appStore;
        const { timeEntry } = this.props;
        return online && (timeEntry && timeEntry.timeEntryType !== TimeEntryType.COLLABORATE && writable
            && !(timeEntry.collaborateTks || timeEntry.collaborateInfo) && timeEntry.matterId);
    }
    
    render() {
        let {
            timeEntry,
            selected,
            onSelect,
            expanded,
            onExpand,
            onChange,
            onPost,
            onEdit,
            onCopy,
            onDelete,
            onSave,
            onCancel,
            onSplit,
            onMerge,
            isMergeable,
            onTransfer,
            isTransferable,
            validationState,
            disabled,
            noCheckBox,
            timeCastEnabledInDayView,
            onSetFieldLoader,
            offlineWorkDate,
            dialogStore,
            t
        } = this.props;
        let providerData = {
            entry: timeEntry,
            selected: selected,
        };
        const { dirty } = timeEntry;
        const clientText = timeEntry.clientId ? `${timeEntry.clientNumber} - ${timeEntry.clientName}` : '';
        const matterText = timeEntry.matterId ? `${timeEntry.matterNumber} - ${timeEntry.matterName}` : '';
        const CustomTooltip = withStyles(styles)(Tooltip);
        const { getAllTimeKeepers, filteredAllTimekeepersList, setTkSearchText } = dialogStore!.rootStore.appStore;
        const matterLoading = dialogStore!.rootStore.timeEntryStore.matterLoading ||
            dialogStore!.rootStore.homeStore.matterLoading;
        
        // Fetching the Offices to show in the office list
        let tkOfficesToShow = dialogStore!.rootStore.appStore.getActiveTKOfficesForDate(
            DateTime.fromISO(timeEntry && timeEntry.workDateTime)
        );

        // get author/collaborators name(s) from entry collaborateInfo and convert to a string
        const collaborateInfo = timeEntry && timeEntry.collaborateInfo && JSON.parse(timeEntry.collaborateInfo);
        let collaborators: string;
        let numberOfCollaborators: number = 0;
        if (collaborateInfo) {
            if (timeEntry.timeEntryType === TimeEntryType.COLLABORATE) {
                collaborators = collaborateInfo.author.tkName;
            } else {
                collaborators = collaborateInfo.collaborators.map((tk: CollaborateType) => tk.tkName).join('\n');
                numberOfCollaborators = collaborateInfo.collaborators.length;
            }
        }  else {
            collaborators = dialogStore!.rootStore.collaboratees.map((tk: CollaborateType) => tk.tkName).join('\n');
            numberOfCollaborators = dialogStore!.rootStore.collaboratees.length;
        }

        return (
            <FeaturesConsumer>
                { (features: Features) =>
            <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
            <>
            <Styled.Container 
                elevation={1}
                square={true}
                expanded={expanded || false}
                posted={timeEntry.isPosted()}
            >
                <MenuProvider id={`menu_${timeEntry.id}`} data={providerData} >
                <Styled.Header 
                    onClick={onExpand && this.expand}
                >
                    {timeEntry.isPosted() ? 
                        <Styled.CheckIcon isReversed={timeEntry.status === 'REVERSE SYNC'} />
                        :
                        <Checkbox
                            checked={selected}
                            onClick={(e) => e.stopPropagation()}
                            onChange={this.select}
                            disableRipple={true}
                            style={{padding: 0}}
                            disabled={noCheckBox || !tk.writable}
                        />
                    }
                    <Styled.TEContent gridAutoFlow="row">
                        {offlineWorkDate && <Styled.ListDate>
                            {DateTime.fromISO(timeEntry.workDateTime).toFormat('EEE')
                            + ', ' + DateTime.fromISO(timeEntry.workDateTime).toFormat(getDateFormat())}
                        </Styled.ListDate>}
                        <Styled.TEDetails
                            gridAutoFlow="column"
                            gridTemplateColoums={timeCastEnabledInDayView ? '1.5fr 1.5fr .5fr .4fr auto' : '1fr 1fr 1.5fr .27fr .18fr auto auto'}
                        >
                            <Tooltip title={clientText}>
                                    <Styled.HeaderItem>{clientText}</Styled.HeaderItem>
                            </Tooltip>
                            <Tooltip title={matterText}>
                                <Styled.HeaderItem>{matterText}</Styled.HeaderItem>
                            </Tooltip>
                            { !timeCastEnabledInDayView &&
                                <CustomTooltip title={timeEntry.narrative}>
                                    <Styled.HeaderItem>{timeEntry.narrative}</Styled.HeaderItem>
                                </CustomTooltip>
                            }
                            <Styled.DurationHeader
                                billable={timeEntry.isBillable()}
                                matter={timeEntry.matterId}
                            >
                                {(timeEntry.duration / 3600).toFixed(2)}
                            </Styled.DurationHeader>
                            { (timeEntry.timeEntryType === TimeEntryType.COLLABORATE && (timeEntry.collaborateTks! || timeEntry.collaborateInfo!)) ?
                                <CustomTooltip title={timeEntry.collaborateTks || collaborators} >
                                    <GroupWorkOutlined/>
                                </CustomTooltip> : <div/>
                            }
                            {expanded ? <div title={t('collapse', { ns: 'common' })}><KeyboardArrowUp/></div>
                                : <div title={t('expand', { ns: 'common' })}><KeyboardArrowDown/></div>}
                            <Styled.DirtyIndicator>{dirty ? '*' : ''}</Styled.DirtyIndicator>
                        </Styled.TEDetails>
                        {
                            timeCastEnabledInDayView &&
                            <Styled.StyledNarrative>
                            <CustomTooltip title={timeEntry.narrative} >
                                <Styled.HeaderItem>{timeEntry.narrative}</Styled.HeaderItem>
                            </CustomTooltip>
                            </Styled.StyledNarrative>
                        }
                    </Styled.TEContent>
                </Styled.Header>
                </MenuProvider>
    
                   { expanded && <Styled.Form>
                        <TimeEntryForm
                            timeEntry={timeEntry}
                            validationState={validationState}
                            durValidationState={this.props.durValidationState}
                            onChange={onChange}
                            onSetFieldLoader={onSetFieldLoader}
                            actionCodesRequired={features.EpochConfigActionCodesRequired}
                            minNarrativeLength={features.EpochConfigNarrativesMinimumChars}
                            maxNarrativeLength={features.EpochConfigNarrativesMaximumChars}
                            disabled={disabled}
                            resetCollaborators={() => this.props.dialogStore!.rootStore.setColloaboratees([])}
                            tkOfficesToShow={tkOfficesToShow!}
                        />
                        <div style={{ display: 'flex'}}>
                            {features.EpochConfigReferenceRequired && timeEntry && !timeEntry.matterId &&
                                        <TextField
                                            label={t('field.reference')}
                                            error={validationState && validationState.isReferenceEmpty}
                                            helperText={validationState && validationState.isReferenceEmpty ? t('validation.reference.invalid') : ''}
                                            value={timeEntry && timeEntry.reference ? timeEntry.reference : ''}
                                            onChange={this.changeReference}
                                        />
                                        }
                            <FlexDiv flex={1} />
                            {validationState && validationState.invalidWorkDate &&
                                <p style={{ color: '#f44336' }}>{t('validation.date.invalid')}</p>}
                            {(timeEntry.sapStatus === 'UNSUBMITTED') && 
                            <Styled.Actions>
                                <TimeKeepersList
                                    render={(tkMenuAnchor, openTkList) =>
                                        <CollaborateBtn
                                            innerRef={tkMenuAnchor}
                                            title={timeEntry.timeEntryType !== TimeEntryType.COLLABORATE ? collaborators : ''}
                                        >
                                            <Button
                                                onClick={(evt) => {
                                                    setTkSearchText('')
                                                    getAllTimeKeepers(timeEntry.workDateTime, timeEntry.matterId)
                                                        .then(() => openTkList(evt))
                                                }}
                                                aria-label={t('action.collaborate')}
                                                disabled={!this.disableCollaborateBtn(tk.writable)}
                                            >
                                                {t('action.collaborate')} ({numberOfCollaborators})
                                            </Button>
                                        </CollaborateBtn>}
                                    collaborate={true}
                                    menuWidth={300}
                                    timeKeepers={filteredAllTimekeepersList}
                                    workDate={timeEntry.workDateTime}
                                    matterId={timeEntry.matterId}
                                />
                                
                                {onSave &&
                                <Tooltip title={t('save', { ns: 'common' })}>
                                    <IconButton
                                        disabled={!dirty || !tk.writable || matterLoading}
                                        onClick={() => onSave!(timeEntry)}
                                    >
                                            <Save />
                                    </IconButton>
                                </Tooltip>}
                                {onPost &&
                                    <Tooltip title={t('action.post')}>
                                        <IconButton disabled={!tk.writable || matterLoading} onClick={() => onPost!(timeEntry)}>
                                            <Check />
                                        </IconButton>
                                    </Tooltip>}
                                {onCancel &&
                                <Tooltip title={t('cancel', { ns: 'common' })}>
                                <IconButton
                                    disabled={!dirty || !tk.writable}
                                    onClick={() => onCancel!(timeEntry)}
                                >
                                        <Cancel />
                                </IconButton>
                                </Tooltip>}
                                {onDelete &&
                                <Tooltip title={t('delete', { ns: 'common' })}>
                                <IconButton disabled={!tk.writable} onClick={() => onDelete!(timeEntry)}>
                                    <Delete />
                                </IconButton>
                                </Tooltip>}
                            </Styled.Actions>}
                       </div>
                    </Styled.Form>}
                <TEContextMenu
                    id={`menu_${timeEntry.id}`}
                    editHandler={onEdit && !timeEntry.isPosted() && tk.writable ? () => onEdit!(timeEntry) : undefined}
                    postHandler={onPost && !timeEntry.isPosted() && tk.writable ? () => onPost!(timeEntry) : undefined}
                    deleteHandler={onDelete && !timeEntry.isPosted() && tk.writable ? () => onDelete!(timeEntry) : undefined}
                    copyHandler={onCopy && tk.writable ? () => onCopy!(timeEntry) : undefined}
                    splitHandler={onSplit && timeEntry.isSplitable() && tk.writable ? () => onSplit!(timeEntry) : undefined}
                    viewHandler={onEdit && timeEntry.isPosted() || !tk.writable ? () => onEdit!(timeEntry) : undefined}
                    mergeHandler={onMerge && isMergeable && !timeEntry.isPosted() && tk.writable ? () => 
                        onMerge!(timeEntry) : undefined}
                    transferHandler={onTransfer && isTransferable && !timeEntry.isPosted() && tk.writable ? () =>
                            onTransfer!(timeEntry) : undefined}
                />
            </Styled.Container>
            </>
            }
            </TKConsumer>
            }
        </FeaturesConsumer>
        );
    }
}

export default withTranslation(['timeentries', 'common'])(TimeEntryPanel);