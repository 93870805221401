import * as React from 'react';
import { TimeCastSegmentGap, TimeCastSegmentGroup } from 'store/home.store';
import ImmutableTimeEntry from '../../api/immutables/ImmutableTimeEntry';
import { AutoSizer, List } from 'react-virtualized';
import SegmentDragSource from 'components/DragNDropTimeCastSegment/SegmentDragSource';

interface Props {
    toggleSegmentGroupOrGapSelection: (groupOrGap: TimeCastSegmentGroup | TimeCastSegmentGap) => void,
    isSelected: (groupOrGap: TimeCastSegmentGroup | TimeCastSegmentGap) => boolean,
    selectSameWindows: (groupOrGap: TimeCastSegmentGroup | TimeCastSegmentGap) => void,
    updateTimeEntryFromDragSource: (timeEntry: ImmutableTimeEntry) => Promise<void>,
    getProgramColor?: (programCode: string) => string,
    segments: Array<TimeCastSegmentGroup | TimeCastSegmentGap>,
    selectedSegments: number
}

const VirtualizedSegmentsList = (props: Props) => {
    const { segments,
        toggleSegmentGroupOrGapSelection,
        isSelected,
        selectedSegments,
        selectSameWindows,
        updateTimeEntryFromDragSource,
        getProgramColor
    } = props;
    return(
        <div style={{ flex: '1 1 auto', height: 'inherit', overflow: 'hidden' }}>
            <AutoSizer>
                {({ width, height }) => (
                    <List
                        height={height}
                        rowHeight={48}
                        rowCount={segments.length}
                        width={width}
                        rowRenderer={({style, key, index}) => (
                            <div key={key}>
                                <SegmentDragSource
                                    key={index}
                                    groupOrGap={segments[index]}
                                    toggleSelection={
                                        () => toggleSegmentGroupOrGapSelection(segments[index])
                                    }
                                    isSelected={isSelected(segments[index])}
                                    handleDoubleClick={() => selectSameWindows(segments[index])}
                                    selectedSegmentsToDrag={selectedSegments}
                                    onDrop={updateTimeEntryFromDragSource}
                                    getProgramColor={getProgramColor}
                                    style={style}
                                />
                            </div>
                        )}
                    />
                )}
            </AutoSizer>
        </div>
    );
}
export default VirtualizedSegmentsList;