import * as React from 'react';
import { inject, observer } from 'mobx-react';
import MergeEntryDialogStore from 'store/mergeEntry.dialog.store';
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    List,
    ListItem,
    ListItemText,
    TextField
} from '@material-ui/core';
import { RootStore } from 'store/root.store';
import TimeEntryForm from 'components/TimeEntryForm/TimeEntryForm';
import { Features, Matter } from '../../api/types/types';
import { InlineDatePicker } from 'material-ui-pickers';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { FlexDiv } from 'common/flex';
import { ApiConsumer } from 'common/ApiProvider';
import RootAPI from '../../api/interfaces/RootAPI';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import Template from '../../api/immutables/ImmutableTemplate';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { getDateFormat, isoDate } from '../../util/date';
import { DateTime } from 'luxon';
import { withTranslation } from 'react-i18next';
import { getTemplateTooltipText } from 'util/template';

interface Props {
    store?: MergeEntryDialogStore;
    // tslint:disable-next-line:no-any
    t: any;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        store: rootStore.mergeEntryDialogStore
    };
})
@observer
class MergeEntryDialog extends React.Component<Props> {

    changeReference = (event: React.ChangeEvent<HTMLInputElement>) => {
        let entry = this.props.store!.entry.setReference(event.target.value);
        this.props.store!.changeEntry(entry);
    }

    setWorkDate = (date: Date) => {
        const workDate: DateTime = DateTime.local(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
            0,
            0,
            0
        );
        this.props.store!.setWorkDate(workDate);
    }
    
    render() {
        const { t, store } = this.props;
        const { 
            isOpen, 
            cancel, 
            matters, 
            entry,
            selectedTemplate, 
            setTemplate, 
            mergeNarrativeFlag,
            toggleMergeNarrativeFlag,
            changeEntry,
            saving,
            wrappedPost,
            wrappedSave,
            durationValidationState,
            setFieldLoaderFn,
            validationState ,
            rootStore
        } = store!;
        const fetchTemplates = rootStore.templateStore.fetchTemplatesInAutoComplate;
        const tkOfficesToShow = rootStore.appStore.getActiveTKOfficesForDate(DateTime.fromISO(entry && entry.workDateTime));

        return (
            <FeaturesConsumer>
                { (features: Features) =>
            <>
                <Dialog
                    PaperProps={{style: {overflow: 'visible', maxWidth: '1024px'}}}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    open={isOpen}
                    onClose={cancel}
                    scroll="paper"
                    maxWidth="md"
                    aria-labelledby="scroll-dialog-title"
                    fullWidth={true}
                >
                <DialogTitle
                    id="dialog-merge-title"
                    style={{paddingBottom: 0}}
                >
                    {t('dialog.merge.title')}
                </DialogTitle>
                <DialogContent>
                    <List
                        style={{
                            width: '100%',
                            overflow: 'auto',
                            maxHeight: 80,
                        }}
                    >
                        {matters && matters.map((matter: Matter) =>
                        <ListItem 
                            key={matter.id}
                            style={{padding: '5px 24px 5px 0'}}
                        >
                            <ListItemText
                                primary={matter.name + '-' + matter.number}
                            />
                        </ListItem>
                        )}
                    </List>
                    <FlexDiv direction="row" style={{alignItems: 'flex-end'}}>
                        <InlineDatePicker
                            format={entry && entry.workDateTime ? 
                                DateTime.fromISO(entry.workDateTime).toFormat(getDateFormat()) : 
                                'MM/dd/yyyy'
                            }
                            onlyCalendar={true}
                            value={entry ? isoDate(DateTime.fromISO(entry.workDateTime)) : ''}
                            disabled={entry && entry.isPosted()}
                            onChange={this.setWorkDate}
                            leftArrowIcon={<KeyboardArrowLeft/>}
                            rightArrowIcon={<KeyboardArrowRight/>}
                        />
                        <FlexDiv flex={1}/>
                        {entry && !entry.isPosted() && <ApiConsumer>
                            { (api: RootAPI) =>
                                <div>
                                    <AutoCompleteField
                                        label={t('field.template.title')}
                                        fetch={(search: string) => fetchTemplates(search, entry)}
                                        currentItem={selectedTemplate}
                                        clearable={true}
                                        disabled={false}
                                        onClear={() => setTemplate(undefined)}
                                        getItemText={(m: Template) => `${m.name}`}
                                        onSelect={setTemplate}
                                        width={200}
                                        tooltip={(m: Template) => getTemplateTooltipText(m, t)}
                                    />
                                </div>}

                        </ApiConsumer>}
                    </FlexDiv>
                    <TimeEntryForm 
                        timeEntry={entry} 
                        durValidationState={durationValidationState}
                        onChange={changeEntry}
                        minHeight={144}
                        validationState={validationState}
                        actionCodesRequired={
                            features.EpochConfigActionCodesRequired
                        }
                        minNarrativeLength={
                            features.EpochConfigNarrativesMinimumChars
                        }
                        maxNarrativeLength={
                            features.EpochConfigNarrativesMaximumChars
                        }
                        onSetFieldLoader={setFieldLoaderFn}
                        tkOfficesToShow={tkOfficesToShow!}
                    />
                </DialogContent>
                <DialogActions>
                {entry && !entry.isPosted() &&
                    <>
                        {features.EpochConfigReferenceRequired && !entry.matterId &&
                        <div style={{ paddingLeft: '20px', paddingBottom: '5px' }}>
                            <TextField
                                label={t('field.reference')}
                                error={validationState && validationState.isReferenceEmpty}
                                helperText={validationState && validationState.isReferenceEmpty ? t('validation.reference.invalid') : ''}
                                value={entry && entry.reference ? entry.reference : ''}
                                onChange={this.changeReference}
                            />
                        </div>}
                    <FormControlLabel
                        style={{
                            marginRight: 'auto',
                            paddingLeft: 20
                        }}
                        control={
                            <Checkbox
                                checked={mergeNarrativeFlag}
                                onChange={toggleMergeNarrativeFlag}
                            />
                        }
                        label={t('dialog.merge.checkbox.merge_narrative.label')}
                    />
                    <Button disabled={saving} onClick={wrappedSave} >Save</Button>
                        <Button disabled={saving} onClick={wrappedPost}>
                            {t('action.post')}
                        </Button>
                        </>
                }
                    <Button onClick={cancel}>
                        {t('cancel', { ns: 'common' })}
                    </Button>
                </DialogActions>
                </Dialog>
            </>
                }
        </FeaturesConsumer>
        )
    }
}

export default withTranslation(['timeentries', 'common'])(MergeEntryDialog);