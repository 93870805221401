import BaseStore from 'store/base.store';
import { action, computed, observable, makeObservable } from 'mobx';
import ImmutableTimeEntry from 'api/immutables/ImmutableTimeEntry';
import TimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import { DateTime } from 'luxon';
import { removeListItem, setListItem } from 'util/array';
import { ValidatePost, ValidateSave, ValidationState } from 'api/immutables/validators';
import { ApiResult } from 'api/util';
import { AggregateTotals, TimeCastSegmentGap, TimeCastSegmentGroup } from 'store/home.store'
import {
    DesktopCaptureData,
    Matter,
    LocalStorageWorkLocale,
    CodeSetFlags,
    TimerChunk,
    Code,
    DayCount,
    TimeEntryType,
    TimeCastSegment,
    DeprecatedDesktopCaptureData,
    WorkLocale
} from '../../api/types/types';
import { TimeCast } from '../../util/TimeCast';
import ImmutableTimer from '../../api/immutables/ImmutableTimer';
import { Platform } from '../../util/Platform';
import { AssociateApiResult } from '../../api/interfaces/TimeEntryAPI';
import logger from '../../logging/logging';
import { TimeEntry as TimeEntryDex } from '../../api/implementations/electron/Dexie';
import { RootStore } from 'store/root.store';

interface MatterTotal {
    name: string;
    hrs: number;
}

interface DateMetaData {
    totals: AggregateTotals;
    matterTotals: MatterTotal[];
    entries: TimeEntry[];
}

export default class TimeEntryManager extends BaseStore {
    @observable selectedEntryIds: number[] = [];
    @observable expandedEntryIds: number[] = [];
    @observable selectedIdsForCollab: number[] = [];

    @observable serverTimeEntries: ImmutableTimeEntry[] = [];
    @observable localTimeEntries: ImmutableTimeEntry[] = [];

    @observable loading: boolean = false;
    @observable validationState = new Map<number, ValidationState>();
    @observable durVstate = new Map<number, boolean | undefined>();

    @observable currentStart: DateTime;
    @observable currentEnd: DateTime;

    @observable timeEntriesCount: DayCount[] = [];

    @observable allWorkLocales: WorkLocale[] = [];

    handlerDestructor: () => void;
    
    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    initializeHandler = () => {
        this.handlerDestructor = this.rootStore.api.TimeEntry.registerReciever(this.recieveEntries);
    }

    getTotalForDateExclusive = (date: string, excludeId: number): number => {
        return this.localTimeEntries.reduce((prev, cur) => {
            if (cur.id === excludeId ) {
                return prev;
            }
            if (cur.workDateTime === date) {
                return prev + cur.duration;
            }
            return prev;
        }, 0);
    }

    @action
    async loadEntries(fromDate: DateTime, untilDate: DateTime) {
        this.selectedEntryIds = [];
        this.selectedIdsForCollab = [];
        this.loading = true;
        this.validationState = new Map<number, ValidationState>();
        this.durVstate = new Map<number, boolean | undefined>();
        this.currentStart = fromDate.plus({});
        this.currentEnd = untilDate.plus({});
        this.serverTimeEntries = await this.rootStore.api.TimeEntry.getEntries(
            fromDate,
            untilDate,
            this.rootStore.api.Session.currentTimeKeeper!
        );
        this.localTimeEntries = this.serverTimeEntries.slice().map(e => e.clone());
        this.loading = false;
    }

    checkLocalTimeEntries = (entArr: ImmutableTimeEntry[]) => {
        if (Platform.isElectron()) {
            const localIdsArr = entArr.map(x => {
                let tempEntry = x as TimeEntryDex;
                return tempEntry.localId;
            });
            let uniqueIdsArr = [...new Set(localIdsArr)];
            uniqueIdsArr.map(a => {
                const localNegativeIdx = this.localTimeEntries.findIndex(t => t.id! === -a!);
                let tempLocalEntries = this.localTimeEntries;
                if (localNegativeIdx > -1) {
                    tempLocalEntries.splice(localNegativeIdx, 1);
                }
                this.localTimeEntries = tempLocalEntries;
            });
        }
    }

    @action recieveEntries = (entries: ImmutableTimeEntry[]) => {
        if (!this.currentStart || !this.currentEnd) {
            return;
        }
        this.checkLocalTimeEntries(entries);
        entries.forEach(e => {
            const dirtyEntry = this.localTimeEntries.find(l => {
                if (l.id === e.id && l.dirty) {
                    return true;
                } else {
                    return false;
                }
            });
            if (e.deleted) {
                this.localTimeEntries = removeListItem(this.localTimeEntries, e.id!);
                this.serverTimeEntries = removeListItem(this.serverTimeEntries, e.id!);
                return;
            }
            if ( e.timeKeeperId !== this.rootStore.api.Session.currentTimeKeeper) {
                return;
            }
            let ewdt = DateTime.fromISO(e.workDateTime);
            if (ewdt < this.currentStart || ewdt > this.currentEnd ) {
                return;
            }
            let newLocal2 = this.localTimeEntries.slice();
            let newServer2 = this.serverTimeEntries.slice();
            // Update local entries only if it is not being edited
            if (!dirtyEntry) {
                setListItem(newLocal2, e);
            }
            setListItem(newServer2, e);
            this.serverTimeEntries = newServer2;
            this.localTimeEntries = newLocal2;

        });
    }

    @action determineCodeSets = async (id: number) => {
        if (Platform.isElectron()) {
            this.setCodeSetsOnExpand(id);
        }
    }

    @action setCodeSetsOnExpand = (id: number) => {
        const te = this.localTimeEntries.find((e) => e.id === id);
        if (te) {
            if (te.matterId) { // if Matter exists, then hydrate entry with code set flags
                this.rootStore.api.Code.determineCodeSetFields(te.matterId, te.workDateTime)
                    .then(codeSetFlags => {
                        te.isActCode = codeSetFlags.isActCode;
                        te.isPhaseCode = codeSetFlags.isPhaseCode;
                        te.isFfTaskCode = codeSetFlags.isFfTaskCode;
    
                        let newLocal2 = this.localTimeEntries.slice();
                        let newServer2 = this.serverTimeEntries.slice();
                        // Update local entries only if it is not being edited
                        if (!te.dirty) {
                            setListItem(newLocal2, te);
                        }
                        setListItem(newServer2, te);
                        this.serverTimeEntries = newServer2;
                        this.localTimeEntries = newLocal2;
                    })
                    .catch(e => {
                        logger.error('Time Entries, Setting of CodeSets on Expand failed.\n', e);
                    });
            }
        }
    }

    @action openMultipleNewTimeEntryDialog = async (dates: DateTime[]) => {
        try {
            const timeEntries = [];
            const sortedDates = dates.sort((d1, d2) => Date.parse(d1.toISO()) - Date.parse(d2.toISO()));
            await this.rootStore.multipleTimeEntriesDialogStore.clear();
            for (const date of sortedDates) {
                const activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(date);
                const newEntry = new ImmutableTimeEntry();
                newEntry.workDateTime = date.toISO();
                newEntry.timeKeeperId = this.rootStore.api.Session.currentTimeKeeper!;
                newEntry.duration = 0;
                newEntry.office = activeTimeKeeper ? activeTimeKeeper.office : undefined;
                newEntry.officeName = activeTimeKeeper ? activeTimeKeeper.officeName : undefined;
                newEntry.sapStatus = SapStatus.NEW;
                timeEntries.push(newEntry);
            }
            await this.rootStore.multipleTimeEntriesDialogStore.open(timeEntries);
        } catch (e) {
            logger.error('Time Entries, Opening Multiple New Time Entry Dialogue failed.\n', e);
        }
    }

    @action setEntryFieldValues = async (date: DateTime,
                                         newEntry: ImmutableTimeEntry,
                                         groups?: Array<TimeCastSegmentGroup | TimeCastSegmentGap>,
                                         timerSegments?: Array<TimerChunk>,
                                         timers?: Array<ImmutableTimer>
    ) => {
        try {
            const activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(date);
            let tcDuration: number = 0;
            let timersDuration: number = 0;
            let tcNarrative: string = '';
            let timerNarrative: string = '';
            newEntry = newEntry
                .setOffice(newEntry.office ? newEntry.office : activeTimeKeeper ? activeTimeKeeper.office : undefined)
                .setOfficeName(newEntry.office ? newEntry.officeName : activeTimeKeeper ? activeTimeKeeper.officeName : undefined)
                .setNarrative(newEntry.narrative ? newEntry.narrative : '');
            newEntry.workDateTime = date.toISO();
            newEntry.timeKeeperId = this.rootStore.api.Session.currentTimeKeeper!;
            newEntry.sapStatus = SapStatus.UNSUBMITTED;
            let matterNumber: string | number = '';
            if (groups && groups.length > 0) {
                let uniqueTitles = new Set<string>();
                this.rootStore.timeEntryDialogStore.createAnotherFlag = false;
                groups
                    .forEach(group => {
                        const startTime = group.startTime.toLocaleString(DateTime.TIME_SIMPLE);
                        const endTime = group.endTime.toLocaleString(DateTime.TIME_SIMPLE);
                        if (group.gap) {
                            const gapNarrative = `${startTime} - ${endTime}: Gap`;
                            uniqueTitles.add(gapNarrative);
                        } else {
                            group.segments.forEach((se: TimeCastSegment) => {
                                if (se.type === 'DESKTOP_CAPTURE') {
                                    let segmentTitle = (se.data as DesktopCaptureData).title;
                                    if (!segmentTitle) {
                                        segmentTitle = (se.data as DeprecatedDesktopCaptureData).windowTitle;
                                    }
                                    let lastIdxOfDash = segmentTitle.lastIndexOf('-');
                                    if (lastIdxOfDash > 0) {
                                        segmentTitle = segmentTitle.substr(0, lastIdxOfDash);
                                    }
                                    uniqueTitles.add(segmentTitle || '');
                                    const matterNo = (se.data as DesktopCaptureData).matterNumber;
                                    if (matterNo) {
                                        matterNumber = matterNo;
                                    }
                                } else {
                                    uniqueTitles.add(TimeCast.getSegmentGroupTitle(se) || '');
                                }
                            });
                        }
                    });
                tcNarrative = `${[...uniqueTitles].join(', ')}`.trim();
                tcDuration = groups
                    .map(s => DateTime.fromISO(s.endTime.toISO())
                        .diff(DateTime.fromISO(s.startTime.toISO()), 'seconds')
                        .get('seconds'))
                    .reduce((previousValue: number, currentValue: number) => {
                        return previousValue + currentValue
                    }, 0);
            }
            if (timerSegments && timerSegments.length > 0) {
                timerNarrative = timerSegments
                    .filter(s => s.description && s.description)
                    .map(f => f.description)
                    .join(' ');
                timerNarrative = timerNarrative.replace(/  +/g, ' ');

                timersDuration = timerSegments
                    .map(s => DateTime.fromISO(s.endTime)
                        .diff(DateTime.fromISO(s.startTime), 'seconds')
                        .get('seconds'))
                    .reduce((previousValue: number, currentValue: number) => {
                        return previousValue + currentValue
                    }, 0);

                // assign matter number from least bottom selected timer segments
                let template;
                let filteredTimers = (timers || [])
                    .filter(t => timerSegments.some(s => s.timerId === t.id));
                filteredTimers = filteredTimers.sort((t1, t2) => {
                    return (t1.lastModified > t2.lastModified) ? 1 : -1
                });
                for await (let st of filteredTimers) {
                    if (st.matterId) {
                        matterNumber = st.matterId;
                        break;
                    } else if (st.templateId) {
                        template = await this.rootStore.api.Template.getTemplate(st.templateId);
                        let preTemplateNarrative = newEntry.narrative;
                        newEntry = newEntry.loadFromTemplate(template);
                        newEntry.narrative = [newEntry.narrative, preTemplateNarrative].join(' ').trim();
                        // this.rootStore.timeEntryDialogStore.selectedTemplate = template;
                        // matterNumber = template.matterId!;
                        break;
                    }
                }
            }

            let finalMatterObj;
            if (matterNumber) {
                if (typeof matterNumber === 'string') {
                    const matters = await this.rootStore.api.Matter.searchMatters(matterNumber, true);
                    if (matters.length !== 0) {
                        finalMatterObj = matters[0];
                        if (finalMatterObj.tracked) {
                            newEntry = newEntry.setMatter(finalMatterObj);
                            newEntry = await this.setCodeSetFields(finalMatterObj, newEntry);
                        }
                    }
                } else if (typeof matterNumber === 'number') {
                    finalMatterObj = await this.rootStore.api.Matter.get(matterNumber);
                    if (finalMatterObj) {
                        newEntry = newEntry.setMatter(finalMatterObj);
                        newEntry = await this.setCodeSetFields(finalMatterObj, newEntry);
                        // newEntry.isPhaseCode = finalMatterObj.isPhaseCode;
                        // newEntry.isFfTaskCode = finalMatterObj.isFfTaskCode;
                        // newEntry.isActCode = finalMatterObj.isActCode;
                    }
                }
            }
            newEntry = newEntry.setDuration(tcDuration + timersDuration + (newEntry.actualDuration || 0));
            if (newEntry) {
                if (tcNarrative && !timerNarrative) {
                    newEntry.narrative = (newEntry.narrative ?
                        (newEntry.narrative + ' ') : '') + tcNarrative.replace(/,\s*$/, '');
                }
                if (timerNarrative && !tcNarrative) {
                    newEntry.narrative = (newEntry.narrative ? (newEntry.narrative + ' ') : '') + timerNarrative;
                }
                if (tcNarrative && timerNarrative) {
                    newEntry.narrative = (newEntry.narrative ?
                        (newEntry.narrative + ' ') : '') + tcNarrative + ', ' .concat(timerNarrative);
                }
            }
            return newEntry;
        } catch (e) {
            logger.info('Time Entries, Set Entry Field Values failed.\n', e);
            return Object.assign(new ImmutableTimeEntry(), newEntry);
        }
    }

    setCodeSetFields = async (matter: Matter, entry: ImmutableTimeEntry) => {
        try {
            let workDate: string = entry.workDateTime;
            const codeSetFlags: CodeSetFlags = await this.rootStore.api.Code.determineCodeSetFields(matter.id, workDate);
            entry.isPhaseCode = codeSetFlags.isPhaseCode;
            entry.isFfTaskCode = codeSetFlags.isFfTaskCode;
            entry.isActCode = codeSetFlags.isActCode;
            if (codeSetFlags.phases.length === 1) {
                let phase = codeSetFlags.phases[0];
                entry = entry.setPhase(phase);
                let tasks = await this.rootStore.api.Code.getTaskCodes(phase.id, entry.workDateTime, '');
                if (tasks.length === 1) {
                    let task = tasks[0];
                    entry = entry.setTask(task);
                }
            }
            if (codeSetFlags.ffTasks.length === 1) {
                let ffTask = codeSetFlags.ffTasks[0];
                entry = entry.setFFTask(ffTask);
                let ffActs = await this.rootStore.api.Code.getFFActCodes(ffTask.id, entry.workDateTime, '');
                if (ffActs.length === 1) {
                    let ffAct = ffActs[0];
                    entry = entry.setFFAct(ffAct);
                }
            }
            if (codeSetFlags.activities.length === 1) {
                let act = codeSetFlags.activities[0];
                entry = entry.setAct(act);
            }
            return entry;
        } catch (e) {
            logger.info('Time Entries, Setting Code Set Fields failed.\n', e);
            return Object.assign(new ImmutableTimeEntry(), entry);
        }
    }

    @action openNewTimeEntryDialog = async(
        date: DateTime,
        selectedSegments?: Array<TimeCastSegmentGroup | TimeCastSegmentGap>,
        selectedTimerSegments?: Array<TimerChunk>,
        timers?: Array<ImmutableTimer>,
        miniCalendarDate?: DateTime
    ) => {
        const sortedSegments = selectedSegments && selectedSegments.sort((sg1, sg2) => {
            return (sg1.startTime > sg2.startTime) ? 1 : -1;
        });
        const sortedTimerSegments = selectedTimerSegments && selectedTimerSegments.sort((sg1, sg2) => {
            return (DateTime.fromISO(sg1.startTime) > DateTime.fromISO(sg2.startTime)) ? 1 : -1;
        });
        const newEntry = await this.setEntryFieldValues(
            date,
            new ImmutableTimeEntry(),
            sortedSegments,
            sortedTimerSegments,
            timers || []
        );
        const disableCreateAnother = (selectedSegments && selectedSegments.length > 0) ||
        (selectedTimerSegments && selectedTimerSegments.length > 0) ? true : false;

        const entry = await this.rootStore.timeEntryDialogStore.open(
            newEntry,
            this.createNewTimeEntry,
            disableCreateAnother,
            async (results) => {
                // this shouldn't be possible because we disable the 'create another' feature if
                // there are segments selected
                if (results.length > 1) {
                    throw 'Fatal: there was more than 1 time entry created.'
                } else if (results.length === 0) {
                    throw 'Fatal: no time entry was created.'
                }
                if (!results[0].status.failed) {
                    let tcApis, timerApis;
                    if (selectedSegments && selectedSegments.length > 0) {
                        let toSaveTCSegments: TimeCastSegment[] = [];
                        selectedSegments.filter(s => !s.gap).forEach(group => {
                            (group as TimeCastSegmentGroup).segments.forEach(segment => {
                                segment.associatedTimeEntry = results[0].object.id;
                                if (!segment.deleted) {
                                    toSaveTCSegments.push(segment);
                                }
                            });
                        });
                        tcApis = await this.rootStore.api.TimeCast.saveSegments(toSaveTCSegments);
                    }

                    if (selectedTimerSegments && selectedTimerSegments.length > 0) {
                        selectedTimerSegments.forEach(s => s.timeEntryId = results[0].object.id);

                        let te = results[0].object;
                        timerApis = await this.rootStore.api.Timer.updateChunks(selectedTimerSegments);
                        // to avoid multiple calls in web had to calls the update timer duration api
                        // if (te.sapStatus !== SapStatus.UNSUBMITTED) {
                        //     this.rootStore.api.Timer.updateTimerDurationFromTimeEntry([te.id!])
                        // }
                    }

                    const apiResult: AssociateApiResult = {
                        TimeEntryApi: results[0]
                    }
                    if (timerApis) {
                        apiResult.TimerChunkApis = timerApis;
                    }
                    if (tcApis) {
                        apiResult.TimeCastApis = tcApis;
                    }
                    this.rootStore.homeStore.associatedCallback(apiResult);
                    if (miniCalendarDate) {
                        this.getTimeEntriesCount(
                            miniCalendarDate.startOf('month').minus({days: 6}),
                            DateTime.utc().endOf('month').plus({days: 6})
                        );
                    }
                }
            }
        ) as ImmutableTimeEntry;

        this.createNewTimeEntry(entry);
    }

    @action createNewTimeEntry = (entry: ImmutableTimeEntry) => {
        if (DateTime.fromISO(entry.workDateTime) >= this.currentStart
            && DateTime.fromISO(entry.workDateTime) <= this.currentEnd) {
            setListItem(this.localTimeEntries, entry);
        }
        setListItem(this.serverTimeEntries, entry);
    }

    @action loadEntry = async (t: ImmutableTimeEntry) => {
        try {
            if (t.matterId) { // if Matter exists, then hydrate entry with code set flags
                const codeSetFlags = await this.rootStore.api.Code.determineCodeSetFields(
                    t.matterId, t.workDateTime
                );
                t.isActCode = codeSetFlags.isActCode;
                t.isPhaseCode = codeSetFlags.isPhaseCode;
                t.isFfTaskCode = codeSetFlags.isFfTaskCode;
            }
            let entry = await this.rootStore.timeEntryDialogStore
                .open(t, this.createNewTimeEntry) as ImmutableTimeEntry;
            if (DateTime.fromISO(entry.workDateTime) >= this.currentStart
                && DateTime.fromISO(entry.workDateTime) <= this.currentEnd) {
                setListItem(this.localTimeEntries, entry);
            } else {
                this.localTimeEntries = this.localTimeEntries.filter((e) => e.id !== entry.id);
            }
            setListItem(this.serverTimeEntries, entry);
            this.expandedEntryIds = this.expandedEntryIds.filter((expId) => expId !== entry.id!);
        } catch (e) {
            logger.info('Time Entries, Load Entry failed.\n', e);
        }
    }

    @action revertEntry = (entry: ImmutableTimeEntry) => {
        try {
            let idx = this.localTimeEntries.findIndex((t) => entry.id === t.id);
            this.rootStore.api.TimeEntry.getEntry(entry.id!).then(async (te) => {
                if (te.matterId) { // if Matter exists, then hydrate entry with code set flags
                    const codeSetFlags = await this.rootStore.api.Code.determineCodeSetFields(
                        te.matterId, te.workDateTime
                    );
                    te.isActCode = codeSetFlags.isActCode;
                    te.isPhaseCode = codeSetFlags.isPhaseCode;
                    te.isFfTaskCode = codeSetFlags.isFfTaskCode;
                }
                this.localTimeEntries[idx] = te;
                this.validationState.delete(entry.id!);
                this.durVstate.delete(entry.id!);
                this.localTimeEntries[idx].selectedCodeSetTemplate = null;
            });
        } catch (e) {
            logger.info('Time Entries, Revert Entry failed.\n', e);
        }
    }

    @action deleteEntries = async (entries: ImmutableTimeEntry[]) => {
        try {
            if (entries.filter(e => e.dirty).length !== 0) {
                await this.confirm('dialog.confirm.message.unsaved_changes');
            }
            const confirmDelete = await this.confirm('dialog.confirm.message.delete');
            if (!confirmDelete) { return };
            this.loading = true;

            let newEntries = entries.map((entry) => {
                let newEntry = entry.clone();
                newEntry.deleted = true;
                return newEntry;
            });
            let results = await this.rootStore.api.TimeEntry.updateEntries(newEntries) as ApiResult<ImmutableTimeEntry>[];
            if (results.some((r) => r.status.failed)) {
                this.rootStore.snackbarStore.triggerSnackbar(`${results.map(r => r.status.message).join(',')}`);
                return;
            }
            results.forEach((result) => {
                this.localTimeEntries = this.localTimeEntries.filter((e) => e.id !== result.object.id);
                this.serverTimeEntries = this.serverTimeEntries.filter((e) => e.id !== result.object.id);
            });
            // In TimeEntries page, check if current page has zero entries and move current page to previous page
            if (this.rootStore.routerStore.location.pathname === '/timeentries') {
                if (this.rootStore.timeEntryStore.paginatedTimeEntries.length === 0 &&
                    this.rootStore.timeEntryStore.currentPage !== 1) {
                    this.rootStore.timeEntryStore.setPageNum(this.rootStore.timeEntryStore.currentPage - 1);
                }
            }
            this.removeFromSelected(entries);
            this.loading = false;
            this.rootStore.snackbarStore.triggerSnackbar('app.snackbar.info.deleted');
        } catch (e) {
            logger.info('Time Entries, Delete Entries failed.\n', e);
        }
    }

    @action saveEntries = async (entries: ImmutableTimeEntry[]) => {
        try {
            let toSave: ImmutableTimeEntry[] = [];
            let codes: Code[] = [];
            if (this.rootStore.api.Code.getAll) {
                codes = await this.rootStore.api.Code.getAll();
            }
            await Promise.all(entries.map(async (entry) => {
                if (entry) {
                    let narrativeMinLength;
                    let narrativeMaxLength;
                    // todo pass in total duration for given day from store for 24 hour validation. 
                    if (entry.matterId) {
                        const matter = await this.rootStore.api.Matter.get(entry.matterId);
                        if (matter) {
                            entry.bannedWords = matter.bannedWords;
                            entry.blockBillingWords = matter.blockBillingWords;
                            narrativeMinLength = matter.minLength;
                            narrativeMaxLength = matter.maxLength;
                        }
                    }
                    const activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(entry.workDateTime));
                    let vstate = ValidateSave(
                        entry,
                        this.getTotalForDateExclusive(entry.workDateTime, entry.id!),
                        this.rootStore.appStore.features,
                        activeTimeKeeper,
                        narrativeMinLength,
                        narrativeMaxLength
                    );
                    if (Platform.isElectron()) {
                        if (codes.length > 0) {
                            vstate = await this.validateCodeSets(entry, vstate, codes);
                        }
                    }
                    if (vstate.valid && !this.durVstate.get(entry.id!)) {
                        toSave.push(entry);
                        this.validationState.delete(entry.id!);
                    } else {
                        if (!vstate.valid) {
                            this.validationState.set(entry.id!, vstate);
                        }
                        this.expandedEntryIds = this.expandedEntryIds.concat([entry.id!]);
                    }
                    if (this.rootStore.collaboratees.length > 0) {
                        // if entry has collaborateTks then assign rootStore collaboratees ids to it
                        if (entry.collaborateTks && entry.collaborateTks.length > 0) {
                            entry.collaborateTks = this.rootStore.collaboratees.map(tk => tk.timeKeeperId).join();
                        } else {
                            // construct the collaborateInfo object and asign to time entry
                            const matterId = entry.matterId || undefined;
                            const author = {
                                timeKeeperId: activeTimeKeeper!.id,
                                tkName: activeTimeKeeper!.name,
                                matterId
                            };
                            const collaborators = this.rootStore.collaboratees.map(tk => ({ ...tk, matterId }));
                            entry.collaborateInfo = JSON.stringify({ author, collaborators });
                        }
                    } else {
                        entry.collaborateTks = '';
                        entry.collaborateInfo = '';
                    }
                }
            }));
            if (toSave.length === 0) {
                return;
            }
            let results = await this.rootStore.api.TimeEntry.updateEntries(toSave) as ApiResult<ImmutableTimeEntry>[];
            if (results.some((r) => r.status.failed)) {
                results.forEach((result) => {
                    this.rootStore.snackbarStore.triggerSnackbar(result.status.message);
                });
                return;
            }
            if (results.some((r) => !r.status.failed)) {
                results.forEach((result) => {
                    this.rootStore.snackbarStore.triggerSnackbar('app.snackbar.info.saved');
                });
                this.rootStore.setColloaboratees([]);
            }
            this.removeFromSelected(toSave);

            toSave.forEach((entry) => {
                let idx = this.expandedEntryIds.findIndex((id) => entry.id === id);
                if (idx !== -1) {
                    this.expandedEntryIds = this.expandedEntryIds.filter((expId) => expId !== entry.id!);
                }
            });
            let objects: ImmutableTimeEntry[] = results.map(r => {
                let o = r.object as ImmutableTimeEntry;
                let initialEntry = toSave.find(t => t.id === o.id);
                o.isActCode = initialEntry!.isActCode;
                o.isFfTaskCode = initialEntry!.isFfTaskCode;
                o.isPhaseCode = initialEntry!.isPhaseCode;
                return o;
            });
            let localIdxs: number[] = [];
            this.serverTimeEntries = this.serverTimeEntries.map((entry, idx) => {
                let obj = objects.find((o) => entry.id === o.id);
                if (obj) {
                    localIdxs.push(idx);
                    return Object.assign(new ImmutableTimeEntry(), obj).clone();
                } else {
                    return entry;
                }
            });
            localIdxs.forEach((idx) => {
                console.assert(this.localTimeEntries[idx].id === this.serverTimeEntries[idx].id,
                    'local and server do not match!!!');
                this.localTimeEntries[idx] = this.serverTimeEntries[idx];
            });

            this.localTimeEntries = await this.localTimeEntries.filter((entry) => {
                return DateTime.fromISO(entry.workDateTime) >= this.currentStart &&
                    DateTime.fromISO(entry.workDateTime) <= this.currentEnd
            });
            this.serverTimeEntries = await this.serverTimeEntries.filter((entry) => {
                return DateTime.fromISO(entry.workDateTime) >= this.currentStart &&
                    DateTime.fromISO(entry.workDateTime) <= this.currentEnd
            });
        } catch (e) {
            logger.info('Time Entries, Save Entries failed.\n', e)
        }
    }

    @action removeFromSelected = (tes: ImmutableTimeEntry[]) => {
        tes.forEach((entry, idx) => {
            let selIdx = this.selectedEntryIds.findIndex((id) => id === entry.id);
            if (selIdx > -1) {
                this.selectedEntryIds.splice(selIdx, 1);
            }
        });
    }

    @action postOneEntry = async (id: number) => {
        await this.postEntries([id]);
    }

    setLocalStorageWorkLocation = (entry: ImmutableTimeEntry) => {
        const activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(entry.workDateTime));
        if (entry.workLocaleId) {
            let workLocaleArr = JSON.parse(localStorage.getItem('workLocale') || '[]');
            let activeWorkLocaleItem = workLocaleArr.find((x: LocalStorageWorkLocale) => x.tkId === activeTimeKeeper!.timeKeeperId);
            if (activeWorkLocaleItem) {
                // Having the work Locale different so that item should change else it remains the same
                if (entry.workLocaleId !== activeWorkLocaleItem.workLocaleId) {
                    workLocaleArr.find((v: LocalStorageWorkLocale) =>
                     v.tkId === activeTimeKeeper!.timeKeeperId).workLocaleId = entry.workLocaleId;
                    localStorage.setItem('workLocale', JSON.stringify(workLocaleArr));
                }
            } else {
                // If we dont have existing timekeeper in the local storage we will push that to the local Storage variable
                workLocaleArr.push({workLocaleId: entry.workLocaleId, tkId: activeTimeKeeper!.timeKeeperId});
                localStorage.setItem('workLocale', JSON.stringify(workLocaleArr));
            }
        }
    }

    @action postEntries = async (ids: number[]) => {
        try {
            let postedEntries: ImmutableTimeEntry[] = [];
            let codes: Code[] = [];
            if (this.rootStore.api.Code.getAll) {
                codes = await this.rootStore.api.Code.getAll();
            }
            // ids.forEach((idx) => {
            await Promise.all(ids.map(async (idx) => {
                let entry = this.localTimeEntries.find((e) => e.id === idx);
                let matterEntryType: string = '';
                let matterStatusDesc: string = '';
                let narrativeMinLength;
                let narrativeMaxLength;
                if (entry) {
                    const activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(entry.workDateTime));
                    if (entry.matterId) {
                        if (entry.isActCode === undefined && entry.isPhaseCode === undefined && entry.isFfTaskCode === undefined) {
                            const codeSets = await this.rootStore.api.Code.determineCodeSetFields(entry.matterId!, entry.workDateTime);
                            entry.isPhaseCode = codeSets.isPhaseCode;
                            entry.isActCode = codeSets.isActCode;
                            entry.isFfTaskCode = codeSets.isFfTaskCode;
                        }
                        const matter = await this.rootStore.api.Matter.get(entry.matterId);
                        if (matter) {
                            matterEntryType = matter.entryType;
                            matterStatusDesc = matter.statusDescription;
                            entry.bannedWords = matter.bannedWords;
                            entry.blockBillingWords = matter.blockBillingWords;
                            narrativeMinLength = matter.minLength;
                            narrativeMaxLength = matter.maxLength;
                        }
                    }
                    // todo pass in total duration for given day from store for 24 hour validation. 
                    let vstate = ValidatePost(
                        entry,
                        0,
                        matterStatusDesc,
                        matterEntryType,
                        this.rootStore.appStore.features,
                        activeTimeKeeper,
                        narrativeMinLength,
                        narrativeMaxLength
                    );
                    if (Platform.isElectron()) {
                        if (codes.length > 0) {
                            vstate = await this.validateCodeSets(entry, vstate, codes);
                        }
                    }
                    if (vstate.valid && !this.durVstate.get(entry.id!)) {
                        entry = entry.setPosted();
                        postedEntries.push(entry);
                    } else {
                        if (!vstate.valid) {
                            this.validationState.set(entry.id!, vstate);
                        }
                        this.expandedEntryIds = this.expandedEntryIds.concat([entry.id!]);
                        this.setCodeSetsOnExpand(entry.id!);
                    }
                    this.setLocalStorageWorkLocation(entry);
                }
            }));
            // });
            if (postedEntries.length > 0) {
                await this.saveEntries(postedEntries);
                this.rootStore.api.Timer.updateTimerDurationFromTimeEntry(ids);
                this.rootStore.homeStore.setTimersForDay();
            }
        } catch (e) {
            logger.info('Time Entries, Post Entries failed.\n', e);
        }
    }

    @action validateCodeSets = async(entry: TimeEntry, vstate: ValidationState, codes: Code[]) => {
        if (entry.phaseId) {
            let phaseCode = codes.find(code => {
                return code.id === entry!.phaseId;
            })
            if (phaseCode && phaseCode.deleted) {
                vstate.missing.phase = true;
            }
        }
        if (entry.taskCodeId) {
            let taskCode = codes.find(code => {
                return code.id === entry!.taskCodeId;
            })
            if (taskCode && taskCode.deleted) {
                vstate.missing.task = true;
            }
        }
        if (entry.actCodeId) {
            let actCode = codes.find(code => {
                return code.id === entry!.actCodeId;
            })
            if (actCode && actCode.deleted) {
                vstate.missing.activity = true;
            }
        }
        if (this.rootStore.appStore.features.EpochConfigFlatFeeCodesEnabled) {
            if (entry.ffTaskCodeId) {
                let ffTaskCode = codes.find(code => {
                    return code.id === entry!.ffTaskCodeId;
                })
                if (ffTaskCode && ffTaskCode.deleted) {
                    vstate.missing.ffTask = true;
                }
            }
            if (entry.ffActCodeId) {
                let ffActCode = codes.find(code => {
                    return code.id === entry!.ffActCodeId;
                })
                if (ffActCode && ffActCode.deleted) {
                    vstate.missing.ffAct = true;
                }
            }
        }
        return vstate;
    }

    @action postSelectedEntries = async () => {
        const collabs = this.rootStore.collaboratees;
        if (collabs.length > 0) {
            this.rootStore.confirmCollaborateDialogStore
                .open()
                .then(() => {
                    this.postEntries(this.selectedIdsForCollab);
                    this.selectedEntryIds = [];
                    this.selectedIdsForCollab = [];
                })
                .catch(() => {
                    return;
                });
        } else {
            this.postEntries(this.selectedEntryIds);
            this.selectedEntryIds = [];
            this.selectedIdsForCollab = [];
        }
    }

    @action deleteSelectedEntries = async () => {
        let entries: ImmutableTimeEntry[] = [];
        this.selectedEntryIds.forEach((idx) => {
            let entry = this.localTimeEntries.find((e) => e.id === idx);
            if (entry) {
                entries.push(entry.clone());
            }
        });
        await this.deleteEntries(entries);
        this.selectedEntryIds = [];
        this.selectedIdsForCollab = [];
    }

    @action saveSelectedEntries = async () => {
        let entries: ImmutableTimeEntry[] = [];
        this.selectedEntryIds.forEach((idx) => {
            let entry = this.localTimeEntries.find((e) => e.id === idx);
            if (entry) {
                entries.push(entry.clone());
            }
        });
        await this.saveEntries(entries);
        this.selectedEntryIds = [];
        this.selectedIdsForCollab = [];
    }

    @action changeEntry = (t: ImmutableTimeEntry, newVState?: ValidationState, durVstate?: boolean) => {
        this.validationState.delete(t.id!);
        this.durVstate.delete(t.id!);
        if (newVState) {
            this.validationState.set(t.id!, newVState);
        }
        if (durVstate) {
            this.durVstate.set(t.id!, durVstate);
        }
        setListItem(this.localTimeEntries, t);
    }

    @action saveDirtyEntries = async () => {
        let entries: ImmutableTimeEntry[] = this.localTimeEntries.filter(t => t.dirty);
        const collabs = this.rootStore.collaboratees;
        if (collabs.length > 0) {
            this.rootStore.confirmCollaborateDialogStore
                .open()
                .then(() => {
                    this.saveEntries(entries);
                    this.selectedEntryIds = [];
                    this.selectedIdsForCollab = [];
                })
                .catch(() => {
                    return;
                });
        } else {
            this.saveEntries(entries);
            this.selectedEntryIds = [];
            this.selectedIdsForCollab = [];
        }
        this.rootStore.timeEntryStore.setReferenceTE(null);
    }

    @action splitEntry = async (t: ImmutableTimeEntry) => {
        let entryToSplit: ImmutableTimeEntry = t;
        if (t.dirty) {
            let res = await this.confirm('dialog.confirm.message.unsaved_changes');
            if (res) {
                entryToSplit = this.serverTimeEntries.find(te => te.id! === t.id!)!;
            }
        }
        let entries = await this.rootStore.splitEntryDialogStore.open(entryToSplit) as ImmutableTimeEntry[];
        this.localTimeEntries = removeListItem(this.localTimeEntries, entries[0].id!);
        this.serverTimeEntries = removeListItem(this.serverTimeEntries, entries[0].id!);
        this.selectedEntryIds = [];
        this.selectedIdsForCollab = [];
    }

    @action moveSelectedEntries = async () => {
        let entries: ImmutableTimeEntry[] = await this.getEntriesToChange() as ImmutableTimeEntry[];
        entries.forEach(async (e) => {
            if (e.matterId) {
                const codeSetFlags = await this.rootStore.api.Code.determineCodeSetFields(
                    e.matterId, e.workDateTime
                );
                e.isActCode = codeSetFlags.isActCode;
                e.isPhaseCode = codeSetFlags.isPhaseCode;
                e.isFfTaskCode = codeSetFlags.isFfTaskCode;
            }
            return e;
        })
        let results = await this.rootStore.moveDateDialogStore.open(entries) as ImmutableTimeEntry[];
        await this.saveEntries(results);
        this.setSelectedTimeEntries([]);
    }

    // Merge Entries 
    @action mergeEntries = async () => {
        let entries: ImmutableTimeEntry[] = await this.getEntriesToChange() as ImmutableTimeEntry[];
        let timerChunks = await this.rootStore.api.Timer.getChunksByTimeEntryId(this.selectedEntryIds);
        let timeCastSegments =
            await this.rootStore.api.TimeCast.getTimeCastSegmentsByTimeEntryIds(this.selectedEntryIds);
        let results = await this.rootStore.mergeEntryDialogStore.openMerge(entries) as ImmutableTimeEntry[];
        this.setSelectedTimeEntries([]);
        // Condition is to prevent error when cancelling merge
        if (results.length > 0) {
            // The last item in the results list is the merged Entry as we've sent it in that order
            let mergedEntry = results[results.length - 1];
            timerChunks.forEach(ch => ch.timeEntryId = mergedEntry.id);
            if (timerChunks.length > 0) {
                await this.rootStore.api.Timer.updateChunks(timerChunks);
            }
            timeCastSegments.forEach(seg => seg.associatedTimeEntry = mergedEntry.id);
            let result = timeCastSegments.map(tcs => tcs.id!);
            if (timeCastSegments.length > 0) {
                await this.rootStore.api.TimeCast.updateTimeCastSegments(result, mergedEntry.id!);
            }
            // popping as we are not going to remove them from the results.
            results.pop();
            results.forEach((res) => {
                this.localTimeEntries = this.localTimeEntries.filter((e) => e.id !== res.id);
                this.serverTimeEntries = this.serverTimeEntries.filter((e) => e.id !== res.id);
            });
        }
    }

    @action transferEntries = async () => {
        let entries: ImmutableTimeEntry[] = await this.getEntriesToChange() as ImmutableTimeEntry[];
        let transferedEntry = await this.rootStore.transferEntryDialogStore.openTransfer(entries) as ImmutableTimeEntry;
        if (!transferedEntry) { return; }
        this.setSelectedTimeEntries([]);
    }

    @action getEntriesToChange = async () => {
        let entries: ImmutableTimeEntry[] = [];
        const isDirty: boolean = this.selectedEntryIds.some((idx) => {
            let entry = this.localTimeEntries.find((e) => e.id === idx);
            if (entry && entry.dirty) {
                return true;
            }
            return false;
        })
        if (isDirty) {
            const unsavedConfirmation = await this.confirm('dialog.confirm.message.unsaved_changes');
            if (!unsavedConfirmation) { return; }
        }
        this.selectedEntryIds.forEach((ids) => {
            let entry = this.serverTimeEntries.find((e) => e.id === ids);
            if (entry) {
                entries.push(entry.clone());
            }
        })
        return entries;
    }

    @action copyTimeEntry = async (t: ImmutableTimeEntry) => {
        if (t.isPosted()) {
            let trackedMatter = await this.isTrackedMatter(t.matterId!);
            if (!trackedMatter) {
                const matterLabel = this.rootStore.appStore.features.EpochConfigMatterLabel;
                // tslint:disable-next-line:max-line-length
                this.rootStore.snackbarStore.triggerSnackbar(
                    'list.row.entry.right_click.copy.snackbar.error.untracked_matter',
                    { matterLabel, ns: 'timeentries' }
                );
                return;
            }
        }
        let entry = t.clone();
        entry.id = undefined;
        entry.guid = undefined;
        entry.workLocation = undefined;
        entry.sapStatus = SapStatus.UNSUBMITTED;
        entry.timeEntryType = TimeEntryType.COPY;
        entry.collaborateTks = '';
        entry.collaborateInfo = '';
        await this.loadEntry(entry);
    }

    @action isTrackedMatter = async (id: number) => {
        let matters = await this.rootStore.api.Matter.getTrackedMatters();
        return matters.find((m) => m.id === id);
    }

    @action setSelectedTimeEntries = (ids: number[]) => {
        this.selectedEntryIds = ids;
        this.selectedIdsForCollab = [];
        this.selectedEntryIds.forEach((idx) => {
            let entry = this.localTimeEntries.find((e) => e.id === idx);
            if (entry!.timeEntryType !== TimeEntryType.COLLABORATE && !(entry!.collaborateTks || entry!.collaborateInfo)) {
                this.selectedIdsForCollab.push(idx);
            }
        });
    };

    @action setExpandedTimeEntries = (ids: number[]) => {
        this.expandedEntryIds = ids;
    };

    @action.bound
    async loadAllWorkLocales() {
        this.allWorkLocales = await this.rootStore.api.TimeEntry.searchWorkLocales('');
    }

    @action resetDirty = () => {
        this.localTimeEntries = this.serverTimeEntries.slice().map(e => e.clone());
    }

    @computed get dirty(): boolean {
        return (this.localTimeEntries.filter(e => e.dirty)).length > 0;
    }

    getMetaDataForDay = (date: DateTime): DateMetaData | null | undefined => {
        return this.dateMetaDataMap.get(date.toISODate());
    };

    getTimeEntriesCountForDay = (date: DateTime) => {
        return this.timeEntriesCount.filter((t) => t.workDate === date.toISODate())[0];
    }

    getTimeEntriesCount = async (from: DateTime, to: DateTime) => {
        let getTimeEntriesCountCall = this.rootStore.api.TimeEntry.getTimEntriesCount;
        if (getTimeEntriesCountCall) {
            this.timeEntriesCount = await getTimeEntriesCountCall(from, to);
        }
    }

    @computed get dateMetaDataMap(): Map<string, DateMetaData> {
        let dateMap = new Map<string, DateMetaData>();
        this.localTimeEntries.forEach((te: TimeEntry) => {
            let date = DateTime.fromISO(te.workDateTime!, { zone: 'utc' }).toISODate();
            let meta = dateMap.get(date);
            if (meta === undefined) {
                meta = {
                    totals: {
                        posted: te.isPosted() ? te.duration : 0,
                        billable: te.isBillable() ? te.duration : 0,
                        draft: te.isDraft() ? te.duration : 0,
                        nonbillable: te.isNonBillable() ? te.duration : 0
                    },
                    matterTotals: [{
                        name: te.matterName!,
                        hrs: te.duration
                    }],
                    entries: [te]
                };
            } else {
                meta.entries.push(te);
                const existingMatterTotalEntry = meta.matterTotals.find(mTotal => mTotal.name === te.matterName!);
                if (existingMatterTotalEntry) {
                    existingMatterTotalEntry.hrs += te.duration;
                } else {
                    meta.totals.posted += te.isPosted() ? te.duration : 0;
                    meta.totals.billable += te.isBillable() ? te.duration : 0;
                    meta.totals.draft += te.isDraft() ? te.duration : 0;
                    meta.totals.nonbillable += te.isNonBillable() ? te.duration : 0;
                    meta.matterTotals.push({
                        name: te.matterName!,
                        hrs: te.duration
                    });
                }
            }
            dateMap.set(date, meta);
        });
        return dateMap;
    }

    getAggregateTotalsFor = (dates: DateTime[]): AggregateTotals => {
        let draft = 0;
        let posted = 0;
        let billable = 0;
        let nonbillable = 0;
        let billableDraft = 0;
        let billablePost = 0;

        for (const date of dates) {
            const meta = this.getMetaDataForDay(date);
            if (meta) {
                const entries = meta.entries;
                if (entries && entries.length > 0) {
                    for (const entry of entries) {
                        if (this.rootStore.appStore.features.EpochConfigTKGoalsEnabled) {
                            billableDraft += (entry.isDraft() && entry.isBillable()) ? entry.duration : 0;
                            billablePost += (entry.isPosted() && entry.isBillable()) ? entry.duration : 0;
                        }
                        draft += entry.isDraft() ? entry.duration : 0;
                        posted += entry.isPosted() ? entry.duration : 0;
                        billable += entry.isBillable() ? entry.duration : 0;
                        nonbillable += entry.isNonBillable() ? entry.duration : 0;
                    }
                }
            }
        }

        return {
            draft: ImmutableTimeEntry.durationToHours(draft),
            posted: ImmutableTimeEntry.durationToHours(posted),
            billable: ImmutableTimeEntry.durationToHours(billable),
            nonbillable: ImmutableTimeEntry.durationToHours(nonbillable),
            billableDraft: ImmutableTimeEntry.durationToHours(billableDraft),
            billablePosted: ImmutableTimeEntry.durationToHours(billablePost)
        };
    };

    @computed get workDateNonCollaborated(): string {
        if (this.selectedIdsForCollab.length === 1) {
            const entry = this.localTimeEntries.find(
                t => t.id === this.selectedIdsForCollab[0]
            );
            if (entry) {
                return entry.workDateTime;
            }
        } else {
            let entries: ImmutableTimeEntry[] = [];
            this.selectedIdsForCollab.map((idx) => {
                let entry = this.localTimeEntries.find((e) => e.id === idx);
                if (entry) {
                    entries.push(entry.clone());
                }
            });
            if (entries.length > 0) {
                const sameWorkDates = entries.every(e => e.workDateTime === entries[0].workDateTime);
                if (sameWorkDates) {
                    return entries[0].workDateTime;
                }
            }
        }
        // Return current date on multiple selections
        return DateTime.local().startOf('day').toISO();
    }
}
