import styled, { css } from 'common/styled-components';
import { NavLink } from 'react-router-dom';

export const NavBarContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: ${props => props.theme.secondary.main};
`;

export const IconItem = styled.li`
    overflow: hidden;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-decoration: none;
    font-size: 24px;
    ${props => props.theme.animationsEnabled && 'transition: background-color 0.5s cubic-bezier(0.25, 0.8, 0.25, 1)'};
    color: ${props => props.theme.secondary.contrastText};
`;

export const CustomNavLink = styled(NavLink).attrs({activeClassName: 'active'})`
    flex: 1;
    &.active {
        ${IconItem} {
            color: ${props => props.theme.primary.main};
        }
    }
`;

export const IconText = styled.div`
    padding-top: 3px;
    font-size: 11px;
    white-space: nowrap;
`;

export const IconList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    a {
        text-decoration: none;
        outline: none;
    }
`;