import * as React from 'react';
import * as Styled from './styled';
import { ButtonBase, IconButton, Icon, Input, Paper, Tooltip, CircularProgress } from '@material-ui/core';
import { Add, Delete, Language, Search } from '@material-ui/icons';
import NarrativeStore from 'store/narrative.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { Route } from 'react-router';
import NarrativeForm from './NarrativeForm';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { theme } from 'common/theme';
import { Loading } from '../../containers/TimeEntries/styled';
import { TKConsumer } from 'common/TKProvider';
import { TimeKeeperAssignment } from '../../api/types/types';
import { withTranslation } from 'react-i18next';

interface Props {
    narrativeStore?: NarrativeStore;
    match: {
        url: string;
    };
    // tslint:disable-next-line:no-any
    t: any;
}

interface GGProps extends ListRowProps {
    narrativeStore?: NarrativeStore;
    match: {
        url: string;
    };
    // tslint:disable-next-line:no-any
    t: any;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        narrativeStore: rootStore.narrativeStore
    };
})
@observer
class ListRow extends React.Component<GGProps> {

    render() {
        const { index, key, style, narrativeStore, t } = this.props;
        const {
            narratives,
            selectedNarrative,
            changeSelectedNarrative,
            deleteNarrative
        } = narrativeStore!;
        const curNarrative = narratives[index];

        return (
            <div
                key={key}
                style={{ ...style }}
                onClick={() => changeSelectedNarrative(curNarrative.id!)}
            >
                <ButtonBase style={{width: '100%', height: '100%'}}>
                    {curNarrative ? (
                        <Styled.ListItem
                            style={{height: style.height}}
                            selected={selectedNarrative && selectedNarrative.id === curNarrative.id}
                        >
                            <div style={{ minWidth: '20px' }}>
                                <Styled.Key>{curNarrative.key}</Styled.Key>
                                <Styled.Replacement>{curNarrative.replacement}</Styled.Replacement>
                            </div>
                            <TKConsumer>
                                { (tk: TimeKeeperAssignment) =>
                            <div>
                                {!curNarrative.global ? tk.writable &&
                                    <Tooltip title={t('delete', { ns: 'common' })}>
                                        <IconButton onClick={() => deleteNarrative(curNarrative.id!)}>
                                            <Delete/>
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title={t('list.item.icon.global')}>
                                        <Icon style={{ margin: '12px' }}>
                                            <Language style={{ color: theme.primary.main }}/>
                                        </Icon>
                                    </Tooltip>
                                }
                            </div>}
                            </TKConsumer>
                        </Styled.ListItem>
                    ) : (
                        <Styled.ListItem data-no-narrative={true} />
                    )}
                </ButtonBase>
            </div>
        );
    }
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        narrativeStore: rootStore.narrativeStore
    };
})
@observer
class NarrativesDesktop extends React.Component<Props> {
    isFetching = true;
    listRef = React.createRef<List>();
    componentDidMount() {
        const narrativeStore = this.props.narrativeStore!;
        narrativeStore.fetchAllNarratives(); // load all narratives to check aginst when adding new
        narrativeStore.reset();
        narrativeStore.fetchMoreAvailNarratives();
        this.isFetching = false;
        narrativeStore.newNarrative();
    }

    componentWillUnmount() {
        this.props.narrativeStore!.resetSearchText();
    }

    fetchMoreitems = async (clientHeight: number, scrollHeight: number, scrollTop: number) => {
        let bottom = scrollHeight - scrollTop - clientHeight;
        if (bottom < 400 && !this.isFetching) {
            this.isFetching = true;
            await this.props.narrativeStore!.fetchMoreAvailNarratives();
            this.isFetching = false;
        }
    }

    handleOnChange = (input: string) => {
        this.listRef.current!.scrollToRow(0);
        this.props.narrativeStore!.onSearchChange(input);
    }

    render() {
        const { t, match, narrativeStore } = this.props;
        const {
            narratives,
            newNarrative,
            searchText,
            loading
        } = narrativeStore!;

        return (
            <Styled.Container>
                <Styled.Header>
                    <Styled.SearchContainer>
                        <Search
                            style={{ marginRight: '6px' }}
                        />
                        <Input
                            placeholder={t('header.field.search.placeholder')}
                            fullWidth={true}
                            style={{ maxWidth: '60%' }}
                            value={searchText}
                            onChange={e => this.handleOnChange(e.target.value)}
                        />
                    </Styled.SearchContainer>

                    <Styled.Title variant="h6">
                        {t('header.title')}
                    </Styled.Title>
                    <TKConsumer>
                        {(tk: TimeKeeperAssignment) =>
                            <div style={{ flex: '1' }}>
                                {tk.writable &&
                                    <Tooltip title={t('header.action.add')}>
                                        <Styled.New
                                            onClick={_ => {
                                                newNarrative();
                                            }}
                                        >
                                            <Add />
                                        </Styled.New>
                                    </Tooltip>
                                }
                            </div>}
                    </TKConsumer>
                </Styled.Header>

                <Styled.ContentContainer>
                    <Styled.ListContainer square={true}>
                        <AutoSizer>
                            {({ width, height }) => {
                                return (<List
                                    ref={this.listRef}
                                    height={height}
                                    rowHeight={80}
                                    rowCount={narratives.length}
                                    width={width}
                                    onScroll={({ clientHeight, scrollHeight, scrollTop }: 
                                                    {clientHeight: number; scrollHeight: number; scrollTop: number}) =>
                                        this.fetchMoreitems(clientHeight, scrollHeight, scrollTop)}
                                    rowRenderer={(props) => {
                                        return (
                                            <ListRow
                                                match={match}
                                                t={t}
                                                {...props}
                                            />
                                        );
                                    }}
                                />);
                            }}
                        </AutoSizer>
                    </Styled.ListContainer>
                    <Styled.FormContainer>
                        <Paper style={{ padding: '10px' }}>
                            <Route
                                path={`${match.url}/:id`}
                                component={NarrativeForm}
                            />
                        </Paper>
                    </Styled.FormContainer>
                </Styled.ContentContainer>
                {loading &&
                        <Loading>
                            <div style={{top: '-15%', position: 'relative'}}>
                                <CircularProgress size={100} />
                            </div>
                        </Loading>}
            </Styled.Container>
        );
    }
}

export default withTranslation(['narratives', 'common'])(NarrativesDesktop);