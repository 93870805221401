import * as React from 'react';
import * as Styled from './styled';
import { TimerChunk } from '../../api/types/types';
import { Checkbox } from '@material-ui/core';
import ImmutableTimer from '../../api/immutables/ImmutableTimer';
import NarrativeField from 'components/NarrativeField';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { Features } from '../../api/types/types';
import { InsertDriveFile } from '@material-ui/icons';
import { countryTimeZone } from '../../App';
import { getTimerDurationTxt } from 'util/timer';
import { withTranslation } from 'react-i18next';

interface Props {
    timer: ImmutableTimer;
    segmentChange?: (newSeg: TimerChunk) => void;
    onAddNote?: () => void;
    // tslint:disable-next-line:no-any
    t: any;
}
interface RunningTimerChunkState {
    durTxt: string;
}
class RunningTimerChunkItem extends React.Component<Props, RunningTimerChunkState> {
    interval: NodeJS.Timeout;

    constructor(props: Props) {
        super(props);
        this.state = { durTxt: '' };
        this.interval = setInterval(this.timerTick, 250);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    narrativeChange = () => {
        return ;
        // const newChunk = JSON.parse(JSON.stringify(this.props.timer));
        // newChunk.description = text;
        // if (this.props.segmentChange) {
        //     this.props.segmentChange(newChunk);
        // }
    }

    onClickNarrative = () => {
        if (this.props.onAddNote) {
            this.props.onAddNote();
        }
    }
    timerTick = () => {
        this.setState({ durTxt: getTimerDurationTxt(this.props.timer, undefined, true) });
    }
    onChangeDummy = () => {
        // do nothing on change
    }
    render() {
        const { timer, onAddNote, t } = this.props;
        const countrycode = countryTimeZone.getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        // TODO: Internationalization date
        const locale = (countrycode.country || 'US').toUpperCase() === 'US' ? 'en-US' : 'en-GB';
        return (
            <FeaturesConsumer>
                {(features: Features) =>
                <div style={{ height: 80 }}>
                    <Styled.SegmentPaper submitted={false} hasEntry={false} square={true}>
                        <Styled.Control>
                            <Checkbox
                                onClick={(e) => e.stopPropagation()}
                                disableRipple={true}
                                style={{padding: 0}}
                                disabled={true}
                            />
                        </Styled.Control>
                        <Styled.DateContainer>
                            {(new Date(timer.startedOn!)).toLocaleDateString(locale,
                                {year: 'numeric', month: '2-digit', day: '2-digit'})}
                        </Styled.DateContainer>
                        <Styled.TimeContainer>
                            {this.state.durTxt}
                        </Styled.TimeContainer>
                        <Styled.NarrativeContainer>
                            <div onClick={this.onClickNarrative} style={{width: '100%'}}>
                                <NarrativeField
                                    onChange={this.onChangeDummy}
                                    onBlur={this.narrativeChange}
                                    value={timer.notes!}
                                    placeholder={t('active_chunk.field.narrative.placeholder')}
                                    maxLength={features.EpochConfigNarrativesMaximumChars}
                                    disabled={true}
                                    style={{maxHeight: 52}}
                                />
                            </div>
                        </Styled.NarrativeContainer>
                        <Styled.RunningItemActionContainer>
                            <Styled.CompactIconButton onClick={onAddNote} title={t('active_chunk.icon.tooltip')}>
                                <InsertDriveFile fontSize="small" />
                            </Styled.CompactIconButton>
                        </Styled.RunningItemActionContainer>
                    </Styled.SegmentPaper>
                </div>
                }
            </FeaturesConsumer>
        )
    }
}

export default withTranslation(['timers'])(RunningTimerChunkItem);