import React, { Component } from 'react';
import { Button, Checkbox, Paper, Typography } from '@material-ui/core';
import { RootStore } from 'store/root.store';
import AppStore from 'store/app.store';
import { inject, observer } from 'mobx-react';
import { FlexDiv } from 'common/flex';
import { Platform } from '../../util/Platform';
import logger from '../../logging/logging';
import { Settings } from 'luxon';
import { withTranslation } from 'react-i18next';
import { i18n } from 'i18next';
import { locales } from '../../i18n/config';

interface Props {
    rootStore?: RootStore;
    appStore?: AppStore;
    // tslint:disable-next-line:no-any
    t: any;
    i18n: i18n;
}

interface State {
    shiftKeyPressed: boolean;
    logging: boolean;
}

const SUPPORTED_LANGUAGES = ['en', 'de'];
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        rootStore: rootStore,
        appStore: rootStore.appStore
    };
})

@observer
class GeneralSettings extends Component<Props, State> {
    state: State = {
        shiftKeyPressed: false,
        logging: false
    }
    epochDBFileRef = React.createRef<HTMLInputElement>();
    
    componentDidMount(): void {
        document.addEventListener('keyup', this.keyup);
        document.addEventListener('keydown', this.keydown);
        const enableLogging = localStorage.getItem('enableLogging');
        if (enableLogging) {
            this.setState({ logging: JSON.parse(enableLogging) });
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('keyup', this.keyup);
        document.removeEventListener('keydown', this.keydown);
    }
    
    exportLocalDB = async () => {
        if (this.props.rootStore!.api.Session.exportLocalDBToJSON) {
            this.props.rootStore!.api.Session.exportLocalDBToJSON!();
            this.setState({ shiftKeyPressed: false });
        }
    }

    doUploadDB = () => {
        this.epochDBFileRef.current!.click();
    }

    handleDBUpload = async (selectorFiles: FileList) => {
        let file: File = selectorFiles[0];
        if (file) {
            await this.props.rootStore!.api.Session.importDB!(file);
        } else {
            logger.error('failed importing db, could not get file from input');
        }
    }

    render() {
        const { t } = this.props;
        const disableAnimsValue = this.props.appStore!.disableAnimations;
        const lngs: string[] = Object.keys(this.props.i18n.services.resourceStore.data);

        return (
            <Paper style={{ padding: '15px' }}>
                <FlexDiv style={{ width: '100%' }} direction="column">
                    <Typography variant="headline">
                        {t('tab.general.title')}
                    </Typography>
                    <FlexDiv direction={'row'} style={{alignItems: 'center'}}>
                        <Checkbox
                            onChange={(evt, checked) => this.setAnimationsValue(checked)}
                            checked={disableAnimsValue}
                        />
                        <Typography variant={'subheading'}>{t('tab.general.field.checkbox.animation')}</Typography>
                    </FlexDiv>
                    <FlexDiv direction={'row'} style={{alignItems: 'center'}}>
                        <Checkbox
                            onChange={(evt, checked) => this.setPassiveLogging(checked)}
                            checked={this.state.logging}
                        />
                        <Typography variant={'subheading'}>{t('tab.general.field.checkbox.logging.label')}</Typography>
                    </FlexDiv>
                    <FlexDiv direction={'row'} style={{alignItems: 'center'}}>
                        <Typography variant={'subheading'} style={{padding: '0 17px'}}>{t('tab.general.field.language.label')}</Typography>
                        <select
                            style={{padding: '10px'}}
                            value={this.props.i18n.language}
                            onChange={(e) => {
                                const lang = e.target.value;
                                this.props.i18n.changeLanguage(lang, () => {
                                    Settings.defaultLocale = lang;
                                });
                            }}
                        >
                            {lngs.map((lng: string) => (
                                <option value={lng} key={lng}>
                                    {locales.find(l => l.code === lng)!.name}
                                </option>
                            ))}
                        </select>
                    </FlexDiv>

                    {this.state.shiftKeyPressed && Platform.isElectron() &&
                        <FlexDiv direction={'column'} style={{alignItems: 'flex-start'}}>
                            <Button onClick={this.exportLocalDB}>
                                {t('tab.general.action.desktop.database.download')}
                            </Button>
                        </FlexDiv>
                    }
                    {Platform.isElectron() && process.env.NODE_ENV === 'development' &&
                        <FlexDiv direction={'column'} style={{alignItems: 'flex-start'}}>
                            <input
                                ref={this.epochDBFileRef}
                                style={{ display: 'none' }}
                                accept=".zip"
                                type="file"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleDBUpload(event.target.files!);
                                }}
                            />
                            <Button onClick={this.doUploadDB}>
                                {t('tab.general.action.desktop.database.upload')}
                            </Button>
                        </FlexDiv>
                    }
                </FlexDiv>
            </Paper>
        );
    }

    private setPassiveLogging = async (val: boolean) => {
        let logging = val;
        if (val) {
            logging = await this.props.rootStore!.confirmStore.confirm(
                'tab.general.field.checkbox.logging.dialog.message',
                { ns: 'settings' }
            );
        }
        this.setState({ logging });
        if (logging) {
            logger.silent = false;
        } else {
            logger.silent = true;
        }
        localStorage.setItem('enableLogging', JSON.stringify(logging));
    }

    private setAnimationsValue(checked: boolean) {
        const store = this.props.appStore!;
        store.setDisableAnimations(checked);
    }

    private keydown = (e: KeyboardEvent) => {
        if (e.shiftKey) {
            this.setState({ shiftKeyPressed: true });
        }
    }

    private keyup = (e: KeyboardEvent) => {
        // `e.ctrlKey` for keyup events is false for some reason
        // that's why we skip the check

        if (e.keyCode === 16) {
            this.setState({ shiftKeyPressed: false });
        }
    }
}

export default withTranslation(['settings'])(GeneralSettings);