import styled, { css } from 'common/styled-components';
import { NavLink } from 'react-router-dom';
import { Avatar, IconButton } from '@material-ui/core';
import { keyframes } from 'styled-components';

export const NavBarContainer = styled.div`
    background-color: ${props => props.theme.secondary.main};
`;

export const Logo = styled.li`
    display: flex;
    justify-content: center;
    margin-top: 9px;
    margin-bottom: 9px;
`;

const Img = styled.img`
    overflow: hidden;
    max-width: none;
    height: 60px;
`;

export const EPImg = styled(Img)`
    width: 0;
`;

export const OImg = styled(Img)`

`;

export const CHImg = styled(Img)`
    width: 0;
`;

export const IconItem = styled.li<{selected?: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
    font-size: 30px;
    padding: 10px 0 10px 10px;
    transition: ${props => props.theme.animationsEnabled && 'background-color 0.5s cubic-bezier(0.25, 0.8, 0.25, 1)'};
    color: white;
    margin-bottom: 5px;
    &:hover {
        padding-left: 8px;
        color: ${props => props.theme.primary.main};
        background-color: ${props => props.theme.secondary.light};
        border-left: 2px solid ${props => props.theme.primary.main};
    }
    
    ${props => props.selected ? `border-left: 2px solid ${props.theme.primary.main};` : ''}
    ${props => props.selected ? `color: ${props.theme.primary.main};` : ''}
`;

export const CustomNavLink = styled(IconButton)`
    &.active {
        ${IconItem} {
            color: ${props => props.theme.primary.main};
        }
    }
`;

export const IconText = styled.div`
    margin-left: 22px;
    margin-bottom: auto;
    margin-top: 7px;
    font-size: 16px;
    text-transform: uppercase;
    white-space: nowrap;
    display: flex;
    flex: 1;
`;

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    
    to {
        transform: rotate(360deg);
    }
`;

const spinback = keyframes`
    from {
        transform: rotate(0deg);
    }
    
    to {
        transform: rotate(-360deg);
    }
`;

export const IconList = styled.ul.attrs<{expanded: boolean, animationsEnabled?: boolean}>({})`
    list-style-type: none;
    margin: 0;
    padding: 0;
    
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    a {
        text-decoration: none;
        outline: none;
    }
    width: ${props => props.expanded ? '270px' : '50px'};
    transition: ${props => props.animationsEnabled && 'all 0.5s cubic-bezier(0.55, 0, 0.1, 1)'};
    
    ${EPImg} {
        width: 0;
    }
        
    ${OImg} {
        -webkit-animation: ${props => props.animationsEnabled ? `${spinback} 1.1s cubic-bezier(.55,0,.1,1)` : ''};
        animation: ${props => props.animationsEnabled ? `${spinback} 1.1s cubic-bezier(.55,0,.1,1)` : ''};
    }
    
    ${CHImg} { 
        width: 0px;
    }
    
    ${props => props.expanded && css`
        vertical-align: top;
        ${Logo} {
            padding-left: 9px;
        }
        ${EPImg} {
            width: 73px;
        }

        ${OImg} {
            -webkit-animation: ${props.animationsEnabled ? `${spin} 1.1s cubic-bezier(.55, 0, .1, 1)` : ''};
            animation: ${props.animationsEnabled ? `${spin} 1.1s cubic-bezier(.55, 0, .1, 1)` : ''};
        }

        ${CHImg} { 
            width: 77px;
        }
        
    `}
`;
// tslint:disable-next-line:no-any
export const NavBarStyles = (muitheme: any) => ({
    customBadge: {
        backgroundColor: '#ffffff',
        color: 'black'
    }
})
export const SmallAvatar = styled(Avatar)<{primary?: boolean}>`
    width: 30px !important;
    height: 30px !important;
    font-size: 1rem !important;
    ${props => props.primary ? `
        background: ${props.theme.primary.main} !important;
    ` : ``}
`
export const CollaborateBtn = styled.div`

`;