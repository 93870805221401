import Dexie from 'dexie';
import {
    MatterTypeText,
    Client,
    Code,
    CodeType,
    TimeKeeperAssignment,
    Matter,
    Narrative,
    CustomDictionary, TimerChunk, TimeCastSegment, TimeCastProgram, Setting, TkGoals, TimeEntryType, TkOffice, WorkLocale
} from 'api/types/types'
import ImmutableTimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import relationships from 'dexie-relationships';
import logger from '../../../logging/logging';
type Syncable = TimeEntry
    | Template
    | NarrativeI
    | CustomDictionaryI
    | Timer
    | TimerChunkI
    | TimeCastSegmentI
    | TimeCastProgramI
    | SettingI;

interface Modifications {
    deleted: boolean,
    id: number,
    lastModified: Date,
    serverDirty: boolean
}

function SyncableReadInterceptor(obj: Syncable) {
    if (obj && !obj.id) {
        obj.id = -1 * obj.localId!;
    }
    return obj;
}
function SyncableUpdateInterceptor(
    modifications: Object, primKey: number, obj: Syncable) {
    // never save a negative ID to the server 
    if (obj.id! < 0) {
        return { id: null }
    }
    return;
}
function SyncableNarrativeUpdateInterceptor(
    modifications: Modifications, primKey: number, obj: Syncable) {
    // never save a negative ID to the server
    if (modifications.deleted) {
        return { deleted: 1}
    } else {
        return { deleted: 0}
    }
}
// tslint:disable-next-line:no-any
function SyncableCreateNarrativeInterceptor (primKey: number, obj: any, transaction: any) {
    if (obj.deleted) {
        obj.deleted = 1
    } else {
        obj.deleted = 0;
    }
}
function SyncableReadNarrativeInterceptor (obj: Syncable) {
    if (obj && !obj.id) {
        obj.id = -1 * obj.localId!;
    }
    if (obj.deleted) {
        obj.deleted = true
    } else {
        obj.deleted = false;
    }
    return obj;
}
export class EpochDatabase extends Dexie {
    // master data
    matters: Dexie.Table<MatterI, number>;
    clients: Dexie.Table<Client, number>;
    codes: Dexie.Table<Code, number>;
    codeSetMappings: Dexie.Table<CodeSetMapping, number>;
    timeKeeperAssignments: Dexie.Table<TimeKeeperAssignment, number>;
    matterTkMappings: Dexie.Table<MatterTkMapping, number>;
    matterOfficeMappings: Dexie.Table<MatterOfficeMapping, number>;
    bannedWordsMapping: Dexie.Table<MatterWordsMapping, number>;
    blockBillingWordsMapping: Dexie.Table<MatterWordsMapping, number>;
    tkHours: Dexie.Table<TkHours, number>;
    actionCodes: Dexie.Table<ActionCodeI, number>;
    actionCodeMappings: Dexie.Table<ActionCodeMapping, number>;
    // user data 
    timeEntries: Dexie.Table<TimeEntry, number>;
    templates: Dexie.Table<Template, number>;
    narratives: Dexie.Table<NarrativeI, number>;
    dictionaries: Dexie.Table<CustomDictionaryI, number>;
    timers: Dexie.Table<Timer, number>;
    timerChunks: Dexie.Table<TimerChunkI, number>;
    timecastSegments: Dexie.Table<TimeCastSegmentI, number>;
    timecastPrograms: Dexie.Table<TimeCastProgramI, number>;
    settings: Dexie.Table<SettingI, number>;
    tkGoals: Dexie.Table<TkGoals, number>;
    tkOffices: Dexie.Table<TkOffice, number>;
    workLocales: Dexie.Table<WorkLocale, number>;
    constructor() {
        super('epoch', {addons: [relationships]});
        this.version(1).stores(
            {
                matters: '&id, number, matterGroup, description, clientId, startDate, endDate, typeText, type',
                clients: '&id, name, number',
                codes: '&id, name, description, codeSet, deleted, startDate, endDate',
                codeSetMappings: '&id, matterId, type, codeSet, startDate, endDate',
                timeKeeperAssignments: '&id, timeKeeperId, startDate, endDate, office, name, networkId, deleted',
                matterTkMappings: '&id, matterId, tracked, timeKeeperId',
                matterOfficeMappings: '&id, matterId, office, status',
                tkHours: '&id, timeKeeperId, startDate, endDate',
                actionCodes: '&id, actionCode',
                actionCodeMappings: '&id, actionCodeId, matterGroup, billingRuleCode, inactive',
                timeEntries: '++localId, &id, timeKeeperId, matterId, clientId, workDateTime, serverDirty',
                templates: '++localId, &id, name, serverDirty'
            }
        );
        this.version(2).stores(
            {
                matters: '&id, number, matterGroup, description, clientId, startDate, endDate, typeText, type',
                clients: '&id, name, number',
                codes: '&id, name, description, codeSet, deleted, startDate, endDate',
                codeSetMappings: '&id, matterId, type, codeSet, startDate, endDate',
                timeKeeperAssignments: '&id, timeKeeperId, startDate, endDate, office, name, networkId, deleted',
                matterTkMappings: '&id, matterId, tracked, timeKeeperId',
                matterOfficeMappings: '&id, matterId, office, status',
                tkHours: '&id, timeKeeperId, startDate, endDate',
                actionCodes: '&id, actionCode',
                actionCodeMappings: '&id, actionCodeId, matterGroup, billingRuleCode, inactive',
                timeEntries: '++localId, &id, timeKeeperId, matterId, clientId, workDateTime, serverDirty',
                templates: '++localId, &id, timeKeeperId, name, serverDirty'
            }
        );
        this.version(3).stores(
            {
                matters: '&id, number, matterGroup, description, clientId, startDate, endDate, typeText, type',
                clients: '&id, name, number',
                codes: '&id, name, description, codeSet, deleted, startDate, endDate',
                codeSetMappings: '&id, matterId, type, codeSet, startDate, endDate',
                timeKeeperAssignments: '&id, timeKeeperId, startDate, endDate, office, name, networkId, deleted',
                matterTkMappings: '&id, matterId, tracked, timeKeeperId',
                matterOfficeMappings: '&id, matterId, office, status',
                tkHours: '&id, timeKeeperId, startDate, endDate',
                actionCodes: '&id, actionCode',
                actionCodeMappings: '&id, actionCodeId, matterGroup, billingRuleCode, inactive',
                timeEntries: '++localId, &id, timeKeeperId, matterId, clientId, workDateTime, serverDirty',
                templates: '++localId, &id, timeKeeperId, name, serverDirty',
                narratives: '++localId, &id, deleted, global'
            }
        );
        this.version(4).stores(
            {
                dictionaries: '++localId,&id,deleted'
            }
        );
        this.version(5).stores(
            {
                timers: '++localId,&id,deleted,serverDirty,timeKeeperId,matterId,name,favorite',
                timerChunks: '++localId,&id, deleted, serverDirty, timerId, timeEntryId'
            }
        );
        this.version(6).stores({
            timers: '++localId,&id,deleted,serverDirty,timeKeeperId,matterId,templateId,name,favorite',
        })
        this.version(7).stores({
            timers: '++localId,&id,deleted,serverDirty,active,timeKeeperId,matterId,templateId,name,favorite',
        })
        this.version(8).stores({
            timecastSegments: '++localId,&id,startTime,endTime,data,type,' +
                'associatedTimeEntry,createdBy,createdOn,lastModified,serverDirty,deleted',
            timecastPrograms: '++localId,&id,code,programName' +
                'operatingSystem,architecture,knownExecutable,knownPath,' +
                'submittedBy,createdOn,lastModified,hidden,serverDirty,deleted',
            settings: '++localId,&id,&key,value,deleted,lastModified,' +
                'global,serverDirty'
        })
        this.version(9).stores({
            settings: '++localId,&id,key,value,deleted,lastModified,' +
                'global,serverDirty'
        })
        this.version(10).stores({
            bannedWordsMapping: 'matterId, bannedWords',
            blockBillingWordsMapping: 'matterId, blockBillingWords'
        })
        this.version(11).stores({
            timecastSegments: '++localId,&id,startTime,endTime,data,type,' +
                'associatedTimeEntry,createdBy,createdOn,lastModified,serverDirty,deleted,foreignIdentifier',
        })
        this.version(12).stores({
            timers: '++localId,&id,deleted,serverDirty,timeKeeperId,matterId,name,favorite,lastModified'
        })
        // Improve codeSet queries by using compound index.
        this.version(13).stores({
            codes: '&id, name, description, codeSet, deleted, startDate, endDate, type, [codeSet+type]',
            codeSetMappings: '&id, matterId, type, codeSet, startDate, endDate, [matterId+type]',
        })
        // Introduce TkGoals for FMG.
        this.version(14).stores({
            tkGoals: '&id, timekeeperId, goalYear, april, august, december, february, january, july, june, march, may, november, october, office, september, tkName, yearTotal, [timekeeperId+goalYear]'
        })
        this.version(15).stores({
            timers: '++localId,&id,deleted,serverDirty,timeKeeperId,matterId,name,favorite,lastModified,templateId'
        })
        this.version(16).stores({
            timers: '++localId,&id,deleted,serverDirty,timeKeeperId,matterId->matters.id,name,favorite,lastModified,templateId->templates.id',
            timerChunks: '++localId,&id, deleted, serverDirty, timerId->timers.id, timeEntryId->timeEntries.id',
            matterTkMappings: '&id, matterId, tracked, timeKeeperId, [matterId+timeKeeperId]',
        })
        this.version(17).stores({
            timers: '++localId,id->timerChunks.timerId,deleted,serverDirty,timeKeeperId,matterId->matters.id,name,favorite,lastModified,templateId->templates.id',
            matterTkMappings: '&id, matterId->matters.id, tracked, timeKeeperId, [matterId+timeKeeperId]',
            tkOffices: `&id, office, startDate, endDate, timekeeperId`,
            workLocales: `&id, createdOn, lastModified, localeSearch, sapCode, workCity, workCountry, workRegion`,
            clients: '&id, name, number, clientGroup, maxLength, minLength, parent, searchTerm',
        });
        
        this.matters = this.table('matters')
        this.clients = this.table('clients')
        this.codes = this.table('codes')
        this.codeSetMappings = this.table('codeSetMappings')
        this.timeKeeperAssignments = this.table('timeKeeperAssignments')
        this.matterTkMappings = this.table('matterTkMappings')
        this.matterOfficeMappings = this.table('matterOfficeMappings')
        this.tkHours = this.table('tkHours')
        this.actionCodes = this.table('actionCodes')
        this.actionCodeMappings = this.table('actionCodeMappings')
        this.timeEntries = this.table('timeEntries')
        this.templates = this.table('templates')
        this.narratives = this.table('narratives')
        this.dictionaries = this.table('dictionaries');
        this.timers = this.table('timers');
        this.timerChunks = this.table('timerChunks');
        this.timecastSegments = this.table('timecastSegments');
        this.timecastPrograms = this.table('timecastPrograms');
        this.settings = this.table('settings');
        this.bannedWordsMapping = this.table('bannedWordsMapping');
        this.blockBillingWordsMapping = this.table('blockBillingWordsMapping');
        this.tkGoals = this.table('tkGoals');
        this.workLocales = this.table('workLocales');
        this.registerForeignKeyHooks();
    }
    destroy = async () => {
        return await Promise.all([
            this.matters.clear(),
            this.clients.clear(),
            this.codes.clear(),
            this.codeSetMappings.clear(),
            this.timeKeeperAssignments.clear(),
            this.matterTkMappings.clear(),
            this.matterOfficeMappings.clear(),
            this.tkHours.clear(),
            this.actionCodes.clear(),
            this.actionCodeMappings.clear(),
            this.timeEntries.clear(),
            this.templates.clear(),
            this.dictionaries.clear(),
            this.narratives.clear(),
            this.timers.clear(),
            this.timerChunks.clear(),
            this.timecastSegments.clear(),
            this.timecastPrograms.clear(),
            this.settings.clear(),
            this.bannedWordsMapping.clear(),
            this.blockBillingWordsMapping.clear(),
            this.tkGoals.clear(),
            this.workLocales.clear()
        ])
    }
    registerForeignKeyHooks = () => {
        this.timeEntries.hook('reading', SyncableReadInterceptor);
        this.templates.hook('reading', SyncableReadInterceptor);
        this.narratives.hook('reading', SyncableReadNarrativeInterceptor);
        this.dictionaries.hook('reading', SyncableReadInterceptor);
        this.timers.hook('reading', SyncableReadInterceptor);
        this.timerChunks.hook('reading', SyncableReadInterceptor);
        this.timecastSegments.hook('reading', SyncableReadInterceptor);
        this.timecastPrograms.hook('reading', SyncableReadInterceptor);
        this.settings.hook('reading', SyncableReadInterceptor);
        this.narratives.hook('creating', SyncableCreateNarrativeInterceptor);
        this.narratives.hook('updating', SyncableNarrativeUpdateInterceptor);
        
        // this.timeEntries.hook('updating', SyncableUpdateInterceptor);
        // this.templates.hook('updating', SyncableUpdateInterceptor);
        // this.narratives.hook('updating', SyncableUpdateInterceptor);
        // this.dictionaries.hook('updating', SyncableUpdateInterceptor);
        // this.timers.hook('updating', SyncableUpdateInterceptor);
        // this.timerChunks.hook('updating', SyncableUpdateInterceptor);

    }
}
export interface TimerChunkI extends TimerChunk {
    serverDirty?: boolean;
    localId?: number;
}
export interface CustomDictionaryI extends CustomDictionary {
    serverDirty?: boolean;
    localId?: number;
}
export interface NarrativeI extends Narrative {
    serverDirty?: boolean;
    localId?: number;
}
export interface TimeEntry {
    office?: string;
    officeName?: string;
    duration: number;
    actualDuration: number;
    workDateTime: string;
    sapStatus: SapStatus;
    reference: string | null;
    workLocation?: string | null;
    workLocaleId?: number | null;

    localId?: number;
    id?: number | null;
    timeKeeperId: number;
    matterId: number | null;
    phaseId?: number | null;
    taskCodeId?: number | null;
    actCodeId: number | null;
    ffTaskCodeId: number | null;
    ffActCodeId: number | null;
    narrative: string | null;
    createdOn: string | null;
    deleted: boolean | null;
    lastModified: string | null;
    actionCodeId: number | null;
    actionCode?: string | null;
    bannedWords?: string[] | null;
    matterNumber?: string | null;
    matterName?: string | null;
    matterDescription?: string | null;
    clientId?: number | null;
    clientNumber?: string | null;
    clientName?: string | null;
    matterTypeText?: MatterTypeText | null;
    billingLang?: string | null;
    billingLangText?: string | null;
    timeEntryUnit?: string | null;
    blockBillingWords?: string[] | null;
    entryType?: string | null;
    matterStatus?: string | null;
    matterStatusDesc?: string | null;
    matterStartDate?: string | null;
    matterEndDate?: string | null;
    phaseName?: string | null;
    phaseDesc?: string | null;
    taskCode?: string | null;
    taskCodeDesc?: string | null;
    actCode?: string | null;
    actCodeDesc?: string | null;
    ffTaskCode?: string | null;
    ffTaskCodeDesc?: string | null;
    ffActCode?: string | null;
    ffActCodeDesc?: string | null;
    actionResponse?: string | null;
    timeEntryType?: TimeEntryType;
    collaborateTks?: string;
    collaborateInfo?: string;
    
    serverDirty?: boolean;
    isPhaseCode: boolean;
    isActCode: boolean;
    isFfTaskCode: boolean;
    stopEntry?: boolean;
}
export interface Timer {
    serverDirty?: boolean;
    localId?: number;
    id?: number;
    timeKeeperId: number;
    templateId?: number;
    matterId?: number;
    name: string;
    active?: boolean;
    deleted?: boolean;
    startedOn?: string;
    startedTimezone?: string;
    notes?: string;
    favorite: boolean;
    totalDuration: number;
    pendingDuration: number;
    convertedDuration: number;
    dirty?: boolean;
    lastModified?: string;
    lastActive?: string;
    chunks: TimerChunk[];
}
export interface Template {
    name: string;
    localId?: number;
    id?: number | null;
    timeKeeperId: number;
    matterId: number | null;
    phaseId?: number | null;
    taskCodeId?: number | null;
    actCodeId: number | null;
    ffTaskCodeId: number | null;
    ffActCodeId: number | null;
    narrative: string | null;
    createdOn: string | null;
    deleted: boolean | null;
    lastModified: string | null;
    actionCodeId: number | null;
    actionCode?: string | null;
    bannedWords?: string[] | null;
    matterNumber?: string | null;
    matterName?: string | null;
    matterDescription?: string | null;
    clientId?: number | null;
    clientNumber?: string | null;
    clientName?: string | null;
    matterTypeText?: MatterTypeText | null;
    billingLang?: string | null;
    billingLangText?: string | null;
    timeEntryUnit?: string | null;
    blockBillingWords?: string[] | null;
    entryType?: string | null;
    matterStatus?: string | null;
    matterStatusDesc?: string | null;
    matterStartDate?: string | null;
    matterEndDate?: string | null;
    phaseName?: string | null;
    phaseDesc?: string | null;
    taskCode?: string | null;
    taskCodeDesc?: string | null;
    actCode?: string | null;
    actCodeDesc?: string | null;
    ffTaskCode?: string | null;
    ffTaskCodeDesc?: string | null;
    ffActCode?: string | null;
    ffActCodeDesc?: string | null;
    actionResponse?: string | null;
    
    serverDirty?: boolean;
    isPhaseCode: boolean;
    isActCode: boolean;
    isFfTaskCode: boolean;
    stopEntry?: boolean;
    localUid?: string | null;
}
export interface ActionCodeI {
    id: number;
    actionCode: string;
    language: string;
    actionText: string;
    lastModified: string;
    inactive: boolean;
}
export interface ActionCodeMapping {
    id: number;
    actionCodeId: number;
    actionCode: string;
    matterGroup: string;
    actionResponse: string;
    stopEntry: boolean;
    billingRuleCode: string;
    inactive: boolean;
}
export interface TkHours {
    id: number;
    timeKeeperId: number;
    startDate: string;
    endDate: string;
    dayHours: number;
    weekHours: number;
    monthHours: number;
    yearHours: number;
}
export interface MatterOfficeMapping {
    id: number;
    matterId: number;
    office: string;
    status: string;
    lastModified: string;
}
export interface MatterTkMapping {
    id: number;
    tracked: boolean;
    matterId: number;
    timeKeeperId: number;
    userId: number;
    lastModified: string;
    matter?: MatterI;
}
export interface MatterWordsMapping {
    matterId: number;
    words: string[];
}
export interface CodeSetMapping {
    id: number;
    matterId: number;
    type: CodeType;
    codeSet: string;
    startDate: string;
    endDate: string;
    lastModified: string;
    codes: Code[];
}
export interface MatterI extends Matter {
    billingOffice: string;
    billingPartnerName: string;
    category: string;
}
export interface SettingI extends Setting {
    targetId?: number
    serverDirty?: boolean;
    localId?: number;
}

export interface TimeCastProgramI extends TimeCastProgram {
    localId?: number
    serverDirty?: boolean
}

export interface TimeCastSegmentI extends TimeCastSegment {
    localId?: number
    serverDirty?: boolean
}

// tslint:disable-next-line:no-any
window.onunhandledrejection = function (event: any) {
    let reason = event.reason;
    logger.warn('Unhandled promise rejection:', (reason && (reason.stack || reason)));
};