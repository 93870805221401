import { observable, computed, action, makeObservable } from 'mobx';
import { CustomDictionary } from 'api/types/types';
import BaseStore from 'store/base.store';
import { RootStore } from 'store/root.store';
import { DictionaryManager, Spellcheck } from '@fulcrumgt/react-fulcrum-editor';
import { removeListItem } from 'util/array';

export default class CustomDictionaryStore extends BaseStore {
    @observable customWords: CustomDictionary[] = [];
    @observable serverWords: CustomDictionary[] = [];
    @observable dictionaryManager: DictionaryManager = Spellcheck.globalDictionaryManager;
    @observable searchDictionaryText: string = '';
    @observable noSpace: boolean = false;
    @observable noDup: boolean = false;
    @observable selectedDictionaryIds: number[] = [];
    @observable isNewWord: boolean = false;

    handlerDestructor: () => void;
    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
        this.initializeHandler();
    }
    initializeHandler = () => {
        this.handlerDestructor = this.rootStore.api.CustomDictionary.registerReciever(this.recieveDictionary);
    }

    @action recieveDictionary = (dictionaries: CustomDictionary[]) => {
        dictionaries.forEach(dictionary => {
            if (dictionary.deleted) {
                this.customWords = removeListItem(this.customWords, dictionary.id!);
                this.serverWords = removeListItem(this.customWords, dictionary.id!);
            } else {
                const indx = this.customWords.findIndex(c => c.id! === dictionary.id!);
                if (indx > -1) {
                    this.customWords.splice(indx, 1);
                    this.serverWords.splice(indx, 1);
                }
                this.customWords.push(dictionary);
                this.serverWords.push(dictionary);
            }
        });
    }
    
    @action saveToDictionary = async (entries: CustomDictionary[]) => {
        try {
            let resp = await this.rootStore.api.CustomDictionary.saveUserDictionary(entries);
            let results = resp.map((r) => Object.assign(
                new CustomDictionary(), JSON.parse(JSON.stringify(r.object)))
            );
            results.forEach((d) => {
                this.dictionaryManager.addIgnoreWord(d.dictionary);
                const idx = this.customWords.findIndex(cd => cd.id === d.id);
                if (idx > -1) {
                    this.customWords[idx] = d;
                    this.serverWords[idx] = d;
                } else {
                    this.customWords[0] = d;
                    this.serverWords[0] = d;
                }
            });
            this.rootStore.snackbarStore.triggerSnackbar('tab.dictionary.action.add.snackbar', { ns: 'settings'});
        } catch (e) {
            return;
        } finally {
            this.isNewWord = false;
        }
    }
    
    @action dictionaryChange = async (d: CustomDictionary) => {
        const idx = this.customWords.findIndex(cd => cd.id === d.id);
        if (idx > -1) {
            let i = this.selectedDictionaryIds.findIndex(cd => cd === idx);
            if ( i === -1) {
                this.selectedDictionaryIds.push(idx);
            }
            this.customWords[idx] = d.clone();
            this.customWords[idx].errors.spaceError = false;
            this.customWords[idx].errors.dupError = false;
            this.customWords[idx].errors.blankError = false;
            if (this.customWords[idx].dictionary !== this.serverWords[idx].dictionary) {
                this.customWords[idx].dirty = true;
            } else {
                this.customWords[idx].dirty = false;
            }
        } else {
            this.customWords.unshift(d.clone());
            this.serverWords.unshift(d.clone());
        }
    }
    
    ignoreCustomWords = async () => {
        this.dictionaryManager.ignoredWords.clear();
        this.customWords.forEach((dict) => {
            this.dictionaryManager.addIgnoreWord(dict.dictionary);
        });
    }
    
    @action loadDictionaries = async () => {
        this.customWords = await this.rootStore.api.CustomDictionary.getAllUserDictionaries();
        this.serverWords = this.customWords.slice().map(d => d.clone())
    }
    
    @action setSearchText = (input: string) => {
        this.noSpace = false;
        this.noDup = false;
        this.searchDictionaryText = input;
        this.selectedDictionaryIds = [];
    }
    
    @action deleteWord = async (id: number | null | undefined) => {
        const confirmDelete = await this.confirm('dialog.confirm.message.delete');
        if (!confirmDelete) { return };

        const idx = this.customWords.findIndex(d => d.id === id);
        if (idx > -1) {
            this.dictionaryManager.removeIgnoreWord(this.customWords[idx].dictionary);
            this.customWords[idx].deleted = true;
            await this.rootStore.api.CustomDictionary.saveUserDictionary([this.customWords[idx]]);
            this.customWords.splice(idx, 1);
            this.serverWords.splice(idx, 1);
        }
        this.rootStore.snackbarStore.triggerSnackbar('app.snackbar.info.deleted');
    }
    
    @action addNewWord = async (d: CustomDictionary) => {
        if (this.validateWord(d.dictionary)) {
            this.noSpace = true;
        } else if (this.isWordDuplicate(d.dictionary)) {
            this.noDup = true;
        } else {
            this.noSpace = false;
            this.noDup = false;
            let newWord: CustomDictionary = d.clone();
            newWord.dirty = true;
            this.isNewWord = true;
            this.setSearchText('');
            await this.dictionaryChange(newWord);
            await this.saveToDictionary([newWord]);
        }
    }
    
    // check for duplicates in dirty entries
    isWordDuplicateInDirtyEntries = (text: string, cd: CustomDictionary[]) => {
        let unquieEntries: CustomDictionary[] =  [];
        let entries = cd.filter((d) => {
            if (unquieEntries.find((word) => (word.dictionary === d.dictionary && !d.deleted))) {
                return true;
            }
            unquieEntries.push(d)
            return false;
        })
        let dup = entries.find(d => (d.dictionary === text && !d.deleted));
        return dup ? true : false;
    }

    @action saveDirtyEntries = async () => {
        let dirtyEntries = this.customWords.filter(w => w.dirty);
        dirtyEntries.forEach((t) => {
            t.errors.spaceError = this.validateWord(t.dictionary);
            // initially making dupError to false
            t.errors.dupError = false;
            if (dirtyEntries.length >  1) {
                t.errors.dupError = this.isWordDuplicateInDirtyEntries(t.dictionary, dirtyEntries);
            }
            t.errors.dupError = t.errors.dupError ? t.errors.dupError : this.isWordDuplicate(t.dictionary);

            t.errors.blankError = t.dictionary.length === 0;
            let idx = this.customWords.findIndex(c => c.id === t.id);
            if (idx > -1) {
                this.customWords.splice(idx, 1, t);
            }
        });
        
        let shouldISave = this.customWords.some(
            d => d.errors.dupError || d.errors.spaceError || d.errors.blankError)
        if (!shouldISave) {
            await this.saveToDictionary(dirtyEntries);
        } else {
            return;
        }
        this.searchDictionaryText = ''
    }
     
    @action resetWords = () => {
        this.customWords = this.serverWords.slice().map(d => d.clone());
    }
    
    @action validateWord = (text: string): boolean => {
        return text.indexOf(' ') > -1;
    }
    
    @action isWordDuplicate = (text: string): boolean => {
        let dup = this.serverWords.find(d => d.dictionary === text);
        return dup ? true : false;
    }
    
    @computed get filteredDictionaries(): CustomDictionary[] {
        let filteredWords: CustomDictionary[] = [];
        let dictionaryWords: CustomDictionary[] = [];
        if (!this.searchDictionaryText || this.searchDictionaryText.length === 0) {
            filteredWords =  this.customWords;
        }
        filteredWords = this.customWords.filter(
            (d) => d.dictionary.toLowerCase().includes(this.searchDictionaryText.toLowerCase())
        );
        if (this.dirty && this.searchDictionaryText.length > 0) {
            this.selectedDictionaryIds.forEach( (idx) => {
                const i = dictionaryWords.findIndex(cd => cd.id === idx);
                if (i === -1) {
                    dictionaryWords.push(this.customWords[idx])
                }
            })
            dictionaryWords = dictionaryWords.concat(filteredWords);
        }

        return filteredWords.sort((w1, w2) => w1.dictionary.localeCompare(w2.dictionary));
    };
    
    @computed get dirty(): boolean {
        return !this.isNewWord && this.customWords.some(c => c.dirty);
    }
    
}