import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const LoginContainer = styled.div`
    background: #444;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;
export const LoginBox = styled(Paper)`
    max-width: 450px;
    padding: 25px;
    width: 100%;
    margin: 0 auto;
`;
export const LogoContainer = styled.div`
    text-align: center;
    padding: 20px;
`;
export const ButtonContainer = styled.div`
    margin-top: 20px;
`;
export const FieldCont = styled.div`
padding: 10px;
`;

export const InvalidCred = styled.div`
    text-align: center;
`;

export const LoginProgressDiv = styled.div`
    margin-top: 20px;
    text-align: center;
`;