import { createMuiTheme } from '@material-ui/core';

export interface ThemeInterface {
  primary: {
    main: string
    light: string
    dark: string
    contrastText: string
  };
  secondary: {
    main: string
    light: string
    dark: string
    contrastText: string
  };
  approved: string;
  rejected: string;
  textShadow: string;
}

export default interface FullInterface extends ThemeInterface {
  crateItem: ThemeInterface;
  white: {
    main: string
    light: string
    dark: string
    contrastText: string
  };
  summary: {
    green: string
    orange: string
    blue: string
    red: string
  },
    chart: {
      main: {
        green: string,
        orange: string,
        gray: string
      },
      hover: {
        green: string,
        orange: string,
        gray: string
      }
    }
  animationsEnabled: boolean;
  error: string;
}

export const theme: FullInterface = {
  primary: {
    main: '#ff9800',
    light: '#ffc947',
    dark: '#c66900',
    contrastText: '#424242',
  },
  secondary: {
    main: '#444',
    light: '#757575',
    dark: '#1b1b1b',
    contrastText: '#fff',
  }, 
  white: {
    main: '#f3f3f3',
    light: '#fff',
    dark: '#d4d4d4',
    contrastText: '#111',
  },
  animationsEnabled: true, 
  summary: {
    green: '#4bb361',
    orange: '#ff9800',
    blue: '#5e7bff',
    red: '#ff5c5c'
  },
  chart: {
    main: {
      green: '#4bb361',
      orange: '#ff9800', 
      gray: '#898989'
    },
    hover: {
      green: '#7cd37c', 
      orange: '#ffc97a', 
      gray: '#dddddd'
    }
  },
  approved: '#2db346',
  rejected: '#cd5e68',
  textShadow: 'none',
  crateItem: {
    primary: {
      main: '#444',
      light: 'none',
      dark: '#102027',
      contrastText: '#444',
    },
    secondary: {
      main: '#88498b',
      light: '#df78ef',
      dark: '#790e8b',
      contrastText: '#ffffff',
    },
    approved: '#2db346',
    rejected: '#cd5e68',
    textShadow: 'none'
  },
  error: '#ef4e4e'
};

export const muitheme = createMuiTheme({
  overrides: {
      MuiCollapse: {
          entered: {
              height: 'auto',
              overflow: 'visible'
          }
      }
  },
  palette: {
      primary: {
          light: '#ffc947',
          main: '#ff9800',
          dark: '#c66900',
          contrastText: '#000',
      },
      secondary: {
          light: '#6d6d6d',
          main: '#424242',
          dark: '#1b1b1b',
          contrastText: '#fff',
      }
  },
  typography: {
      h6: {
          fontWeight: 300,
      },
      useNextVariants: true,
  },
});