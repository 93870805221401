import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Settings } from 'luxon';
import { resources } from './locales/resources';

const options = {
  order: [
    'cookie',
    'localStorage',
    'sessionStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain'
  ],
  lookupLocalStorage: 'lng'
};

export const locales: {
  code: string;
  name: string;
}[] = [
  { code: 'ar', name: 'Arabic' },
  { code: 'en', name: 'English (US)' },
  { code: 'en_GB', name: 'English (UK)' },
  { code: 'de', name: 'Deutsch' },
  { code: 'fr', name: 'French' },
  { code: 'it', name: 'Italian' },
  { code: 'ja', name: 'Japanese' }
];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    fallbackLng: 'en',
    supportedLngs: locales.map((l) => l.code),
    ns: [
      'common',
      'home',
      'timeentries',
      'matters',
      'templates',
      'timers',
      'narratives',
      'settings'
    ],
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources,
    react: {
      useSuspense: false
    }
  });

Settings.defaultLocale = i18n.language;

export default i18n;