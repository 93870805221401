import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import TimerStore from 'store/Timer/timer.store';
import * as Styled from './styled';
import {
    Add, Close,
    Delete, FlipToBack, FlipToFront,
    IndeterminateCheckBox,
    Refresh, Save,
    Search,
    SelectAll,
    Stop,
    ViewList
} from '@material-ui/icons';
import {
    Checkbox, CircularProgress,
    FormControlLabel,
    IconButton,
    Input,
    List,
    Popover,
    TextField,
    Tooltip,
} from '@material-ui/core';
import ImmutableTimer from '../../api/immutables/ImmutableTimer';
import { Platform } from '../../util/Platform';
import TimerNewDialogStore from 'store/Timer/timer.new.dialog.store';
import { Loading } from '../../containers/TimeEntries/styled';
import { getTimerDurationTxt } from 'util/timer';
import { withTranslation } from 'react-i18next';

interface Props {
    rootStore?: RootStore;
    timerStore?: TimerStore;
    timerDialogStore?: TimerNewDialogStore;
    // tslint:disable-next-line:no-any
    t: any;
}
interface TimerLinkItemProps {
    timer: ImmutableTimer;
    onClick?: () => void;
    selected?: boolean;
    onStart: () => void;
    onStop: () => void;
    loadingId?: number;
    includeSubmitted: boolean;
}
interface TimerLinkItemState {
    durTxt: string;
}
class TimerLinkItem extends React.Component<TimerLinkItemProps, TimerLinkItemState> {
    interval: NodeJS.Timeout;

    constructor(props: TimerLinkItemProps) {
        super(props);
        this.state = { durTxt: '' };
        if (props.timer.active) {
            this.interval = setInterval(this.timerTick, 250);
        }
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    getSnapshotBeforeUpdate(prevProps: TimerLinkItemProps) {
        if (prevProps.timer.active === this.props.timer.active) {
            return null;
        }
        clearInterval(this.interval);
        if (this.props.timer.active) {
            this.interval = setInterval(this.timerTick, 250);
        }
        return null;
    }
    timerTick = () => {
        const { timer, includeSubmitted } = this.props;
        this.setState({ durTxt: getTimerDurationTxt(timer, includeSubmitted) });
    }
    render() {
        const { 
            timer,
            onStart,
            onStop,
            onClick,
            selected,
            includeSubmitted
        } = this.props;
        const durText = timer.active ? this.state.durTxt : getTimerDurationTxt(timer, includeSubmitted);
        return (
            <Styled.PopOutListItem selected={selected}>
                {!timer.active ?
                <Styled.PlayBtn onClick={onStart}/> :
                <Stop color="primary" onClick={onStop}/>
                }
                <Styled.TextContainer onClick={onClick}>
                    <Styled.TimerText title={timer.name} selected={selected} noWrap={true}>
                        {timer.name}
                    </Styled.TimerText>
                    <Styled.DurText variant={'subtitle2'}>{durText}</Styled.DurText>
                </Styled.TextContainer>
            </Styled.PopOutListItem>
        );
    }
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        rootStore: rootStore,
        timerStore: rootStore.timerStore,
        timerDialogStore: rootStore.newTimerDialogStore
    };
})
@observer
class TimerPopOut extends React.Component<Props> {
    state = {
        anchorEl: null
    }
    componentWillMount() {
        this.props.timerStore!.loadTimers();
        this.props.timerStore!.onMountTimerPopOut();
    }
    handleClose = () => {
        this.setState({ anchorEl: null });
    }
    newTimerPopOver = (evt: React.MouseEvent<HTMLElement>) => {
        this.props.timerDialogStore!.setName('');
        this.props.timerDialogStore!.start = false;
        this.setState({ anchorEl: evt.currentTarget });
    }
    saveNewTimer = async () => {
        let store = this.props.timerDialogStore!;

        if (store.saving) {
            return;
        }
        store.saving = true;

        let saved = await store.saveNew();
        store.saving = false;
        if (!store.validation.name) {
            this.setState({ anchorEl: null });
        }
        if (saved) {
            this.props.timerStore!.recieveTimers(saved);
        }
    }
    render() {
        const { t, timerStore, timerDialogStore } = this.props;
        const {
            filteredTimersPopout,
            selectTimerInPopOut,
            selectedTimerIds,
            deleteSelectedTimers,
            startTimer,
            stopTimer,
            loadingTimer,
            toggleSelectAllTimers,
            newTimer,
            searchTextInPopOut,
            onSearchChangePopOut,
            assignSelectedFromPopOut,
            resetSelected,
            minimize,
            pinToTop,
            unpin,
            closePopOut,
            popOutOnTop,
            toggleSelectAll,
            newTimerFromPopOut,
            popOutLoader,
            timerLoading,
            playAndStopLoading,
            activeTimerUpdateSteps,
            rootStore
        } = timerStore!;
        const {
            name,
            setName, start,
            toggleStart,
            validation,
        } = timerDialogStore!;
        const includeSubmitted = rootStore.appStore.features.EpochConfigTimeSegmentsSubmittedVisible;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const disableTimers = timerLoading || !!loadingTimer || !!playAndStopLoading || !!activeTimerUpdateSteps;
        return (
            <Styled.Container>
                {Platform.isElectron() &&
                <Styled.TitleBar>
                    <Tooltip title={t('popout_window.desktop_titlebar.minimize')}>
                        <Styled.FrameBtn onClick={minimize}>
                            <IndeterminateCheckBox/>
                        </Styled.FrameBtn>
                    </Tooltip>
                    {!popOutOnTop ? 
                    <Tooltip title={t('popout_window.desktop_titlebar.pin_top')}>
                        <Styled.FrameBtn onClick={pinToTop}>
                            <FlipToFront/>
                        </Styled.FrameBtn>
                    </Tooltip> :
                    <Tooltip title={t('popout_window.desktop_titlebar.unpin')}>
                        <Styled.FrameBtn onClick={unpin}>
                            <FlipToBack/>
                        </Styled.FrameBtn>
                    </Tooltip>
                    }
                    <Tooltip title={t('close', { ns: 'common' })}>
                        <Styled.FrameBtn onClick={closePopOut}>
                            <Close/>
                        </Styled.FrameBtn>
                    </Tooltip>
                </Styled.TitleBar>
                }
                <Styled.Header disabled={disableTimers}>
                    <Styled.SearchContainer>
                        <Search
                            style={{ marginRight: '6px' }}
                        />
                        <Input
                            placeholder={t('popout_window.field.search.placeholder')}
                            fullWidth={true}
                            value={searchTextInPopOut}
                            onChange={e => onSearchChangePopOut(e.target.value)}
                        />
                    </Styled.SearchContainer>
                    <Styled.ActionContainer>
                        <Styled.ActionButton>
                            <Tooltip title={t(`${toggleSelectAll ? 'deselect_all' : 'select_all'}`, { ns: 'common' })}>
                                <IconButton onClick={toggleSelectAllTimers}>
                                    <SelectAll/>
                                </IconButton>
                            </Tooltip>
                        </Styled.ActionButton>
                        <Styled.ActionButton>
                            <Tooltip title={t('popout_window.action.assign')}>
                                <IconButton onClick={assignSelectedFromPopOut} disabled={selectedTimerIds.size <= 0}>
                                    <ViewList/>
                                </IconButton>
                            </Tooltip>
                        </Styled.ActionButton>
                        <Styled.ActionButton>
                            <Tooltip title={t('popout_window.action.reset')}>
                                <IconButton onClick={resetSelected} disabled={selectedTimerIds.size <= 0}>
                                    <Refresh/>
                                </IconButton>
                            </Tooltip>
                        </Styled.ActionButton>
                        <Styled.ActionButton>
                            <Tooltip title={t('popout_window.action.delete')}>
                                <IconButton onClick={deleteSelectedTimers} disabled={selectedTimerIds.size <= 0}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </Styled.ActionButton>
                        <Styled.ActionButton>
                            <Tooltip title={t('popout_window.action.add.tooltip')}>
                                <IconButton onClick={Platform.isElectron() ? newTimerFromPopOut : this.newTimerPopOver}>
                                    <Add/>
                                </IconButton>
                            </Tooltip>
                        </Styled.ActionButton>
                        <Popover
                            id="timer-pop"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={this.handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Styled.PopOverContainer>
                                <TextField
                                    value={name}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setName(evt.target.value)}
                                    label={t('popout_window.action.add.popover.field.name')}
                                    fullWidth={true}
                                    error={!!validation.name}
                                    helperText={validation.name ? t(validation.name) : undefined}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={start}
                                            onChange={toggleStart}
                                        />
                                    }
                                    label={t('popout_window.action.add.popover.field.start_checkbox')}
                                />
                                <Styled.SaveBtn>
                                    <Tooltip title={t('save', { ns: 'common' })}>
                                        <IconButton 
                                            style={{padding: 0, width: '24px', height: '24px'}} 
                                            onClick={this.saveNewTimer}
                                        >
                                            <Save/>
                                        </IconButton>
                                    </Tooltip>
                                </Styled.SaveBtn>
                            </Styled.PopOverContainer>
                        </Popover>                       
                    </Styled.ActionContainer>
                </Styled.Header>
                <Styled.MainWrapper direction="column" flex={1} disabled={disableTimers}>
                    <Styled.ListWrapper>
                        <List>
                            {filteredTimersPopout.map((timer: ImmutableTimer) => {
                                return (
                                    <TimerLinkItem
                                        key={timer.id!.toString()}
                                        timer={timer}
                                        onClick={() => selectTimerInPopOut(timer.id)}
                                        onStart={startTimer(timer)}
                                        onStop={stopTimer(timer.id!, true)}
                                        loadingId={loadingTimer}
                                        includeSubmitted={includeSubmitted}
                                        selected={selectedTimerIds.has(timer.id)}
                                    />
                                );
                            })}
                        </List>
                    </Styled.ListWrapper>
                </Styled.MainWrapper>
                {popOutLoader && 
                <Loading>
                    <div style={{top: '-15%', position: 'relative'}}>
                        <CircularProgress size={100}/>
                    </div>
                </Loading>}
            </Styled.Container>
        )
    }
}

export default withTranslation(['timers', 'common'])(TimerPopOut);