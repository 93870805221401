// TODO: REMOVE NEXT LINE
// http://10.1.2.194:8080
import * as React from 'react';
import HomeDesktop from './Home.desktop';
import HomeMobile from './Home.mobile';
import { Desktop, Mobile } from 'common/responsive';

export default class Home extends React.Component {
    render() {
        return (
            <>
                <Desktop>
                    <HomeDesktop />
                </Desktop>
                <Mobile>
                    <HomeMobile />
                </Mobile>
            </>
        );
    }
}