import styled from 'styled-components';
import { FlexDiv } from 'common/flex';

export const NewEntryNavButton = styled.div`
    margin-left: auto;
    margin-right: 0;
`;

export const Container = styled(FlexDiv)`
    height: 100%;
    margin-bottom: 50px;
`;

export const ViewChanger = styled.div`
    height: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);    
    text-align: center;
    background: ${props => props.theme.white.main}
`;

export const CalendarContainer = styled.div<{shrink?: boolean}>`
    height: ${props => props.shrink ? '90px' : '325px'};
    transition: ${props => props.theme.animationsEnabled && 'all 0.3s cubic-bezier(0.55, 0, 0.1, 1)'};
    overflow: hidden;
`;

export const DayBox = styled.div<{enabled?: boolean}>`
    color: ${props => !props.enabled && 'rgba(0, 0, 0, 0.5)'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const DateContainer = styled.div<{selected?: boolean, today?: boolean}>`
    height: 37px;
    width: 37px;
    border-radius: 50%;
    padding-top: 9px;
    text-align: center;
    background: ${props => props.today && props.theme.primary.light};
    background: ${props => props.selected && props.theme.secondary.light};
    color: ${props => props.selected && props.theme.white.main}
    transition:  ${props => props.theme.animationsEnabled && 'all 0.3s cubic-bezier(0.55, 0, 0.1, 1)'};
`;

export const TimeEntryIndicator = styled.div`
    margin-top: 1px;
    height: 4px;
    width: 4px;
    background-color: ${props => props.theme.primary.main}
    border-radius: 50%;
`;