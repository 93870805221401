import * as React from 'react';
import * as Styled from './styled';
import { Add, Delete, Save, Search, Undo } from '@material-ui/icons';
import { Button, IconButton, Input, Tooltip, FormHelperText } from '@material-ui/core';
import CustomDictionaryStore from 'store/customDictionary.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { CustomDictionary, TimeKeeperAssignment } from 'api/types/types';
import { FabContainer } from '../Home/styled.desktop';
import { DirtyIndicator } from 'components/TimeEntryPanel/styled';
import { TKConsumer } from 'common/TKProvider';
import { withTranslation } from 'react-i18next';
interface Props {
    customDictionaryStore?: CustomDictionaryStore;
    // tslint:disable-next-line:no-any
    t: any;
}

class CustomDictionaryForm extends React.Component<{
    onEdit: (d: CustomDictionary) => void,
    dictionary: CustomDictionary,
    onDelete: () => void,
    // tslint:disable-next-line:no-any
    t: any
}> {
    changeDictionary(text: string) {
        let newWord: CustomDictionary = this.props.dictionary.clone();
        newWord.dictionary = text;
        this.props.onEdit(newWord);
    }
    render() {
        const {
            onEdit,
            dictionary,
            onDelete,
            t
        } = this.props;
        return(
            <div>
                <TKConsumer>
                    { (tk: TimeKeeperAssignment) =>
                <Styled.FormMainContent>
                    <Input
                        value={dictionary.dictionary}
                        onChange={e => this.changeDictionary(e.target.value)}
                        style={{
                            width: '95%'
                        }}
                        disabled={false || !tk.writable}
                    />
                    <DirtyIndicator> {dictionary.dirty ? '*' : ''} </DirtyIndicator>
                    { tk.writable &&
                        <Tooltip title={t('delete', { ns: 'common' })}>
                            <IconButton onClick={onDelete}>
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    }
                </Styled.FormMainContent>}
                </TKConsumer>
                {(dictionary.errors.spaceError || dictionary.errors.dupError ||
                    dictionary.errors.blankError) &&
                <FormHelperText error={true}>
                    {dictionary.errors.spaceError ? t('tab.dictionary.validation.space') :
                        dictionary.errors.dupError ? t('tab.dictionary.validation.duplicate') :
                            dictionary.errors.blankError ? t('tab.dictionary.validation.required') : ''}
                </FormHelperText>}
            </div>
        );
    }
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        customDictionaryStore: rootStore.customDictionaryStore
    };
})

@observer
class CustomDictionaryContainer extends React.Component<Props> {
    searchFieldRef = React.createRef<HTMLInputElement>();
    
    componentDidMount() {
        this.props.customDictionaryStore!.setSearchText('');
    }

    addNewWord = async () => {
        if (this.props.customDictionaryStore) {
            await this.props.customDictionaryStore.addNewWord(
                new CustomDictionary(this.props.customDictionaryStore.searchDictionaryText)
            );
        }
        if (this.searchFieldRef.current) {
            this.searchFieldRef.current.focus();
        } 
    }
    render() {
        const { t } = this.props;
        const {
            filteredDictionaries,
            searchDictionaryText,
            setSearchText,
            dictionaryChange,
            deleteWord,
            dirty,
            saveDirtyEntries,
            resetWords,
            noSpace,
            noDup
        } = this.props.customDictionaryStore!
        return(
            <Styled.Container>
                <TKConsumer>
                    { (tk: TimeKeeperAssignment) =>
                    <div>
                        <Search
                            style={{ marginRight: '6px' }}
                        />
                        <Input
                            placeholder={tk.writable ?
                                t('tab.dictionary.field.search.placeholder.tk_writable')
                            :
                                t('tab.dictionary.field.search.placeholder.tk_not_writable')
                            }
                            fullWidth={true}
                            style={{ maxWidth: '50%' }}
                            value={searchDictionaryText}
                            onChange={e => setSearchText(e.target.value)}
                            autoFocus={true}
                            inputRef={this.searchFieldRef}
                        />
                        {tk.writable && searchDictionaryText.length > 0 &&
                        <>
                            <Tooltip title={t('tab.dictionary.action.add.tooltip')}>
                            <IconButton onClick={this.addNewWord}>
                                <Add/>
                            </IconButton>
                            </Tooltip>
                        </>
                        }
                    </div>}
                </TKConsumer>
                {(noSpace || noDup) &&
                <FormHelperText error={true}>
                    {noSpace ? t('tab.dictionary.validation.space') : ''}
                    {noDup ? t('tab.dictionary.validation.duplicate') : ''}
                </FormHelperText>
                }
                {/* FIXME: Use a virtual scroller */}
                <Styled.MainContent> 
                    {
                        filteredDictionaries.map((d: CustomDictionary) => 
                            <CustomDictionaryForm 
                                key={d.id as number} 
                                onEdit={dictionaryChange}
                                dictionary={d as CustomDictionary}
                                onDelete={() => deleteWord(d.id)}
                                t={t}
                            />
                        )
                    }
                </Styled.MainContent>
                <FabContainer onScreen={dirty}>
                    <Button
                        onClick={saveDirtyEntries}
                        variant="extendedFab"
                        aria-label={t('save', { ns: 'common' })}
                    >
                        <Save/>
                        {t('tab.dictionary.fab_container.action.save')}
                    </Button>
                    <Button
                        onClick={resetWords}
                        variant="extendedFab"
                        color="secondary"
                        aria-label={t('clear', { ns: 'common' })}
                    >
                        <Undo />
                        {t('clear', { ns: 'common' })}
                    </Button>
                </FabContainer>
            </Styled.Container>
        )
    }
}

export default withTranslation(['settings', 'common'])(CustomDictionaryContainer);