import styled from 'styled-components'

export const NarrativeCodeRows = styled.div`
    display: flex;
    padding: 10px;
    min-height: 56px;
    align-items: center;
    
    &:nth-child(2n+1) {
        background-color: rgba(0,0,0,0.1);
    }
    
    & > div:nth-child(1) {
        width: 80px;
    }
    
    & > div:nth-child(2) {
        width: 160px;
    }
    
    & > div:nth-child(3) {
        flex: 1;
        width: 460px;
    }
    
    & > div:nth-child(3):last-child {
        width: 540px;
    }

    & > div:nth-child(4) {
        display: none;
        width: 80px;
    }
    
    &:hover > div:nth-child(4) {
        display: initial;
    }
    
    & > div {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    
    &:hover > div {
        white-space: initial;
        text-overflow: initial;
    }
`;

export const NarrativeCodeHeader = styled(NarrativeCodeRows)`
    font-weight: 500;
`;