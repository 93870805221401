import styled from 'styled-components';

export const Day = styled.div<{align?: 'default' | 'center'}>`
    flex: 1;
    text-align: ${props => props.align === 'center' ? 'center' : 'left'};
    padding: 8px;
`;

export const WeekHeaderContainer = styled.div`
    background: ${props => props.theme.white.main};
    display: flex;
    align-items: stretch;
    min-height: 36px;
    font-size: 14px;
`;

export const Calendar = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: 1fr;
    flex: 1;
`;

export const DayBox = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 10px 8px 8px 10px;
    &:hover {
        background: rgba(0, 0, 0, 0.02);
    }
`;

export const DayBoxWrapper = styled.div`
    & * {
        height: 100%;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;