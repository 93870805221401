import * as React from 'react';
import * as Styled from './styled';
import { IconButton, TextField } from '@material-ui/core';
import { Cancel, Delete, Save, StarRate } from '@material-ui/icons';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import { ApiConsumer } from 'common/ApiProvider';
import RootAPI from 'api/interfaces/RootAPI';
import { Client, Code, Matter, ActionCode, Features, CodeSetFlags } from 'api/types/types';
import Template from 'api/immutables/ImmutableTemplate';
import { FlexDiv } from 'common/flex';
import NarrativeField from 'components/NarrativeField';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { parseCode } from '../../util/utils';
import { LinearProgressBar } from 'components/LoadingSpinner/LinearProgressBar';
import { TKConsumer } from 'common/TKProvider';
import { TimeKeeperAssignment } from '../../api/types/types';
import { withTranslation } from 'react-i18next';
import rootStore from 'store/root.store';
import { i18n } from 'i18next';
export interface TemplateFormValidation {
    name?: string | boolean
    matter?: string | boolean
    narrative?: string | boolean
}

interface Props {
    template: Template;
    onChange: (t: Template, n?: TemplateFormValidation) => void;
    onSave?: (t: Template) => void;
    onCancel?: (t: Template) => void;
    onDelete?: (t: Template) => void;
    validation: TemplateFormValidation;
    maxNarrativeLength?: number;
    actionCodesRequired?: boolean;
    disabled?: boolean;
    // tslint:disable-next-line:no-any
    t: any;
    i18n: i18n;
}
interface State {
    setFieldLoader: boolean;
}
export const MatterItemFormatter = (m: Matter) => (
    <FlexDiv style={{alignItems: 'center'}} direction="row" fillContainer={true}>
        <div 
            style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'block',
                textOverflow: 'ellipsis' 
            }}
        >
            {`${m.number} - ${m.name}`}
        </div>
    <FlexDiv flex={1}/>
    {/* tslint:disable-next-line:no-any */}
    {(m as any).tracked && <StarRate />}
    </FlexDiv>);
class TemplateForm extends React.Component<Props, State> {
    state = {
        setFieldLoader: false
    }
    setName = (name?: string | null) => {
        let template = this.props.template.setName(name ? name : '');
        let newValidation = Object.assign({}, this.props.validation);
        newValidation.name = false;
        this.props.onChange(template, newValidation);
    }

    setMatter = (api: RootAPI) => async (m?: Matter | null) => {
        let template = this.props.template;
        let newVState = Object.assign({}, this.props.validation);
        if (m) {
            this.setState({
                setFieldLoader: true
            });
            this.props.onChange(template.setMatter(m));
            if (!m.tracked) {
                await api.Matter.track([m.id]);
            }
            const codeSetFlags: CodeSetFlags = await api.Code.determineCodeSetFields(m.id, '');
            m.isPhaseCode = codeSetFlags.isPhaseCode;
            m.isFfTaskCode = codeSetFlags.isFfTaskCode;
            m.isActCode = codeSetFlags.isActCode;

            if (codeSetFlags.phases.length === 1) {
                let phase = codeSetFlags.phases[0];
                template = template.setPhase(phase);
                let tasks = await api.Code.getTaskCodes(phase.id, '', '');
                if (tasks.length === 1) {
                    let task = tasks[0];
                    template = template.setTask(task);
                }
            }

            if (codeSetFlags.ffTasks.length === 1) {
                let ffTask = codeSetFlags.ffTasks[0];
                template = template.setFFTask(ffTask);
                let ffActs = await api.Code.getFFActCodes(ffTask.id, '', '');
                if (ffActs.length === 1) {
                    let ffAct = ffActs[0];
                    template = template.setFFAct(ffAct);
                }
            }

            if (codeSetFlags.activities.length === 1) {
                let act = codeSetFlags.activities[0];
                template = template.setAct(act);
            }
        }

        newVState.matter = false;
        this.props.onChange(template.setMatter(m), newVState);
        this.setState({
            setFieldLoader: false
        });
        if (m) {
            if (template.name.trim() === '') {
                this.setName(m!.name);
            }
        }
    }
    setNarrative = (text: string) => {
        let template = this.props.template.setNarrative(text);
        let newValidation = Object.assign({}, this.props.validation);
        newValidation.narrative = false;
        this.props.onChange(template, newValidation);
    }
    
    setClient = (c?: Client | null) => {
        let template = this.props.template.setClient(c);
        this.props.onChange(template);
    }
    
    setPhase = (api: RootAPI) => async (c?: Code | null) => {
        let template = this.props.template.setPhase(c);
        if (c) {
            let tasks = await api.Code.getTaskCodes(c.id, '', '');
            if (tasks.length === 1) {
                let task = tasks[0];
                template = template.setTask(task);
            }
        }
        this.props.onChange(template);
    }
    
    setTask = (c?: Code | null) => {
        let template = this.props.template.setTask(c);
        this.props.onChange(template);
    }
    setAct = (c?: Code | null) => {
        let template = this.props.template.setAct(c);
        this.props.onChange(template);
    }
    
    setFFTask = (api: RootAPI) => async (c?: Code | null) => {
        let template = this.props.template.setFFTask(c);
        if (c) {
            let ffActs = await api.Code.getFFActCodes(c.id, '', '');
            if (ffActs.length === 1) {
                let ffAct = ffActs[0];
                template = template.setFFAct(ffAct);
            }
        }
        this.props.onChange(template);
        return template;
    }
    
    setFFAct = (c?: Code | null) => {
        let template = this.props.template.setFFAct(c);
        this.props.onChange(template);
    }
    
    searchCodes = 
        (fetchFunc: (matterId: number) => Promise<Code[]>) => 
        async (searchText: string): Promise<Code[]> => {
            let entry = this.props.template;
            if (!entry.matterId) {
                return [];
            }
            let codes = await fetchFunc(entry.matterId);
            return codes.filter((c) => c.name.includes(searchText));
    }
    setActionCode = (c?: ActionCode | null) => {
        let entry = this.props.template.setActionCode(c);
        let newValidation = Object.assign({}, this.props.validation);
        newValidation.narrative = false;
        this.props.onChange(entry, newValidation);
    }
    fetchPhaseCodes = (api: RootAPI) => async (template: Template, searchText?: string) => {
        let results = await api.Code.getPhaseCodes(template.matterId!, '', searchText!);
        if (results.length === 1) {
            let result = JSON.parse(JSON.stringify(results[0]));
            await this.setPhase(result);
        } 
        return results;
    }
    fetchTaskCodes = (api: RootAPI) => async (template: Template, searchText?: string) => {
        let results = await api.Code.getTaskCodes(template.phaseId!, '', searchText);
        if (results.length === 1) {
            let result = JSON.parse(JSON.stringify(results[0]));
            await this.setTask(result);
        } 
        return results;
    }
    fetchActivityCodes = (api: RootAPI) => async (template: Template, searchText?: string) => {
        let results = await api.Code.getActivityCodes(template.matterId!, '', searchText);
        if (results.length === 1) {
            let result = JSON.parse(JSON.stringify(results[0]));
            this.setAct(result);
        }
        return results;
    }
    fetchFFTaskCodes = (api: RootAPI) => async (template: Template, searchText?: string) => {
        let results = await api.Code.getFFTaskCodes(template.matterId!, '', searchText);
        if (results.length === 1) {
            let result = JSON.parse(JSON.stringify(results[0]));
            this.setFFTask(result);
        }
        return results;
    }
    fetchFFActCodes = (api: RootAPI) => async (template: Template, searchText?: string) => {
        let results = await api.Code.getFFActCodes(template.ffTaskCodeId!, '', searchText);
        if (results.length === 1) {
            let result = JSON.parse(JSON.stringify(results[0]));
            this.setFFAct(result);
        }
        return results;
    }
    fetchMatters = (api: RootAPI) => async (entry: Template, features: Features, searchText: string) => {
        let  results: Matter[] = []
        if (features.EpochConfigTrackedMatterClientsEnabled) { 
            return await api.Matter.searchMatters(
                searchText, true, entry.clientId!,
            );
        } else {
            results = await api.Matter.searchMatters(
                searchText, true, entry.clientId!
            );
            if (results.length === 0) {
                results = await api.Matter.getAvailableMatters(
                    searchText,
                    false,
                    entry.clientId!,
                    '',
                    0,
                    50
                )
            }
        }
        return results;
    }
    changeNarrativeLanguage = (key: string) => {
        let entry = this.props.template.setBillingLanguage(key);
        this.props.onChange(entry);
    }
    render() {
        const {
            template,
            onSave,
            onCancel,
            onDelete,
            validation,
            disabled,
            maxNarrativeLength,
            t
        } = this.props;
        const { setFieldLoader } = this.state;
        const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;
        return (
            <FeaturesConsumer>
                { (features: Features) =>
                <ApiConsumer>
                    { (api: RootAPI) =>
                <TKConsumer>
                    { (tk: TimeKeeperAssignment) =>
                <div>
                    <div title={template.name ? template.name : ''}>
                        <TextField
                            label={t('template_details.field.name')}
                            fullWidth={true}
                            inputProps={{
                                maxlength: 100
                            }}
                            style={{marginBottom: '14px'}}
                            value={template.name ? template.name : ''}
                            onChange={e => this.setName(e.target.value)}
                            error={!!validation.name}
                            helperText={!!validation.name ? t(validation.name) : undefined}
                            disabled={disabled || !tk.writable}
                        />
                    </div>
                    <Styled.Fields>
                        <AutoCompleteField 
                            label={t('template_details.field.client')}
                            fetch={api.Client.searchClients}
                            currentItem={template.client}
                            getItemText={(c: Client) => parseCode(c.number, c.name)}
                            clearable={true}
                            onClear={() => this.setClient(null)}
                            onSelect={this.setClient}
                            disabled={disabled || !tk.writable}
                        />
                        <div>
                            <AutoCompleteField  
                                label={t('template_details.field.matter', { matterLabel })}
                                fetch={(searchText: string) =>
                                    this.fetchMatters(api)(template, features, searchText)
                                }
                                currentItem={template.matter}
                                clearable={true}
                                disabled={disabled || !tk.writable}
                                onClear={() => this.setMatter(api)(null)}
                                getItemText={(m: Matter) => parseCode(m.number, m.name)}
                                formatItem={MatterItemFormatter}
                                onSelect={this.setMatter(api)}
                                errored={!!validation.matter}
                                errorMessage={!!validation.matter ? t(validation.matter, { matterLabel }) : undefined}
                                tooltip={(m: Matter) => m.description}
                            />
                            {setFieldLoader && <LinearProgressBar color={'primary'} progressBar={50}/>}
                        </div>
                        {/** TODO figure out when to display phase and tasks sets */}
                        {<>
                            {template.isPhaseCode && 
                            <>
                            <AutoCompleteField
                                label={t('template_details.field.phase')}
                                currentItem={template.phase}
                                clearable={true}
                                disabled={!template.matterId || disabled || !tk.writable}
                                onClear={() => this.setPhase(api)(null)}
                                onSelect={this.setPhase(api)}
                                fetch={(searchText ) => 
                                    this.fetchPhaseCodes(api)(template, searchText)}
                                getItemText={(c: Code) => parseCode(c.name, c.description)}
                                
                            />
                            <AutoCompleteField
                                label={t('template_details.field.task')}
                                currentItem={template.task}
                                clearable={true}
                                onSelect={this.setTask}
                                onClear={() => this.setTask(null)}
                                fetch={(searchText ) => 
                                    this.fetchTaskCodes(api)(template, searchText)}
                                disabled={template.phase === null || disabled || !tk.writable}
                                getItemText={(c: Code) => parseCode(c.name, c.description)}
                            />    
                            </>}   
                            {template.isActCode && 
                            <AutoCompleteField
                                label={t('template_details.field.activity')}
                                currentItem={template.activity}
                                clearable={true}
                                onClear={() => this.setAct(null)}
                                onSelect={this.setAct}
                                fetch={(searchText ) => 
                                    this.fetchActivityCodes(api)(template, searchText)}
                                disabled={!template.matterId || disabled || !tk.writable}
                                getItemText={(c: Code) => parseCode(c.name, c.description)}
                            />}
                        </>}
                        {features.EpochConfigFlatFeeCodesEnabled &&
                            template.isFfTaskCode && <>
                            <AutoCompleteField
                                label={t('template_details.field.ff_task')}
                                currentItem={template.ffTask}
                                clearable={true}
                                onClear={() => this.setFFTask(api)(null)}
                                onSelect={this.setFFTask(api)}
                                disabled={!template.matterId || disabled || !tk.writable}
                                fetch={(searchText ) => 
                                    this.fetchFFTaskCodes(api)(template, searchText)}
                                getItemText={(c: Code) => parseCode(c.name, c.description)}
                            />
                            <AutoCompleteField
                                label={t('template_details.field.ff_activity')}
                                currentItem={template.ffActivity}
                                disabled={!template.ffTaskCodeId || disabled || !tk.writable}
                                clearable={true}
                                onClear={() => this.setFFAct(null)}
                                onSelect={this.setFFAct}
                                fetch={(searchText ) => 
                                    this.fetchFFActCodes(api)(template, searchText)}
                                getItemText={(c: Code) => parseCode(c.name, c.description)}
                            />
                        </>}
                        {features.EpochConfigActionCodesRequired && 
                            <AutoCompleteField
                                label={t('template_details.field.action_code')}
                                currentItem={template.actionCodeObj}
                                disabled={false || !tk.writable}
                                clearable={true}
                                onClear={() => this.setActionCode(null)}
                                onSelect={this.setActionCode}
                                fetch={(searchText ) => 
                                    api.Code.getActionCodes(
                                        undefined,
                                        searchText,
                                        )}
                                getItemText={
                                    (code: ActionCode) => 
                                    `${code.actionCode}${code.actionText ? ` - ${code.actionText}` : ''}`}
                            />}
                    </Styled.Fields>
                    <NarrativeField
                        placeholder={template.billingLangText ?
                            `${t('template_details.field.narrative')} (${template.billingLangText})` : t('template_details.field.narrative')}
                        value={template.narrative}
                        onChange={this.setNarrative}
                        style={{marginTop: '14px', maxHeight: '300px', overflow: 'auto'}}
                        helperText={!!validation.narrative ? t(validation.narrative, { maxNarrativeLength }) : undefined}
                        error={!!validation.narrative}
                        disabled={disabled || !tk.writable}
                        maxLength={features.EpochConfigNarrativesMaximumChars}
                        dictionaryKey={template.matterBillingLangDictionary}
                        changeLanguage={this.changeNarrativeLanguage}
                    />
                    { tk.writable &&
                    <Styled.Actions>
                        {onSave && <IconButton onClick={() => onSave(template)}><Save /></IconButton>}
                        {onCancel && <IconButton onClick={() => onCancel(template)}><Cancel /></IconButton>}
                        {onDelete && <IconButton onClick={() => onDelete(template)}><Delete /></IconButton>}
                    </Styled.Actions>
                    }
                </div>}
                </TKConsumer>}
                </ApiConsumer>
                }
            </FeaturesConsumer>
        );
    }
}

export default withTranslation(['templates'])(TemplateForm);