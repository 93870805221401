import React, { Component, CSSProperties } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { Checkbox, IconButton } from '@material-ui/core';
import { MatterListType } from '../../api/types/types';
import { RemoveFromQueue } from '@material-ui/icons';
import * as Styled from './styled';
import { observer } from 'mobx-react';
import { FlexDiv } from 'common/flex';
import { TKConsumer } from 'common/TKProvider';
import { TimeKeeperAssignment } from 'api/types/types';
import { useTranslation } from 'react-i18next';
interface Props {
    matters: MatterListType[]; // List to fetch
    fetchMore: () => void;
    isTrackedList: boolean;
    onSelect?: (ids: number[]) => void;
    selected: number[];
    untrack: (ids: number[]) => void;
}

const MatterPanel: React.FC<{
    matter: MatterListType,
    isTrackedList: boolean,
    onSelect?: (selected: boolean) => void,
    selected?: boolean,
    untrack: (ids: number[]) => void,
    style: CSSProperties
}> = ({ matter, isTrackedList, onSelect, selected, untrack, style }) => {
    const { t } = useTranslation(['matters']);
    const select = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onSelect!(checked);
    }

    return (
        <TKConsumer>
        { (tk: TimeKeeperAssignment) =>
        <div style={style}>
            <Styled.MatterListItem key={matter.id as number}>
                {(!isTrackedList && !matter.isHeader) &&
                <Checkbox
                    checked={selected}
                    onChange={select}
                    disabled={!tk.writable}
                />}
                <Styled.MatterLabel
                    isHeader={matter.isHeader}
                    title={matter.description}
                >
                    {matter.number + ' - ' + matter.name}
                </Styled.MatterLabel>
                {(isTrackedList && !matter.isHeader && tk.writable) &&
                <IconButton title={t('left_panel.list.row.icon.tooltip')} onClick={() => untrack([matter.id])}>
                    <RemoveFromQueue/>
                </IconButton>}
            </Styled.MatterListItem>
        </div>
        }
        </TKConsumer>
    );
}

@observer
export default class MatterList extends Component<Props> {
    fetchMoreitems = (cHeight: number, scrollHeight: number, scrollTop: number) => {
        let bottom = scrollHeight - (scrollTop + cHeight);
        if (bottom <= 10 && bottom !== 0) {
            this.props.fetchMore();
        }
    }
    selectMatters = (mat: MatterListType) => () => {
        let selectedArr = this.props.selected.slice();
        if (selectedArr.includes(mat.id)) {
            selectedArr = selectedArr.filter((s) => s !== mat.id);
        } else {
            selectedArr.push(mat.id);
        }
        this.props.onSelect!(selectedArr);
    }

    render() {
        const { matters, isTrackedList, selected, untrack } = this.props!;
        return (
            <FlexDiv direction="column" flex={1}>
                <AutoSizer>
                    {({ width, height }) => {
                        return (
                            <div>
                                <List
                                    height={height}
                                    rowHeight={48}
                                    rowCount={matters.length}
                                    width={width}
                                    onScroll={({ clientHeight, scrollHeight, scrollTop }: 
                                                   {clientHeight: number; scrollHeight: number; scrollTop: number}) =>
                                        this.fetchMoreitems(clientHeight, scrollHeight, scrollTop)}
                                    rowRenderer={({style, key, index}) => {
                                        return (
                                            <MatterPanel
                                                matter={matters[index]}
                                                isTrackedList={isTrackedList}
                                                onSelect={this.selectMatters(matters[index])}
                                                selected={selected.includes(matters[index].id as number)}
                                                untrack={untrack}
                                                style={style} 
                                                key={key}
                                            />
                                           
                                        );
                                    }}
                                />
                            </div>
                        );
                    }}
                </AutoSizer>
            </FlexDiv>
        );
    }
}
