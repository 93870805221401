import BaseStore from 'store/base.store';
import { observable, action, makeObservable } from 'mobx';
import ImmutableTemplate from '../../api/immutables/ImmutableTemplate';
import { RootStore } from 'store/root.store';
import { Matter } from '../../api/types/types';

export default class TimerPanelItemStore extends BaseStore {
    @observable timerTemplate: Map<number, ImmutableTemplate> = new Map();
    @observable timerMatter: Map<number, Matter | null> = new Map();
    @observable expandedTimerPanelIds: number[] = [];

    constructor(root: RootStore) {
        super(root);
        makeObservable(this);
    }

    async setTimerTemplate(id: number) {
        this.timerTemplate.set(id, await this.rootStore.api.Template.getTemplate(id));
    }
    
    async setTimerMatter(id: number) {
        this.timerMatter.set(id, await this.rootStore.api.Matter.get(id));
    }
    
    @action setExpendedTimerPanels = (ids: number[]) => {
        this.expandedTimerPanelIds = ids;
    }

    @action.bound
    reset() {
        this.timerTemplate = new Map();
        this.timerMatter = new Map();
        this.expandedTimerPanelIds = [];
    }
}