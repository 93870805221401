import { t } from 'i18next';
import { DateTime } from 'luxon';
import { action, computed, observable, makeObservable } from 'mobx';
import { debounce } from 'typescript-debounce-decorator';
import BaseStore from './base.store';
import { RootStore } from './root.store';
import { ApiResult } from 'api/util';
import { TimeEntryType } from 'api/types/types';
import { ValidatePost, ValidateSave, ValidationState } from 'api/immutables/validators';
import ImmutableTimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import { getDayNames } from 'components/Calendar/Calendar';

export interface EntriesGroup {
    id: number;
    sampleEntry: ImmutableTimeEntry;
    mon?: ImmutableTimeEntry;
    tue?: ImmutableTimeEntry;
    wed?: ImmutableTimeEntry;
    thu?: ImmutableTimeEntry;
    fri?: ImmutableTimeEntry;
    sat?: ImmutableTimeEntry;
    sun?: ImmutableTimeEntry;
}

export interface GroupedEntries {
    [key: string]: ImmutableTimeEntry[]
}

interface DateRange {
    startDate: DateTime;
    endDate: DateTime;
}

// tslint:disable-next-line:no-any
const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) { previous[group] = []; }
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

export default class GridViewStore extends BaseStore {
    @observable timeEntryGroups: EntriesGroup[] = [];
    @observable totalPerDayMap: Map<string, number> = new Map();
    @observable loading: boolean = false;
    @observable validationStates: Map<number, ValidationState | undefined> = new Map();
    // post entry with zero duration will add relevant day to array
    @observable validationStatesDurations: Map<number, string[]> = new Map();
    @observable expandAll: boolean = false;
    groupIdCount: number = 0;
    dateRange: DateRange;
    failedEntries: ImmutableTimeEntry[] = [];

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
        this.debounceInit = this.debounceInit.bind(this);
    }

    @action.bound
    toggleExpandAll() {
        this.expandAll = !this.expandAll;
    }

    @action.bound
    init(props: DateRange) {
        this.setDateRange(props);
        this.reset();
        this.debounceInit();
    }

    setDateRange = (dates: DateRange) => {
        this.dateRange = dates;
    }

    reset = async() => {
        this.expandAll = false;
        this.groupIdCount = 0;
        this.timeEntryGroups = [];
        this.totalPerDayMap.clear();
        this.validationStates.clear();
        this.validationStatesDurations.clear();
    }

    @debounce(500, {leading: false})
    async debounceInit() {
        const serverEntries = await this.loadEntries();
        const entries = this.removeDuplicateEntries(serverEntries, this.failedEntries);
        const groupedEntries: GroupedEntries = groupBy(entries, (e: ImmutableTimeEntry) =>
            `${e.matterId} ${e.narrative} ${e.phaseId} ${e.taskCodeId} ${e.actCodeId} ${e.ffTaskCodeId} ${e.ffActCodeId} ${e.actionCodeId}`
        );
        await this.generateGroups(groupedEntries);
        for (const group of this.timeEntryGroups) {
            this.validateEntryToPost(group.id, group.sampleEntry.clone().setDuration(60 * 60));
        }
        this.setTotal();
        if (this.timeEntryGroups.length === 0) {
            this.addGroup();
        }
        this.failedEntries = [];
    }

    loadEntries = async() => {
        this.loading = true;
        let serverEntries: ImmutableTimeEntry[] = await this.rootStore.api.TimeEntry.getEntries(
            this.dateRange.startDate,
            this.dateRange.endDate,
            this.rootStore.api.Session.currentTimeKeeper!
        );
        this.loading = false;
        return serverEntries;
    }

    removeDuplicateEntries = (serverEntries: ImmutableTimeEntry[], localEntries: ImmutableTimeEntry[]) => {
        const localIds = localEntries.map(loaclEntry => loaclEntry.id);
        return localEntries.concat(serverEntries.filter(entry => !localIds.includes(entry.id)));
    }

    // create groups from server entries
    generateGroups = async (groupedEntries: GroupedEntries) => {
        for (const group of Object.values(groupedEntries)) {
            let sampleEntry: ImmutableTimeEntry = (await this.createNewEntry());
            sampleEntry = this.setCommonFields(sampleEntry, group[0]);
            let newGroup = {
                id: ++this.groupIdCount,
                sampleEntry
            };
            group.map((entry: ImmutableTimeEntry) => {
                const key: string = DateTime.fromISO(entry.workDateTime).weekdayShort;
                if (newGroup[key]) {
                    let duplicateGroup = {
                        id: ++this.groupIdCount,
                        sampleEntry
                    };
                    duplicateGroup[key] = entry;
                    this.timeEntryGroups.push(duplicateGroup);
                } else {
                    newGroup[key] = entry;
                }
            });
            this.timeEntryGroups.push(newGroup);
        }
    }

    setCommonFields(newEntry: ImmutableTimeEntry, entry: ImmutableTimeEntry) {
        return newEntry
            .setClient(entry.client)
            .setMatter(entry.matter)
            .setCodeSet(entry.selectedCodeSetTemplate)
            .setPhase(entry.phase)
            .setTask(entry.task)
            .setAct(entry.activity)
            .setFFTask(entry.ffTask)
            .setFFAct(entry.ffActivity)
            .setActionCode(entry.actionCodeObj)
            .setNarrative(entry.narrative || '');
    }

    setTotal = () => {
        this.weekDays.forEach((day: string) => {
            this.setTotalPerDay(day);
        });
    }

    @action.bound
    setTotalPerDay(day: string) {
        const newTotal = this.timeEntryGroups.reduce(
            (result: number, group: EntriesGroup) => result + ((group[day] ? group[day].duration : 0) / (60 * 60))
        , 0);
        this.totalPerDayMap.set(day, newTotal);
    }

    @action.bound
    async addGroup() {
        let sampleEntry = await this.createNewEntry();
        let newGroup = {
            id: ++this.groupIdCount,
            sampleEntry
        };
        this.timeEntryGroups.push(newGroup);
    }

    async createNewEntry(dayIndex: number = 0) {
        const newEntry: ImmutableTimeEntry = await this.rootStore.timeEntryStore.setEntryFieldValues(
            this.utcStartDate.plus({ 'day': dayIndex }),
            new ImmutableTimeEntry()
        );
        newEntry.sapStatus = SapStatus.NEW;
        newEntry.timeEntryType = TimeEntryType.GRID;
        return newEntry;
    }

    @action.bound
    async deleteGroup(groupId: number) {
        if (!await this.confirm('dialog.confirm.message.delete')) { return };
        const draftEntries: ImmutableTimeEntry[] = [];
        let remainingEntries: ImmutableTimeEntry[] = [];
        const { id, sampleEntry, ...obj } = (this.timeEntryGroups.find(group => group.id === groupId) as EntriesGroup);
        const entries  = Object.values(obj);

        entries.forEach((entry: ImmutableTimeEntry) => {
            if (entry.sapStatus === SapStatus.UNSUBMITTED) {
                let newEntry = entry.clone();
                newEntry.deleted = true;
                draftEntries.push(newEntry);
            } else if (entry.isPosted()) {
                // posted entries cannot be deleted
                remainingEntries.push(entry);
            }
        });

        this.loading = true;
        let results: ApiResult<ImmutableTimeEntry>[] = [];
        if (draftEntries.length > 0) {
            results = await this.rootStore.api.TimeEntry.updateEntries(draftEntries);
        }
        const entriesFailDeletion = results.filter(re => re.status.failed).map(re => re.object);
        remainingEntries = remainingEntries.concat(entriesFailDeletion);
        let groupObj = {};
        remainingEntries.forEach(entry => {
            const [key, value] = Object.entries(obj).filter(([k, ent]) => entry.id && entry.id === ent.id)[0];
            groupObj[key] = value;
        });
        if (remainingEntries.length === 0) {
            // remove group from list if all contained entries deleted
            this.timeEntryGroups = this.timeEntryGroups.filter(group => group.id !== id);
            this.rootStore.snackbarStore.triggerSnackbar(
                'view.grid.item.action.delete.snackbar.group', { ns: 'home' }
            );
        } else {
            const newGroup = { id, sampleEntry, ...groupObj };
            const index = this.timeEntryGroups.findIndex(g => g.id === id);
            this.timeEntryGroups.splice(index, 1, newGroup);
            this.rootStore.snackbarStore.triggerSnackbar(
                'view.grid.item.action.delete.snackbar.draft', { ns: 'home' }
            );
        }
        this.loading = false;
        this.setTotal();
        // add empty group if list is empty
        if (this.timeEntryGroups.length === 0) {
            this.addGroup();
        }
    }

    @action.bound
    onChange(groupId: number, entry: ImmutableTimeEntry, newVState?: ValidationState, day: string = 'sampleEntry') {
        const index = this.timeEntryGroups.findIndex(g => g.id === groupId);
        const newGroup = this.timeEntryGroups[index];
        if (day === 'sampleEntry') {
            this.validationStates.set(groupId, newVState);
            const { id, ...obj } = newGroup;
            Object.keys(obj).forEach(key => {
                newGroup[key] = this.setCommonFields(obj[key], entry);
            });
        } else {
            newGroup[day] = entry;
        }
        this.timeEntryGroups.splice(index, 1, newGroup);
    }

    extractEntries = async(isSave?: boolean) => {
        const sapStatus = [SapStatus.NEW, SapStatus.UNSUBMITTED];
        let timeEntries: ImmutableTimeEntry[] = [];
        for (const group of this.timeEntryGroups) {
            const { id, sampleEntry, ...obj } = group;
            if (isSave) { // land from save action
                const groupDirtyEntries = Object.values(obj).filter(entry => entry.dirty);
                if (groupDirtyEntries.length > 0) {
                    // if group data complete then loop on entries of the group
                    if (await this.validateEntryToSave(id, sampleEntry)) {
                        timeEntries = timeEntries.concat(groupDirtyEntries.map(entry => {
                            entry.sapStatus = SapStatus.UNSUBMITTED;
                            return entry;
                        }));
                    } else { // group data missing then keep track of entries
                        this.failedEntries = this.failedEntries.concat(groupDirtyEntries);
                    }
                }
            } else { // land from post action
                const toPost = Object.values(obj).filter(entry => sapStatus.includes(entry.sapStatus));
                if (toPost.length > 0) {
                    for (const entry of toPost) {
                        // TODO: use Promse.All to improve performance
                        if (await this.validateEntryToPost(id, entry)) {
                            timeEntries.push(entry.setPosted());
                        } else {
                            this.failedEntries.push(entry);
                        }
                    }
                }
            }
        }
        return timeEntries;
    }

    async validateEntryToSave(groupId: number, entry: ImmutableTimeEntry) {
        const { features, getActiveTimeKeeperForDate } = this.rootStore.appStore;
        let narrativeMinLength;
        let narrativeMaxLength;
        if (entry.matterId) {
            const matter = await this.rootStore.api.Matter.get(entry!.matterId);
            if (matter) {
                narrativeMinLength = matter.minLength;
                narrativeMaxLength = matter.maxLength;
            }
        }
        const validationState = ValidateSave(
            entry, 
            0,
            features,
            getActiveTimeKeeperForDate(DateTime.fromISO(entry.workDateTime)),
            narrativeMinLength,
            narrativeMaxLength
        );
        if (!features.EpochConfigValidateRestrictionsOnSave || !narrativeMinLength) {
            validationState.narrativeLength = !entry.narrative || entry.narrative.length < features.EpochConfigNarrativesMinimumChars;
        }
        validationState.missing.matter = !entry.matter;
        if (!validationState.valid) {
            this.validationStates.set(groupId, validationState);
            return false;
        } else {
            return true;
        }
    }

    async validateEntryToPost(groupId: number, entry: ImmutableTimeEntry) {
        const { features, getActiveTimeKeeperForDate } = this.rootStore.appStore;
        let matterEntryType: string = '';
        let matterStatusDesc: string = '';
        let narrativeMinLength;
        let narrativeMaxLength;
        if (entry.matterId) {
            const matter = await this.rootStore.api.Matter.get(entry.matterId);
            if (matter) {
                matterEntryType = matter.entryType;
                matterStatusDesc = matter.statusDescription;
                narrativeMinLength = matter.minLength;
                narrativeMaxLength = matter.maxLength;
                entry.bannedWords = matter.bannedWords;
                entry.blockBillingWords = matter.blockBillingWords;
            }
        }
        const validationState = ValidatePost(
            entry,
            0,
            matterStatusDesc,
            matterEntryType,
            features,
            getActiveTimeKeeperForDate(DateTime.fromISO(entry.workDateTime)),
            narrativeMinLength,
            narrativeMaxLength
        );

        if (!validationState.valid) {
            this.validationStates.set(groupId, validationState);
            if (validationState.zeroDuration && !entry.deleted) {
                const day: string = DateTime.fromISO(entry.workDateTime).weekdayShort;
                const durationsValidation = this.validationStatesDurations.get(groupId) || [];
                durationsValidation.push(day);
                this.validationStatesDurations.set(groupId, durationsValidation);
            }
            return false;
        } else {
            return true;
        }
    }

    @action.bound
    async wrappedSaveAll() {
        this.loading = true;
        const entriesToSave = await this.extractEntries(true);
        if (entriesToSave.length === 0) {
            const key = this.failedEntries.length === 0 ? 'no_entries' : 'not_valid';
            this.rootStore.snackbarStore.triggerSnackbar(
                `view.grid.action.save.snackbar.${key}`, { ns: 'home' }
            );
            this.failedEntries = [];
        } else {
            const result = await this.submitAllEntries(entriesToSave);
            const key = result.entriesNotSubmitted > 0 ? 'some_not_saved' : 'all_saved';
            this.rootStore.snackbarStore.triggerSnackbar(`view.grid.action.save.snackbar.${key}`,
                {
                    ns: 'home',
                    saved: result.entriesSubmitted,
                    notSaved: result.entriesNotSubmitted
                }
            );
            this.init(this.dateRange);
        }
        this.loading = false;
    }

    @action.bound
    async wrappedPostAll() {
        this.loading = true;
        const entriesToPost = await this.extractEntries();
        if (entriesToPost.length === 0) {
            const key = this.failedEntries.length === 0 ? 'no_entries' : 'not_valid';
            this.rootStore.snackbarStore.triggerSnackbar(
                `view.grid.action.post.snackbar.${key}`, { ns: 'home' }
            );
            this.failedEntries = [];
        } else {
            const result = await this.submitAllEntries(entriesToPost);
            const key = result.entriesNotSubmitted > 0 ? 'some_not_posted' : 'all_posted';
            this.rootStore.snackbarStore.triggerSnackbar(`view.grid.action.post.snackbar.${key}`,
                {
                    ns: 'home',
                    posted: result.entriesSubmitted,
                    notPosted: result.entriesNotSubmitted
                }
            );
            this.init(this.dateRange);
        }
        this.loading = false;
    }

    @action.bound
    async submitAllEntries(entries: ImmutableTimeEntry[]) {
        const total = entries.length;
        let entriesSubmitted = 0;
        const results = await this.rootStore.api.TimeEntry.updateEntries(entries);
        // if API fails all entries will be added to failed entries to re-render them
        if (results.length === 0) {
            this.failedEntries.push(...entries);
        }
        for (let id = 0; id < results.length; id++) {
            // updating entries only when status is success and updating the saved entries count.
            if (!results[id].status.failed) {
                entriesSubmitted++;
            } else {
                this.failedEntries.concat(results[id].object);
            }
        }
        return {
            entriesSubmitted,
            entriesNotSubmitted: total - entriesSubmitted
        };
    }

    @action.bound
    setValidationStateDurations(groupId: number, day: string) {
        const durations = (this.validationStatesDurations.get(groupId) || []).filter(d => d !== day);
        this.validationStatesDurations.set(groupId, durations);
    }

    @computed get dirty(): boolean {
        return this.timeEntryGroups.some(group => {
            const { id, sampleEntry, ...obj } = group;
            return Object.values(obj).some(day => day.dirty);
        });
    }

    @computed get utcStartDate(): DateTime {
        const date = this.dateRange.startDate;
        return DateTime.utc(date.year, date.month, date.day);
    }

    @computed get weekDays() {
        return getDayNames(this.rootStore.appStore!.startOfWeek, t);
    }
}