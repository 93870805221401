import MatterAPI from 'api/interfaces/MatterAPI';
import BaseWebImplementation from './Base.impl';
import WebRootImpl from './Root.impl';
import { Matter } from '../../types/types';

export default class MatterImpl extends BaseWebImplementation implements MatterAPI {

    handlers: (((matters: Matter[]) => void) | null )[] = [];

    constructor(api: WebRootImpl) {
        super(api);
    }

    registerReciever = (handler: (matters: Matter[]) => void) => {
        this.handlers.push(handler);
        const theIndex = this.handlers.length - 1;
        return  () => {
            this.handlers[theIndex] = null;
        }
    }

    recieve = (matters: Matter[]) => {
        this.handlers.filter(h => h !== null).forEach(h => h!(matters))
    }

    searchMatters = async (searchText: string, showTracked?: boolean, clientId?: number,
                           workDate?: string, offset: number = 0, limit: number = 50) => {
        // tslint:disable-next-line:max-line-length
        let resp = await this.http.get(
            `/matters?offset=${offset}&limit=${limit}` +
            `${searchText ? `&search=${encodeURIComponent(searchText)}` : ``}` +
            `&showTracked=${showTracked}` +
            `${clientId ? `&clientId=${clientId}` : ``}` +
            `${workDate ? `&workDate=${workDate}` : ``}`
        );
        return resp.data;
    }

    getAvailableMatters = async (searchText: string, showTracked: boolean = true, clientId?: number,
                                 workDate?: string, offset?: number, limit?: number) => {
        return await this.searchMatters(searchText, showTracked, clientId, workDate, offset, limit);
    }
    
    get = async (id: number) => {
        let resp = await this.http.get(`/matters/${id}`);
        return resp.data;
    }

    // setCodeSetFlags = async (m: Matter, date: string) => {
    //     m = await this.get(m.id); // in case if Matter doesn't exist anymore or end date is expired
    //    
    //     let flags: CodeSetFlags = {
    //         isPhaseCode: false,
    //         isFfTaskCode: false,
    //         isActCode: false,
    //         phases: [],
    //         ffTasks: [],
    //         activities: []
    //     };
    //     try {
    //         flags = await this.root.Code.determineCodeSetFields(m.id, date);
    //         return flags;
    //     } catch (e) {
    //         // do nothing
    //     }
    //    
    //     return flags;
    // }

    track = async (ids: number[]) => {
        let csIds = ids.join(',');
        
        return await this.http.post(`/matters/tracked?ids=${csIds}`, undefined);
    }

    untrack = async (ids: number[]) => {
        let csIds = ids.join(',');
        
        await this.http.delete(`/matters/tracked?ids=${csIds}`);
    }

    getTrackedMatters = async () => {
        let resp = await this.http.get(`/matters/tracked?tkId=${this.root.Session.currentTimeKeeper}`);
        return resp.data;
    }
}
