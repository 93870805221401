import RootImpl from './Root.impl';
import { AxiosInstance } from 'axios';

export default class BaseWebImplementation {
    root: RootImpl;
    constructor(root: RootImpl) {
        this.root = root;
    }
    get http(): AxiosInstance {
        return this.root.Session.axios;
    }
};