export const STYLES_FOR_PDF = `
.pdfTable {
    border-collapse: collapse;
}
.tableRow {
    border: 1px solid #ddd;
}
th {
    background-color: #ff9800;
}
td {
    border-right: 1px solid #ddd;
    text-align: center;
    font-size: 10px;
}
.grpHeader {
    font-weight: bold;
    font-size: 20px;
    background: #ccc;
}
.totals {
    font-weight: bold;
}
.timeKeeper {
    font-size: 17px;
    background: #FFFFFF;
    font-weight: 400;
}
body{
    -webkit-print-color-adjust: exact;
    display: block;
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

@page { 
    size: auto;
    
    @bottom-left {
        content: counter(page);
     }
}
`