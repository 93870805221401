import * as React from 'react';
import MatterContainer from './MatterContainer';
import * as Styled from './styled';
import MattersStore from 'store/matters.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { withTranslation } from 'react-i18next';

interface Props {
    mattersStore: MattersStore;
    // tslint:disable-next-line:no-any
    t: any;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        mattersStore: rootStore.mattersStore
    };
})

@observer
class Matters extends React.Component<Props> {
    componentWillMount() {
        // this.props.mattersStore!.loadAvailableMatters('',  false, 0, 50);
        this.props.mattersStore!.setAvailableSearchText('');
        this.props.mattersStore!.setTrackedSearchText('');
        this.props.mattersStore!.loadTrackedMatters();
        this.props.mattersStore!.loadAvailableMatters('', false);
    }
    untrack = (ids: number[]) => {
        const { t, mattersStore } = this.props;
        const matterLabel = mattersStore.rootStore.appStore.features.EpochConfigMatterLabel;
        if (confirm(t('left_panel.list.row.icon.dialog.message.online', { matterLabel }))) {
            this.props.mattersStore!.untrack(ids);
        } else {
            return;
        }

    }
    searchTrackedMatters = (val: string) => {
        this.props.mattersStore!.setTrackedSearchText(val);
    }

    searchAvailableMatters = (val: string) => {
        this.props.mattersStore!.setAvailableSearchText(val);
        this.props.mattersStore!.loadAvailableMatters(val, false);
    }

    render() {
        const { t, mattersStore } = this.props;
        const {
            availableClientMattersTuple,
            trackedClientMattersTuple,
            fetchMoreAvailMatters,
            fetchMoreTrackedMatters,
            trackedMatters,
            availableMatters,
            selectedMatters,
            setSelectedMatters,
            track,
            loadingAvailable,
            loadingTracked,
            searchTrackedText,
            searchAvailableText,
            rootStore
        } = mattersStore!;
        const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;

        return (
            <Styled.MainWrapper container={true}>
                <Styled.WrapperItem xs={12} sm={6}>
                    <MatterContainer
                        title={t('left_panel.header.search.tooltip', { matterLabel })}
                        matters={trackedClientMattersTuple}
                        fetchMore={fetchMoreTrackedMatters}
                        isTrackedList={true}
                        selected={selectedMatters}
                        onSelect={setSelectedMatters}
                        untrack={this.untrack}
                        loading={loadingTracked}
                        search={searchTrackedText}
                        onInputChange={this.searchTrackedMatters}
                    />
                </Styled.WrapperItem>
                <Styled.WrapperItem xs={12} sm={6}>
                    <MatterContainer
                        title={t('right_panel.header.search.tooltip', { matterLabel })}
                        matters={availableClientMattersTuple}
                        fetchMore={fetchMoreAvailMatters}
                        isTrackedList={false}
                        selected={selectedMatters}
                        onSelect={setSelectedMatters}
                        track={track}
                        untrack={this.untrack}
                        loading={loadingAvailable}
                        search={searchAvailableText}
                        onInputChange={this.searchAvailableMatters}
                    />
                </Styled.WrapperItem>
            </Styled.MainWrapper>
         );
    }
}

export default withTranslation(['matters'])(Matters);