import styled from 'common/styled-components';
import { IconButton, Paper, Typography } from '@material-ui/core';

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    height: 64px;
    background-color: ${props => props.theme.primary.main};
    display: flex;
    align-items: center;
`;

export const SearchContainer = styled.div`
    flex: 1;
    padding: 12px 12px 12px 24px;
    display: flex;
    align-items: flex-end;
`;

export const Title = styled(Typography)`
    flex: 1;
    text-align: center;
`;

export const New = styled(IconButton)`
    float: right;
    && {
        margin-right: 24px;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;  
`;

export const ListContainer = styled(Paper)`
    flex: 1;
`;

export const FormContainer = styled.div`
    flex: 3;
    margin: 10px;
    overflow: hidden;
`;

export const ButtonsContainer = styled.div`
    display: flex; 
    padding: 20px 0 6px 10px;
`;

export const ListItem = styled.div<{selected?: boolean}>`
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    cursor: pointer;
    &:hover {
        background: ${props => !props.selected && props.theme.white.main};
    }
    background: ${props => props.selected && props.theme.white.dark};
    transition: ${props => props.theme.animationsEnabled && 'all 0.1s'};
`;

export const Key = styled.div`
    max-width: 140px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Replacement = styled.div`
    font-size: 14px;
    margin-top: 4px;
    color: ${props => props.theme.secondary.light};
    max-width: 180px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const TextContainer = styled.div<{disabled?: boolean}> `
  pointer-events: ${props => props.disabled ? `none` : `auto`};
  ${props => props.disabled ? `color: rgba(0, 0, 0, 0.38)` : ``};
`;