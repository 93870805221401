import React, { Component } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { DragObjectType } from 'components/DragNDropTimeCastSegment/SegmentDragSource';
import { Badge } from '@material-ui/core';
import { TimeCastSegmentGap, TimeCastSegmentGroup } from 'store/home.store';
import { TimerChunk } from '../../api/types/types';
import { getDurStringBetween } from 'components/TimerChunkItem/TimerChunkItem';

interface Props {}

interface DragProps {
    isDragging: boolean,
    item: DragObjectType,
    currentOffset: XYCoord | null
}

interface DraggableComponentProps {
    groupOrGap?: TimeCastSegmentGap | TimeCastSegmentGroup;
    timerSegment?: TimerChunk;
}

export class DraggableComponent extends React.Component<DraggableComponentProps> {
    render() {
        const { groupOrGap, timerSegment } = this.props;
        let title;
        if (groupOrGap) {
            title = groupOrGap.gap ? 'Gap' : groupOrGap.title;
        }
        if (timerSegment) {
            title = getDurStringBetween(new Date(timerSegment.startTime), new Date(timerSegment.endTime));
        }
        return (<span>{title}</span>);
    }
}

export const DraggableLayer = (props: Props) => {
    const { isDragging, item, currentOffset } = useDragLayer<DragProps>(
        (monitor) => 
            ({
                isDragging: monitor.isDragging(),
                item: monitor.getItem(),
                currentOffset: monitor.getClientOffset() || monitor.getInitialClientOffset()
            } as DragProps)
    );
    
    const offset = currentOffset ? currentOffset : { x: 0, y: 0 };
    const style: React.CSSProperties = {
        display: isDragging ? 'block' : 'none',
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 1500,
        left: 0,
        top: 0,
        width: '100%',
        transform: `translate(${offset.x}px, ${offset.y}px)`,
        backgroundColor: 'white'
    };
    return (
        <div style={style}>
            {item && item.selectedSegmentsToDrag &&
                <Badge
                    badgeContent={item.selectedSegmentsToDrag}
                    color={'primary'}
                    style={{ position: 'absolute', zIndex: 9000 }}
                >
                    <DraggableComponent
                        groupOrGap={item.groupOrGap}
                        timerSegment={item.timerSegment}
                    />
                </Badge>
            }
        </div>
    );
}

class SegmentDragSourceList extends Component {
    render() {
        return (
            <div>
               <DraggableLayer /> 
            </div>
        );
    }
}

export default SegmentDragSourceList;