import React from 'react';
import { PhoneCallData } from '../../api/types/types';
import { Phone, PhoneCallback } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export const PhoneCallDescription = (props: {data: PhoneCallData}) => {
    const { t } = useTranslation(['home']);
    const data = props.data;
    const translationKey = 'view.day.segment_panel.segment.phone';
    return (
        <span>
            {data.initiator ? <Phone style={{fontSize: '13px'}}/> : <PhoneCallback style={{fontSize: '13px'}} />}
            &nbsp;
            {data.initiator ? t(`${translationKey}.to`) : t(`${translationKey}.from`)}
            &nbsp;
            {data.otherParty ? (
                data.otherParty.fullName
            ) : (
                data.initiator ? data.toNumber : data.fromNumber
            )}
        </span>
    )
}