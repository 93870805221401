import * as React from 'react';
import {
    AppBar,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Popover,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Toolbar,
    Tooltip,
    Checkbox
} from '@material-ui/core';
import {
    Done,
    GetApp,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Loop,
    PictureAsPdf,
    Print,
    Search,
    Today
} from '@material-ui/icons';
import { DateTime } from 'luxon';
import * as Styled from './styled';
import { Client, Features, Matter, MatterTypeText, PdfFormatType, TimeKeeperAssignment } from '../../api/types/types';
import RootAPI from '../../api/interfaces/RootAPI';
import { ApiConsumer } from 'common/ApiProvider';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import TimeEntryStore, { MATTER_TYPE } from 'store/timeentry.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import TimeEntryList from 'components/TimeEntryList/TimeEntryList';
import TimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry';
import { InlineDatePicker } from 'material-ui-pickers';
import { StandardTextFieldProps } from '@material-ui/core/TextField';
import { FlexDiv } from 'common/flex';
import ImmutableTimeEntry from '../../api/immutables/ImmutableTimeEntry';
import Pagination from '../Pagination/Pagination';
import ExcelIcon from '../../images/excel.png';
import { exportToXLSX, toExcelFormat } from '../../util/ExportToExcel';
import { renderToStaticMarkup } from 'react-dom/server';
import PrintAsTimeEntryPdfExport from './PrintAsTimeEntryPdfExport';
import printJS from 'print-js';
import * as StyledPdf from './styled.pdf';
import { fileNameForExport, systemTimeZone } from '../../util/utils';
import { buildPdf } from '../../util/SaveAsTimeEntryPDF';
import { Platform } from '../../util/Platform';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { isoDate, getDateFormat } from '../../util/date';
import { FabContainerView } from 'components/FabContainer/FabContainerView';
import { TKConsumer } from 'common/TKProvider';
import { withTranslation } from 'react-i18next';
import { i18n } from 'i18next';
import BulkUploadButton from 'containers/BulkUploadButton';
const { STYLES_FOR_PDF } = StyledPdf;

interface State {
    expanded: boolean,
    selected: boolean,
    popOverPdfEl: HTMLElement | null,
    pdfFormatTypeValue: string
}

interface Props {
    timeEntryStore: TimeEntryStore;
    // tslint:disable-next-line:no-any
    t: any;
    i18n: i18n;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        timeEntryStore: rootStore.timeEntryStore
    };
})

@observer
class TimeEntries extends React.Component<Props, State> {
    entriesListRef: React.RefObject<HTMLDivElement>;
    state = {
        expanded: false,
        selected: false,
        popOverPdfEl: null,
        pdfFormatTypeValue: PdfFormatType.DATETYPE
    };
    constructor(props: Props) {
        super(props);
        this.entriesListRef = React.createRef();
    }
    componentDidMount() {
        let loc = DateTime.local().setZone(systemTimeZone);
        let f: Features = this.props.timeEntryStore!.rootStore.appStore.features;
        let fromDate = DateTime.utc(loc.year, loc.month, loc.day).minus({
             days: f.EpochConfigTimeEntriesPageDays ? f.EpochConfigTimeEntriesPageDays : 7});
        let untilDate = DateTime.utc(loc.year, loc.month, loc.day);
        this.props.timeEntryStore!.setTimeEntryDateRange();
        this.props.timeEntryStore!.setTimeEntryRange(fromDate, untilDate);
        this.props.timeEntryStore!.getEntries();
        this.props.timeEntryStore!.expandedEntryIds = [];
        this.props.timeEntryStore!.rootStore.collaboratees = [];
    }
    componentWillUnmount() {
        this.props.timeEntryStore!.resetTimeEntryStore();
    }
    handleFromDateChange = (d: Date) => {
        const workDate: DateTime = DateTime.local(
            d.getFullYear(),
            d.getMonth() + 1,
            d.getDate(),
            0,
            0,
            0
        );

        let fromDate = workDate;
        let untilDate = this.props.timeEntryStore!.untilDate;
        if (untilDate <= workDate) {
            untilDate = workDate;
        }
        this.props.timeEntryStore!.setTimeEntryRange(fromDate, untilDate);
    };
    handleUntilDateChange = (d: Date) => {
        const workDate: DateTime = DateTime.local(
            d.getFullYear(),
            d.getMonth() + 1,
            d.getDate(),
            0,
            0,
            0
        );

        let fromDate = this.props.timeEntryStore!.fromDate;
        let untilDate = workDate;
        if (fromDate >= untilDate) {
            fromDate = workDate;
        }
        this.props.timeEntryStore!.setTimeEntryRange(fromDate, untilDate);
    };
    handleClientChange = (c: Client | null | undefined) => {
        this.props.timeEntryStore!.setClient(c);
        this.handleMatterChange(null);
    };
    handleMatterChange = (m: Matter | null | undefined) => {
        this.changePage(1);
        this.props.timeEntryStore.selectedEntryIds = [];
        this.props.timeEntryStore!.setMatter(m);
        this.props.timeEntryStore!.setReferenceTE(null);
    };
    handleSearchTextChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.changePage(1);
        this.props.timeEntryStore!.setSearchText(evt.target.value);
    };
    handleStatusChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.changePage(1);
        this.props.timeEntryStore!.setStatus(evt.target.value);
    };
    handleMatterTypeChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.changePage(1);
        this.props.timeEntryStore!.setMatterType(evt.target.value);
    };
    reset = () => {
        let loc = DateTime.local();
        this.props.timeEntryStore!.setSearchText('');
        this.props.timeEntryStore!.setStatus('All');
        this.props.timeEntryStore!.setMatterType(MATTER_TYPE.All);
        this.props.timeEntryStore!.setTimeEntryDateRange();
        this.props.timeEntryStore!.setClient(null);
        this.handleMatterChange(null);
        this.props.timeEntryStore!.getEntries();
        this.props.timeEntryStore!.expandedEntryIds = []
        // resetting pagination values
        this.props.timeEntryStore!.setPageNum(1);
        this.props.timeEntryStore!.setEntriesPerPage(25);
    };
    search = () => {
        this.props.timeEntryStore!.getEntries();
        this.props.timeEntryStore!.setPageNum(1);
    }
    editEntry = async (t: TimeEntry) => {
        await this.props.timeEntryStore!.loadEntry(t.clone());
    }
    deleteEntry = (t: TimeEntry) => {
        this.props.timeEntryStore!.deleteEntries([t]);
    }
    saveEntry = (t: TimeEntry) => {
        const collabs = this.props.timeEntryStore!.rootStore.collaboratees;
        if (collabs.length > 0) {
            this.props.timeEntryStore!.rootStore.confirmCollaborateDialogStore
                .open()
                .then(() => this.props.timeEntryStore!.saveEntries([t]))
                .catch(() => {
                    return;
                });
        } else {
            this.props.timeEntryStore!.saveEntries([t]);
        }
    }
    postEntry = (t: TimeEntry) => {
        const collabs = this.props.timeEntryStore!.rootStore.collaboratees;
        if (collabs.length > 0) {
            this.props.timeEntryStore!.rootStore.confirmCollaborateDialogStore
                .open()
                .then(() => this._postEntry(t))
                .catch(() => {
                    return;
                });
        } else {
            this._postEntry(t);
        }
    }
    _postEntry = async (t: TimeEntry) => {
        let expandedIds = this.props.timeEntryStore!.expandedEntryIds;
        if (!expandedIds.includes(t.id!)) {
            await this.props.timeEntryStore!.determineCodeSets(t.id!);
        }
        this.props.timeEntryStore!.postEntries([t.id!]);
    }
    clearSelectedEntries = () => {
        this.props.timeEntryStore!.setSelectedTimeEntries([]);
        this.props.timeEntryStore!.rootStore.collaboratees = [];
    }
    checkText = (str: string|undefined|null, ifUndefined: string = '') => {
        str = str === null ? ifUndefined : str;
        str = str!.replace(/"/g, '""');
        return str;
    }
    fetchRefs = async (text: string) => {
        const { serverTimeEntries } = this.props.timeEntryStore!;
        let ret: TimeEntry[] = [];
        const uniqueRet = new Map();
        
        serverTimeEntries.forEach((t) => {
            uniqueRet.set(t.reference, t);
        });
        uniqueRet.forEach((te, k) => {
            if (k) { ret.push(te); }
        });
        return ret;
    }
    exportToExcel = async () => {
        const { filteredEntries, rootStore } = this.props.timeEntryStore!;
        const { features, getActiveTimeKeeper } = rootStore.appStore;
        const ffConfigEnabled = features.EpochConfigFlatFeeCodesEnabled;
        const actCodeEnabled = features.EpochConfigActionCodesRequired;
        const currentTimeKeeper = getActiveTimeKeeper();
        
        const fileName = fileNameForExport(currentTimeKeeper, this.props.t('export.pdf.title'));
        
        const sortByDateEntries = filteredEntries.sort((e1, e2) => {
            if (DateTime.fromISO(e1.workDateTime) > DateTime.fromISO(e2.workDateTime)) { return 1; }
            if (DateTime.fromISO(e1.workDateTime) < DateTime.fromISO(e2.workDateTime)) { return -1; }
            return 0;
        });
        const excelEntries = toExcelFormat(
            sortByDateEntries, 
            ffConfigEnabled, 
            actCodeEnabled
        );
        
        exportToXLSX(excelEntries, fileName);
    }
    customizeHeadersForPdf = (): Map<string, ImmutableTimeEntry[]> => {
        let timeEntriesMap = new Map<string, ImmutableTimeEntry[]>();

        switch (this.state.pdfFormatTypeValue) {
            case PdfFormatType.CLIENTTYPE:
                timeEntriesMap = this.props.timeEntryStore!.groupEntriesByClient;
                break;
            case PdfFormatType.MATTERTYPE:
                timeEntriesMap = this.props.timeEntryStore!.groupEntriesByMatter;
                break;
            case PdfFormatType.DATETYPE:
                [...this.props.timeEntryStore!.timeEntriesMapForPDF.entries()].sort()
                    .forEach(([date, tentries]) => {
                        let d = DateTime.fromISO(date).toFormat('DDDD');
                        timeEntriesMap.set(d, tentries);
                    });
                break;
            default:
                break;
        }
        
        return timeEntriesMap;
    }
    printPDF = () => {
        const currentTimeKeeper = this.props.timeEntryStore!.rootStore.appStore.getActiveTimeKeeper();
        const fileName = fileNameForExport(currentTimeKeeper, this.props.t('export.pdf.title'))

        const ffConfigEnabled = this.props.timeEntryStore!.rootStore.appStore.features.EpochConfigFlatFeeCodesEnabled;
        const actionEnabled = this.props.timeEntryStore!.rootStore.appStore.features.EpochConfigActionCodesRequired;
        let timeEntriesMap = this.customizeHeadersForPdf();

        let html = renderToStaticMarkup(
            <PrintAsTimeEntryPdfExport
                timeEntriesMap={timeEntriesMap}
                ffConfigEnabled={ffConfigEnabled}
                actionEnabled={actionEnabled}
                exportType={this.state.pdfFormatTypeValue}
                timeKeeper={currentTimeKeeper ? currentTimeKeeper.name : ''}
            />);
        
        let pdfDiv = new DOMParser().parseFromString(html, 'text/html');
        let pdfContainer = document.createElement('div');
        
        if (pdfDiv.body.firstChild) {
            pdfContainer.appendChild(pdfDiv.body.firstChild);
            pdfContainer.setAttribute('style', 'visibility: hidden')
            document.body.appendChild(pdfContainer);
            printJS({
                printable: 'printjs-form',
                type: 'html',
                documentTitle: this.props.t('export.pdf.title'),
                style: STYLES_FOR_PDF,
                onLoadingStart: () => { document.title = fileName; },
                onPrintDialogClose: () => { document.title = 'Epoch'; },
                honorColor: true,
                honorMarginPadding: true
            });
            
            document.body.removeChild(pdfContainer);
        }
        
        this.handleClosePdfPopOver();
    }
    saveAsPDF = async () => {
        const { t, timeEntryStore } = this.props;
        const { features, getActiveTimeKeeper } = timeEntryStore!.rootStore.appStore;
        const currentTimeKeeper = getActiveTimeKeeper();
        const timeEntriesMap = this.customizeHeadersForPdf();
        const ffConfigEnabled = features.EpochConfigFlatFeeCodesEnabled;
        const actCodeEnabled = features.EpochConfigActionCodesRequired;
        const fileName = fileNameForExport(currentTimeKeeper, t('export.pdf.title'));

        let doc = buildPdf(timeEntriesMap, ffConfigEnabled, actCodeEnabled,
             this.state.pdfFormatTypeValue, currentTimeKeeper ? currentTimeKeeper.name : '', t);
        
        if (Platform.isElectron()) {
            let pdfString = doc.output('datauristring');
            const {dialog} = require('electron').remote;
            const fs = require('fs-jetpack');
            let dataUriToBuffer = require('data-uri-to-buffer');
            const path = require('path');
            const bufferData = dataUriToBuffer(pdfString);

            dialog.showSaveDialog({
                    defaultPath: fileName,
                    filters: [
                        {
                            name: 'pdf',
                            extensions: ['pdf']
                        }
                    ]
                }, (file: string) => {
                    if (file === undefined) {
                        return;
                    }
                    fs.write(
                        path.normalize(file),
                        bufferData
                    )
                }
            );
        } else {
            doc.save(`${fileName}.pdf`);
        }
        
        this.handleClosePdfPopOver();
    }
    openPdfPopOver = async (evt: React.MouseEvent<HTMLElement>) => {
        this.setState({ popOverPdfEl: evt.currentTarget });
    }
    handleClosePdfPopOver = () => {
        this.setState({ popOverPdfEl: null });
    }
    handlePdfFormatTypeChange = (event: React.ChangeEvent<HTMLFormElement>, val: string) => {
        this.setState({ pdfFormatTypeValue: val });
    }
    changePage = (pageNum: number) => {
        this.props.timeEntryStore!.setPageNum(pageNum);
        this.entriesListRef.current!.scrollTop = 0;
    }
    changeEntriesPerPage = (n: number) => {
        this.props.timeEntryStore.setEntriesPerPage(n);
        this.changePage(1);
    }
    fetchMatters = (api: RootAPI) => async (searchText: string, features: Features, clientId: number | undefined) => {
        let  results: Matter[] = await api.Matter.searchMatters(
            searchText, true, clientId!
        );

        if (!features.EpochConfigTrackedMatterClientsEnabled && results.length === 0) {
            results = await api.Matter.getAvailableMatters(
                searchText, false, clientId!
            );
        }
        return results;
    }

    render() {
        const { t, timeEntryStore } = this.props;
        const {
            timeEntriesMap,
            fromDate,
            untilDate,
            status,
            matterType,
            selectedEntryIds,
            expandedEntryIds,
            searchText,
            client,
            matter,
            loading,
            noEntries,
            validationState,
            referenceTE,
            setReferenceTE,
            durVstate,
            allEntriesSelected,
            toggleSelectAllFlag,
            copyTimeEntry,
            filteredEntries,
            currentPage,
            entriesPerPage,
            setFieldLoaderFn,
            matterLoading,
            rootStore
        } = timeEntryStore!;
        let fieldSize: 3 | 4 = (matter && matter.id === -1) ? 3 : 4;
        const { popOverPdfEl, pdfFormatTypeValue } = this.state;
        const openedPdfEl = Boolean(popOverPdfEl);
        const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;
        
        return (
            <FeaturesConsumer>
            { (features: Features) =>
            <ApiConsumer>
                {(api: RootAPI) =>
                    <>
                        <AppBar position={'static'}>
                            <Toolbar variant={'regular'}>
                                <Grid container={true} xs={12}>
                                    <Styled.StyledGrid
                                        container={true}
                                        xs={12}
                                        spacing={8}
                                    >
                                        <Grid item={true} xs={2}>
                                            <TextField
                                                id={'input-with-icon-textfield'}
                                                label={t('header.field.search.label')}
                                                placeholder={t('header.field.search.place_holder')}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position={'start'}>
                                                            <Search/>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                value={searchText}
                                                fullWidth={true}
                                                onChange={this.handleSearchTextChange}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={2}>
                                            <InlineDatePicker
                                                format={fromDate.toFormat(getDateFormat())}
                                                value={isoDate(fromDate)}
                                                label={t('header.field.date.from.label')}
                                                onChange={this.handleFromDateChange}
                                                leftArrowIcon={<KeyboardArrowLeft/>}
                                                rightArrowIcon={<KeyboardArrowRight/>}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position={'end'}>
                                                            <Today/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                TextFieldComponent={
                                                    (props: StandardTextFieldProps) => 
                                                        <TextField {...props} fullWidth={true}/>
                                                }
                                            />
                                        </Grid>
                                        <Grid item={true} xs={2}>
                                            <InlineDatePicker
                                                format={untilDate.toFormat(getDateFormat())}
                                                value={isoDate(untilDate)}
                                                label={t('header.field.date.to.label')}
                                                onChange={this.handleUntilDateChange}
                                                leftArrowIcon={<KeyboardArrowLeft/>}
                                                rightArrowIcon={<KeyboardArrowRight/>}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position={'end'}>
                                                            <Today/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                TextFieldComponent={
                                                    (props: StandardTextFieldProps) =>
                                                        <TextField {...props} fullWidth={true}/>
                                                }
                                            />
                                        </Grid>
                                        <Grid item={true}>
                                            <FlexDiv style={{marginTop: 7}}>
                                                <IconButton title={t('header.action.reset.tooltip')} onClick={this.reset}>
                                                    <Loop />
                                                </IconButton>
                                                <IconButton title={t('header.action.apply_date.tooltip')} onClick={this.search}>
                                                    <Done />
                                                </IconButton>
                                                <IconButton
                                                    key={'csv'}
                                                    onClick={this.exportToExcel}
                                                    title={t('header.action.download_excel.tooltip')}
                                                    disabled={noEntries}
                                                >
                                                    <img src={ExcelIcon}/>
                                                </IconButton>
                                                <IconButton
                                                    key={'pdf'}
                                                    onClick={this.openPdfPopOver}
                                                    title={t('header.action.pdf.tooltip')}
                                                    disabled={noEntries}
                                                >
                                                    <PictureAsPdf/>
                                                </IconButton>
                                                <BulkUploadButton />
                                                <Popover
                                                    id="download-pdf-pop"
                                                    open={openedPdfEl}
                                                    anchorEl={popOverPdfEl}
                                                    onClose={this.handleClosePdfPopOver}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <div>
                                                        <FormControl style={{width: '140px', padding: '10px'}}>
                                                            <FormLabel>{t('header.action.pdf.menu.group_by.label')}:</FormLabel>
                                                            <RadioGroup
                                                                aria-label={t('header.action.pdf.menu.group_by.label')}
                                                                name={'groupBy'}
                                                                value={pdfFormatTypeValue}
                                                                onChange={this.handlePdfFormatTypeChange}
                                                            >
                                                                {Object.keys(PdfFormatType).map((key) => {
                                                                    const typeKey = PdfFormatType[key].toLowerCase().replace(' ', '_');
                                                                    const transKey = `header.action.pdf.menu.group_by.option.${typeKey}`;
                                                                    return(
                                                                        <FormControlLabel
                                                                            key={key}
                                                                            control={
                                                                                <Radio/>
                                                                            }
                                                                            label={t(transKey, {matterLabel})}
                                                                            value={PdfFormatType[key]}
                                                                        />
                                                                    )
                                                                })}
                                                            </RadioGroup>
                                                            <Styled.PrintIcons>
                                                                <Tooltip title={t('header.action.pdf.menu.action.print.tooltip')}>
                                                                    <IconButton
                                                                        onClick={this.printPDF}
                                                                        aria-label={t('header.action.pdf.menu.action.print.tooltip')}
                                                                    >
                                                                        <Print/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title={t('header.action.pdf.menu.action.download.tooltip')}>
                                                                    <IconButton
                                                                        onClick={this.saveAsPDF}
                                                                        aria-label={t('header.action.pdf.menu.action.download.tooltip')}
                                                                    >
                                                                        <GetApp/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Styled.PrintIcons>
                                                        </FormControl>
                                                    </div>
                                                </Popover>
                                            </FlexDiv>
                                        </Grid>
                                        <TKConsumer>
                                            { (tk: TimeKeeperAssignment) => 
                                        <Grid item={true} xs={2}>
                                            {matter && matter.id === -1 ?
                                                <FlexDiv
                                                    fillContainer={true}
                                                    style={{ marginTop: 5, alignItems: 'center' }}
                                                >
                                                    <Tooltip title={t('select_all', { ns: 'common' })}>
                                                        <Checkbox
                                                            checked={allEntriesSelected}
                                                            onChange={toggleSelectAllFlag}
                                                            style={{ padding: 0 }}
                                                            disabled={!tk.writable}
                                                        />
                                                    </Tooltip>
                                                    &nbsp;
                                                    { tk.writable &&
                                                    <span> {t('select_all', { ns: 'common' })} </span>
                                                    }
                                                </FlexDiv>
                                                :
                                                null
                                            }
                                        </Grid>
                                        }
                                        </TKConsumer>
                                    </Styled.StyledGrid>
                                    <Styled.StyledGrid
                                        container={true}
                                        xs={12}
                                        spacing={8}
                                    >
                                        <Grid item={true} xs={2}>
                                            <FormControl
                                                fullWidth={true}
                                            >
                                                <InputLabel shrink={true}>
                                                    {t('header.field.status.label')}
                                                </InputLabel>
                                                <Select
                                                    input={<Input name={'status'} id={'status-label-placeholder'}/>}
                                                    displayEmpty={true}
                                                    name={'select-status'}
                                                    value={status}
                                                    onChange={this.handleStatusChange}
                                                >
                                                    <MenuItem value={'All'}>{t('header.field.status.option.all')}</MenuItem>
                                                    <MenuItem value={SapStatus.POSTED}>{t('header.field.status.option.posted')}</MenuItem>
                                                    <MenuItem value={SapStatus.UNSUBMITTED}>{t('header.field.status.option.draft')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} xs={2}>
                                            <FormControl
                                                fullWidth={true}
                                            >
                                                <InputLabel shrink={true}>
                                                    {t('header.field.matter_type.label', {matterLabel})}
                                                </InputLabel>
                                                <Select
                                                    input={<Input name={'matter'} id={'matter-label-placeholder'}/>}
                                                    displayEmpty={true}
                                                    name={'select-matter-type'}
                                                    value={matterType}
                                                    onChange={this.handleMatterTypeChange}
                                                >
                                                    {Object.keys(MATTER_TYPE).map((key) => {
                                                        const typeKey = MATTER_TYPE[key].toLowerCase().replace(' ', '_');
                                                        return (
                                                            <MenuItem
                                                                key={key}
                                                                value={key}
                                                            >
                                                                {t(`header.field.matter_type.option.${typeKey}`)}
                                                            </MenuItem>
                                                        )}
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} xs={3} lg={fieldSize} md={fieldSize}>
                                            <AutoCompleteField
                                                label={t('field.client')}
                                                fetch={api.Client.searchClients}
                                                currentItem={client}
                                                getItemText={(c: Client) => `${c.number} - ${c.name}`}
                                                clearable={true}
                                                onSelect={this.handleClientChange}
                                                onClear={() => this.handleClientChange(null)}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={3} lg={fieldSize} md={fieldSize}>
                                            <AutoCompleteField
                                                label={t('field.matter', {matterLabel})}
                                                fetch={(text: string) =>
                                                    this.fetchMatters(api)(text, features,
                                                         client ? client.id : undefined)
                                                        .then((res) => {
                                                            let blank: Matter = {
                                                                id: -1,
                                                                number: '',
                                                                name: 'Blank',
                                                                description: '',
                                                                clientId: -1,
                                                                isPhaseCode: false,
                                                                isActCode: false,
                                                                isFfTaskCode: false,
                                                                clientName: '',
                                                                clientNumber: '',
                                                                status: '',
                                                                language: '',
                                                                languageText: '',
                                                                timeEntryUnit: '',
                                                                entryType: '',
                                                                type: '',
                                                                typeText: MatterTypeText.NON_BILLABLE,
                                                                statusDescription: '',
                                                                lastModified: '',
                                                                startDate: '',
                                                                endDate: '',
                                                                bannedWords: [],
                                                                blockBillingWords: [],
                                                            };
                                                            if (!client && !text) {
                                                                res.unshift(blank)
                                                            }
                                                            return res;
                                                    })}
                                                currentItem={matter}
                                                getItemText={(m: Matter) => {
                                                    if (m.id === -1) {
                                                        return m.name;
                                                    }
                                                    return `${m.number} - ${m.name}`;
                                                }}
                                                clearable={true}
                                                onSelect={this.handleMatterChange}
                                                onClear={() => this.handleMatterChange(null)}
                                                tooltip={(m: Matter) => m.description}
                                            />
                                        </Grid>
                                        { matter && matter.id === -1 &&
                                            <Grid item={true} xs={2} lg={2} md={2}>
                                                <AutoCompleteField
                                                    label={t('field.reference')}
                                                    fetch={this.fetchRefs}
                                                    currentItem={referenceTE}
                                                    getItemText={(entry: TimeEntry) => entry.reference!}
                                                    clearable={true}
                                                    onSelect={setReferenceTE}
                                                    onClear={() => setReferenceTE(null)}
                                                />
                                            </Grid>
                                        }
                                    </Styled.StyledGrid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                        <Pagination
                            entriesPerPage={entriesPerPage}
                            currentPage={currentPage}
                            entriesLength={filteredEntries.length}
                            changeEntriesPerPage={this.changeEntriesPerPage}
                            onPageChange={this.changePage}
                        />
                        <Styled.TimeEntryList innerRef={this.entriesListRef}>
                            {[...timeEntriesMap].map(([date, entries]) => (
                                <TimeEntryList
                                    entries={entries}
                                    validationMap={validationState}
                                    durValidationMap={durVstate}
                                    date={DateTime.fromISO(date)}
                                    onChange={this.props.timeEntryStore!.changeEntry}
                                    selected={selectedEntryIds}
                                    onSelect={this.props.timeEntryStore!.setSelectedTimeEntries}
                                    expanded={expandedEntryIds}
                                    onExpand={this.props.timeEntryStore!.setExpandedTimeEntries}
                                    onCreateEntry={this.props.timeEntryStore!.openNewTimeEntryDialog}
                                    onPost={this.postEntry}
                                    onSave={this.saveEntry}
                                    onDelete={this.deleteEntry}
                                    onCopy={copyTimeEntry}
                                    onEdit={this.editEntry}
                                    onCancel={this.props.timeEntryStore!.revertEntry}
                                    onSplit={this.props.timeEntryStore!.splitEntry}
                                    onMerge={this.props.timeEntryStore!.mergeEntries}
                                    onTransfer={this.props.timeEntryStore!.transferEntries}
                                    buildCodeSets={this.props.timeEntryStore!.determineCodeSets}
                                    aggregateTotals={this.props.timeEntryStore!.getAggregateTotalsFor}
                                    onSetFieldLoader={setFieldLoaderFn}
                                />
                            ))}
                            {loading && <Styled.Loading>
                                <div style={{top: '-15%', position: 'relative'}}>
                                    <CircularProgress size={100} />
                                </div>
                            </Styled.Loading>}
                            {noEntries && <Styled.NoEntry>{t('list.empty')}</Styled.NoEntry>}
                        </Styled.TimeEntryList>
                        <FabContainerView
                            dirty={this.props.timeEntryStore!.dirty}
                            saveDirtyEntries={this.props.timeEntryStore!.saveDirtyEntries}
                            clearSelectedEntries={this.clearSelectedEntries}
                            postSelectedEntries={this.props.timeEntryStore!.postSelectedEntries}
                            moveSelectedEntries={this.props.timeEntryStore!.moveSelectedEntries}
                            mergeEntries={this.props.timeEntryStore!.mergeEntries}
                            transferEntries={this.props.timeEntryStore!.transferEntries}
                            deleteSelectedEntries={this.props.timeEntryStore!.deleteSelectedEntries}
                            selectedEntryIds={selectedEntryIds}
                            disableSavePost={matterLoading}
                        />
                    </>}
            </ApiConsumer>
            }
            </FeaturesConsumer>
        );
    }
}

export default withTranslation(['timeentries', 'common'])(TimeEntries);