import React, { Component, RefObject } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { InputAdornment, Menu, TextField, Button } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import * as Styled from 'components/TimeKeepersList/styled.desktop';
import AppStore from 'store/app.store';
import { inject } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { Platform } from '../../util/Platform';
import { CollaborateType, TimeKeeperAssignment } from '../../api/types/types';
import { withTranslation } from 'react-i18next';

interface Props {
    render: (tkMenuAnchor: RefObject<HTMLElement | HTMLButtonElement>, 
             openTkList: (
                 event: React.MouseEvent<HTMLButtonElement | HTMLLIElement | HTMLElement>
             ) => void) => JSX.Element,
    collaborate: boolean;
    menuWidth?: number;
    appStore?: AppStore;
    timeKeepers: TimeKeeperAssignment[];
    workDate: string;
    matterId?: number | null;
    // tslint:disable-next-line:no-any
    t: any;
}
interface State {
    tkMenuOpen: boolean;
    selectedTks: CollaborateType[];
    searchText: string;
    enableSave: boolean;
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        appStore: rootStore.appStore
    };
})
class TimeKeepersList extends Component<Props, State> {
    timeKeeperMenuAnchor = React.createRef<HTMLElement | HTMLButtonElement>();
    isFetching = false; // used to make one api call at a time
    state: State = {
        tkMenuOpen: false,
        selectedTks: [],
        searchText: '',
        enableSave: false
    };
    componentWillMount(): void {
        this.setState({ selectedTks: this.props.appStore!.rootStore.collaboratees });
    }
    componentWillUnmount(): void {
        this.setState({ selectedTks: [] });
    }
    fetchMoreTimeKeepers = async (cHeight: number, scrollHeight: number, scrollTop: number, workDate: string, matterId?: number | null) => {
        if (this.props.collaborate) {
            let bottom = scrollHeight - (scrollTop + cHeight);
            if (scrollTop === 0) {
                return
            }
            if (bottom <= 15 && !this.isFetching) {
                this.isFetching = true;
                await this.props.appStore!.fetchMoreTimeKeepers(workDate, matterId);
                this.isFetching = false;
            }
        }
    }
    openTkList = (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement | HTMLElement>) => {
        this.setState({ tkMenuOpen: true });
        this.props.appStore!.setTkSearchText('');
        this.setState({ searchText: '' });
    }
    handleTkSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
        let search = evt.target.value || '';
        this.setState({ searchText: search });
        this.props.appStore!.setTkSearchText(search);
        if (this.props.collaborate) {
            this.props.appStore!.debouncedGetTimekeepersList(this.props.workDate, this.props.matterId);
        }
    };
    handleTkSelection = (event: React.MouseEvent<HTMLElement>, val: TimeKeeperAssignment) => {
        let tkList = Array.from(this.state.selectedTks);
        const index = tkList.findIndex(tk => tk.timeKeeperId === val.timeKeeperId);
        
        // if the item exist in the array then remove it, otherwise push to array
        if (index > -1) {
            tkList.splice(index, 1);
        } else {
            tkList.push({
                timeKeeperId: val.timeKeeperId,
                tkName: val.name
            });
        }

        // previous and current TK list to be compared to identify if there are any change to enable save button
        const newListSorted = JSON.stringify(tkList.sort((a, b) => a.timeKeeperId - b.timeKeeperId));
        const oldListSorted = JSON.stringify(this.props.appStore!.rootStore.collaboratees.sort((a, b) => a.timeKeeperId - b.timeKeeperId));

        this.setState({
            selectedTks: tkList,
            enableSave: newListSorted !== oldListSorted
        });
    }
    saveCollabs = () => {
        this.props.appStore!.rootStore.setColloaboratees(this.state.selectedTks);
        this.setState({ enableSave: false, tkMenuOpen: false });
    }
    isTkSelected = (id: number) => {
        return this.state.selectedTks.findIndex(tk => tk.timeKeeperId === id) > -1;
    }
    setTimeKeeper = async (tkId: number) => {
        await this.props.appStore!.setTimekeeper(tkId);
        if (Platform.isElectron() && this.props.appStore!.rootStore.api.TimeEntry.getServerEntries) {
            this.props.appStore!.rootStore.api.TimeEntry.getServerEntries!(tkId);
        }
    }
    onMenuClose = () => {
        this.setState({ enableSave: false, tkMenuOpen: false, selectedTks: this.props.appStore!.rootStore.collaboratees });
        setTimeout(() => {
                this.props.appStore!.currentEnd = 0;
                this.props.appStore!.allTimeKeepersList = [];
            }, 500);
    }
    render() {
        const { tkMenuOpen, searchText } = this.state;
        const { collaborate, menuWidth, timeKeepers, workDate, matterId, t } = this.props;
        return (
            <>
                {this.props.render(this.timeKeeperMenuAnchor, this.openTkList)}
                <AutoSizer>
                    {({ width, height }) => {
                        return (
                            <Menu
                                anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                                anchorEl={this.timeKeeperMenuAnchor.current}
                                open={tkMenuOpen}
                                onClose={() => this.onMenuClose()}
                                PaperProps={{
                                    style: {
                                        maxHeight: 48 * 8,
                                        minWidth: menuWidth,
                                        overflow: 'hidden'
                                    }
                                }}
                                disableRestoreFocus={collaborate}
                                disableBackdropClick={collaborate}
                                disableEscapeKeyDown={collaborate}
                            >
                                <TextField
                                    id={'search-with-icon-textfield'}
                                    placeholder={t('timekeeper.list.field.search.placeholder')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position={'start'}>
                                                <Search />
                                            </InputAdornment>
                                        )
                                    }}
                                    value={searchText}
                                    fullWidth={true}
                                    onChange={this.handleTkSearch}
                                    style={{
                                        padding: 5,
                                        outline: 'none'
                                    }}
                                />
                                {timeKeepers.length >= 1 ?
                                    <>
                                        <List
                                            height={timeKeepers.length >= 6 ? 48 * 6 : 48 * timeKeepers.length}
                                            rowHeight={48}
                                            rowCount={timeKeepers.length}
                                            overscanRowCount={8}
                                            width={menuWidth!}
                                            onScroll={({ clientHeight, scrollHeight, scrollTop }:
                                                { clientHeight: number; scrollHeight: number; scrollTop: number }) =>
                                                this.fetchMoreTimeKeepers(clientHeight, scrollHeight, scrollTop, workDate, matterId)}
                                            rowRenderer={({ style, key, index }) => {
                                                // created a variable to avoid build errors
                                                let tkId = this.props.appStore!.currentTimekeeper.timeKeeperId
                                                let isSelected = timeKeepers[index] && timeKeepers[index].timeKeeperId === tkId;
                                                return (
                                                    <div>
                                                        {timeKeepers[index] &&
                                                            <Styled.TKMenuItem
                                                                selected={isSelected}
                                                                onClick={(isSelected || collaborate) ? undefined
                                                                    : () => this.setTimeKeeper(timeKeepers[index].timeKeeperId)}
                                                                key={key}
                                                                style={style}
                                                            >
                                                                <Styled.NameSpan>
                                                                    {timeKeepers[index].name}
                                                                    ({timeKeepers[index].office})
                                                                    {timeKeepers[index].delegated && !collaborate ?
                                                                        <Styled.DelegatedSpan>
                                                                            {t('timekeeper.list.row.status.delegated')}
                                                                        </Styled.DelegatedSpan>
                                                                    :
                                                                        <></>
                                                                    }
                                                                </Styled.NameSpan>
                                                                {(!isSelected && collaborate) &&
                                                                    <div style={{ margin: '10px' }}>
                                                                        <Styled.CollaborateCheckbox
                                                                            checked={this.isTkSelected(
                                                                                timeKeepers[index].timeKeeperId
                                                                            )}
                                                                            onClick={evt => this.handleTkSelection(
                                                                                evt, timeKeepers[index]
                                                                            )}
                                                                        />
                                                                    </div>
                                                                }
                                                            </Styled.TKMenuItem>
                                                        }
                                                    </div>

                                                )
                                            }}
                                        />
                                    </>
                                    : <div style={{ paddingLeft: 12 }}>{t('timekeeper.list.empty')}</div>
                                }
                                {collaborate &&
                                    <>
                                        <hr style={{ marginBottom: '0px' }} />
                                        <div style={{ float: 'right' }}>
                                            <Button onClick={this.saveCollabs} disabled={!this.state.enableSave}>{t('save')}</Button>
                                            <Button onClick={this.onMenuClose}>{t('cancel')}</Button>
                                        </div>
                                    </>
                                }
                            </Menu>
                       )
                    }}
                </AutoSizer>
            </>
        );
    }
}

export default withTranslation(['common'])(TimeKeepersList);