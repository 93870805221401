import styled from 'common/styled-components';
import { Checkbox, MenuItem } from '@material-ui/core';

export const DelegatedSpan = styled.span`
    background: ${props => props.theme.approved};
    color: #FFF;
    margin-left: 19px;
    border-radius: 6px;
    padding: 0px 8px;
`;
export const NameSpan = styled.span`
    flex: 3;
    overflow: hidden;
    text-overflow: ellipsis;
`;
export const CollaborateCheckbox = styled(Checkbox)`
    flex: 1;
`;
export const TKMenuItem = styled(MenuItem)`
    padding-top: 0px !important;
    padding-bottom: 0px !important;
`;