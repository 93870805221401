import * as React from 'react';
import { useContext } from 'react';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import { observer } from 'mobx-react';
import * as Styled from './styled';
import { RootStoreContext } from '../../App';
import CustomDictionaryContainer from '../CustomDictionary/CustomDictionaryContainer';
import { Route } from 'react-router';
import Dashboard from './Dashboard';

interface Props {
    location: {
        pathname: string;
    };
    match: {
        url: string;
        path: string;
        params: {
            id: string;
        }
    };
    // tslint:disable-next-line:no-any
    t: any;
}

export default observer(function (props: Props) {
    let store = useContext(RootStoreContext).reportsStore;
    let routerStore = useContext(RootStoreContext).routerStore;
    const { match, t } = props;
    
    const onViewDashboard = (id: string) => {
        store.setDashboard(id);
        routerStore.push('/reports/dashboard');
    };

    return (
        <div>
            <Styled.Header>
                <Styled.Title variant="h6">
                    Reports
                </Styled.Title>
            </Styled.Header>
            <div style={{ background: '#fafafa', height: '100%' }}>
                {/*<Route path={`${match.path}/dashboard`} exact={true} component={Dashboard}/>*/}
                <Styled.Container>
                    <Paper style={{ padding: '15px' }}>
                        {Object.keys(store.dashboardsByCategory).map((category) => {
                            let dashboards = store.dashboardsByCategory[category];
    
                            return (
                                <div key={category}>
                                    <Typography variant="h6" style={{ fontSize: '22px', textAlign: 'center' }}>
                                        {category}
                                    </Typography>
                                    <div style={{ marginTop: '6px' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dashboards.map((dashboard) => (
                                                    <Styled.Row
                                                        key={dashboard.id}
                                                        onClick={() => onViewDashboard(dashboard.id)}
                                                    >
                                                        <TableCell>
                                                            <Styled.LinkText>{dashboard.name}</Styled.LinkText>
                                                        </TableCell>
                                                    </Styled.Row>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            );
                        })}
                    </Paper>
                </Styled.Container>
            </div>
        </div>
    );
});