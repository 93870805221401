import styled from 'styled-components'

export const OutlinedList = styled.div`
  border-radius: 5px;
  border: 1px solid #BCBCBC;
  padding: 10px;
  margin: 5px;
  margin-top: 24px;
  max-height: 218px;
  overflow: auto;
`

export const OutlinedListTitle = styled.div`
  position: absolute;
  background-color: white;
  margin-top: -20px;
  padding: 0 5px;
  font-size: smaller;
  color: #7E7E7E;
`

export const AddURLButton = styled.span`
  cursor: pointer;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`