import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    FormHelperText,
    TextField
} from '@material-ui/core';
import SplitentryDialogStore from 'store/splitEntry.dialog.store';
import * as Styled from './styled';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import DurationField from 'components/DurationField/DurationField';
import { MatterItemFormatter } from 'components/TemplateForm/TemplateForm';
import { Matter, Features } from '../../api/types/types';
import { ApiConsumer } from 'common/ApiProvider';
import RootAPI from '../../api/interfaces/RootAPI';
import { withTranslation } from 'react-i18next';

interface Props {
    dialogStore?: SplitentryDialogStore;
    // tslint:disable-next-line:no-any
    t: any;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        dialogStore: rootStore.splitEntryDialogStore
    };
})
@observer
class SplitEntryDialog extends React.Component<Props> {
    
    changeSplitNum = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.dialogStore!.setSplitNumber(Number(event.target.value));
    }
    
    setDuration = (index: number) => (duration: number) => {
        duration = (duration < 0) ? 0 : duration;
        let entries = this.props.dialogStore!.splitEntries.slice();
        let oldEntry = entries[index];
        oldEntry.actualDuration = oldEntry.duration;
        let entry = this.props.dialogStore!.entry.setMatter(oldEntry.matter);
        entry = entry.setDuration(duration);
        entries[index] = {
            duration: entry.duration,
            matter: oldEntry.matter,
            actualDuration: entry.actualDuration,
            timeEntryUnit: entry.timeEntryUnit
        };
        this.props.dialogStore!.setEntries(entries);
    }
    
    setMatter = (index: number) => async(m: Matter | null) => {
        let entries = this.props.dialogStore!.splitEntries.slice();
        let oldEntry = entries[index];
        if (m) {
            if (!m.tracked) {
                await this.props.dialogStore!.rootStore.api.Matter.track([m.id]);
            }
            m = await this.props.dialogStore!.rootStore.api.Matter.get(m.id);
        }
        let entry  = this.props.dialogStore!.entry.setMatter(m);
        entry = entry.setDuration(oldEntry.actualDuration);
        entries[index] = {
            duration: entry.duration,
            matter: entry.matter,
            actualDuration: entry.actualDuration,
            timeEntryUnit: entry.timeEntryUnit
        };
        this.props.dialogStore!.setEntries(entries);
    }

    increment = (timeEntryUnit?: string | null) => {
        if (timeEntryUnit === 'Z2') {
            return 0.25;
        } else if (timeEntryUnit === 'Z3') {
            return 0.05;
        }
        return 0.1;
    }
    
    render() {
        const { t, dialogStore } = this.props;
        let {
            isOpen,
            cancel,
            entry,
            numSplit,
            splitEntries,
            unequalDuration,
            savable,
            saving,
            wrappedSplitSave,
            tooSmallIntervals,
            filledEntries,
            invalidDuration,
            invalidTotal,
            rootStore
        } = dialogStore!;
        const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;

        return (
            <Dialog
                open={isOpen}
                onClose={cancel}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>{t('dialog.split.title')}</DialogTitle>
                <Styled.DialogContent>
                    <Styled.OriginalEntry>
                        <div>
                            {entry && entry.matter ? `${entry.matter!.number} - ${entry.matter!.name}` : ''}
                        </div>
                        <div>
                            {entry ? `${(entry.duration / 3600).toFixed(2)}` : ''}
                        </div>
                    </Styled.OriginalEntry>
                    <div>
                        <TextField
                            label={t('dialog.split.field.number_of_entries')}
                            value={numSplit}
                            type="number"
                            onChange={this.changeSplitNum}
                            inputProps={{
                                min: 2
                            }}
                        />
                    </div>
                    {tooSmallIntervals && <FormHelperText error={true}>
                        {t('dialog.split.validation.minumim.minutes', { minutes: entry ? entry.roundingInterval / 60 : 0})}
                    </FormHelperText>}
                    {numSplit === 1 && <FormHelperText error={true}>
                        {t('dialog.split.validation.minumim.entries')}
                    </FormHelperText>}
                    <ApiConsumer>
                        { (api: RootAPI) =>
                    <Styled.Entries>
                        {splitEntries.map(({duration, matter, timeEntryUnit}, index) =>
                            <Styled.EntryInput key={index}>
                                <AutoCompleteField
                                    label={t('field.matter', {matterLabel})}
                                    fetch={(searchText: string) =>
                                        api.Matter.searchMatters(searchText, true)}
                                    currentItem={matter}
                                    clearable={true}
                                    onClear={() => this.setMatter(index)(null)}
                                    formatItem={MatterItemFormatter}
                                    getItemText={(m: Matter) => `${m.number} - ${m.name}`}
                                    onSelect={this.setMatter(index)}
                                />
                                <DurationField
                                    duration={duration}
                                    increment={this.increment(timeEntryUnit)}
                                    onChange={this.setDuration(index)}
                                    error={invalidDuration}
                                    errorText={invalidDuration ? t('validation.duration.exceed_24') : ''}
                                />
                            </Styled.EntryInput>
                        )}
                    </Styled.Entries>}
                    </ApiConsumer>
                    {unequalDuration && !tooSmallIntervals && <FormHelperText error={true}>
                        {t('dialog.split.validation.total_duration.not_equal')}
                    </FormHelperText>}
                    {!filledEntries && <FormHelperText error={true}>
                        {t('dialog.split.validation.matter', { matterLabel })}
                    </FormHelperText>}
                    {invalidTotal && <FormHelperText error={true}>
                        {t('dialog.split.validation.total_duration.exceed_24')}
                    </FormHelperText>}
                </Styled.DialogContent>
                <DialogActions>
                    <Button
                        disabled={!savable || saving}
                        onClick={wrappedSplitSave}
                    >
                        {t('save', { ns: 'common' })}
                    </Button>
                    <Button onClick={cancel}>{t('cancel', { ns: 'common' })}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation(['timeentries', 'common'])(SplitEntryDialog);