import styled from 'styled-components';
import {
    CircularProgress,
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper, TextField,
    Typography,
    Fab,

} from '@material-ui/core';

export const Container = styled.div<{disabled: boolean}>`
    height: 100%;
    display: flex;
    flex-direction: column;
    ${({ disabled }) => disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`;
export const FabIcon = styled(Fab)<{isLastModifiedToday: boolean}>`
 && {
        min-width: 28px;
        border-style: ${props => props.isLastModifiedToday ? 'solid' : 'none'};
        border-width: ${props => props.isLastModifiedToday ? '2px' : '0px'};
        border-color: ${props => props.isLastModifiedToday ? 'red' : ''};
    }
`;
export const Header = styled.div`
    height: 64px;
    background-color: ${props => props.theme.primary.main};
    display: flex;
    align-items: center;
`;

export const SearchContainer = styled.div`
    flex: 1;
    padding: 12px 12px 12px 24px;
    display: flex;
    align-items: flex-end;
`;
export const SortContainer = styled.div`
    margin-right: 5px;
    border-radius: 20px;
    &:hover {
        background-color: white;
    }
`;

export const Title = styled(Typography)`
    flex: 1;
    text-align: center;
`;

export const New = styled(IconButton)`
    margin-right: 24px;  
`;

export const ContentContainer = styled.div<{expanded?: boolean}>`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - ${props => props.expanded ? '128px' : '64px'});
`;

export const ListContainer = styled(Paper)`
    flex: 1; 
    overflow: auto;
`;

export const FormContainer = styled.div`
    flex: 3;
    flex-direction: column;
    display: flex;
    margin: 10px;
    overflow: hidden;
`;

export const ButtonsContainer = styled.div`
    display: flex; 
    padding: 20px 0 6px 10px;
`;

export const ListText = styled(ListItemText)`
    
`;

export const CustomSecondaryAction = styled(ListItemSecondaryAction)`
    //display: none;
`;

export const CustomListItem = styled(ListItem)`
    && {
        padding: 0;
        margin: 0;
    }
    text-decoration: none;
`;

export const CustomListItemContent = styled.div<{selected?: boolean}>`
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 0 20px;
    &:hover{
        background: ${props => !props.selected && props.theme.white.main};
    }
    background: ${props => props.selected && props.theme.white.dark};
    transition: ${props => props.theme.animationsEnabled && 'all 0.1s'};
`;

export const ListItemContent = styled.div`
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    &:hover ${CustomSecondaryAction}{
        display: block;
    }
`;
export const TimerHeaderContainer = styled.div`
  text-align: center;
  width: 165px;
  height: 52px;
`;
export const TimerHeaderText = styled.div`
  font-size: 20px;
    padding: 10px;
    color: #656565;
`;
export const TimerHeader = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    &> div {
        margin: 4px 0px;  
      }
`;
// NavTimer
export const NavTimerContainer = styled.div<{disabled?: boolean}>`
    max-width: 280px;
    max-height: 64px;
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    &:first-child { 
        padding-left: 20px;
    }
    ${({ disabled }) => disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`;
export const NewActions = styled.div`
    flex: 1;
    text-align: right;
`;
export const TimerChunkList = styled.div`
    position: fixed;    
`;
export const EditTimeLabel = styled.div`
    margin-bottom: 10px;
`;
export const FabProgress = styled(CircularProgress)`
    position: absolute;
    top: -6;
    left: -6;
    z-index: 1;
`
export const AutoCompleteFilters = styled.div`
    flex: 2;
    padding-right: 12px;
`;
export const AdvancedHeader = styled('div')`
    height: 64px;
    background-color: ${props => props.theme.primary.main};
    display: flex;
    padding-left: 24px;
`;
export const FilterButton = styled(IconButton)`
    align-self: center;
`;