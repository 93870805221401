import React from 'react';
import { ConversationHistoryData } from '../../api/types/types';
import { Phone } from '@material-ui/icons';

interface Props {
    data: ConversationHistoryData
}

export const ConversationHistoryDescription = (props: Props) => (
    <>
        <Phone style={{fontSize: '13px'}}/>
        &nbsp;{props.data.subject}&nbsp;
    </>
);