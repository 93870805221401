import styled from 'styled-components';
import { Paper, Tab, Typography } from '@material-ui/core';

export const Container = styled(Paper)<{expanded?: boolean}>`
    transition: ${props => props.theme.animationsEnabled && 'width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1)'};
    width: ${props => props.expanded ? '280px' : '70px'};
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 10px;
`;

export const Title = styled(Typography)`
    padding: 9px;
`;

export const SummaryInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
`;

export const SummarySection = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
`;

export const SummaryLine = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 3px 0;
`;

export const MiniSummaryInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px;
`;

export const MiniSummaryLine = styled.div`
    margin: 8px 0px;
    font-size: 14px;
`;

export const ChartText = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: center;
    margin-bottom: 3px;
`;

export const StyledDoughnutContainer = styled.div`
    width: 250px;
    heigth: 120px;
    margin: 0px 10px;
`;

export const StyledTab = styled(Tab)`
    && {
        min-width: 50%;
        max-width: 100%;
        padding: 0;
        height: 30px;
    }
`;

export const Inner = styled.div`
    width: 280px;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const MiniDateContainer = styled.div<{enabled?: boolean, selected?: boolean, today?: boolean}>`
    height: 30px;
    width: 30px;
    line-height: 12px;
    border-radius: 50%;
    padding-top: 9px;
    text-align: center;
    background: ${props => props.today && props.theme.primary.light};
    background: ${props => props.selected && props.theme.secondary.light};
    color: ${props => props.selected && props.theme.white.main}
    transition: ${props => props.theme.animationsEnabled && 'all 0.3s cubic-bezier(0.55, 0, 0.1, 1)'};
`;

export const MiniDayBox = styled.div<{enabled?: boolean, selected?: boolean, today?: boolean}>`
    user-select: none;
    cursor: pointer;
    margin: 0px;
    ${props => !props.enabled && `
        color: rgba(0, 0, 0, 0.5);
        cursor: auto;
    `};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    
    &:hover ${MiniDateContainer} {
        ${props => !props.today && !props.selected && props.enabled && 'background-color: rgb(215, 215, 215);'}
    }
`;

export const MiniTimeEntryIndicator = styled.div`
    margin-top: 4px;
    height: 4px;
    width: 4px;
    background-color: ${props => props.theme.primary.main};
    border-radius: 50%;
`;

export const MiniCalenderContainer = styled.div`
    margin-top: auto;
`;

export const MiniCalInner = styled.div`
    height: auto;
`;

export const MiniCalendarDate = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
    align-items: center;
    margin: 2px;
`;
export const ChartTextItem = styled.div`
    text-align: center;
    padding: 0 12px;
`;