import { RootStore } from './root.store';
import { DialogStore, error } from '@fulcrumgt/mobx-store-utils';
import { action } from 'mobx';

export default abstract class DialogRootStore<I, O> extends DialogStore<I, O> {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        this.rootStore = rootStore;
    }

    @error() @action.bound handleError(err: {message: string}) {
        this.rootStore.onError(err.message);
    }
}
