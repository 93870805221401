import React from 'react';
import { useDrop } from 'react-dnd';
import { DragItemTypes } from 'components/DragNDropTimeCastSegment/SegmentDragSource';
import ImmutableTimeEntry, { SapStatus } from '../../api/immutables/ImmutableTimeEntry';
import { TimeEntryType } from 'api/types/types';
import { Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const NewTimeEntryDropTarget = () => {
    const { t } = useTranslation(['home']);
    let entry = new ImmutableTimeEntry();
    entry = entry.setStatus(SapStatus.UNSUBMITTED);
    entry.timeEntryType = TimeEntryType.NORMAL;
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: DragItemTypes.ELEMENT_TYPE,
        drop: (item, monitor) => (
            entry // This will become available at the dragSource in endDrag method as getDropResult.
        ),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const style: React.CSSProperties = {
        height: 75,
        backgroundColor: '#fff',
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        opacity: canDrop ? 0.5 : 0.7,
        border: '1px solid #ccc',
        display: canDrop ? 'flex' : 'none',
        transition: 'ease'
    };

    return (
        <div ref={drop} id={'DropContainer'}>
            <Paper style={style}>
                {!isOver ? t('view.day.segment.drag_and_drop.timeentry_panel.message') : ''}
            </Paper>
        </div>
    );
}

export default NewTimeEntryDropTarget;