import Template from 'api/immutables/ImmutableTemplate';
import rootStore from 'store/root.store';

// tslint:disable-next-line:no-any
export const getTemplateTooltipText = (m: Template, t: any) => {
    if (!rootStore.templateStore.templateFromMatter) {
        return m.name;
    }

    const charLength = 70;
    const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;
    const getMessage = (key: string) => t(`field.template.tooltip.${key}`, { ns: 'timeentries', matterLabel });
    // previous entry as template tooltip message
    return (m.matterName ? `${getMessage('matter')}: ${m.matterName}\n` : '') +
        (m.clientName ? `${getMessage('client')}: ${m.clientName}\n` : '') +
        (m.phaseName ? `${getMessage('phase')}: ${m.phaseName} - ${m.phaseDesc}\n` : '') +
        (m.taskCode ? `${getMessage('task')}: ${m.taskCode} - ${m.taskCodeDesc}\n`  : '') +
        (m.actCode ? `${getMessage('activity')}: ${m.actCode} - ${m.actCodeDesc}\n`  : '') +
        (m.ffTaskCode ? `${getMessage('ff_task')}: ${m.ffTaskCode} - ${m.ffTaskCodeDesc}\n`  : '') +
        (m.ffActCode ? `${getMessage('ff_activity')}: ${m.ffActCode} - ${m.ffActCodeDesc}\n`  : '') +
        (m.narrative ? `${getMessage('narrative')}: ${m.narrative.substring(0, charLength)}${m.narrative.length > charLength ? '…' : ''}` : '');
}