import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, IconButton
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import TimerPopoutErrorMessageDialogStore from 'store/Timer/timer.popout.error.message.dialog.store';
import * as Styled from './styled';
import { Info } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';

export interface PopoutErrorMessage {
    message: string,
    color: string
}
interface Props {
    timerPopoutErrorDialog?: TimerPopoutErrorMessageDialogStore;
    // tslint:disable-next-line:no-any
    t: any;
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        timerPopoutErrorDialog: rootStore.timerPopoutErrorDialogStore
    };
})
@observer
class TimerPopoutErrorDialog extends React.Component<Props> {
    cancel = () => {
        this.props.timerPopoutErrorDialog!.cancel();
    };
    render() {
        const { t, timerPopoutErrorDialog } = this.props;
        const { isOpen, errorMessages } = timerPopoutErrorDialog!;

        return(
            <Dialog
                open={isOpen}
                onClose={this.cancel}
                maxWidth="md"
                fullScreen={true}
            >
                <DialogTitle>
                    <IconButton style={{height: '50px', padding: '0px 12px'}}>
                        <Info/>
                    </IconButton>
                    <label style={{verticalAlign: 'middle'}}>{t('popout_window.dialog.error.title')}</label>
                </DialogTitle>
                <DialogContent>
                    <Styled.ErrorMessageContainer>
                    {errorMessages.length > 0 && errorMessages.length === 1 ?
                        errorMessages.map((popMsg: PopoutErrorMessage, index: number) =>
                            <React.Fragment key={index}>
                                <label style={{color: popMsg.color}}> {`${popMsg.message}`} </label>
                                <br />
                                <br />
                            </React.Fragment>
                        ) : errorMessages.map((popMsg: PopoutErrorMessage, index: number) =>
                            <React.Fragment key={index}>
                                <label style={{color: popMsg.color}}> {`${index + 1}. ${popMsg.message}`} </label>
                                <br />
                                <br />
                            </React.Fragment>
                        )
                    }
                    </Styled.ErrorMessageContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.cancel}>{t('popout_window.dialog.error.action')}</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withTranslation(['timers'])(TimerPopoutErrorDialog);