import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as Styled from './styled';
import { FormHelperText, MenuItem, TextField, Select, Checkbox, FormControlLabel } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import { ApiConsumer } from 'common/ApiProvider';
import RootAPI from 'api/interfaces/RootAPI';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import {
    ActionCode,
    Client,
    Code,
    CodeSetTemplate,
    CodeType,
    Features,
    Matter,
    NarrativeWarning,
    TimeKeeperAssignment,
    TkOfficeOfficeName,
    WorkLocale
} from 'api/types/types';
import {
    setMatter,
    setNarrative,
    setPhase,
    setTask,
    setAct,
    setFFTask,
    setFFAct,
    setActionCode,
    changeNarrativeLanguage,
    fetchPhaseCodes,
    fetchTaskCodes,
    fetchActivityCodes,
    fetchFFTaskCodes,
    fetchFFActCodes,
    fetchMatters,
    getMatterTooltipText,
    setCodeSetTemplate
} from 'util/timeEntry';
import DurationField from 'components/DurationField';
import NarrativeField from 'components/NarrativeField';
import { DateTime } from 'luxon';
import { MatterItemFormatter } from 'components/TemplateForm/TemplateForm';
import { InlineDatePicker } from 'material-ui-pickers';
import { FlexDiv } from 'common/flex';
import { StandardTextFieldProps } from '@material-ui/core/TextField';
import { ValidationState } from 'api/immutables/validators';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { getStringMatches, parseCode } from '../../util/utils';
import { isoDate, getDateFormat } from '../../util/date';
import { LinearProgressBar } from 'components/LoadingSpinner/LinearProgressBar';
import { TKConsumer } from 'common/TKProvider';
import { RootStore } from 'store/root.store';
import TimeEntryStore from 'store/timeentry.store';
import { withTranslation } from 'react-i18next';

interface Props {
    timeEntry: TimeEntry;
    onChange: (t: TimeEntry, newVState?: ValidationState, durErrState?: boolean) => void;
    disabled?: boolean;
    showDate?: boolean;
    minHeight?: number;
    durValidationState?: boolean;
    validationState?: ValidationState;
    minNarrativeLength: number;
    maxNarrativeLength: number;
    actionCodesRequired: boolean;
    includeNarrative?: boolean;
    includeDuration?: boolean;
    resetCollaborators?: () => void;
    onSetFieldLoader?: (value: boolean) => void;
    tkOfficesToShow: TkOfficeOfficeName[];
    rootStore?: RootStore;
    timeEntryStore?: TimeEntryStore;
    // tslint:disable-next-line:no-any
    t: any;
}
interface State {
    setFieldLoader: boolean;
    narrativeWarningText: string;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        rootStore: rootStore,
        timeEntryStore: rootStore.timeEntryStore
    };
})
@observer
class TimeEntryForm extends React.Component<Props, State> {
    static defaultProps = {
        includeDuration: true,
        includeNarrative: true
    }
    state = {
        setFieldLoader: false,
        narrativeWarningText: ''
    }

    matterNarrativeWarningsKeys: string[] = [];
    matterNarrativeWarningsList: NarrativeWarning[] = [];
    narrativeWarnings: Set<string> = new Set('');

    componentDidMount() {
        this.onLoad();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.timeEntry.matterId !== this.props.timeEntry.matterId) {
            this.onLoad();
        }
    }

    componentWillUnmount() {
        this.props.timeEntryStore!.setNarrativeAutoPopulate(false);
        this.props.timeEntryStore!.resetNarrativePreviousPopulatedCodes();
    }

    onLoad = async() => {
        const { timeEntry, rootStore } = this.props;
        if (timeEntry.matterId) {
            const matter = await rootStore!.api.Matter.get(timeEntry.matterId);
            if (matter && matter.narrativeWarnings) {
                this.matterNarrativeWarningsKeys = matter.narrativeWarnings.map(w => w.bannedWord);
                this.matterNarrativeWarningsList = matter.narrativeWarnings;
                if (timeEntry.narrative) {
                    const matches = getStringMatches(timeEntry.narrative, this.matterNarrativeWarningsKeys);
                    this.updateNarrativeWarnings(matches);
                }
            }
        }
    }

    _setMatter = async (m: Matter | null) => {
        const { timeEntry, onChange, validationState, resetCollaborators } = this.props;
        if (resetCollaborators) {
            resetCollaborators();
        }
        this.setState({ setFieldLoader: true });
        this.resetNarrativeWarnings();
        if (m) {
            if (m.narrativeWarnings) {
                this.matterNarrativeWarningsKeys = m.narrativeWarnings.map(w => w.bannedWord);
                this.matterNarrativeWarningsList = m.narrativeWarnings;
                if (timeEntry.narrative) {
                    const matches = getStringMatches(timeEntry.narrative, this.matterNarrativeWarningsKeys);
                    this.updateNarrativeWarnings(matches);
                }
            }
        }
        await setMatter(m, timeEntry, onChange, validationState);
        this.setState({ setFieldLoader: false });
    }

    resetNarrativeWarnings = () => {
        this.narrativeWarnings.clear();
        this.matterNarrativeWarningsKeys = [];
        this.matterNarrativeWarningsList = [];
        this.setState({ narrativeWarningText: '' });
    }

    _setNarrative = (text: string) => {
        const { timeEntry, onChange, validationState } = this.props;
        setNarrative(text, timeEntry, onChange, validationState);
        if (this.matterNarrativeWarningsKeys) {
            const matches = getStringMatches(text, this.matterNarrativeWarningsKeys);
            this.updateNarrativeWarnings(matches);
        }
    }

    updateNarrativeWarnings = (matches: string[]) => {
        const diff = matches.length - this.narrativeWarnings.size;
        if (diff === 0) {
            return;
        } else if (diff > 0) {
            matches.map((item: string) => {
                if (!this.narrativeWarnings.has(item)) {
                    this.narrativeWarnings.add(item);
                }
            });
        } else {
            Array.from(this.narrativeWarnings).map((item: string) => {
                if (!matches.includes(item)) {
                    this.narrativeWarnings.delete(item);
                }
            });
        }
        const narrativeWarningText: string[] = this.matterNarrativeWarningsList
            .filter((obj: NarrativeWarning) => obj.bannedWord.toLowerCase() === Array.from(this.narrativeWarnings).pop())
            .map((obj: NarrativeWarning) => obj.responseMessage);
        this.setState({ narrativeWarningText: narrativeWarningText[0] });
    }

    getNarrativeWarningElement = () => (
        this.state.narrativeWarningText ? (
            <p
                style={{
                    color: '#FFA500',
                    margin: 'auto'
                }}
            >
                {this.state.narrativeWarningText}
            </p>
        ) : null
    );

    setClient = (c?: Client | null) => {
        let entry = this.props.timeEntry.setClient(c);
        this.props.onChange(entry);
        this.setState({ narrativeWarningText: '' });
        this.resetNarrativeWarnings();
    }

    setWorkLocale = (w?: WorkLocale | null) => {
        let newVState;
        let entry = this.props.timeEntry.setWorkLocaleId(w ? w!.id : null);
        if (this.props.validationState) {
            newVState = this.props.validationState!.clone();
            newVState.missing.workLocale = false;
        }
        this.props.onChange(entry, newVState);
    }

    getWorkLocaleValue = (entry: TimeEntry) => {
        return this.props.timeEntryStore!.allWorkLocales!.find(x => x.id === entry.workLocaleId);
    }

    _setActionCode = (c: ActionCode | null) => {
        const { timeEntry, onChange, validationState } = this.props;
        setActionCode(c, timeEntry, onChange, validationState);
        if (timeEntry.narrative && this.matterNarrativeWarningsKeys) {
            const matches = getStringMatches(timeEntry.narrative, this.matterNarrativeWarningsKeys);
            this.updateNarrativeWarnings(matches);
        }
    }

    setDuration = (dur: number, err?: boolean) => {
        let newVState;
        dur = (dur < 0) ? 0 : dur;
        let entry = this.props.timeEntry.setDuration(dur);

        if (this.props.validationState) {
            newVState = this.props.validationState!.clone();
            newVState.zeroDuration = false;
            newVState.twentyFourDuration = false;
        }
        this.props.onChange(entry, newVState, err);
    }
    
    setWorkDate = (d: Date) => {
        const date: DateTime = DateTime.fromJSDate(d);
        let entry = this.props.timeEntry.setWorkDate(date);
        this.props.onChange(entry);
    }

    _setCodeSetTemplate = async (c: CodeSetTemplate | null) => {
        const { timeEntry, onChange, validationState } = this.props;
        setCodeSetTemplate(c, timeEntry, onChange, validationState);
        if (timeEntry.narrative && this.matterNarrativeWarningsKeys) {
            const matches = getStringMatches(timeEntry.narrative, this.matterNarrativeWarningsKeys);
            this.updateNarrativeWarnings(matches);
        }
    }

    handleOfficeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let tempOfficeName = this.props.tkOfficesToShow.filter(x => x.office === event.target.value)[0].officeName;
        // Changing both office and office name here
        let entry = this.props.timeEntry.setOffice(event.target.value).setOfficeName(tempOfficeName);
        this.props.onChange(entry);
    }

    getCheckboxMessageKey = (entry: TimeEntry) => {
        return (entry.isPhaseCode && entry.isActCode) ? 'task_activity' :
            entry.isPhaseCode ? 'task' :
                entry.isActCode ? 'activity' : 'task_activity';
    }

    handleIncludeCodesChange = (e: React.ChangeEvent, checked: boolean) => {
        const { timeEntry, timeEntryStore, onChange } = this.props;
        let entry = timeEntry.clone();
        this.props.timeEntryStore!.setNarrativeAutoPopulate(checked);
        if (checked) {
            if (entry.actCodeId && entry.actCode && entry.actCodeDesc) {
                const actObj: Code = {
                    id: entry.actCodeId,
                    name: entry.actCode,
                    description: entry.actCodeDesc,
                    type: CodeType.ACT
                };
                entry = timeEntryStore!.setNarrativeFromCodes(entry, actObj);
            }
            if (entry.taskCodeId && entry.taskCode && entry.taskCodeDesc) {
                const taskObj: Code = {
                    id: entry.taskCodeId,
                    name: entry.taskCode,
                    description: entry.taskCodeDesc,
                    type: CodeType.TASK
                };
                entry = timeEntryStore!.setNarrativeFromCodes(entry, taskObj);
            }
            onChange(entry);
        }
    }

    render() {
        const { setFieldLoader, narrativeWarningText } = this.state;
        const {
            timeEntry,
            disabled,
            showDate,
            includeNarrative,
            includeDuration,
            durValidationState,
            validationState,
            tkOfficesToShow,
            onChange,
            rootStore,
            t
        } = this.props;
        const matterLabel = rootStore!.appStore.features.EpochConfigMatterLabel;
        let conflictingBannedWords = [] as string[];
        let conflictingBlockBillingWords = [] as string[];
        let durErrTxt = '';
        let matterStatusErrText: string;
        if (validationState) {
            conflictingBannedWords = validationState.narrativeBannedWords;
            conflictingBlockBillingWords = validationState.narrativeBlockBillingWords;
            if (validationState.twentyFourDuration) {
                durErrTxt = t('validation.duration.exceed_24');
            } 
            if (validationState.zeroDuration) {
                durErrTxt = t('validation.duration.zero');
            }
        }
        if (durValidationState) {
            durErrTxt = t('validation.duration.invalid');
        }
        let bwText = (conflictingBannedWords.length > 0) ? `${conflictingBannedWords.join(', ')}` : '';
        let bbText = (conflictingBlockBillingWords.length > 0) ? `${conflictingBlockBillingWords.join(', ')}` : '';
        let narBwBbWords = bwText ? `${bwText}` + (bbText && ', ') + `${bbText}` : `${bbText}`;
        let narHelperText = (bwText || bbText) && 
            `${t('form.validation.narrative.banned_or_block_billing_words')}: ${narBwBbWords}`;
        let narrativeErrored = (validationState && validationState.narrativeLength) ||
            (validationState && validationState.maxNarrativeLength) ||
            ((conflictingBannedWords.length + conflictingBlockBillingWords.length) > 0) ||
            !!narrativeWarningText;
        let invDur = (validationState ? validationState.duration : false);
        if (validationState && validationState.narrativeLength) {
            const minLength = validationState.narrativeMinLength;
            narHelperText = t('form.validation.narrative.length.min', { minLength });
        }
        if (validationState && validationState.matterStatusInvalid) {
            matterStatusErrText = `${t('form.validation.matter.status', { matterLabel })}: ${timeEntry.matterStatusDesc}`;
        }
        if (validationState && validationState.matterEntryTypeInvalid) {
            matterStatusErrText = t('form.validation.matter.type_cost', { matterLabel });
        }
        if (validationState && validationState.maxNarrativeLength) {
            const maxLength = validationState.narrativeMaxLength;
            narHelperText = t('form.validation.narrative.length.max', { maxLength });
        }
        let increment: number = 0.1;
        if (timeEntry!.timeEntryUnit === 'Z2') {
            increment = 0.25;
        } else if (timeEntry!.timeEntryUnit === 'Z3') {
            increment = 0.05;
        }
        const officeCode = parseCode(timeEntry.office, timeEntry.officeName);
        return (
            <FeaturesConsumer>
                { (features: Features) =>
                <ApiConsumer>
                    { (api: RootAPI) =>
                <TKConsumer>
                    { (tk: TimeKeeperAssignment) =>
                        <FlexDiv direction="column">
                            <Styled.FieldContainer minHeight={this.props.minHeight}>
                                <Styled.Fields>
                                    {showDate &&
                                    <InlineDatePicker
                                        format={timeEntry ? 
                                            DateTime.fromISO(timeEntry.workDateTime).toFormat(getDateFormat())
                                            : 'MM/dd/yyyy'}
                                        onlyCalendar={true}
                                        value={timeEntry ? isoDate(DateTime.fromISO(timeEntry.workDateTime)) : ''}
                                        disabled={timeEntry && timeEntry.isPosted() || disabled || !tk.writable}
                                        onChange={this.setWorkDate}
                                        leftArrowIcon={<KeyboardArrowLeft/>}
                                        rightArrowIcon={<KeyboardArrowRight/>}
                                        TextFieldComponent={(props: StandardTextFieldProps) =>
                                            <TextField
                                                {...props}
                                                style={{justifyContent: 'flex-end'}}
                                            />
                                        }
                                    />}
                                    <AutoCompleteField
                                        label={t('field.client')}
                                        fetch={(search: string) => api.Client.searchClients(search)}
                                        currentItem={timeEntry.client ? timeEntry.client : null}
                                        getItemText={(c: Client) => parseCode(c.number, c.name)}
                                        clearable={true}
                                        onClear={() => this.setClient(null)}
                                        onSelect={this.setClient}
                                        disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                    />
                                    {features.EpochConfigTimeEntriesMattersRequired && <div>
                                        <AutoCompleteField
                                            label={t('field.matter', { matterLabel })}
                                            errorMessage={t('form.validation.matter.invalid', { matterLabel })}
                                            errored={validationState ? validationState.missing.matter : false}
                                            fetch={(searchText: string) => fetchMatters(timeEntry, features, searchText)}
                                            currentItem={timeEntry.matter}
                                            clearable={true}
                                            onClear={() => this._setMatter(null)}
                                            formatItem={MatterItemFormatter}
                                            getItemText={(m: Matter) => parseCode(m.number, m.name)}
                                            onSelect={this._setMatter}
                                            disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                            tooltip={getMatterTooltipText}
                                        />
                                        {setFieldLoader && <LinearProgressBar color={'primary'} progressBar={50}/>}
                                    </div>}
                                            {
                                                tkOfficesToShow.length > 1 ?
                                                    <Select
                                                        title={officeCode}
                                                        displayEmpty={true}
                                                        name={'select-office'}
                                                        value={timeEntry.office}
                                                        disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                                        onChange={this.handleOfficeChange}
                                                        style={{paddingTop: `16px`}}
                                                    >
                                                        {
                                                            tkOfficesToShow.map((tko: TkOfficeOfficeName) => {
                                                                const tkOfficeCode = parseCode(tko.office, tko.officeName);
                                                                return (
                                                                    <MenuItem value={tko.office} key={tko.office} title={tkOfficeCode}>
                                                                        {tkOfficeCode}
                                                                    </MenuItem>
                                                                )
                                                            })

                                                        }
                                                    </Select> : 
                                                    <TextField
                                                        title={officeCode}
                                                        label={t('form.field.office.label')}
                                                        disabled={true}
                                                        value={officeCode}
                                                    // TODO fetch offices from user, disable iff there is only one
                                                    />
                                            }
                                    {features.EpochConfigWorkLocaleEnabled &&
                                        <AutoCompleteField
                                            label={t('form.field.work_location.label')}
                                            fetch={(search: string) => api.TimeEntry.searchWorkLocales(search)}
                                            currentItem={this.getWorkLocaleValue(timeEntry)}
                                            getItemText={(w: WorkLocale) => parseCode(w.localeSearch, null)}
                                            clearable={true}
                                            onClear={() => this.setWorkLocale(null)}
                                            onSelect={this.setWorkLocale}
                                            disabled={disabled || timeEntry.isPosted() || !tk.writable || setFieldLoader}
                                            errorMessage={t('form.validation.work_location')}
                                            errored={validationState ? validationState.missing.workLocale : false}
                                        />
                                    }
                                    {/** TODO figure out when to display phase and tasks sets */}
                                    {(timeEntry.matter && !timeEntry.isPosted()) &&
                                    features.EpochConfigCodeSetTemplatesEnabled &&
                                    <AutoCompleteField
                                        label={t('form.field.code_template.label')}
                                        fetch={(searchText) => api.CodeSet.getCodeSets(timeEntry.matterId!, searchText, 0, 200)}
                                        currentItem={timeEntry.selectedCodeSetTemplate}
                                        clearable={true}
                                        disabled={timeEntry.isPosted() || disabled || !tk.writable}
                                        onClear={() => this._setCodeSetTemplate(null)}
                                        getItemText={(cs: CodeSetTemplate) => parseCode(cs.codeSetName, cs.codeSetRef)}
                                        onSelect={this._setCodeSetTemplate}
                                    />
                                    }
                                    {<>
                                        {timeEntry.isPhaseCode && <>
                                            <AutoCompleteField
                                                errorMessage={t('form.validation.phase')}
                                                errored={validationState ? validationState.missing.phase : false}
                                                label={t('form.field.phase.label')}
                                                currentItem={timeEntry.phase}
                                                clearable={true}
                                                disabled={
                                                    timeEntry.matterId === null ||
                                                    disabled ||
                                                    timeEntry.isPosted() || !tk.writable}
                                                onClear={() => setPhase(null, timeEntry, onChange, validationState)}
                                                onSelect={(c: Code) => setPhase(c, timeEntry, onChange, validationState)}
                                                fetch={(searchText) => fetchPhaseCodes(timeEntry, searchText)}
                                                getItemText={(c: Code) => parseCode(c.name, c.description)}
                                            />
                                            <AutoCompleteField
                                                errorMessage={t('form.validation.task')}
                                                errored={validationState ? validationState.missing.task : false}
                                                label={t('form.field.task.label')}
                                                currentItem={timeEntry.task}
                                                clearable={true}
                                                onSelect={(c: Code) => setTask(c, timeEntry, onChange, validationState)}
                                                onClear={() => setTask(null, timeEntry, onChange, validationState)}
                                                fetch={(searchText) => fetchTaskCodes(timeEntry, searchText)}
                                                disabled={timeEntry.phase === null ||
                                                disabled ||
                                                timeEntry.isPosted() || !tk.writable}
                                                getItemText={(c: Code) => parseCode(c.name, c.description)}
                                            />
                                        </>}
                                        {timeEntry.isActCode &&
                                        <AutoCompleteField
                                            errorMessage={t('form.validation.activity')}
                                            errored={validationState ? validationState.missing.activity : false}
                                            label={t('form.field.activity.label')}
                                            currentItem={timeEntry.activity}
                                            clearable={true}
                                            onClear={() => setAct(null, timeEntry, onChange, validationState)}
                                            onSelect={(c: Code) => setAct(c, timeEntry, onChange, validationState)}
                                            fetch={(searchText) => fetchActivityCodes(timeEntry, searchText)}
                                            disabled={!timeEntry.matterId || disabled || timeEntry.isPosted() || !tk.writable}
                                            getItemText={(c: Code) => parseCode(c.name, c.description)}
                                        />
                                        }
                                    </>}
                                    { 
                                        features.EpochConfigFlatFeeCodesEnabled &&
                                        timeEntry.isFfTaskCode && 
                                        <>
                                            <AutoCompleteField
                                                errorMessage={t('form.validation.ff_task')}
                                                errored={validationState ? validationState.missing.ffTask : false}
                                                label={t('form.field.ff_task.label')}
                                                currentItem={timeEntry.ffTask}
                                                clearable={true}
                                                onClear={() => setFFTask(null, timeEntry, onChange, validationState)}
                                                onSelect={(c: Code) => setFFTask(c, timeEntry, onChange, validationState)}
                                                fetch={(searchText) => fetchFFTaskCodes(timeEntry, searchText)}
                                                getItemText={(c: Code) => parseCode(c.name, c.description)}
                                                disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                            />
                                            <AutoCompleteField
                                                errorMessage={t('form.validation.ff_activity')}
                                                errored={validationState ? validationState.missing.ffAct : false}
                                                label={t('form.field.ff_activity.label')}
                                                currentItem={timeEntry.ffActivity}
                                                disabled={
                                                    !timeEntry.ffTaskCodeId ||
                                                    disabled ||
                                                    timeEntry.isPosted() ||
                                                    !tk.writable
                                                }
                                                clearable={true}
                                                onClear={() => setFFAct(null, timeEntry, onChange, validationState)}
                                                onSelect={(c: Code) => setFFAct(c, timeEntry, onChange, validationState)}
                                                fetch={(searchText) => fetchFFActCodes(timeEntry, searchText)}
                                                getItemText={(c: Code) => parseCode(c.name, c.description)}
                                            />
                                        </>}
                                    {
                                        this.props.actionCodesRequired &&
                                        <AutoCompleteField
                                            errorMessage={t('form.validation.action')}
                                            errored={validationState ? validationState.missing.action : false}
                                            label={t('form.field.action_code.label')}
                                            currentItem={timeEntry.actionCodeObj}
                                            disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                            clearable={true}
                                            onClear={() => this._setActionCode(null)}
                                            onSelect={this._setActionCode}
                                            fetch={(searchText ) => api.Code.getActionCodes(timeEntry.matterId!, searchText)}
                                            getItemText={(code: ActionCode) =>
                                                `${code.actionCode}${code.actionText ? `
                                                    - ${code.actionText}` : ''}`
                                            }
                                        />
                                    }
                                </Styled.Fields>
                            </Styled.FieldContainer>
                            <FlexDiv flex={1} />
                            <Styled.FieldContainer
                                minHeight={this.props.minHeight}
                                style={{display: 'grid', alignItems: 'end'}}
                            >
                                <Styled.NarrativeCheckbox>
                                    <FormControlLabel
                                        disabled={timeEntry.isPosted() || !(timeEntry.isPhaseCode || timeEntry.isActCode)}
                                        control={<Checkbox onChange={this.handleIncludeCodesChange} />}
                                        label={t(`form.checkbox.auto_populate.${this.getCheckboxMessageKey(timeEntry)}`)}
                                    />
                                </Styled.NarrativeCheckbox>
                                <Styled.BottomFields>
                                {includeDuration && <Styled.DurationFieldContainer>
                                    <DurationField
                                        duration={timeEntry!.duration}
                                        increment={increment}
                                        onChange={this.setDuration}
                                        error={invDur || durValidationState}
                                        errorText={durErrTxt}
                                        disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                    />
                                </Styled.DurationFieldContainer>}
                                {includeNarrative &&
                                    <Styled.NarrativeFieldContainer>
                                        <NarrativeField
                                            helperText={narHelperText.length > 0 ? narHelperText : this.getNarrativeWarningElement()}
                                            error={narrativeErrored}
                                            value={timeEntry.narrative}
                                            placeholder={timeEntry.billingLangText ?
                                                `${t('form.field.narrative.label')} (${timeEntry.billingLangText})`
                                                :
                                                t('form.field.narrative.label')
                                            }
                                            onChange={this._setNarrative}
                                            disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                            maxLength={features.EpochConfigNarrativesMaximumChars}
                                            dictionaryKey={timeEntry.matterBillingLangDictionary}
                                            changeLanguage={(key: string) => changeNarrativeLanguage(key, timeEntry, onChange)}
                                        />
                                    </Styled.NarrativeFieldContainer>}
                                </Styled.BottomFields>
                                {timeEntry.actionResponse &&
                                <FormHelperText error={true}>
                                    {timeEntry.actionResponse}
                                </FormHelperText>
                                }
                                {matterStatusErrText &&
                                <FormHelperText error={true}>
                                    {matterStatusErrText}
                                </FormHelperText>
                                }
                            </Styled.FieldContainer>
                            </FlexDiv>}
                </TKConsumer>}
                    </ApiConsumer>
                }
            </FeaturesConsumer>
        );
    }
}

export default withTranslation(['timeentries'])(TimeEntryForm);