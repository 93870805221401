import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import ImmutableTimeEntry, { SapStatus } from '../../api/immutables/ImmutableTimeEntry';
import { parseCode } from '../../util/utils';
import { PdfFormatType } from '../../api/types/types';
import { DateTime } from 'luxon';
import { getDailyTotals, getGrandTotals } from '../../util/SaveAsTimeEntryPDF';
import rootStore from 'store/root.store';
import { withTranslation } from 'react-i18next';

interface Props {
    timeEntriesMap: Map<string, ImmutableTimeEntry[]>,
    ffConfigEnabled: boolean,
    actionEnabled: boolean,
    exportType: string,
    timeKeeper: string,
    // tslint:disable-next-line:no-any
    t: any
}
class PrintAsTimeEntryPdfExport extends Component<Props> {
    
    render() {
        const { timeEntriesMap, actionEnabled, ffConfigEnabled, exportType, t } = this.props;
        let narrativeColSpan = 10;
        if (ffConfigEnabled && actionEnabled) {
            narrativeColSpan = 11;
        }
        if (actionEnabled && !ffConfigEnabled) {
            narrativeColSpan = 9;
        }
        const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;

        return (
            <form id={'printjs-form'}>
                <Typography variant={'h1'}>
                    Epoch
                </Typography>
                <Table id={'printjs-form'} className={'pdfTable'}>
                    <TableHead>
                        <TableCell colSpan={2} className={'timeKeeper'}>
                            {this.props.timeKeeper}
                        </TableCell>
                        <TableRow>
                            {exportType !== PdfFormatType.DATETYPE &&
                            <TableCell>
                                {t('export.pdf.table.row.header.date')}
                            </TableCell>
                            }
                            {exportType !== PdfFormatType.CLIENTTYPE &&
                            <TableCell>
                                {t('export.pdf.table.row.header.client')}
                            </TableCell>
                            }
                            {exportType !== PdfFormatType.MATTERTYPE &&
                            <TableCell>
                                {t('export.pdf.table.row.header.matter', { matterLabel })}
                            </TableCell>
                            }
                            <TableCell>
                                {t('export.pdf.table.row.header.office')}
                            </TableCell>
                            <TableCell>
                                {t('export.pdf.table.row.header.phase')}
                            </TableCell>
                            <TableCell>
                                {t('export.pdf.table.row.header.task')}
                            </TableCell>
                            <TableCell>
                                {t('export.pdf.table.row.header.activity')}
                            </TableCell>
                            <TableCell>
                                {t('export.pdf.table.row.header.hours')}
                            </TableCell>
                            <TableCell>
                                {t('export.pdf.table.row.header.status')}
                            </TableCell>
                            {ffConfigEnabled &&
                            <TableCell>
                                {t('export.pdf.table.row.header.ff_task')}
                            </TableCell>
                            }
                            {ffConfigEnabled &&
                            <TableCell>
                                {t('export.pdf.table.row.header.ff_activity')}
                            </TableCell>
                            }
                            {actionEnabled &&
                            <TableCell>
                                {t('export.pdf.table.row.header.action_code')}
                            </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...timeEntriesMap.entries()].map(([grpHeader, entries]) => (
                            <>
                                <TableRow className={'tableRow'}>
                                    <TableCell colSpan={narrativeColSpan} className={'grpHeader'}>
                                        {grpHeader}
                                    </TableCell>
                                </TableRow>
                                {entries.map((te) => (
                                    <>
                                        <TableRow className={'tableRow'}>
                                            {exportType !== PdfFormatType.DATETYPE &&
                                            <TableCell>
                                                {DateTime.fromISO(te.workDateTime).toFormat('DDDD')}
                                            </TableCell>
                                            }
                                            {exportType !== PdfFormatType.CLIENTTYPE &&
                                            <TableCell>
                                                {parseCode(te.clientNumber, te.clientName)}
                                            </TableCell>
                                            }
                                            {exportType !== PdfFormatType.MATTERTYPE &&
                                            <TableCell>
                                                {parseCode(te.matterNumber, te.matterName)}
                                            </TableCell>
                                            }
                                            <TableCell>
                                                {parseCode(te.office, te.officeName)}
                                            </TableCell>
                                            <TableCell>
                                                {parseCode(te.phaseName, te.phaseDesc)}
                                            </TableCell>
                                            <TableCell>
                                                {parseCode(te.taskCode, te.taskCodeDesc)}
                                            </TableCell>
                                            <TableCell>
                                                {parseCode(te.actCode, te.actCodeDesc)}
                                            </TableCell>
                                            <TableCell>
                                                {(te.duration / 3600).toFixed(2).toString()}
                                            </TableCell>
                                            <TableCell>
                                                {this.status(te.sapStatus, t)}
                                            </TableCell>
                                            {ffConfigEnabled &&
                                            <TableCell>
                                                {parseCode(te.ffTaskCode, te.ffTaskCodeDesc)}
                                            </TableCell>}
                                            {ffConfigEnabled &&
                                            <TableCell>
                                                {parseCode(te.ffActCode, te.ffActCodeDesc)}
                                            </TableCell>}
                                            {actionEnabled && 
                                            <TableCell>
                                                {te.actionCode}
                                            </TableCell>}
                                        </TableRow>
                                        {te.narrative &&
                                        <TableRow className={'tableRow'} style={{textAlign: 'left'}}>
                                            <TableCell colSpan={narrativeColSpan}>
                                                {te.narrative}
                                            </TableCell>
                                        </TableRow>
                                        }
                                    </>
                                ))}
                                <TableRow className={'tableRow'}>
                                    <TableCell colSpan={4} />
                                    <TableCell colSpan={2} className={'totals'}>
                                        {exportType === PdfFormatType.DATETYPE ? t('export.pdf.table.row.total.daily') :
                                            exportType === PdfFormatType.MATTERTYPE ? t('export.pdf.table.row.total.matter', { matterLabel }) :
                                                exportType === PdfFormatType.CLIENTTYPE && t('export.pdf.table.row.total.client')
                                        }
                                    </TableCell>
                                    <TableCell colSpan={1} className={'totals'}>
                                        {getDailyTotals(entries).toFixed(2)}
                                    </TableCell>
                                    <TableCell colSpan={3}/>
                                </TableRow>
                            </>
                        ))}
                        <TableRow className={'tableRow'}>
                            <TableCell colSpan={4} />
                            <TableCell colSpan={2} className={'totals'}>{t('export.pdf.table.row.footer.final_total')} </TableCell>
                            <TableCell colSpan={1} className={'totals'}>
                                {getGrandTotals(timeEntriesMap).toFixed(2)}
                            </TableCell>
                            <TableCell colSpan={3}/>
                        </TableRow>
                    </TableBody>
                </Table>
            </form>
        );
    }

    // tslint:disable-next-line:no-any
    private status(sapStatus: SapStatus, t: any) {
        switch (sapStatus) {
            case SapStatus.QUEUED:
                return t('export.pdf.table.column.status.posted');
                break;
            case SapStatus.SUBMITTED:
                return  t('export.pdf.table.column.status.posted');
                break;
            case SapStatus.UNSUBMITTED:
                return  t('export.pdf.table.column.status.draft');
                break;
            default:
                return '';
        }
    }
    
}

export default withTranslation(['timeentries'])(PrintAsTimeEntryPdfExport);