import styled from 'common/styled-components';
import { TableRow, Typography } from '@material-ui/core';

export const Row = styled(TableRow)`
    &:hover {
        background: rgb(255, 242, 219);
    }
    cursor: pointer;
    height: 40px;
`;

export const Title = styled(Typography)`
    flex: 1;
    text-align: center;
`;

export const Header = styled.div`
    height: 64px;
    background-color: ${props => props.theme.primary.main};
    display: flex;
    align-items: center;
`;

export const LinkText = styled.div`
    color: #2728f2;
`;

export const Container = styled.div`
    margin: 20px 20%;
`;

export const SupersetContainer = styled.div`
    width: 100% !important;
    height: 100% !important;
    & iframe {
        width: 100% !important;
        height: 100% !important;
        border: 0;
    }
`;
