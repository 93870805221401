import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import HomeStore from 'store/home.store';
import * as Styled from './styled.mobile';
import Calendar from 'components/Calendar';
import { DateTime } from 'luxon';
import { getStartOf, Mode } from 'components/Calendar/Calendar';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Add, ArrowDropDown, ArrowDropUp, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { ViewMode } from './Home.desktop';
import { withTranslation } from 'react-i18next';
import { i18n } from 'i18next';

interface Props {
    homeStore?: HomeStore;
    // tslint:disable-next-line:no-any
    t: any;
    i18n: i18n;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        homeStore: rootStore.homeStore
    };
})
@observer
class HomeDesktop extends React.Component<Props> {
    getCalendarText = () => {
        const {
            calendarMode,
            mainCalendarDate,
        } = this.props.homeStore!;
        switch (calendarMode) {
            case ViewMode.MONTH:
                return mainCalendarDate.toFormat('LLLL y');
            case ViewMode.WEEK:
                const start = getStartOf(mainCalendarDate, 'week');
                return `${start.toLocaleString()} 
                - ${start.plus({ days: 6 }).toLocaleString()}`;
            default:
                return mainCalendarDate.toLocaleString();
        }
    };
    
    onCellClick = (date: DateTime) => {
        const { setSelectedDates } = this.props.homeStore!;
        setSelectedDates([date]);
    };

    calendarCell = (date: DateTime) => {
        const { mainCalendarDate, selectedDates } = this.props.homeStore!;
        let loc = DateTime.local();
        return (
            <Styled.DayBox
                enabled={date.month === mainCalendarDate.month}
                onMouseDown={_ => this.onCellClick(date)}
            >
                <Styled.DateContainer 
                    selected={selectedDates.findIndex((sd) => sd.startOf('day').equals(date.startOf('day'))) > -1}
                    today={date.startOf('day').equals((DateTime.utc(loc.year, loc.month, loc.day).startOf('day')))}
                >
                    {date.day}
                </Styled.DateContainer>
                <div style={{height: 'auto'}}>
                    <Styled.TimeEntryIndicator />
                </div>
            </Styled.DayBox>
        );
    };

    render() {
        const { t, homeStore } = this.props;
        const {
            mainCalendarDate,
            calendarMode,
            changeViewMode,
            stepMainDate
        } = homeStore!;
    
        return (
            <Styled.Container direction="column">
                <AppBar position="static" elevation={0}>
                    <Toolbar>
                        <IconButton
                            onClick={() => stepMainDate(-1)}
                            color="secondary"
                            aria-label={t('pagination.previous')}
                        >
                            <KeyboardArrowLeft />
                        </IconButton>
                        <Typography variant="h6" style={{width: '160px', textAlign: 'center'}}>
                            {this.getCalendarText()}
                        </Typography>
                        <IconButton
                            onClick={() => stepMainDate(1)}
                            color="secondary"
                            aria-label={t('pagination.next')}
                        >
                            <KeyboardArrowRight />
                        </IconButton>
                        <Styled.NewEntryNavButton>
                            {/* {withinEditableRange(compareDate) &&  */}
                            <IconButton
                                color="secondary"
                                aria-label={t('add')}
                                // onClick={this.newDialogEntryFromSelected}
                            >
                                {
                                    <Add />
                                }
                            </IconButton>
                            {/* } */}
                        </Styled.NewEntryNavButton>
                    </Toolbar>
                </AppBar>
                <Styled.CalendarContainer shrink={calendarMode === ViewMode.WEEK}>
                {calendarMode !== ViewMode.DAY && (
                    <Calendar
                        currentDate={mainCalendarDate}
                        cellComponent={this.calendarCell}
                        alignDays="center"
                        mode={
                            calendarMode === ViewMode.MONTH
                                ? Mode.MONTH
                                : Mode.WEEK
                        }
                    />
                )}
                </Styled.CalendarContainer>
                <Styled.ViewChanger 
                    onClick={() => calendarMode === ViewMode.MONTH 
                        ? changeViewMode(ViewMode.WEEK) 
                        : changeViewMode(ViewMode.MONTH)}
                >
                    {calendarMode === ViewMode.MONTH
                        ? <ArrowDropUp />
                        : <ArrowDropDown />
                    }
                    
                </Styled.ViewChanger>
            </Styled.Container>
        );
    }
}

export default withTranslation(['common'])(HomeDesktop);