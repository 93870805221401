import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Search } from '@material-ui/icons';
import { CircularProgress, IconButton, Input } from '@material-ui/core';
import * as Styled from './styled';
import { MatterListType } from '../../api/types/types';
import MatterList from 'containers/Matters/MatterList';
import { TKConsumer } from 'common/TKProvider';
import { TimeKeeperAssignment } from 'api/types/types';
import { withTranslation } from 'react-i18next';
interface Props {
    title?: string; // Label name on Header
    matters: MatterListType[]; // List to fetch
    fetchMore: () => void;
    isTrackedList: boolean;
    selected: number[];
    onSelect?: (ids: number[]) => void;
    track?: () => void;
    untrack: (ids: number[]) => void;
    loading: boolean;
    search?: string;
    onInputChange: (val: string) => void;
    // tslint:disable-next-line:no-any
    t: any;
}

@observer
class MatterContainer extends Component<Props> {
    render() {
        const {
            title,
            matters,
            fetchMore,
            isTrackedList,
            selected,
            onSelect,
            track,
            untrack,
            loading,
            search,
            onInputChange,
            t
        } = this.props;
        return (
            <TKConsumer>
            { (tk: TimeKeeperAssignment) => 
            <Styled.Container>
                <Styled.Header container={true}>
                    <Styled.HeaderChild flex={8}>
                        <Search style={{ marginRight: '6px' }}/>
                        <Input
                            placeholder={title}
                            fullWidth={true}
                            style={{ maxWidth: '80%' }}
                            value={search}
                            onChange={e => onInputChange(e.target.value)}
                        />
                    </Styled.HeaderChild>
                    <Styled.HeaderChild flex={1}>
                        {/*<Styled.MatterHeaderLabel variant="h6">*/}
                            {/*{title}*/}
                        {/*</Styled.MatterHeaderLabel>*/}
                        {!isTrackedList && tk.writable &&
                        <IconButton title={t('right_panel.header.icon.tooltip')} onClick={track}>
                            <Styled.TrackBtn/>
                        </IconButton>}
                    </Styled.HeaderChild>
                </Styled.Header>
                {loading ? <Styled.Loading>
                    <CircularProgress size={100} />
                </Styled.Loading> :
                <MatterList
                    matters={matters}
                    fetchMore={fetchMore}
                    isTrackedList={isTrackedList}
                    selected={selected}
                    onSelect={onSelect}
                    untrack={untrack}
                />
                }
            </Styled.Container>
            }
            </TKConsumer>
        );
    }
}

export default withTranslation(['matters'])(MatterContainer);
